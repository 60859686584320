import { CloseOutlined } from "@ant-design/icons";
import { Button, Spin, Tooltip, Input } from "antd";
import { InputLinkedin, SearchContainer, Email, InputWrapper, InputEmail, Container, InputHelperMessage } from "./Search.styled";
import { useMemo, useState } from "react";


const Search = ({
  handleLinkedinOnChange,
  loading,
  handleRemoveEmail,
  handleAddEmail,
  emails,
  linkedin,
  phones,
  handleSearch,
  countryCode,
  handleCountryCode,
  handleAddPhoneNumber,
  handleRemovePhoneNumber,
}) => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const disabledSearch = useMemo(() => {
    // countryCode.length > 1 - this check is due to '+' always being prefixed programmatically
    return !(!!linkedin?.length || !!emails?.length || (!!phones?.length && countryCode.length > 1));
  }, [linkedin, emails, phones, countryCode]);

    return (
      <SearchContainer>
        {loading ? 
          <Spin />
        :
          <>
            <InputWrapper>
              <InputLinkedin
                value={linkedin}
                placeholder="Enter LinkedIn URL"
                onChange={handleLinkedinOnChange}
              />
            </InputWrapper>
            <br />
            <Container>
              {emails.length ? (
                emails.map((email, index) => {
                  return (
                    <div key={index}>
                      <Email
                      >
                        {email}
                        <CloseOutlined
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          onClick={() => handleRemoveEmail(index)}
                        />
                      </Email>
                    </div>
                  );
                })
              ) : (
                <InputHelperMessage>
                  Entered emails will be displayed here.
                </InputHelperMessage>
              )}
            </Container>
            <br />
            <InputWrapper>
              <InputEmail
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
              />
              <Button disabled={!email.length} onClick={() => {handleAddEmail(email)}}>Add</Button>
            </InputWrapper>
            <br />
            <Container>
              {phones.length ? (
                phones.map((phone, index) => {
                  return (
                    <div key={index}>
                      <Email
                      >
                        {phone}
                        <CloseOutlined
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          // can use currying here or dataset attributes
                          onClick={() => handleRemovePhoneNumber(index)}
                        />
                      </Email>
                    </div>
                  );
                })
              ) : (
                <InputHelperMessage>
                  Entered phone numbers will be displayed here.
                </InputHelperMessage>
              )}
            </Container>
            <br />
            <InputWrapper>
              <InputEmail
                placeholder="Enter Phone Number w/o Country Code"
                value={phone}
                onChange={(e) => setPhone(e.target.value.trim().toLowerCase())}
              />
              <Button disabled={!phone.length} onClick={() => {handleAddPhoneNumber(phone)}}>Add</Button>
            </InputWrapper>
            <br />
            <InputWrapper>
              <Input
                addonBefore="Country Code"
                value={countryCode} 
                onChange={handleCountryCode}
                placeholder="Enter the Country Code"
              />
            </InputWrapper>
            <br />
            <div>
              <Tooltip
                overlayStyle={{
                  maxWidth: "120px",
                  fontSize: "10px",
                }}
                placement="left"
                title="Please enter linkedIn URL/Email/Phone to search."
              >
                <Button type="primary" disabled={disabledSearch} onClick={handleSearch}>
                  Search
                </Button>
              </Tooltip>
            </div>
          </>
        }
      </SearchContainer>
    );
}

export default Search;