import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import SortIcon from "@mui/icons-material/Sort";
import { Autocomplete, Box, Button, Chip, CircularProgress, Divider, FormControl, IconButton, InputLabel, MenuItem, Popover, Select, Stack, TextField, Typography } from "@mui/material";
import { message } from "antd";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import DateRange from "../../../components/date-and-time/DateRange";
import { url } from "../../../url";
import { FILTER_OPTIONS, SORT_OPTIONS } from "../customer-dashboard-utils";

const Toolbar = ({
    toolbarData,
    setToolbarData,
    filterObject,
    sortObject,
    handleSortingValueChange,
    handleFilterValueChange,
    handleFilterFieldChange,
    handleSortingFieldChange,
    handleFilterDelete,
    handleSortingFieldDelete,
    filterField,
    sortingField,
}) => {
    const [customersData, setCustomersData] = useState({ isLoading: false, data: [] });
    const [openFilterDialog, setOpenFilterDialog] = useState(false);
    const [openSortDialog, setOpenSortDialog] = useState(false);
    const filterButtonRef = useRef(null);
    const sortButtonRef = useRef(null);

    // Handle filter dialog open/close
    const handleOpenFilterDialog = () => {
        setOpenFilterDialog(true);
    };

    const handleCloseFilterDialog = () => {
        setOpenFilterDialog(false);
    };

    const handleOpenSortDialog = () => {
        setOpenSortDialog(true);
    };

    const handleCloseSortDialog = () => {
        setOpenSortDialog(false);
    };

    const handleDateRangeChange = (newValue) => {
        setToolbarData((preToolbarData) => ({ ...preToolbarData, dateRange: newValue }));
    };

    const fetchCustomers = async () => {
        try {
            setCustomersData({ isLoading: true, data: [] });
            const response = await axios.get(url.customersAPI);
            const customersList = Object.entries(response?.data?.response).map(([accountId, customerData]) => {
                return { value: accountId, label: customerData?.company_name, userId: customerData?.users?.[0] };
            });
            setCustomersData({ isLoading: false, data: customersList });
        } catch (error) {
            setCustomersData({ isLoading: false, data: [] });
            message.error(error?.response?.data?.data?.message || "Failed to load customers!");
        }
    };
    const handleCustomerChange = (event, newCustomer) => {
        console.log(newCustomer);
        setToolbarData((preToolbarData) => ({ ...preToolbarData, accountId: newCustomer?.value }));
    };

    useEffect(() => {
        fetchCustomers();
    }, []);
    return (
        <Box
            sx={(theme) => ({
                position: "sticky",
                top: theme.custom.top.level2,
                zIndex: theme.zIndex.navbar,
                height: theme.custom.heights.filterbar,
                boxSizing: "border-box",
                pl: 3,
                pr: 3,
                borderBlockEnd: theme.palette.border.primary,
                backgroundColor: theme.palette.background.default,
            })}
        >
            <Box height={1} display={"flex"} alignItems={"center"}>
                <DateRange dateRange={toolbarData?.dateRange} onChange={handleDateRangeChange} disableTime/>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Autocomplete
                    sx={{ ml: 2, mr: 2, width: "15rem" }}
                    options={customersData?.data || []}
                    getOptionLabel={(option) => option?.label || ""}
                    onChange={handleCustomerChange}
                    loading={customersData?.isLoading}
                    disablePortal
                    ListboxProps={{
                        sx: {
                            maxHeight: "300px",
                            overflowY: "auto",
                        },
                    }}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={
                                <>
                                    <Typography
                                        component="span"
                                        sx={{
                                            color: "warning.main", // Warning color from theme
                                            display: "inline-flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        Customer&nbsp;
                                        <Typography component="span" sx={{ color: "error.main" }}>
                                            *
                                        </Typography>
                                    </Typography>
                                </>
                            }
                            InputProps={{
                                ...params.InputProps,

                                endAdornment: (
                                    <>
                                        {customersData?.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                    renderOption={(props, option, { selected }) => {
                        const { key, ...optionProps } = props;
                        return (
                            <li key={option.value} {...optionProps}>
                                <Stack>
                                    <Typography variant="body1">{option.label}</Typography>
                                    <Typography color="text.secondary" variant="body2">
                                        {option.value}
                                    </Typography>
                                </Stack>
                            </li>
                        );
                    }}
                />
                <Divider orientation="vertical" variant="middle" flexItem />

                <Box display={"flex"} flexDirection={"column"} justifyContent={"space-around"} sx={{ ml: 2 }}>
                    <Button
                        ref={filterButtonRef}
                        variant="text"
                        onClick={handleOpenFilterDialog}
                        sx={{ marginRight: "1rem", border: "none", background: "none", fontWeight: "bold" }}
                        startIcon={<FilterListIcon />} // Add Filter Icon here
                    >
                        Filter
                    </Button>
                    <Button
                        ref={sortButtonRef}
                        variant="text"
                        onClick={handleOpenSortDialog}
                        sx={{ marginRight: "1rem", border: "none", background: "none", fontWeight: "bold" }}
                        startIcon={<SortIcon />} // Add Sort Icon here
                    >
                        Sort
                    </Button>
                </Box>
                {(Object.keys(filterObject).length > 0 || Object.keys(sortObject).length > 0) && (
                    <Divider orientation="vertical" variant="middle" flexItem />
                )}

                <Stack sx={{ ml: 2, height: "100%", p: 1, flexWrap: "wrap", flex: "1 1 30%" }} direction="row" spacing={1}>
                    {Object.keys(filterObject).map((field) => {
                        return (
                            <Chip
                                key={field}
                                label={`${FILTER_OPTIONS?.[field]?.label}: ${filterObject?.[field]}`}
                                onDelete={handleFilterDelete(field)}
                                size="small"
                                sx={{
                                    fontSize: "0.75rem", // Adjust font size
                                    height: 20, // Reduce height
                                    padding: "0 4px", // Adjust padding
                                    "& .MuiChip-deleteIcon": {
                                        fontSize: "0.9rem", // Adjust delete icon size
                                    },
                                }}
                            />
                        );
                    })}
                    {Object.keys(sortObject).map((field) => {
                        return (
                            <Chip
                                key={field}
                                label={`${SORT_OPTIONS?.[field]?.label}: ${sortObject?.[field]}`}
                                onDelete={handleSortingFieldDelete(field)}
                                size="small"
                                sx={{
                                    fontSize: "0.75rem", // Adjust font size
                                    height: 20, // Reduce height
                                    padding: "0 4px", // Adjust padding
                                    "& .MuiChip-deleteIcon": {
                                        fontSize: "0.9rem", // Adjust delete icon size
                                    },
                                }}
                            />
                        );
                    })}
                </Stack>
            </Box>

            {/* Popper for Filter Dialog */}
            <Popover
                open={openFilterDialog}
                anchorEl={filterButtonRef.current}
                onClose={handleCloseFilterDialog}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                sx={{ zIndex: 1300 }}
            >
                <Box sx={{ backgroundColor: "background", borderRadius: 1, display: "flex", gap: 1, padding: 1 }}>
                    <IconButton onClick={handleCloseFilterDialog} sx={{ color: "text.secondary", alignSelf: "flex-end" }}>
                        <CloseIcon />
                    </IconButton>

                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                        <InputLabel>Field</InputLabel>
                        <Select width="5rem" variant="standard" value={filterField} onChange={handleFilterFieldChange}>
                            {Object.keys(FILTER_OPTIONS).map((key) => (
                                <MenuItem key={key} value={key}>{FILTER_OPTIONS?.[key]?.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        sx={{ minWidth: 120 }}
                        inputProps={{ maxLength: 100 }}
                        variant="standard"
                        label="Value"
                        value={filterObject?.[filterField] || ""}
                        onChange={handleFilterValueChange}
                        fullWidth
                        placeholder={FILTER_OPTIONS?.[filterField]?.placeholder || ""}
                    />
                </Box>
            </Popover>

            {/* Popper for Sort Dialog */}
            <Popover
                anchorEl={sortButtonRef.current}
                open={openSortDialog}
                onClose={handleCloseSortDialog}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
                <Box sx={{ backgroundColor: "background", p: 1, borderRadius: 1, display: "flex", gap: 1 }}>
                    <IconButton onClick={handleCloseSortDialog} sx={{ color: "text.secondary", alignSelf: "flex-end" }}>
                        <CloseIcon />
                    </IconButton>

                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                        <InputLabel>Field</InputLabel>
                        <Select width="5rem" variant="standard" value={sortingField} onChange={handleSortingFieldChange}>
                            <MenuItem value="click_date">EP Opened At</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                        <InputLabel>Order</InputLabel>
                        <Select
                            width="5rem"
                            variant="standard"
                            value={sortObject?.[sortingField] || ""}
                            onChange={handleSortingValueChange}
                        >
                            <MenuItem value="asc">ASC</MenuItem>
                            <MenuItem value="desc">DESC</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Popover>
        </Box>
    );
};

export default Toolbar;
