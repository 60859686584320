import React from "react";
import Header from "../Header/Header";
import TabsHomePage from "../Home/TabsHomePage";

const CustomerDashboard = ({loggedInUserDetails, logoutUser}) => {
  return (
    <div>
      <Header loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser}/>
      <h3 className="text-center mt-5"><span style={{color: "#4798FE", fontWeight:"600"}}>FINDEM</span> DATA RESEARCH APPLICATION</h3>
      <div className="pe-5 ps-5">
        <TabsHomePage />
      </div>
      <div className="pe-5 ps-5">
        <div className="mt-4 mb-4 total-data-container">
          <div className="row h-100 w-100">
              <div className="col-12 d-flex justify-content-center align-items-center">
                  <p>Customers dashboard</p>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDashboard;
