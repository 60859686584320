import AdsClickIcon from "@mui/icons-material/AdsClick";
import { Box, Button, Card, Dialog, DialogActions, DialogContent, Divider, Grid, Stack, Typography, useTheme } from "@mui/material";
import { Form, message } from "antd";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import dayjs from "dayjs";
import { url } from "../../url";
import { LEADERSHIP_CONST } from "../../utils/constants";
import DataEditor from "../Common/editor/DataEditor";
import DiffDataEditor from "../Common/editor/DiffDataEditor";
import ProfileCreatorForm from "../DummyProfileCreator/ProfileCreatorForm";
import { reverseTransformObject } from "../DummyProfileCreator/utils";
import FindemLogo from "../utils/FindemLogo/FindemLogo";
import CustomFormToolbar from "./components/CustomFormToolbar";
import FilterToolbar from "./components/FilterToolbar";
import MappedProfileContainer from "./components/MappedProfileContainer";
import { EDITOR_LANGUAGES } from "../Common/editor/editor-utils";
import { useFeatureFlags } from "../../customHooks/useFeatureFlags";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const { PROFILE_STATUS } = LEADERSHIP_CONST;
const LeadershipProfile = ({ loggedInUserDetails, logoutUser }) => {
    const theme = useTheme();
    const [form] = Form.useForm();
    const [cxoProfiles, setCxoProfiles] = useState({ isLoading: true, data: [] });
    const [filterQuery, setFilterQuery] = useState();
    const [page, setPage] = useState(-1);
    const [hasMoreCxoProfiles, setHasMoreCxoProfiles] = useState(true);
    const [selectedProfile, setSelectedProfile] = useState();
    const [lastUpdatedProfile, setLastUpdatedProfile] = useState();
    const [isProfileLoading, setIsProfileLoading] = useState(false);
    const [submitWithError, setSubmitWithError] = useState(false);
    const [isJsonView, setIsJsonView] = useState(false);
    const [isUpdatedView, setIsUpdatedView] = useState(false);
    const [currentFormPath, setCurrentFormPath] = useState("");
    const [tasksCompletedForToday, setTasksCompletedForToday] = useState(0);
    const [openDiffViewer, setOpenDiffViewer] = useState(false);
    const editorRef = useRef(null);

    const fetchCxoProfiles = async (resetPage) => {
        try {
            if (resetPage) {
                setHasMoreCxoProfiles(false);
                setCxoProfiles({ isLoading: true, data: [] });
            }
            const response = await axios.get(url.leadershipProfile.getProfiles, {
                params: { page: resetPage ? 0 : page + 1, filterQuery: filterQuery },
            });
            if (response.data.data.length === 0) {
                setHasMoreCxoProfiles(false);
                return;
            }
            if (resetPage) {
                setCxoProfiles({ isLoading: false, data: response?.data?.data });
                setTasksCompletedForToday(response?.data?.tasksCompletedForToday);
                if (response?.data?.data?.length < 10) {
                    fetchCxoProfiles();
                }
            } else {
                setCxoProfiles({ isLoading: false, data: [...cxoProfiles?.data, ...response.data.data] });
            }

            setPage(resetPage ? 0 : page + 1);
            setHasMoreCxoProfiles(true);
        } catch (error) {
            setCxoProfiles({ ...cxoProfiles, isLoading: false });
            console.log("Failed to fetch profiles:", error);
            message.error("Failed to fetch profiles!");
        }
    };

    const updateProfileDataPostAction = async () => {
        const id = selectedProfile?._id;
        setSelectedProfile();
        try {
            const response = await axios.get(url.leadershipProfile.completedTasksForToday);
            setTasksCompletedForToday(response?.data?.tasksCompletedForToday);
        } catch (error) {
            console.log("Failed to get tasks done for today", error);
            message.error("Failed to get tasks done for today!");
        }
        try {
            setIsJsonView(false);
            setIsUpdatedView(false);
            setCurrentFormPath("");
            const response = await axios.get(url.leadershipProfile.getProfile, { params: { id: id } });
            const filteredProfile = prepareProfileForEdit(response?.data?.data);
            setCxoProfiles((prevCxoProfiles) => {
                const updatedData = prevCxoProfiles.data.map((profile) =>
                    profile.profile_id === response?.data?.data?.profile_id ? response?.data?.data : profile
                );
                return { ...prevCxoProfiles, data: updatedData };
            });
            setSelectedProfile(filteredProfile);
            setIsProfileLoading(false);
        } catch (error) {
            console.log("Failed while getting updated profile!", error);
            message.error("Failed while getting updated profile!");
        }
    };

    const openProfileCreator = async (profile) => {
        try {
            setSelectedProfile();
            setIsProfileLoading(true);
            setIsJsonView(false);
            setIsUpdatedView(false);
            setCurrentFormPath("");
            const response = await axios.get(url.leadershipProfile.getProfile, { params: { id: profile._id } });
            const filteredProfile = prepareProfileForEdit(response?.data?.data);
            setSelectedProfile(filteredProfile);
            setIsProfileLoading(false);
        } catch (error) {
            console.log("Failed to get profile:", error);
            message.error("Failed to get profile!");
            setIsProfileLoading(false);
        }
    };

    const handlePostCreatedProfile = async (createdProfile) => {
        try {
            setIsProfileLoading(true);
            const response = await axios.post(url.leadershipProfile.createProfile, {
                newPublicProfile: createdProfile,
                profileId: selectedProfile?.profile_id,
                companyName: selectedProfile?.company_name,
                submitWithError: submitWithError,
            });
            message.success(response?.data?.message);
            updateProfileDataPostAction();
        } catch (error) {
            console.log("Failed to create new profile:", error);
            if (error?.response?.data?.message) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Failed to create new profile!");
        }
    };
    const handlePostUpdatedProfile = async (updatedProfile) => {
        try {
            const response = await axios.post(url.leadershipProfile.updateProfile, {
                updatedProfile: updatedProfile,
                profileId: selectedProfile?.profile_id,
                companyName: selectedProfile?.company_name,
            });
            message.success(response?.data?.message);
            setIsUpdatedView(false);
        } catch (error) {
            console.log("Failed to update profile:", error);
            if (error?.response?.data?.message) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Failed to update profile!");
        }
    };

    const getProfileIcon = (profile) => {
        if (!profile?.profile_status || profile?.profile_status === PROFILE_STATUS.UNPROCESSED) {
            return;
        } else if (profile?.profile_status === PROFILE_STATUS.VERIFIED) {
            return <CheckCircleIcon color="success" />;
        } else if (profile?.profile_status === PROFILE_STATUS.ERRORED) {
            return <ErrorIcon color="error" />;
        } else if (profile?.profile_status === PROFILE_STATUS.DELETED) {
            return <DeleteIcon color="disabled" />;
        } else if (profile?.profile_status === PROFILE_STATUS.MAPPING_REMOVED) {
            return <RemoveCircleIcon color="warning" />;
        }
    };

    const fetchLastUpdatedProfile = async () => {
        try {
            const response = await axios.get(`${url.leadershipProfile.getLastUpdatedProfile}/${selectedProfile?.profile_id}`);
            const data = response?.data?.data;
            if (response?.data?.data?.ingested) {
                setLastUpdatedProfile({ profile: data?.ingested_profile, updatedAt: data?.updatedAt });
            } else {
                setLastUpdatedProfile({ profile: data?.latest_profile, updatedAt: data?.updatedAt });
            }
            return true;
        } catch (error) {
            setLastUpdatedProfile(null);
            message.error(error?.response?.data?.message || "Updated Profile not found!");
            return false;
        }
    };

    const handleProfileViewChange = async () => {
        if (isUpdatedView) {
            setIsUpdatedView(false);
            return;
        }
        const found = await fetchLastUpdatedProfile();
        found ? setIsUpdatedView(true) : setIsUpdatedView(false);
    };

    const getCurrentFieldPath = (changedField) => {
        changedField = changedField[changedField.length - 1];
        const path = changedField.name.reduce((res, field) => {
            if (Number.isInteger(field)) {
                return (res = res + (parseInt(field) + 1));
            }
            return res + "->" + field;
        }, "");
        setCurrentFormPath(path);
    };

    const handleOpenDiffViewer = async () => {
        const found = await fetchLastUpdatedProfile();
        found ? setOpenDiffViewer(true) : setOpenDiffViewer(false);
    };
    const handleCloseDiffViewer = () => {
        setOpenDiffViewer(false);
    };

    const handleProfileDelete = async () => {
        try {
            const response = await axios.delete(url.leadershipProfile.deleteProfile, {
                params: {
                    profileId: selectedProfile?.profile_id,
                    companyName: selectedProfile?.company_name,
                    platformUserId: loggedInUserDetails?.platform_user_id,
                },
            });
            message.success(response?.data?.message);
            updateProfileDataPostAction();
        } catch (error) {
            console.log("Failed to delete profile:", error);
            if (error?.response?.data?.message) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Failed to delete profile!");
        }
    };

    const getProfileStatusText = (item) => {
        if (item?.profile_status === PROFILE_STATUS.VERIFIED) {
            return item.mapping_found ? "Mapped" : "New";
        } else {
            return PROFILE_STATUS.LABELS[item?.profile_status] || "";
        }
    };
    useEffect(() => {
        fetchCxoProfiles(true);
    }, [filterQuery]);

    //When to show MappedProfile Container.
    const isProfileVerified = selectedProfile?.profile_status === PROFILE_STATUS.VERIFIED;
    const hasLowAccessLevel = loggedInUserDetails?.role?.accessLevel < 4;

    // Only show the component if the conditions are NOT met
    const shouldShowMappedProfileContainer = !(selectedProfile?.profile_status && isProfileVerified && hasLowAccessLevel);

    return (
        <>
            <FilterToolbar filterQuery={filterQuery} setFilterQuery={setFilterQuery} tasksCompletedForToday={tasksCompletedForToday} />
            <Grid container m={0} p={0}>
                <Grid
                    item
                    xs={3}
                    sx={(theme) => ({
                        borderRight: theme.palette.border.et,
                        pt: 0,
                        pl: 0,
                    })}
                >
                    <Box
                        sx={(theme) => ({
                            overflowY: "auto",
                            position: "sticky",
                            top: theme.custom.top.level3,
                            height: `calc(100vh - ${theme.custom.top.level3}px)`,
                            bgcolor: theme.palette.background.secondary,
                        })}
                        id="inifiniteSlider"
                    >
                        <InfiniteScroll
                            dataLength={cxoProfiles?.data?.length}
                            next={fetchCxoProfiles}
                            hasMore={hasMoreCxoProfiles}
                            loader={<h4>Loading...</h4>}
                            scrollableTarget={"inifiniteSlider"}
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    <b>You have seen all the profiles!</b>
                                </p>
                            }
                        >
                            {cxoProfiles?.data?.map((item, index) => {
                                const profileStatusIcon = getProfileIcon(item);
                                return (
                                    <Box key={index}>
                                        <Box
                                            onClick={() => openProfileCreator(item)}
                                            sx={(theme) => ({
                                                cursor: "pointer",
                                                backgroundColor:
                                                    selectedProfile?._id === item._id ? theme.palette.action.selected : "transparent",
                                                borderRadius: "5px",
                                                padding: "0.7rem 1.5rem 0.7rem 1.5rem",
                                            })}
                                        >
                                            <Box width={"100%"} display={"flex"} flexDirection={"column"}>
                                                <Stack>
                                                    <Stack>
                                                        <Box display={"flex"} justifyContent={"space-between"}>
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: "bold",
                                                                    textWrap: "wrap",
                                                                    wordBreak: "break-word",
                                                                }}
                                                            >
                                                                {item?.candidate?.full_name}
                                                            </Typography>
                                                            {item?.profile_status && (
                                                                <Box display={"flex"} alignItems={"center"}>
                                                                    {profileStatusIcon}
                                                                    <Typography
                                                                        width={"100%"}
                                                                        textAlign="right"
                                                                        color="text.secondary"
                                                                        variant="body2"
                                                                    >
                                                                        {getProfileStatusText(item)}
                                                                    </Typography>
                                                                </Box>
                                                            )}
                                                        </Box>

                                                        <Typography fontSize={"0.8rem"} sx={{ opacity: "0.5" }}>
                                                            {item?.candidate?.title}
                                                        </Typography>
                                                    </Stack>
                                                    <Typography fontSize={"0.9rem"}>Company Name: {item?.company_name}</Typography>
                                                    <Typography>
                                                        Scraping Url:{" "}
                                                        <a
                                                            href={item?.scraping_url}
                                                            rel="noreferrer"
                                                            style={{
                                                                maxWidth: "300px",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                                display: "inline-block",
                                                                verticalAlign: "bottom",
                                                            }}
                                                            target="_blank"
                                                        >
                                                            {item?.scraping_url}
                                                        </a>
                                                    </Typography>
                                                    <Typography>
                                                        LinkedIn Url:{" "}
                                                        <a
                                                            href={item?.linkedin_url}
                                                            rel="noreferrer"
                                                            style={{
                                                                maxWidth: "300px",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                                display: "inline-block",
                                                                verticalAlign: "bottom",
                                                            }}
                                                            target="_blank"
                                                        >
                                                            {item?.linkedin_url}
                                                        </a>
                                                    </Typography>

                                                    <Typography
                                                        sx={{ mt: "0.5rem" }}
                                                        width={"100%"}
                                                        textAlign="right"
                                                        color="text.secondary"
                                                        variant="body2"
                                                    >
                                                        {dayjs(item.updated_at).format("MMM D, YYYY h:mm A")}{" "}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </Box>
                                        <Divider key={index}></Divider>
                                    </Box>
                                );
                            })}
                        </InfiniteScroll>
                    </Box>
                </Grid>
                <Grid item xs={9} sx={{ pt: 0 }} flexGrow={1}>
                    {selectedProfile && (
                        <Box display={"flex"} justifyContent={"space-between"} height={"100%"} width={"100%"}>
                            <Stack flexBasis={"75%"} flexGrow={1}>
                                <CustomFormToolbar
                                    selectedProfile={selectedProfile}
                                    handleProfileViewChange={handleProfileViewChange}
                                    isUpdatedView={isUpdatedView}
                                    submitWithError={submitWithError}
                                    setSubmitWithError={setSubmitWithError}
                                    setIsJsonView={setIsJsonView}
                                    isJsonView={isJsonView}
                                    lastUpdatedProfile={lastUpdatedProfile}
                                    currentFormPath={currentFormPath}
                                    handleOpenDiffViewer={handleOpenDiffViewer}
                                />
                                <Box display={"flex"} justifyContent={"center"} bgcolor={"background.paper"} flexGrow={1}>
                                    {isJsonView && (
                                        <DataEditor
                                            editorRef={editorRef}
                                            editorData={reverseTransformObject(form.getFieldsValue())}
                                            readOnly={true}
                                            height={`calc(100vh - ${theme.custom.top.level3}px)`}
                                            language={EDITOR_LANGUAGES.javascript}
                                        />
                                    )}
                                    <Box display={isJsonView ? "none" : "flex"} width={"100%"}>
                                        <ProfileCreatorForm
                                            form={form}
                                            profileData={isUpdatedView ? lastUpdatedProfile?.profile : selectedProfile}
                                            handlePostCreatedProfile={
                                                selectedProfile?.profile_status &&
                                                selectedProfile?.profile_status === PROFILE_STATUS.VERIFIED
                                                    ? handlePostUpdatedProfile
                                                    : handlePostCreatedProfile
                                            }
                                            readOnlyView={
                                                selectedProfile?.profile_status &&
                                                selectedProfile?.profile_status === PROFILE_STATUS.VERIFIED &&
                                                loggedInUserDetails?.role?.accessLevel < 4
                                            }
                                            isUpdate={
                                                !selectedProfile.mapping_found &&
                                                selectedProfile?.profile_status &&
                                                selectedProfile?.profile_status === PROFILE_STATUS.VERIFIED
                                            }
                                            isDelete={
                                                !selectedProfile.mapping_found &&
                                                selectedProfile?.profile_status &&
                                                selectedProfile?.profile_status === PROFILE_STATUS.VERIFIED
                                            }
                                            getCurrentFieldPath={getCurrentFieldPath}
                                            submitDisabled={
                                                selectedProfile.mapping_found &&
                                                selectedProfile?.profile_status &&
                                                selectedProfile?.profile_status === PROFILE_STATUS.VERIFIED
                                            }
                                            handleDelete={handleProfileDelete}
                                        />
                                    </Box>
                                </Box>
                            </Stack>
                            {shouldShowMappedProfileContainer && (
                                <MappedProfileContainer
                                    form={form}
                                    selectedProfile={selectedProfile}
                                    fetchCxoProfiles={fetchCxoProfiles}
                                    setSelectedProfile={setSelectedProfile}
                                    submitWithError={submitWithError}
                                    updateProfileDataPostAction={updateProfileDataPostAction}
                                />
                            )}
                        </Box>
                    )}
                    {!selectedProfile && !isProfileLoading && (
                        <div
                            style={{
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                height: "300px",
                            }}
                        >
                            <div>
                                <AdsClickIcon sx={{ marginRight: "6px" }} />
                                Select left pane to display the details.
                            </div>
                        </div>
                    )}
                    {!selectedProfile && isProfileLoading && (
                        <div
                            style={{
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                height: "300px",
                            }}
                        >
                            <div>
                                <FindemLogo loading={true} color={"blue"} height={30} width={30}></FindemLogo>
                            </div>
                        </div>
                    )}
                </Grid>
            </Grid>
            <Dialog open={openDiffViewer} onClose={handleCloseDiffViewer} maxWidth="xl" fullWidth>
                <DialogContent>
                    <Box height={"90vh"}>
                        <Box display="flex" justifyContent="space-between" mb={2}>
                            <Typography variant="h6" component="div" textAlign="center" width="50%">
                                Current Profile
                            </Typography>
                            <Typography variant="h6" component="div" textAlign="center" width="50%">
                                Last Ingested Profile
                            </Typography>
                        </Box>

                        <DiffDataEditor
                            originalData={reverseTransformObject(form.getFieldsValue())}
                            modifiedData={lastUpdatedProfile?.profile}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDiffViewer} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const prepareProfileForEdit = (profileData) => {
    profileData["created_at"] = dayjs();
    profileData["updated_at"] = dayjs();
    profileData["ext_refs"] = [
        {
            source_ref: profileData?.profile_id,
            source: "leadership_page",
            url: profileData?.scraping_url,
        },
    ];
    profileData["ext_src"] = "leadership_page";
    profileData["ext_src_ref"] = profileData?.profile_id;
    for (let workexp of profileData?.workexp) {
        if (profileData?.company_name === workexp?.company?.name) {
            workexp.duration.to_present = true;
        }
    }

    return profileData;
};
export default LeadershipProfile;
