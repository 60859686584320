import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { decodeString } from "../../../util";
import { Typography } from "@mui/material";

export default function ConfirmContactInfoModal({
  open,
  handleClose,
  handleConfirm,
  data,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal"
        aria-describedby="modal-used"
        maxWidth="xl"
      >
        <div
          style={{
            margin: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "800px",
          }}
        >
          <DialogTitle id={`${"confirm-contact info"}-used`}>
            {"Confirm your changes"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id={`${"contact"}-used`}>
              <Typography
                variant="body1"
                component="p"
                gutterBottom
                sx={{ mb: 2 }}
              >
                {"Name: "}
                <b>
                  {data.full_name
                    ? decodeString(data.full_name)
                    : "Not Available"}
                </b>
              </Typography>
            </DialogContentText>
          </DialogContent>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={handleConfirm}>Save</Button>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
