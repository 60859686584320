import React, { useContext, useState } from "react";
import Header from "../../Header/Header";
import TabsHomePage from "../../Home/TabsHomePage";
import validator from "validator";
import axios from "axios";
import { message } from "antd";
import "./ProfileOptOut.styles.css";
import { extractLinkedInId, isValidLinkedinUrl } from "../../utils/util";
import { url } from "../../../url";
import Search from "./Search/Search";
import OptoutModals, { AGENCY_SOURCE } from "./Modals/OptoutModals";
import UserContext from "../../../UserContext";
import { Paper } from "@mui/material";

const filterNullable = (value) => value !== null || value !== undefined;

const filterNullableIterative = (data, store) => {
    if (!data) {
        return;
    }

    const filteredData = [];

    for (let datum of data) {
        if (datum !== null && datum !== undefined) {
            store.add(datum);
            filteredData.push(datum);
        }
    }

    return filteredData;
};

const ProfileOptOut = ({ logoutUser }) => {
    const loggedInUserDetails = useContext(UserContext);
    // search input
    const [emails, setEmails] = useState([]);
    const [linkedin, setLinkedin] = useState(null);
    // always make it have prefix of +
    const [countryCode, setCountryCode] = useState("+");
    const [phones, setPhones] = useState([]);

    // api state

    // selection modal form state
    const [isSelectionModalOpen, setIsSelectionModalOpen] = useState(false);
    // below is a separate field we get from api response - dont make this a derived state - storing it in state is intentional
    const [isEmailPresent, setIsEmailPresent] = useState(false);
    const [emailsOptions, setEmailsOptions] = useState([]);
    const [linkedinsOptions, setLinkedinsOptions] = useState([]);
    const [phonesOptions, setPhonesOptions] = useState([]);

    // show snackbar if email was opted out and prevent them sending it again
    const [showAlreadyOptedOutSnackbar, setShowAlreadyOptedOutSnackbar] = useState(false);
    // loading states
    const [isOptOutRequestLoading, setIsOptOutRequestLoading] = useState(false);
    const [isDataFetching, setIsDataFetching] = useState(false);

    // Modal State handlers - START
    const handleSelectionModalClose = () => {
        setIsSelectionModalOpen(false);
    };

    const handleSelectionModalOpen = () => {
        setIsSelectionModalOpen(true);
    };

    // Modal State handlers - END

    // input state handlers - START

    const handleAddEmail = (email) => {
        setEmails((prevEmails) => {
            return prevEmails.concat(email.trim());
        });
    };

    const handleRemoveEmail = (index) => {
        setEmails((prevEmails) => {
            return prevEmails.filter((email, iterateIndex) => {
                return index !== iterateIndex;
            });
        });
    };

    const handleAddPhoneNumber = (phone) => {
        if (!validator.isMobilePhone(phone)) {
            message.error("Enter a valid phone numer");
            return;
        }
        setPhones((prevPhones) => {
            return prevPhones.concat(phone.trim());
        });
    };

    const handleRemovePhoneNumber = (index) => {
        setPhones((prevPhones) => {
            return prevPhones.filter((phone, iterateIndex) => {
                return index !== iterateIndex;
            });
        });
    };

    const handleLinkedinOnChange = (e) => {
        setLinkedin(e.target.value.trim());
    };

    const handleCountryCode = (e) => {
        const value = e.target.value;
        if (value.length < 1 || !value.startsWith("+")) {
            return;
        }

        setCountryCode(value.trim());
    };

    // input state handlers - END

    /* Takes in linkedin and returns combined emails from different categorization e.g. work, other email etc */
    const getEmailsByLinkedin = async (linkedinPayload) => {
        const emailsData = new Set();

        const response = await axios.post(url.getEmailsByLinkedin, { linkedin_urls: linkedinPayload });

        const categorizedEmails = response?.data?.data;

        // email
        filterNullableIterative(categorizedEmails?.emails, emailsData);

        // work email
        filterNullableIterative(categorizedEmails?.work_emails, emailsData);

        // other emails
        filterNullableIterative(categorizedEmails?.other_emails, emailsData);

        return Array.from(emailsData);
    };

    /* Takes in emails and returns categorized linkedin
     ** also returns a flag email_present
     */
    const getLinkedinByEmails = async (emails) => {
        const response = await axios.post(url.getLinkedinByEmails, { emails });

        const linkedins = response?.data?.data;
        const linkedinUrls = linkedins?.linkedin_urls?.filter(filterNullable) ?? [];
        const defunctUrls = linkedins?.defunct_urls?.filter(filterNullable) ?? [];
        const emailPresentFlag = linkedins["email_present"];

        return [linkedinUrls, defunctUrls, emailPresentFlag];
    };

    /* opt out api */
    const optOutApi = async (body) => {
        const response = await axios.post(url.optout, body);

        return response;
    };

    /* Takes in phone numbers and a country code - returns emails and linkedins */
    const getEmailAndLinkedinByPhones = async (phones, countryCode) => {
        const body = {
            phones,
            country_code: countryCode,
        };

        const response = await axios.post(url.getEmailsAndLinkedinByPhones, body);
        const data = response?.data?.data;
        const emails = data?.emails;
        const atsEmails = data?.ats_emails;
        const emailsData = new Set();

        // email
        filterNullableIterative(emails, emailsData);
        // ats emails
        filterNullableIterative(atsEmails, emailsData);
        const linkedinUrls = data?.linkedin_urls;

        return [Array.from(emailsData), linkedinUrls];
    };

    /* Wrapper for api call and state side effects - getLinkedinByEmails */
    const getLinkedinByEmailsWrapper = async (emailsFromLinkedin, linkedinUrls, linkedinDefunctUrls) => {
        const [linkedinUrlsFromEmails, linkedinDefunctUrlsFromEmails, emailPresentFlag] = await getLinkedinByEmails(emailsFromLinkedin);

        filterNullableIterative(linkedinUrlsFromEmails, linkedinUrls);
        filterNullableIterative(linkedinDefunctUrlsFromEmails, linkedinDefunctUrls);

        setIsEmailPresent(emailPresentFlag);

        return [linkedinUrlsFromEmails, linkedinDefunctUrlsFromEmails, emailPresentFlag];
    };

    /* Wrapper for api call and state side effects - getEmailsByLinkedin */
    const getEmailsByLinkedinWrapper = async (linkedinPayload, emailsData) => {
        const emailsFromLinkedin = await getEmailsByLinkedin(linkedinPayload);

        filterNullableIterative(emailsFromLinkedin, emailsData);

        return emailsFromLinkedin;
    };

    /* Wrapper for api call and state side effects - getEmailsAndLinkedinByPhones */
    const getEmailsAndLinkedInByPhonesWrapper = async (phones, countryCode, emailsData, linkedinUrls) => {
        const [emails, linkedins] = await getEmailAndLinkedinByPhones(phones, countryCode);

        filterNullableIterative(emails, emailsData);
        // filteredLinkedins can have dupes
        const filteredLinkedins = filterNullableIterative(linkedins, linkedinUrls);

        return [emails, filteredLinkedins];
    };

    // ? reducer action
    const resetSearchForm = () => {
        setEmails([]);
        setLinkedin(null);
        setCountryCode("+");
        setPhones([]);
        setIsEmailPresent(false);
    };

    const handleSearch = async () => {
        try {
            // looked up linkedin but invalid format
            if (linkedin && !!linkedin?.length && !isValidLinkedinUrl(linkedin)) {
                message.warning("Invalid Linkedin URL");
                return;
            }
            // ? handle if already opted out - very flaky so omitting for now
            // response?.data?.isAlreadyOptedOut
            // const response = await axios.post(url.alreadyOptedOut, {
            //   emailsToCheck: emails,
            // });

            setIsDataFetching(true);

            // stores response from apis - truth source for forming checkbox/select options
            const linkedinUrls = new Set();
            const linkedinDefunctUrls = new Set();
            const emailsData = new Set();
            // ! set this as initial value since phones we dont get from api yet - only manual input
            const phonesData = new Set(phones);

            // looked up by linkedin - not necessarily exclusive
            if (!!linkedin?.length) {
                const emailsFromLinkedin = await getEmailsByLinkedinWrapper([linkedin], emailsData);

                if (!!emailsFromLinkedin.length) {
                    await getLinkedinByEmailsWrapper(emailsFromLinkedin, linkedinUrls, linkedinDefunctUrls);
                }
            }

            // looked up by email - not necessarily exclusive
            if (!!emails?.length) {
                const [linkedinUrlsFromEmails, linkedinDefunctUrlsFromEmails, emailPresentFlag] = await getLinkedinByEmailsWrapper(
                    emails,
                    linkedinUrls,
                    linkedinDefunctUrls
                );

                if (emailPresentFlag === true && (!!linkedinUrlsFromEmails.length || !!linkedinDefunctUrlsFromEmails.length)) {
                    const linkedinPayload = (linkedinUrlsFromEmails ?? []).concat(linkedinDefunctUrlsFromEmails ?? []);

                    await getEmailsByLinkedinWrapper(linkedinPayload, emailsData);
                }
            }

            // looked up by phone - not necessarily exclusive
            // country code is some number programmaticly always prefixed with '+' - reason for > 1 length check
            if (!!phones?.length && countryCode.length > 1) {
                const [emailsFromPhones, linkedinsFromPhones] = await getEmailsAndLinkedInByPhonesWrapper(
                    phones,
                    countryCode,
                    emailsData,
                    linkedinUrls
                );

                if (!!emailsFromPhones?.length) {
                    await getLinkedinByEmails(emailsFromPhones, linkedinUrls, linkedinDefunctUrls);
                }

                if (!!linkedinsFromPhones?.length) {
                    await getEmailsByLinkedinWrapper(linkedinsFromPhones, emailsData);
                }
            }

            // checkbox data - all data from the apis after processing, chaining etc
            const linkedinUrlsArr = Array.from(linkedinUrls).map((url) => ({ label: url, value: url }));
            const linkedinDefunctUrlsArr = Array.from(linkedinDefunctUrls).map((url) => ({
                label: `${url}  DEFUNCT`,
                value: url,
                disabled: true,
                checked: false,
            }));
            const emailsDataArr = Array.from(emailsData).map((email) => ({
                label: email,
                value: email,
                checked: false,
            }));
            // send country code separately when opting out but display it as together
            const phonesDataArr = Array.from(phonesData).map((phone) => ({
                label: `${countryCode} ${phone}`,
                value: phone,
                checked: false,
            }));

            setEmailsOptions(emailsDataArr);
            setLinkedinsOptions(linkedinUrlsArr.concat(linkedinDefunctUrlsArr));
            setPhonesOptions(phonesDataArr);
            handleSelectionModalOpen();
        } catch (error) {
            console.log(error);
            message.error(error?.response?.data?.msg ?? "Search Failed");
        } finally {
            setIsDataFetching(false);
        }
    };

    const handleOptOut = async (
        complianceType,
        optOutSourceSelectedOption,
        checkedLinkedins,
        checkedEmails,
        checkedPhones,
        handleCancelConfirmationModal
    ) => {
        try {
            setIsOptOutRequestLoading(true);

            const allEmails = emailsOptions.map((email) => email.value);
            const allLinkedins = linkedinsOptions.map((linkedin) => linkedin.value);
            const allPhones = phonesOptions.map((phone) => phone.value);
            const auditLogCountryCode = countryCode?.length > 1 && !!allPhones.length ? countryCode : null;

            let emailsPayload = [];
            let linkedinsPayload = [];
            let phonesPayload = [];
            const candidateIds = [];

            if (!!allEmails.length) {
                emailsPayload = checkedEmails;
            } else if (!allEmails.length && !!emails.length) {
                emailsPayload = emails;
            }

            if (!!allLinkedins.length) {
                linkedinsPayload = checkedLinkedins;
            } else if (!allLinkedins.length && !!linkedin?.length) {
                linkedinsPayload = [linkedin];
            }

            if (!!allPhones.length) {
                phonesPayload = checkedPhones;
            }

            for (let data of linkedinsPayload) {
                candidateIds.push(`LinkedIn:${extractLinkedInId(data)}`);
            }

            // if linkedin exists in candidateIds then no need to add more due to adding emails or phones
            if (!candidateIds.length && (!!emailsPayload.length || !!phonesPayload.length)) {
                candidateIds.push(`Manual:${crypto.randomUUID()}`);
            }

            // internal api payload
            const optOutPayload = {
                emails: emailsPayload,
                linkedin_urls: linkedinsPayload,
                compliance_type: complianceType,
                candidate_ids: candidateIds,
                user_id: "624c5e436e2d340a5a4ff6dd",
            };

            if (!!phonesPayload.length) {
                optOutPayload["phones"] = phonesPayload;
                optOutPayload["country_code"] = auditLogCountryCode;
            }

            // audit log
            const sourceValue = optOutSourceSelectedOption.value.trim().toLowerCase();
            const optOutAuditLog = {
                source: AGENCY_SOURCE.includes(sourceValue) ? "agency" : "individual",
                name: optOutSourceSelectedOption.value,
                lookUpBy: {
                    emails: emails ?? [],
                    linkedin: !!linkedin?.length ? linkedin : null,
                    phoneNumbers: phones ?? [],
                    countryCode: auditLogCountryCode,
                },
                requestType: "deletion",
            };

            const auditLogsData = {
                eventName: "optout",
                eventType: complianceType,
                userId: loggedInUserDetails._id,
                eventMetaData: {
                    selectedOptOutEmails: emailsPayload,
                    selectedOptOutLinkedinLinks: linkedinsPayload,
                    selectedOptOutPhoneNumbers: phonesPayload,
                    selectedOptOutCountryCode: auditLogCountryCode,
                    optOut: optOutAuditLog,
                    candidate_ids: candidateIds.length > 0 ? candidateIds : null,
                },
                oldRecord: {
                    optoutEmails: allEmails,
                    optoutLinkedinLinks: allLinkedins,
                    optoutPhoneNumbers: allPhones,
                    optoutCountryCode: auditLogCountryCode,
                },
                newRecord: {},
            };

            const payload = {
                opt_out: optOutPayload,
                audit_log_data: auditLogsData,
            };

            const response = await optOutApi(payload);

            console.log("Optout Payload: ", optOutPayload);
            console.log("Auditlog Payload: ", auditLogsData);
            message.success("Successfully removed");
        } catch (error) {
            console.log(error);
            message.error(error?.response?.data?.msg ?? "Opt out Failed");
        } finally {
            setIsSelectionModalOpen(false);
            resetSearchForm();
            setIsOptOutRequestLoading(false);
            handleCancelConfirmationModal();
        }
    };

    return (
        <Paper elevation={3} sx={{height:"80vh"}}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    // border: "2px solid red",
                }}
            >
                <Search
                    loading={isDataFetching}
                    emails={emails}
                    linkedin={linkedin}
                    phones={phones}
                    countryCode={countryCode}
                    handleLinkedinOnChange={handleLinkedinOnChange}
                    handleRemoveEmail={handleRemoveEmail}
                    handleAddEmail={handleAddEmail}
                    handleSearch={handleSearch}
                    handleCountryCode={handleCountryCode}
                    handleAddPhoneNumber={handleAddPhoneNumber}
                    handleRemovePhoneNumber={handleRemovePhoneNumber}
                />
                {isSelectionModalOpen && (
                    <OptoutModals
                        isSelectionModalOpen={isSelectionModalOpen}
                        linkedinsOptions={linkedinsOptions}
                        emailsOptions={emailsOptions}
                        phonesOptions={phonesOptions}
                        lookedUpLinkedin={linkedin}
                        lookedUpEmails={emails}
                        isEmailPresent={isEmailPresent}
                        isOptOutRequestLoading={isOptOutRequestLoading}
                        handleOptOut={handleOptOut}
                        handleSelectionModalClose={handleSelectionModalClose}
                        resetSearchForm={resetSearchForm}
                    />
                )}
            </div>
        </Paper>
    );
};

export default ProfileOptOut;
