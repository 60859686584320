import { Box } from "@mui/material";
import { Switch } from "antd";
import React from "react";
import CompletedTasksCard from "../../../../components/cards/CompletedTasksCard";
import NoTaskAssignedCard from "../../../../components/cards/NoTaskAssignedCard";
import FilterComponent from "./FilterComponent";
import SearchByICP from "./SearchByICP";
import ShortlistSourceFilter from "./ShortlistSourceFilter";
import { checkPermission, DRA_MODULES } from "../../../../utils/rbacUtils";
const FilterToolbar = ({
    loggedInUserDetails,
    showTaskAssignedTo,
    onShowTaskAssignedToCheckedChange,
    data,
    dataInAction,
    filteredCustomersData,
    filteredCustomers,
    handleFilterChange,
    listOfCompanies,
    selectedFilterFullOption,
    handleShortlistSourceChange,
    searchByICPInUse,
    selectedShortlistSourceFilter,
    setICPid,
    setSearchByICPInUse,
    getUsersAfterSave,
    tasksCompletedForToday,
    readOnlyFlag,
}) => {
    return (
        <>
            <Box
                sx={(theme) => ({
                    position: "sticky",
                    top: theme.custom.top.level2,
                    zIndex: theme.zIndex.navbar,
                    height: theme.custom.heights.filterbar,
                    boxSizing: "border-box",
                    pl: 3,
                    pr: 3,
                    borderBlockEnd: theme.palette.border.primary,
                    backgroundColor: theme.palette.background.default,
                })}
            >
                <Box
                    sx={(theme) => ({
                        padding: "",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        pt: 1,
                        pb: 1,
                    })}
                >
                    <Box display={"flex"} alignItems={"center"} flex={"0 1 60vw"}>
                        <Box sx={{ flex: "1 1 26%", pr: 2, borderRight: "1px solid grey" }}>
                            <FilterComponent
                                dataOriginal={data}
                                dataCloned={dataInAction}
                                filteredCustomersData={filteredCustomersData}
                                filteredCustomers={filteredCustomers}
                                handleFilterChange={handleFilterChange}
                                listOfCompanies={listOfCompanies}
                                selectedFilterFullOption={selectedFilterFullOption}
                                searchByICPInUse={searchByICPInUse}
                            />
                        </Box>
                        <Box sx={{ flex: "1 1 20%", pl: 2, pr: 2, borderRight: "1px solid grey" }}>
                            <ShortlistSourceFilter
                                handleShortlistSourceChange={handleShortlistSourceChange}
                                selectedShortlistSourceFilter={selectedShortlistSourceFilter}
                                searchByICPInUse={searchByICPInUse}
                            />
                        </Box>
                        <Box sx={{ flex: "1 1 30%", pl: 2, pr: 2, borderRight: "1px solid grey" }}>
                            <SearchByICP
                                setICPid={setICPid}
                                setSearchByICPInUse={setSearchByICPInUse}
                                getUsersAfterSave={getUsersAfterSave}
                            />
                        </Box>
                        {checkPermission(loggedInUserDetails, DRA_MODULES.assignWork).update && (
                            <h6 className="text-center">
                                {/* <a href={`data:text/csv;charset=utf-8,${auditLogsData}`} download="auditLogs.csv"> */}
                                {/* <Button type="primary" icon={<DownloadOutlined />} size="large" onClick={getAuditLogs}>
                      Download audit logs
                    </Button> */}
                                {/* </a> */}
                                <Switch
                                    checkedChildren="Show all"
                                    unCheckedChildren="Show only assigned"
                                    className="mx-2"
                                    checked={showTaskAssignedTo}
                                    onChange={onShowTaskAssignedToCheckedChange}
                                />
                            </h6>
                        )}
                    </Box>
                    {!readOnlyFlag ? <CompletedTasksCard tasksCompletedForToday={tasksCompletedForToday} /> : <NoTaskAssignedCard />}
                </Box>
            </Box>
        </>
    );
};

export default FilterToolbar;
