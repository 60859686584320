import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import { StoreProvider } from "./store/StoreProvider";
import ThemeProviderWrapper from "./theme/components/ThemeProviderWrapper";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(utc);
dayjs.extend(timezone);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <>
        <StoreProvider>
            <ThemeProviderWrapper>
                <App />
            </ThemeProviderWrapper>
        </StoreProvider>
    </>
);
