import { Box, Divider, TextField } from "@mui/material";
import DateRange from "../../../components/date-and-time/DateRange";
import { useState } from "react";
import dayjs from "dayjs";

const Toolbar = ({ toolbarData, setToolbarData }) => {

    const handleDateRangeChange = (newValue) => {
        setToolbarData((preToolbarData) => ({ ...preToolbarData, dateRange: newValue }));
    };
    const handleAccountIdChange = (event) => {
        setToolbarData((preToolbarData) => ({ ...preToolbarData, accountId: event?.currentTarget?.value }));
    };
    return (
        <Box
            sx={(theme) => ({
                position: "sticky",
                top: theme.custom.top.level2,
                zIndex: theme.zIndex.navbar,
                height: theme.custom.heights.filterbar,
                boxSizing: "border-box",
                pl: 3,
                pr: 3,
                borderBlockEnd: theme.palette.border.primary,
                backgroundColor: theme.palette.background.default,
            })}
        >
            <Box height={1} display={"flex"} alignItems={"center"}>
                <DateRange dateRange={toolbarData?.dateRange} onChange={handleDateRangeChange} />
                <Divider orientation="vertical" variant="middle" flexItem />
                <TextField sx={{ ml: 2 }} label="Account ID" variant="outlined" onChange={handleAccountIdChange} />
            </Box>
        </Box>
    );
};

export default Toolbar;
