// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
    margin: 0;
}

hr {
    padding: 0;
    margin: 0;
}

.total-data-container {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    min-height: 70vh;
}

.company-name {
    color: #4798FE;
    font-weight: 600;
}

.scroll-height {
    min-height: 68vh;
    overflow-y: scroll;
}

/* scroll top button */
.display-scroll-btn {
    display: inline;
}

.hide-scroll-btn {
    display: none;
}

.scroll-top-button {
    background-color: #fff;
    ;
    border: none;
}

.scroll-top-icon {
    color: #4798FE;
    background: white;
    font-weight: bold;
    font-size: 30px;
    position: fixed;
    top: 85vh;
    left: 29vw;
}

/* loader */
.loader-container {
    height: 60vh;
}`, "",{"version":3,"sources":["webpack://./src/features/add-missing-email/addMissingEmails.css"],"names":[],"mappings":"AAAA;IACI,SAAS;AACb;;AAEA;IACI,UAAU;IACV,SAAS;AACb;;AAEA;IACI,4CAA4C;IAC5C,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA,sBAAsB;AACtB;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,sBAAsB;;IAEtB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,SAAS;IACT,UAAU;AACd;;AAEA,WAAW;AACX;IACI,YAAY;AAChB","sourcesContent":["p {\n    margin: 0;\n}\n\nhr {\n    padding: 0;\n    margin: 0;\n}\n\n.total-data-container {\n    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n    min-height: 70vh;\n}\n\n.company-name {\n    color: #4798FE;\n    font-weight: 600;\n}\n\n.scroll-height {\n    min-height: 68vh;\n    overflow-y: scroll;\n}\n\n/* scroll top button */\n.display-scroll-btn {\n    display: inline;\n}\n\n.hide-scroll-btn {\n    display: none;\n}\n\n.scroll-top-button {\n    background-color: #fff;\n    ;\n    border: none;\n}\n\n.scroll-top-icon {\n    color: #4798FE;\n    background: white;\n    font-weight: bold;\n    font-size: 30px;\n    position: fixed;\n    top: 85vh;\n    left: 29vw;\n}\n\n/* loader */\n.loader-container {\n    height: 60vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
