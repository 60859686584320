// const backend_url = "http://localhost:9999";
const backend_url = "https://research.continuvm.com/api";

const url = {
  backend_url: backend_url,
  clearUserSession: backend_url + "/clearUserSession",
  auditPost: backend_url + "/api/v1/audit/post",
  auth: backend_url + "/auth",
  login: backend_url + "/login",
  googleLogin: backend_url + "/googleLogin",
  candidatesAPI: backend_url + "/getCandidates",
  customersAPI: backend_url + "/getCustomers",
  updateEmail: backend_url + "/updateEmail",
  refreshEmail: backend_url + "/refreshEmail",
  // register: backend_url + "/register",
  googleClientId:
    "247555452922-tg2d9m1ap0mki4025ck1f0eacku27gfd.apps.googleusercontent.com",
  users: backend_url + "/getUsers",
  getUser: backend_url + "/getUser",
  auditLogs: backend_url + "/getAuditLogs",
  customersData: backend_url + "/getCustomersData",
  candidatesData: backend_url + "/getCandidatesData",
  updateCandidateHide: backend_url + "/updateCandidateHide",
  addNoEmailFoundCandidate: backend_url + "/addNoEmailFoundCandidate",
  getCsv: backend_url + "/getCsv",
  getLinkedinData: backend_url + "/getLinkedinData",
  getLocationHeuristicsData: backend_url + "/getLocationHeuristicsData",
  markCandidateLinkedinLink: backend_url + "/markCandidateLinkedinLink",
  markLocationHeuristic: backend_url + "/markLocationHeuristic",
  // removeExistingEmail: backend_url + "/removeExistingEmail",
  getWrongEmailData: backend_url + "/getWrongEmailData",
  updateWrongEmailProfileHide: backend_url + "/updateWrongEmailProfileHide",
  customersDataPilot: backend_url + "/getCustomersDataPilot",
  wrongEmailUpdateStatus: backend_url + "/wrongEmailUpdateStatus",
  getContactOutData: backend_url + "/getContactOutData",
  getTaskAssignmentLists: backend_url + "/getTaskAssignmentLists",
  updateCandidateAttributes: backend_url + "/updateCandidateAttributes",
  directUpdateStatus: backend_url + "/directUpdateStatus",
  getEmailsByLinkedin: backend_url + "/getEmailsByLinkedin",
  getLinkedinByEmails: backend_url + "/getLinkedinByEmails",
  optout: backend_url + "/optout",
  timeline: backend_url + "/timeline",
  getFakeCxoHeuristicsData: backend_url + "/getFakeCxoHeuristicsData",
  updateFakeCxoHeuristicsData: backend_url + "/updateFakeCxoHeuristicsData",
  getNewsData: backend_url + "/getNewsData",
  markNewsData: backend_url + "/markNewsData",
  emailNotFound: backend_url + "/emailNotFound",
  updateMissingEmails: backend_url + "/updateMissingEmails",
  noEmailFoundCandidate: backend_url + "/noEmailFoundCandidate",
  handleReportMissingEmails: backend_url + "/handleReportMissingEmails",
  alreadyOptedOut: backend_url + "/alreadyOptedOut",
  peFetchProfiles: backend_url + "/peFetchProfiles",
  rtFetchProfiles: backend_url + "/rtFetchProfiles",
  rtFetchProfilesByIcpId: backend_url + "/rtFetchProfilesByIcpId",
  getIcpsDetails: backend_url + "/getIcpsDetails",
  fetchContactInfoByPrids: backend_url + "/fetchContactInfoByPrids",
  updateStatusRtProfile: backend_url + "/updateStatusRtProfile",
  saveContactInfo: backend_url + "/saveContactInfo",
  fetchUserDetailsByUids: backend_url + "/fetchUserDetailsByUids",
  updateStatusPeSearch: backend_url + "/updateStatusPeSearch",
  fetchProfilesNamesByRefs: backend_url + "/fetchProfilesNamesByRefs",
  fetchPublicProfile: backend_url + "/fetch_public_profiles_by_refs",
  validatePublicProfiles: backend_url + "/validate-public-profiles",
  updatePublicProfile: backend_url + "/updatePublicProfile",
  createPublicProfile: backend_url + "/createPublicProfile",
  peVerifiedIcps: backend_url + "/peVerifiedIcps",
  rtCopilotAssignedUsers: backend_url + "/rtcopilot/assignedUsers",
  rtCopilotPollAssignment: backend_url + "/rtcopilot/poll_assignment",
  rtCopilotWorkDone: backend_url + "/rtcopilot/work-done-count",
  rtCopilotTaskAssignment: backend_url + "/admin/rt-copilot-task-assignment",
  fetchIcpsForVerification: backend_url + "/copilot/fetch_icps_for_verification",
  fetchContactInfoFromMultipleSources: backend_url + "/contact/fetch_contacts",
  //opt-out
  getEmailsAndLinkedinByPhones: backend_url + "/opt_out/get_emails_and_linkedin_by_phones",
  optOutByDomain: backend_url + "/opt_out/optOutByDomain",
  getAltLinkFinderData: backend_url + "/alt-link-finder/get-alt-link-finder-data",
  updateAltLinkData: backend_url + "/alt-link-finder/update-alt-link-data",
  companyProfilesRefreshHistory: backend_url + "/companyProfilesRefreshHistory",
  getCompanyDetails: backend_url + "/getCompanyDetails",
  refreshCompanyProfiles: backend_url + "/refreshCompanyProfiles",
  getCompanyProfilesApiRefreshStatus: backend_url + "/companyProfilesRefresh/apiRefreshStatus",
  getCompanyProfilesDbRefreshStatus: backend_url + "/companyProfilesRefresh/dbRefreshStatus",
  autoAssignTasksForAltLinkFinder: backend_url + "/autoAssignTasks/alt-link-finder",
  downloadRefreshedUidsOfExternalSource: backend_url + "/companyProfilesRefresh/refreshedUidsExternalSource/download",
  downloadRefreshedUidsOfPublicProfiles: backend_url + "/companyProfilesRefresh/refreshedUidsPublicProfiles/download",
  getCopilotIcpsBydate: backend_url + "/profileVerificationStatus/getCopilotIcpsByDate",
  getCopilotIcpStats: backend_url + "/profileVerificationStatus/getCopilotIcpStats",
  //instant-profile-refresh
  instantProfileRefresh: backend_url+"/instantProfileRefresh",
  getInstantProfileRefreshRequests: backend_url + "/instantProfileRefresh/requests",
  getInstantProfileRefreshStatus: backend_url + "/instantProfileRefresh/status",
  //user-groups
  getUserGroups: backend_url + "/userGroups",
  crudUserGroup: backend_url + "/userGroups/group",
  crudUserOfUserGroup: backend_url + "/userGroups/group/user",
  smbCopilotSearchesUrls: {
    fetchIcpsForVerification: backend_url + "/smbCopilotSearches/fetchIcpsForVerification",
    fetchProfilesByIcpId: backend_url + "/smbCopilotSearches/fetchProfilesByIcpId",
    pollAssignment: backend_url + "/smbCopilotSearches/pollAssignment",
    getCustomers: backend_url + "/smbCopilotSearches/getCustomers",
    getIcpsDetails: backend_url + "/smbCopilotSearches/getIcpsDetails",
    getAssignedUsers: backend_url + "/smbCopilotSearches/assignedUsers",
    fetchProfilesNamesByRefs: backend_url + "/smbCopilotSearches/fetchProfilesNamesByRefs",
    fetchContactInfoByPrids: backend_url + "/smbCopilotSearches/fetchContactInfoByPrids",
    getWorkDoneCount: backend_url + "/smbCopilotSearches/workDoneCount",
    saveContactInfo: backend_url + "/smbCopilotSearches/saveContactInfo",
    updateProfileStatus: backend_url + "/smbCopilotSearches/updateProfileStatus",
  },
  // roles
  getRoles: backend_url + "/roles",
  crudRole: backend_url + "/roles/role",
  makeRoleDefault: backend_url + "/roles/role/makeDefault",
  roleRename: backend_url + "/roles/role/rename",
  crudModule: backend_url + "/roles/role/module",
  updateActions: backend_url + "/roles/role/module/actions",
  changeUserRole: backend_url + "/user/changeRole",
  // leadership profile
  leadershipProfile: {
    getProfiles: backend_url + "/leadershipProfile/getCxoProfiles",
    getProfile: backend_url + "/leadershipProfile/getCxoProfile",
    getCompanyFilterOptions: backend_url + "/leadershipProfile/companyFilterOptions",
    createProfile: backend_url + "/leadershipProfile/createProfile",
    findMappings: backend_url + "/leadershipProfile/findMappings",
    updateMappedProfile: backend_url + "/leadershipProfile/updateMappedProfile",
    searchMappingsByLinkedinURL: backend_url + "/leadershipProfile/searchPublicProfileByLinkedinURL",
    getMappedProfileByRef: backend_url + "/leadershipProfile/getPublicProfileByRef",
  }
};

module.exports = { url };
