// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading1 {
    animation: loadingAnimation 2s linear infinite alternate;
}

@keyframes loadingAnimation {
    0% {
        transform: scaleX(0);
        transform-origin: 0%;
    }
    25% {
        transform: scaleX(1);
        transform-origin: 0%;
    }
    50% {
        transform: scaleX(1);
        transform-origin: 100%;
    }

    
    100% {
        transform: scaleX(0);
        transform-origin: 100%;
    }
}
.loading2 {
    animation: loadingAnimation2 2s linear infinite alternate;
}

@keyframes loadingAnimation2 {
    0% {
        transform: scaleX(0);
        transform-origin: 0%;
    }
    25% {
        transform: scaleX(1);
        transform-origin: 50%;
    }
    50% {
        transform: scaleX(1);
        transform-origin: 100%;
    }    
    100% {
        transform: scaleX(0);
        transform-origin: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/feedback/FindemLogo/FindemLogo.css"],"names":[],"mappings":"AAAA;IACI,wDAAwD;AAC5D;;AAEA;IACI;QACI,oBAAoB;QACpB,oBAAoB;IACxB;IACA;QACI,oBAAoB;QACpB,oBAAoB;IACxB;IACA;QACI,oBAAoB;QACpB,sBAAsB;IAC1B;;;IAGA;QACI,oBAAoB;QACpB,sBAAsB;IAC1B;AACJ;AACA;IACI,yDAAyD;AAC7D;;AAEA;IACI;QACI,oBAAoB;QACpB,oBAAoB;IACxB;IACA;QACI,oBAAoB;QACpB,qBAAqB;IACzB;IACA;QACI,oBAAoB;QACpB,sBAAsB;IAC1B;IACA;QACI,oBAAoB;QACpB,sBAAsB;IAC1B;AACJ","sourcesContent":[".loading1 {\n    animation: loadingAnimation 2s linear infinite alternate;\n}\n\n@keyframes loadingAnimation {\n    0% {\n        transform: scaleX(0);\n        transform-origin: 0%;\n    }\n    25% {\n        transform: scaleX(1);\n        transform-origin: 0%;\n    }\n    50% {\n        transform: scaleX(1);\n        transform-origin: 100%;\n    }\n\n    \n    100% {\n        transform: scaleX(0);\n        transform-origin: 100%;\n    }\n}\n.loading2 {\n    animation: loadingAnimation2 2s linear infinite alternate;\n}\n\n@keyframes loadingAnimation2 {\n    0% {\n        transform: scaleX(0);\n        transform-origin: 0%;\n    }\n    25% {\n        transform: scaleX(1);\n        transform-origin: 50%;\n    }\n    50% {\n        transform: scaleX(1);\n        transform-origin: 100%;\n    }    \n    100% {\n        transform: scaleX(0);\n        transform-origin: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
