import React from "react";
import chroma from "chroma-js";

import Select, { createFilter } from "react-select";

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? color.alpha(0.8).css()
        : isFocused
        ? color.alpha(0.1).css()
        : undefined,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : undefined
          : undefined,
      },
    };
  },
  // input: (styles) => ({ ...styles, ...dot() }),
  // placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
  // singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const defaultFilterOption = {
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: "any",
  stringify: (option) => `${option.label} ${option.value}`,
  trim: true,
};

const ReactSelectDropdown = ({
  isClearable,
  isDisabled,
  isSearchable,
  isLoading,
  data,
  name,
  onChange,
  placeholder,
  value,
  width,
  filterOption,
  onMenuScrollToBottom,
}) => {
  return (
    <>
      <Select
        width={width}
        className="basic-single"
        classNamePrefix="select"
        // defaultValue={colourOptions[0]}
        value={value}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={false}
        isSearchable={isSearchable}
        name={name}
        options={data}
        onChange={onChange}
        placeholder={placeholder}
        filterOption={
          filterOption == null
            ? createFilter(defaultFilterOption)
            : createFilter(filterOption)
        }
        styles={colourStyles}
        onMenuScrollToBottom={onMenuScrollToBottom}
      />
    </>
  );
};

export default ReactSelectDropdown;
