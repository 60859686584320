import { useEffect, useState } from "react";
import HeaderComponent from "../Header/Header";
import StatusTable from "./StatusTable";
import { Paper } from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { url } from "../../url";
import { message } from "antd";
import Navbar from "../../layouts/navbar/Navbar";

const ProfileVerificationStatus = ({ loggedInUserDetails, logoutUser }) => {
    const [dateRange, setDateRange] = useState({ startDate: dayjs().startOf("day"), endDate: dayjs() });
    const [icpType, setIcpType] = useState("preprocessing");
    const [copilotIcpData, setCopilotIcpData] = useState({ icp_ids: [], owners: {} });
    const [icpDataRows, setIcpDataRows] = useState({ isLoading: true, data: [] });

    const fetchCopilotIcpsByDate = async () => {
        try {
            const response = await axios.post(url.getCopilotIcpsBydate, {
                start_date: dateRange.startDate.toDate(),
                end_date: dateRange.endDate.toDate(),
                icp_type: icpType,
            });
            setCopilotIcpData(response.data.data);
        } catch (error) {
            console.log("Failed to get copilot Icps:", error);
            message.error("Failed to get ICP IDs!");
        }
    };

    const handleDateChange = (field) => (value) => {
        setIcpDataRows({ data: [], isLoading: true });
        if (dayjs(value).isAfter(dayjs())) {
            value = dayjs();
        }

        const newDateRange = {
            ...dateRange,
            [field]: field === "endDate" && !dayjs(value).isSame(dayjs(), "day") ? dayjs(value).endOf("day") : value,
        };
        setDateRange(newDateRange);
        // validateDateRange(newDateRange.from, newDateRange.to);
    };

    const handleIcpTypeChange = (event) => {
        setIcpDataRows({ data: [], isLoading: true });
        setIcpType(event.target.value);
    };
    useEffect(() => {
        fetchCopilotIcpsByDate();
    }, [dateRange, icpType]);

    return (
        <div>
            <HeaderComponent loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser} />
            <Navbar />
            <Paper elevation={3} sx={{ width: "100%", padding: "0.5rem 2rem 3rem 2rem" }}>
                <StatusTable
                    dateRange={dateRange}
                    handleDateChange={handleDateChange}
                    icpType={icpType}
                    handleIcpTypeChange={handleIcpTypeChange}
                    copilotIcpData={copilotIcpData}
                    icpDataRows={icpDataRows}
                    setIcpDataRows={setIcpDataRows}
                ></StatusTable>
            </Paper>
        </div>
    );
};

export default ProfileVerificationStatus;
