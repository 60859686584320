import {
    Box,
    IconButton,
    List,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import { Divider, message } from "antd";
import axios from "axios";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import SelectInputCard from "./SelectInputCard";
import { url } from "../../../../url";
import ModuleCard from "./ModuleCard";
import { DRA_MODULES, usePermission } from "../../../../utils/rbacUtils";
import RoleRenameCard from "./RoleRenameCard";
const Role = ({ role, rolesData, setRolesData }) => {
    const [moreOpen, setMoreOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openRoleRenameCard, setOpenRoleRenameCard] = useState(false);
    const hasPermission = usePermission(DRA_MODULES.roles);

    const handleMoreClick = (event) => {
        setMoreOpen(true);
        setAnchorEl(event.currentTarget);
    };
    const handleMoreClose = () => {
        setMoreOpen(false);
        setAnchorEl(null);
    };
    const removeRole = async (event) => {
        try {
            const response = await axios.delete(url.crudRole, {
                params: {
                    roleId: role._id,
                },
            });
            const newRolesData = rolesData?.data?.filter((roleData) => roleData._id != role._id);
            setRolesData({ data: newRolesData, isLoading: false });
            setAnchorEl(null);
            message.info(response?.data?.message);
        } catch (error) {
            console.log("Failed to delete role:", error);
            if (error?.response?.data?.message) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Failed to delete role!");
        }
    };

    const updateRole = async (updatedFields) => {
        try {
            const response = await axios.patch(url.crudRole, {
                roleId: role?._id,
                updatedFields: updatedFields,
            });
            return response;
        } catch (error) {
            throw error;
        }
    };
    const addModule = async (newModule) => {
        try {
            const response = await axios.post(url.crudModule, {
                roleId: role._id,
                newModule: newModule,
            });
            const newRoles = rolesData?.data?.map((roleData) => {
                if (roleData._id === role._id) {
                    return response?.data?.data;
                }
                return roleData;
            });
            setRolesData({ ...rolesData, data: [...newRoles] });
        } catch (error) {
            console.log("Failed to add new module:", error);
            if (error?.response?.status) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Failed to add new module!");
        }
    };

    const handleMakeDefault = async () => {
        try {
            const response = await axios.post(url.makeRoleDefault, {
                roleId: role._id,
            });
            const newRoles = rolesData?.data?.map((roleData) => {
                if (roleData.isDefault == true) {
                    return { ...roleData, isDefault: false };
                }
                if (roleData._id === role._id) {
                    return response?.data?.data;
                }
                return roleData;
            });
            setRolesData({ ...rolesData, data: [...newRoles] });
            message.info(response?.data?.message);
        } catch (error) {
            console.log("An error occurred while setting the role as default:", error);
            if (error?.response?.data?.message) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Unable to set the role as default.");
        }
    };
    const handleRoleRename = async (newRoleName) => {
        try {
            const response = await axios.post(url.roleRename, {
                newRoleName: newRoleName,
                roleId: role._id,
            });
            const newRoles = rolesData?.data?.map((roleData) => {
                if (roleData._id === role._id) {
                    return response?.data?.data;
                }
                return roleData;
            });
            setRolesData({ ...rolesData, data: [...newRoles] });
            setOpenRoleRenameCard(false);
        } catch (error) {
            console.log("An error occurred while renaming the role:", error);
            if (error?.response?.data?.message) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Unable to rename the role");
        }
    };
    const showRoleRenameCard = () => {
        setMoreOpen(false);
        setAnchorEl(null);
        setOpenRoleRenameCard(true);
    };

    return (
        <Stack direction={"column"} height={"100%"}>
            <Stack
                boxSizing={"border-box"}
                direction={"column"}
                justifyContent={"space-between"}
                borderRadius="12px"
                background={role.isDefault ? "#E0F7FA" : "#F4F5F7"} // Lighter background for default role
                overflow={"hidden"}
                border={role.isDefault ? "3px solid #0288D1" : "3px solid #F4F5F7"} // Different border for default role
                boxShadow={role.isDefault ? "0px 4px 10px rgba(0, 0, 0, 0.2)" : "none"} // Add shadow to highlight default role
            >
                <Box
                    sx={{
                        position: "sticky",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: role.isDefault ? "#E0F7FA" : "#F4F5F7",
                        padding: "1rem",
                    }}
                >
                    {openRoleRenameCard ? (
                        <RoleRenameCard setOpenRoleRenameCard={setOpenRoleRenameCard} onSubmit={handleRoleRename} />
                    ) : (
                        <>
                            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", alignItems: "flex-start" }}>
                                <Typography
                                    sx={{
                                        fontSize: "1.5rem",
                                        fontWeight: "bold",
                                        color: role.isDefault ? "#0288D1" : "inherit", // Different text color for default role
                                    }}
                                >
                                    {role?.name} {role.isDefault && "(Default)"}
                                </Typography>
                                <Typography fontSize={"0.7rem"} color={"grey"}>
                                    Access Level: {role?.accessLevel}
                                </Typography>
                            </Box>
                            {hasPermission.update && (
                                <IconButton onClick={handleMoreClick}>
                                    <MoreHorizIcon />
                                </IconButton>
                            )}
                        </>
                    )}
                    <Menu
                        open={moreOpen}
                        anchorEl={anchorEl}
                        onClose={handleMoreClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        <MenuItem onClick={showRoleRenameCard}>
                            <ListItemText>Rename</ListItemText>
                        </MenuItem>
                        {!role.isDefault && (
                            <MenuItem onClick={handleMakeDefault}>
                                <ListItemText>Make Default</ListItemText>
                            </MenuItem>
                        )}
                        {hasPermission.delete && !role.isDefault && (
                            <MenuItem disabled={role.isDefault} onClick={removeRole}>
                                <ListItemIcon>
                                    <DeleteIcon color="warning" />
                                </ListItemIcon>
                                <ListItemText>Delete</ListItemText>
                            </MenuItem>
                        )}
                    </Menu>
                </Box>
                <List
                    key={role?._id}
                    sx={{
                        overflow: "hidden",
                        minWidth: "450px",
                        background: "#F4F5F7",

                        maxHeight: "90%",
                        "&:hover": {
                            overflow: "auto",
                        },
                    }}
                >
                    <Divider style={{ margin: "0" }} />
                    {role?.permissions.map((moduleData) => {
                        return (
                            <ModuleCard
                                key={moduleData._id}
                                moduleData={moduleData}
                                role={role}
                                rolesData={rolesData}
                                setRolesData={setRolesData}
                                hasPermission={hasPermission}
                            />
                        );
                    })}
                </List>
                {hasPermission.update && (
                    <SelectInputCard label={"Add Module"} inputLabel={"Module"} onSubmit={addModule} roleData={role} />
                )}
            </Stack>
        </Stack>
    );
};
export default Role;
