import {
    Box,
    Chip,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Tooltip,
    Typography,
    Menu,
    MenuItem,
    FormControlLabel,
    Checkbox,
    MenuList,
} from "@mui/material";
import { MODULE_ACTIONS } from "../../../../utils/rbacUtils";
import Check from "@mui/icons-material/Check";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import axios from "axios";
import { url } from "../../../../url";
import { Divider, message } from "antd";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";

import { PlusCircleFilled, PlusCircleOutlined } from "@ant-design/icons";
import { useState } from "react";

const ModuleCard = ({ moduleData, role, rolesData, setRolesData, hasPermission }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const removeModule = async (event) => {
        try {
            const response = await axios.delete(url.crudModule, {
                params: {
                    roleId: role._id,
                    moduleId: moduleData._id,
                },
            });
            const newRoles = rolesData?.data?.map((roleData) => {
                if (roleData._id == role._id) {
                    return response?.data?.data;
                }
                return roleData;
            });
            setRolesData({ ...rolesData, data: [...newRoles] });
        } catch (error) {
            console.log("Failed to remove user:", error);
            if (error?.response?.status) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Failed to remove user!");
        }
    };
    const removeAction = async (actionToRemove) => {
        try {
            if (!moduleData.actions.includes(actionToRemove)) {
                message.info("Action does not exist!");
                return;
            }
            const newActions = moduleData?.actions.filter((action) => action != actionToRemove);
            const response = await axios.post(url.updateActions, {
                roleId: role._id,
                moduleId: moduleData._id,
                newActions: newActions,
            });
            const newRoles = rolesData?.data?.map((roleData) => {
                if (roleData._id == role._id) {
                    return response?.data?.data;
                }
                return roleData;
            });
            setRolesData({ ...rolesData, data: [...newRoles] });
        } catch (error) {
            console.log("Failed to remove action:", error);
            if (error?.response?.status) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Failed to remove action!");
        }
    };
    const addAction = async (event) => {
        try {
            const newAction = event.currentTarget.dataset?.action;
            if (moduleData.actions.includes(newAction)) {
                message.info("Action already exists!");
                return;
            }
            const newActions = [...moduleData.actions, newAction];
            const response = await axios.post(url.updateActions, {
                roleId: role._id,
                moduleId: moduleData._id,
                newActions: newActions,
            });
            const newRoles = rolesData?.data?.map((roleData) => {
                if (roleData._id == role._id) {
                    return response?.data?.data;
                }
                return roleData;
            });
            setRolesData({ ...rolesData, data: [...newRoles] });
        } catch (error) {
            console.log("Failed to add action:", error);
            if (error?.response?.status) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Failed to add action!");
        }
    };
    const handleMoreClose = () => {
        setAnchorEl(null);
    };
    const handleMoreClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    return (
        <ListItem sx={{ flexDirection: "column", background: "white" }}>
            <Box sx={{ width: "100%", paddingBlock: "0.5rem" }}>
                <Stack width={"100%"} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography fontWeight={"bold"}>{moduleData.moduleLabel}</Typography>
                    {hasPermission.update && (
                        <IconButton onClick={handleMoreClick}>
                            <MoreHorizIcon />
                        </IconButton>
                    )}
                    <Menu
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleMoreClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        {" "}
                        <MenuList dense>
                            {Object.keys(MODULE_ACTIONS).map((action, idx) => {
                                return (
                                    <MenuItem key={idx} data-action={MODULE_ACTIONS[action]} onClick={addAction}>
                                        {moduleData?.actions.includes(MODULE_ACTIONS[action]) ? (
                                            <ListItemIcon>
                                                <Check />
                                            </ListItemIcon>
                                        ) : (
                                            <ListItemIcon />
                                        )}
                                        {MODULE_ACTIONS[action]}
                                    </MenuItem>
                                );
                            })}

                            <MenuItem onClick={removeModule}>
                                <ListItemIcon>
                                    <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText>Delete</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Stack>
                <Stack direction="row" alignItems={"center"}>
                    <Typography fontSize={"0.8rem"}>Access: </Typography>
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                        {moduleData?.actions.map((action) => {
                            return (
                                <Chip
                                    key={action}
                                    label={action}
                                    onDelete={
                                        hasPermission.update
                                            ? action === MODULE_ACTIONS.READ
                                                ? removeModule
                                                : () => removeAction(action)
                                            : ""
                                    }
                                />
                            );
                        })}
                    </Stack>
                </Stack>
            </Box>
            <Divider style={{ margin: "0" }} />
        </ListItem>
    );
};

export default ModuleCard;
