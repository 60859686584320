import React from "react";
import "./leftPane.styles.css";
import { Spin } from "antd";
import dayjs from "dayjs";
import { shortlistSource } from "../../../../util";
import { Box, Divider, Typography } from "@mui/material";

const LeftPaneContainer = ({
    loggedInUserDetails,
    displayUsers,
    displayDetailsOnRightPane,
    selectedLeftPane,
    incrementPage,
    isFullDataLoaded,
    loadingMoreDataSpinner,
    selectedCustomer,
}) => {
    if (displayUsers.length) {
        let [companyName, accountId] = selectedCustomer;
        let leftPaneContainers = displayUsers.map((object, index) => {
            return (
                <Box key={index}>
                    <Box
                        id={index}
                        onClick={displayDetailsOnRightPane}
                        sx={(theme) => ({
                            cursor: "pointer",
                            backgroundColor: Number(selectedLeftPane) === Number(index) ? theme.palette.action.selected : "transparent",
                            borderRadius: "5px",
                            padding: "0.7rem 1.5rem 0.7rem 1.5rem",
                            display: "flex",
                            justifyContent: "space-between",
                        })}
                    >
                        <div>
                            <Typography>
                                <strong>
                                    {object.serialNumber ? String(object.serialNumber) + " -" : null} {object.name}
                                </strong>
                            </Typography>

                            <p className="shortlist-para">CreatedAt {dayjs(object.createdAt).format("DD/MM/YYYY")}</p>
                            <p className="shortlist-para">Shortlisted {object.shortlisted_on}</p>
                            <p className="shortlist-para">
                                Shortlist source:{" "}
                                <strong>
                                    {object.hasOwnProperty("shortlisted_source") ? shortlistSource[object.shortlisted_source] : "Bulk"}
                                </strong>
                                {/* if the shortlisted source doesn't exist then treat it as bulk*/}
                            </p>
                            {<Typography variant="body2">Assigned to : {object.taskAssignedTo}</Typography>}
                        </div>

                        <Typography>
                            <strong>{object.customer}</strong>
                        </Typography>
                    </Box>
                    <Divider key={index}></Divider>
                </Box>
            );
            // }
        });
        return (
            <div className="left-pane-height">
                {leftPaneContainers}
                <div className="d-flex justify-content-center mt-3">
                    {isFullDataLoaded ? (
                        <p className="color-blue">End of Content</p>
                    ) : loadingMoreDataSpinner ? (
                        <Spin tip="Loading..." />
                    ) : (
                        <button onClick={() => incrementPage()} className="load-more-button d-flex align-items-baseline mx-4">
                            <i className="fa-solid fa-rotate-right me-1"></i>Load More Candidates
                        </button>
                    )}
                </div>
            </div>
        );
    } else {
        return <p className="text-dark text-center">Sorry, no data to show for this customer</p>;
    }
};

export default LeftPaneContainer;
