import { Avatar, Card, CardHeader, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditUserModal from "./EditUserModal";
import { useState } from "react";
import { DRA_MODULES, usePermission } from "../../../../utils/rbacUtils";

const UserCard = ({ user }) => {
    const [editUserModalOpen, setEditUserModalOpen] = useState(false);
    const hasPermission = usePermission(DRA_MODULES.users);

    const handleUserCardClick = () => {
        setEditUserModalOpen(true);
    };
    const handleEditUserModalClose = () => {
        setEditUserModalOpen(false);
    };

    return (
        <>
            <Card onClick={hasPermission.update ? handleUserCardClick : undefined} sx={{ margin: "2rem 0", minWidth: 345, cursor: "pointer" }}>
                <CardHeader avatar={<Avatar>{user.name.slice(0, 1).toUpperCase()}</Avatar>} title={user.name} subheader={user.email} />
            </Card>
            {editUserModalOpen && <EditUserModal open={editUserModalOpen} userId={user._id} handleClose={handleEditUserModalClose} />}
        </>
    );
};

export default UserCard;
