// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-grey {
  color: grey;
}

.color-blue {
  color: #4798FE;
}

.color-purple {
  color: #b585ed;
}

.text-end {
  text-align: end;
}

.form-label-width {
  width: 5rem;
}

.margin-form-label {
  margin-left: 100px;
}

.cursor-pointer {
  cursor: pointer;
}

.add-cancel-btn {
  height: 28px;
  font-size: 11px;
}

button {
  border: none;
  background-color: inherit;
}

.form-save-btn-disabled {
  text-align: center;
  border: none;
  background-color: grey;
  color: white;
  width: 7rem;
  height: 2.5rem;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 4px;
}

.form-save-btn-enabled {
  border: none;
  background-color: #98dca1;
  color: white;
  width: 7rem;
  height: 2.5rem;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 4px;
}

/* Right Pane when not clicked*/

.container-height {
  height: 100%;
}

.color-grey {
  color: grey;
}`, "",{"version":3,"sources":["webpack://./src/components/WrongEmail/RightPane/rightPane.styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,sBAAsB;EACtB,YAAY;EACZ,WAAW;EACX,cAAc;EACd,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,cAAc;EACd,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB;;AAEA,+BAA+B;;AAE/B;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb","sourcesContent":[".color-grey {\n  color: grey;\n}\n\n.color-blue {\n  color: #4798FE;\n}\n\n.color-purple {\n  color: #b585ed;\n}\n\n.text-end {\n  text-align: end;\n}\n\n.form-label-width {\n  width: 5rem;\n}\n\n.margin-form-label {\n  margin-left: 100px;\n}\n\n.cursor-pointer {\n  cursor: pointer;\n}\n\n.add-cancel-btn {\n  height: 28px;\n  font-size: 11px;\n}\n\nbutton {\n  border: none;\n  background-color: inherit;\n}\n\n.form-save-btn-disabled {\n  text-align: center;\n  border: none;\n  background-color: grey;\n  color: white;\n  width: 7rem;\n  height: 2.5rem;\n  padding: 6px 12px;\n  font-size: 1rem;\n  border-radius: 4px;\n}\n\n.form-save-btn-enabled {\n  border: none;\n  background-color: #98dca1;\n  color: white;\n  width: 7rem;\n  height: 2.5rem;\n  padding: 6px 12px;\n  font-size: 1rem;\n  border-radius: 4px;\n}\n\n/* Right Pane when not clicked*/\n\n.container-height {\n  height: 100%;\n}\n\n.color-grey {\n  color: grey;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
