import React from "react";
import "./rightPane.styles.css";

const RightPaneWhenNotClicked = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center container-height"
    >
      <div>
        <p className="color-grey">
          <i className="fa-solid fa-arrow-pointer me-1"></i>Select left pane to
          display the details
        </p>
      </div>
    </div>
  );
};

export default RightPaneWhenNotClicked;
