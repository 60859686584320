import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

export default function CustomizedInputBase({ icp_id, set_icp_id, getLinkedinData }) {
    return (
        <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by icp id"
                inputProps={{ 'aria-label': 'search by icp id' }}
                value={icp_id}
                onChange={(e) => set_icp_id(e.target.value.trim())}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="clear" onClick={(e) => {
                set_icp_id("");
            }}>
                <ClearIcon />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton color="primary" sx={{ p: '10px' }} aria-label="search" onClick={(e) => getLinkedinData(1)}>
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}