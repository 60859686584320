import { Box, Container, Paper } from "@mui/material";
import LeftPane from "./LeftPane";
import RightPane from "./RightPane";
import { useState } from "react";

const InstantProfileRefreshRequest = () => {
    const [uids, setUids] = useState("");

    return (
        <Paper sx={{ padding: "0 3rem" }} elevation={0}>
            <Paper sx={{ display: "flex", maxWidth: "100%" }}>
                <LeftPane uid setUids={setUids}></LeftPane>
                <RightPane uids={uids}></RightPane>
            </Paper>
        </Paper>
    );
};

export default InstantProfileRefreshRequest;
