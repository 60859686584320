import { Button, Card, CardActions, CardContent, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

import { useState } from "react";
const RoleRenameCard = ({ onSubmit, setOpenRoleRenameCard }) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState("");

    const closeCard = () => {
        setOpenRoleRenameCard(false);
    };
    const handleValueChange = (event) => {
        setValue(event.target.value);
    };
    const handleAddInput = () => {
        onSubmit(value);
        setValue("");
        setOpen(false);
    };

    return (
        <Card elevation={0} sx={{ background: "#F4F5F7", minWidth: "350px" }}>
            <CardContent>
                <TextField onChange={handleValueChange} value={value} fullWidth label={"New Role Name"} variant="standard" />
            </CardContent>
            <CardActions>
                <Button sx={{ textTransform: "none" }} disabled={value.length == 0} onClick={handleAddInput}>
                    Change
                </Button>
                <IconButton onClick={closeCard}>
                    <CloseIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
};
export default RoleRenameCard;
