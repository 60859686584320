import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Input, Button, Select, Form } from "antd";
import { Option } from "antd/lib/mentions";
import React, { useEffect, useState } from "react";

const SearchByRef = ({ handleSearchButton }) => {
    const [searchedInput, setSearchedInput] = useState("");
    const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(true);

    const onFinish = (values) => {
        handleSearchButton(values);
    };
    const onChangeSearchInput = (e) => {
        if (e.target.value === "") {
            setIsSearchButtonDisabled(true);
        } else {
            setIsSearchButtonDisabled(false);
        }
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "8px 0",
            }}
        >
            <Form name="SearchProfile" initialValues={{ search_by: "source_ref", remember: true }} onFinish={onFinish} style={{ width: "300px" }}>
                <Form.Item label="Search Type" name="search_by" rules={[{ required: true, message: "Please select something!" }]}>
                    <Select>
                        <Option value="source_ref">Search by source_ref</Option>
                        <Option value="ref">Search by ref</Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Value" name="value" rules={[{ required: true, message: "Please input something!" }]}>
                    <Input
                        size="small"
                        placeholder="Search Profile..."
                        onChange={onChangeSearchInput}
                        value={searchedInput}
                        suffix={
                            <Button htmlType="submit" disabled={isSearchButtonDisabled} style={{ border: "0", padding: "0" }}>
                                <SearchOutlined style={{ padding: "5px 10px" }} />
                            </Button>
                        }
                    />
                </Form.Item>
            </Form>
        </div>
    );
};
export default SearchByRef;
