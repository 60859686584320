import { Card, Typography } from "@mui/material";
import React from "react";

const NoTaskAssignedCard = ({ tasksCompletedForToday }) => {
    return (
        <Card
            sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: 2,
                boxShadow: 3,
                backgroundColor: "background",
                maxWidth: 300,
                p: 1,
            }}
        >
            <Typography sx={{ p: 1 }} variant="body2" fontWeight="bold" color="text.secondary">
                No task assigned | Read-only mode
            </Typography>
        </Card>
    );
};
export default NoTaskAssignedCard;
