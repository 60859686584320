// Constant Must be declared in UPPER_SNAKE_CASE. But there lowest level child can be in same case as the value. e.g.
// cont EXT_SOURCE_NAMES = {
//   SOURCE:{
//          LinkedIn: "LinkedIn";  // this last child can have same case for key as the value.
//   }
// }


const SLACK_TEAM_ID = "THLMZA7KN";
const SLACK_CHANNEL_ID = "C04210A95S9";
const DRA_FEEDBACK_SLACK_CHANNEL_APP_URL = `slack://channel?team=${SLACK_TEAM_ID}&id=${SLACK_CHANNEL_ID}`;
const DRA_FEEDBACK_SLACK_CHANNEL_WEB_URL =
    "https://join.slack.com/share/enQtODY3Mjk5MTU1NjkxMi0xOTQwMzUxNGQ1ODU1MmRjZTFlYTI0ODJhYThlYmJhOGU5YjQ0YThiMjg3NWJkYzI4ZmIwMzVkMzY4ZmEyMTQy";
    
const altProfileSourcNames = {
    leadership_page: "leadership_page",
};

const extSourceNames = {
    leadershipProfile: "leadership_page",
    LinkedIn: "LinkedIn",
};
const linkedInUidRegex = /linkedin\.com\/in\/([^/?#]+)\/?/;
const phoneValidationRegex = /^\d+$/;
const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const LEADERSHIP_CONST = {
    PROFILE_STATUS: {
        UNPROCESSED: "unprocessed",
        ERRORED: "errored",
        VERIFIED: "verified",
        AUTO_VERIFIED: "auto verified",
        AUTO_ERRORED: "auto errored",
        DELETED: "deleted",
        MAPPING_REMOVED: "mapping removed",
        LABELS: {
            unprocessed: "Unprocessed",
            errored: "Errored",
            verified: "Verified",
            "auto verified": "Auto Verified",
            "auto errored": "Auto Errored",
            deleted: "Deleted",
            "mapping removed": "Mapping Removed",
        },
        getList() {
            return Object.entries(this.LABELS).map(([value, label]) => ({ value, label }));
        },
    },
};
const AUDIT_LOG_EVENT_NAMES = {
    updateMappedProfileLeadershipProfile: "update_mapped_profile_leadership_profile",
    createProfileLeadershipProfile: "create_profile_leadership_profile",
};

const AUDIT_LOG_EVENT_TYPES = {
    create: "create",
    update: "update",
};

const DATA_STATUS = {
    IDLE: "idle",
    LOADING: "loading",
    SUCCEEDED: "succeeded",
    FAILED: "failed",
};

module.exports = {
    extSourceNames,
    altProfileSourcNames,
    linkedInUidRegex,
    phoneValidationRegex,
    emailValidationRegex,
    LEADERSHIP_CONST,
    AUDIT_LOG_EVENT_NAMES,
    AUDIT_LOG_EVENT_TYPES,
    DATA_STATUS,
    DRA_FEEDBACK_SLACK_CHANNEL_WEB_URL,
    DRA_FEEDBACK_SLACK_CHANNEL_APP_URL
};
