import React, { useRef, useState } from "react";
import Header from "../Header/Header";
import TabsHomePage from "../Home/TabsHomePage";
import { Switch, Typography, Button, Modal, message } from "antd";
import SearchProfile from "./searchProfile";
import TimeLineModal from "./TimeLineModal";
import "./timeLine.css";
import TimeLineChangesModal from "./TimeLineChangesModal";
import { url } from "../../url";
import axios from "axios";
import diff from "deep-diff";
// const staticData = require("./data.json");

const cleanPath = (path) => {
  if (typeof path == "undefined") {
    return path;
  }
  path = path.filter((element) => {
    if (typeof element === "number" || element === "_doc") {
      return false;
    }
    return true;
  });
  path = path.join(" -> ");
  return path;
};

const TimeLine = ({ loggedInUserDetails, logoutUser }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChangesModalOpen, setIsChangesModalOpen] = useState(false);
  const [timeLineData, setTimeLineData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataInModal, setDataInModal] = useState({});
  const [dataInTimeLineModal, setDataInTimeLineModal] = useState({})
  const [messageApi, contextHolder] = message.useMessage();
  
  const showMessage = (message) => {
    messageApi.open({
      type:'error',
      content:message
    })
  }

  const showTimeLineModal = (profile) => {
    console.log(profile)
    setDataInTimeLineModal(profile)
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const showChangesModal = (index) => {
    // console.log(index);
    setDataInModal(timeLineData[index + 1]);
    setIsChangesModalOpen(true);
    // setTimeLineData(timeLineData[index]);
  };

  const handleChangesModalClose = () => {
    setIsChangesModalOpen(false);
  };

  

  const handleSearchButton = (values) => {
    // console.log(inputRefIdREF.current.input.value);
    getTimelineData(values);
  };
  const getTimelineData = async (values) => {
    try {
      setLoading(true);
      let data = await axios.post(url.timeline, {
        search_by: values.search_by,
        value: values.value,
      });
      if(data.data.data.length == 0){
        showMessage(data.data.message)
        setLoading(false);
        setTimeLineData([])
        return 
      }
      data = data.data.data;
      let difference = [data[0]];

      // diffing
      for (let index = 1; index < data.length - 1; index++) {
        const dateTimeFormatOptions = { timeZone: 'UTC', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }
        let tempObj = {
          beforeProfileCollection: `${data[index+1]["collectionName"]}`,
          afterProfileCollection: `${data[index]["collectionName"]}`,
          source_ref: data[index]["source_ref"],
          beforeProfile: data[index+1]["profile"],
          afterProfile: data[index]["profile"],
          beforeProfileDate: `${new Date(data[index+1]["sorted_on_time"]).toLocaleString('en-US', dateTimeFormatOptions)}`,
          afterProfileDate: `${new Date(data[index]["sorted_on_time"]).toLocaleString('en-US', dateTimeFormatOptions)}`
        };

        let diffedData = diff(data[index+1]["profile"], data[index]["profile"]);
        // console.log(diffedData);
        if (diffedData == undefined) {
          continue;
        }
        for (let i = 0; i < diffedData.length; i++) {
          diffedData[i]["Path"] = cleanPath(diffedData[i]["path"]);
          if (diffedData[i]["kind"] === "A") {
            diffedData[i]["Change"] = diffedData[i]["item"]["kind"];

            if (diffedData[i]["item"]["rhs"] !== undefined) {
              diffedData[i]["rhs"] = diffedData[i]["item"]["rhs"];
            }

            if (diffedData[i]["item"]["lhs"] !== undefined) {
              diffedData[i]["lhs"] = diffedData[i]["item"]["lhs"];
            }
          } else {
            diffedData[i]["Change"] = diffedData[i]["kind"];
          }

          if (diffedData[i]["lhs"]) {
            diffedData[i]["Before"] = diffedData[i]["lhs"];
            // delete diffedData[i]["lhs"];
          }

          if (diffedData[i]["rhs"]) {
            diffedData[i]["After"] = diffedData[i]["rhs"];
            // delete diffedData[i]["rhs"];
          }

          if (diffedData[i]["Change"] === "N") {
            diffedData[i]["Change"] = "New";
          } else if (diffedData[i]["Change"] === "D") {
            diffedData[i]["Change"] = "Delete";
          } else if (diffedData[i]["Change"] === "E") {
            diffedData[i]["Change"] = "Edit";
          }
        }
        // console.log(diffedData);
        tempObj["data"] = diffedData;
        difference.push(tempObj);
      }
      // console.log(difference);

      setTimeLineData(difference);
      // console.log(staticData.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if(error?.response?.data?.message){
        showMessage(error.response.data.message)
      }else{
        showMessage(error.message)
      }
      setLoading(false);
      setTimeLineData([])
    }
  };

  // const showLog = (index) => {
  //   console.log(index);
  // };

  // const timelineData = data.slice(1);

  // console.log(timelineData);

  const TimelineItem = ({ data, index }) => (
    <div className="timeline-item">
      <div className="timeline-item-content">
        <span className="tag" style={{ display:"flex", alignItems:"center", background: "lightblue", color: "black", fontSize: "12px" }}>
          <Button style={{display:"block", height:"3.5em", background:"lightblue"}} onClick={()=> showTimeLineModal(data.beforeProfile)}>
            <span>{data.beforeProfileCollection}</span><br />
            <span>{data.beforeProfileDate}</span>
          </Button>
          <span>{"<->"}</span>
          <Button style={{display:"block", height:"3.5em", background:"lightblue"}} onClick={()=> showTimeLineModal(data.afterProfile)}>
            <span>{data.afterProfileCollection}</span><br />
            <span>{data.afterProfileDate}</span>
          </Button>
        </span>
        <br />
        
        <div style={{ textAlign: "left", width: "100%", marginTop:"2em"}}>
          <br />
          <Typography> Total {data.data.length} Changes</Typography>
          <br />

          {/* {data.data.map((item, i) => {
            return <Typography key={i}>{item.Path}</Typography>;
          })} */}
          {/* <ReactJson src={data.data} name={false} displayDataTypes={false} theme="summerfruit:inverted" enableClipboard={false} collapsed={true} /> */}
        </div>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}>
          <Button style={{ borderRadius: "4px", marginTop:"-1em"}} onClick={() => showChangesModal(index)}>
            Show Changes
          </Button>
        </div>
        <span className="circle" />
      </div>
    </div>
  );

  const Timeline = () =>
    timeLineData.length > 0 && (
      <div className="timeline-container">
        <div className="timeline-item">
          <div className="timeline-item-content">
            <span className="tag" style={{ background: "lightblue", color: "black" }}>
            <Button style={{display:"block", height:"3.5em", background:"lightblue"}} onClick={()=>showTimeLineModal(timeLineData[0]?.rootNode)}>
            <span>{timeLineData[1].afterProfileCollection}</span><br />
            <span>{timeLineData[1].afterProfileDate}</span>
          </Button>
            </span>
            <br />
            <div style={{ textAlign: "left" }}>
              <Typography style={{ margin: "2px 0" }}>
                <strong>Full Name: </strong>
                {timeLineData[0]?.rootNode?.candidate?.full_name}
              </Typography>
              <Typography style={{ margin: "2px 0" }}>
                <strong>Address: </strong>
                {timeLineData[0]?.rootNode?.candidate?.address}
              </Typography>
              <Typography style={{ margin: "2px 0" }}>
                <strong>Gender: </strong>
                {timeLineData[0]?.rootNode?.candidate?.gender}
              </Typography>
              <Typography style={{ margin: "2px 0" }}>
                <strong>Linkedin: </strong>
                <a href={timeLineData[0]?.rootNode?.candidate?.linkedin} target="_blank" rel="noreferrer">
                  {timeLineData[0]?.rootNode?.candidate?.linkedin}
                </a>
              </Typography>
              <Typography style={{ margin: "2px 0" }}>
                <strong>Company: </strong>
                {timeLineData[0]?.rootNode?.workexp[0]?.company?.name.toUpperCase()}
              </Typography>
            </div>
            <br />
            <Button style={{ borderRadius: "4px" }} onClick={()=>showTimeLineModal(timeLineData[0]?.rootNode)}>
              Show Profile
            </Button>

            <span className="circle" />
          </div>
        </div>
        {timeLineData.slice(1).map((data, key) => {
          // console.log(data);
          // TODO: handle paths to filter which are nested, current handly only top level fields
          // dont show some fields from data
          // edge case: if after filtering fields there is no data then dont render dabba
          const pathsToFilter = ["updated_at", "created_at", "last_ingested_at"];
          let filteredData = [];

          for (let datum of data.data) {
            if (pathsToFilter.indexOf(datum.Path) === -1) {
              filteredData.push(datum);
            }
          }

          data.data = filteredData;

          if (filteredData.length !== 0) {
            return <TimelineItem data={data} key={key} index={key} />;
          } else {
            return null;
          }
        })}
      </div>
    );

  return (
    <div>
      {/* contextholder is for showing pop up messages */}
      {contextHolder}
      <Header loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser} />
      <div className="pe-5 ps-5">
        <TabsHomePage />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}>
        <br />
        <SearchProfile
          handleSearchButton={handleSearchButton}
        />
        {loading ? <p>Loading this might take a while....</p> : <Timeline />}
      </div>
      <>
        {timeLineData.length > 0 ? (
          <TimeLineModal isModalOpen={isModalOpen} handleClose={handleClose} candidateDetails={dataInTimeLineModal} />
        ) : null}
      </>
      <>
        <TimeLineChangesModal
          isChangesModalOpen={isChangesModalOpen}
          handleChangesModalClose={handleChangesModalClose}
          candidateDetails={dataInModal}
        />
      </>
    </div>
  );
};

export default TimeLine;
