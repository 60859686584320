// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-save-btn-disabled {
    text-align: center;
    border: none;
    background-color: grey;
    color: white;
    width: 7rem;
    height: 2.5rem;
    padding: 6px 12px;
    font-size: 1rem;
    border-radius: 4px;
}

.form-save-btn-enabled {
    border: none;
    background-color: #98dca1;
    color: white;
    width: 7rem;
    height: 2.5rem;
    padding: 6px 12px;
    font-size: 1rem;
    border-radius: 4px;
}

.opt-out-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.opt-out-btn {
    margin-right: 3rem;
}

.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    color: #0d6efd !important;
    font-weight: 300;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #495057 !important;
    font-weight: 300;
}`, "",{"version":3,"sources":["webpack://./src/components/LocationHeuristics/LocationHeuristics.styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;IACtB,YAAY;IACZ,WAAW;IACX,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;AACpB","sourcesContent":[".form-save-btn-disabled {\n    text-align: center;\n    border: none;\n    background-color: grey;\n    color: white;\n    width: 7rem;\n    height: 2.5rem;\n    padding: 6px 12px;\n    font-size: 1rem;\n    border-radius: 4px;\n}\n\n.form-save-btn-enabled {\n    border: none;\n    background-color: #98dca1;\n    color: white;\n    width: 7rem;\n    height: 2.5rem;\n    padding: 6px 12px;\n    font-size: 1rem;\n    border-radius: 4px;\n}\n\n.opt-out-box {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n}\n\n.opt-out-btn {\n    margin-right: 3rem;\n}\n\n.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {\n    color: #0d6efd !important;\n    font-weight: 300;\n}\n\n.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {\n    color: #495057 !important;\n    font-weight: 300;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
