const altProfileSourcNames = {
    leadership_page: "leadership_page",
};

const extSourceNames = {
    leadershipProfile: "leadership_page",
    LinkedIn: "LinkedIn",
};
const linkedInUidRegex = /linkedin\.com\/in\/([^\/?#]+)\/?/;
module.exports = { extSourceNames, altProfileSourcNames, linkedInUidRegex };
