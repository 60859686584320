import React, { Component } from "react";
import { IdleTimerProvider } from "react-idle-timer";
import { Modal } from "react-bootstrap";

const IdleTimer = React.forwardRef((props, ref) => {
  return (
    <IdleTimerProvider
      ref={ref}
      timeout={30 * 60 * 1000}
      {...props}
    ></IdleTimerProvider>
  );
});

class IdleComponent extends Component {
  constructor(props) {
    super(props);
    this.myInput = React.createRef();
    this.state = {
      displayModal: false,
      timerId: "",
    };
  }

  onIdle = () => {
    this.setState(
      {
        displayModal: true,
      },
      () => this.isUserStillIdle()
    );
  };

  isUserStillIdle = () => {
    if (this.state.displayModal) {
      let id = setTimeout(() => {
        this.props.logoutUser();
      }, 30000);
      this.setState({
        timerId: id,
      });
    } else {
      clearInterval(this.state.timerId);
      this.setState({
        timerId: "",
      });
    }
  };

  logMeOut = () => {
    this.setState(
      {
        timerId: "",
      },
      () => this.props.logoutUser()
    );
  };

  stayLoggedIn = () => {
    this.setState(
      {
        displayModal: false,
      },
      () => this.isUserStillIdle()
    );
  };

  render() {
    return (
      <div>
        <IdleTimer onIdle={this.onIdle}></IdleTimer>
        <Modal
          show={this.state.displayModal}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Body>
            <p className="text-center">You have been idle for 30 minutes.</p>
            <p className="text-center">Do you wish to stay logged in?</p>
            <div className="d-flex justify-content-around mt-5">
              <button
                type="button"
                className="modal-button mt-auto"
                style={{ backgroundColor: "grey", color: "white" }}
                onClick={this.logMeOut}
              >
                No, log me out
              </button>
              <button
                type="submit"
                className="modal-button mt-auto"
                style={{ backgroundColor: "#98dca1", color: "white" }}
                onClick={this.stayLoggedIn}
              >
                Yes, stay logged in
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default IdleComponent;
