import { Button, Divider, IconButton, InputBase, Paper, Stack, Tooltip } from "@mui/material";
import ProfileCard from "./ProfileCard";
import SearchBar from "../SeachBar/SearchBar";
import { useMemo, useState } from "react";
import ReactSelectDropdown from "../Common/ReactSelectDropdown";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchIcon from "@mui/icons-material/Search";
import { Switch } from "antd";

// {
//   "_id": "65a07d7c9d0b43a3caa2d6c2",
//   "profile_id": "pub_5c7bae970cadfda94cd1e462",
//   "account_id": "7of9oso8zkavwmnwnce1y",
//   "channel": "ats",
//   "icp_id": "63ff9d606bfe52b36b50ccc0",
//   "priority": "low",
//   "sandbox_name": "findem_ai_public_profiles",
//   "verification_state": {
//       "status": "unverified"
//   },
//   "verification_state_ts": 1705101568060,
//   "icp_name": "Test Assign 01",
//   "icp_search_link": "https://app-next.findem.ai/searches/63ff9d606bfe52b36b50ccc0?joid=625ee19aadb3d7d7c1ef7202&redirect_joid=625ee19aadb3d7d7c1ef7202",
//   "customer_name": "Findem",
//   "ep_link": "https://app-next.findem.ai/profiles/pub_5c7bae970cadfda94cd1e462?state=/searches/63ff9d606bfe52b36b50ccc0&icpid=63ff9d606bfe52b36b50ccc0&joid=63ff9d606bfe52b36b50ccc0&aId=7of9oso8zkavwmnwnce1y"
// }

const peDropdownFilterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: "any",
    stringify: (option) => `${option.data.customer_name} ${option.data.decoded_string} ${option.value} ${option.data.mapped_icp_type}`,
    trim: true,
};

const LeftPane = ({
    data,
    handleClickLeftPane,
    handleShowScrollButton,
    scrollToTopButtonRef,
    showScrollToTopButton,
    scrollToTop,
    selectedIndex,
    searchIcpRef,
    handleLoadMoreData,
    showLoadMoreButton,
    isLoading,
    peVerifiedIcpsDropdownLoading,
    peVerifiedIcps,
    handleOnChangePeVerifiedIcps,
    peDropdownSelectedValue,
    fetchData,
    page,
    setPage,
    endOfPageReached,
    scrollableContainerRef,
    populatePeVerifiedIcpsDropdown,
    showTaskAssignedTo,
    loggedInUserDetails,
    handleShowCustomerSearches,
    showCustomerSearches,
    handleRefreshData,
    handleIncrementIcpPage,
    showOnDemandSearches,
    handleShowOnDemandSearches,
    handleManualSearchIcpIdChange,
    manualSearchIcpId,
    handleFetchManualIcpId,
}) => {
    const handleNext = async () => {
        // console.log("starrr: ", scrollableContainerRef.current.scrollTop);
        // setTimeout(() => {
        //   console.log("BREAKPOINT");
        //   scrollableContainerRef.current.scrollTop = 0;
        // }, 8000);
        const icpId = peDropdownSelectedValue?.value ?? manualSearchIcpId?.trim();
        const position_to_scroll_to = scrollableContainerRef.current.scrollTop;
        await fetchData(true, page + 1, false, icpId, false, null, null);
        // TODO: revisit this - this is temp only - instead of timeout this can be solved
        // with better component hierarchy - isLoading ? <Component /> : <Loading /> may be
        // causes dismount of component which causes scroll of the container to reset at the top
        // instead of preserving position
        // also cant access the ref as its not been created yet is only explanation I can think of now
        // running in hte next event loop seems to work though is kind of confusing
        // is the dom not created or something related to event loop - not able to find now
        // setTimeout(
        //   () => {
        //     console.log(
        //       "I run after data is fetched",
        //       scrollableContainerRef,
        //       position_to_scroll_to
        //     );
        //     scrollableContainerRef.current.scrollTop = position_to_scroll_to;
        //   },
        //   0,
        //   position_to_scroll_to
        // );
        setPage((page) => page + 1);
    };

    const onMenuScrollToBottom = () => {
        // dont send another request if previous one isnt resolved yet
        if (!peVerifiedIcpsDropdownLoading) {
            handleIncrementIcpPage();
        }
    };

    return (
        <Stack>
            <div>
                <div
                    style={{
                        width: "500px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <Paper sx={{ my: 1, mx: 1 }}>
                        <ReactSelectDropdown
                            isClearable={false}
                            isDisabled={false}
                            isSearchable={true}
                            isLoading={peVerifiedIcpsDropdownLoading}
                            data={peVerifiedIcps}
                            name="PE verified icps"
                            onChange={handleOnChangePeVerifiedIcps}
                            placeholder={"Select PE verified icps"}
                            value={peDropdownSelectedValue}
                            width={"500px"}
                            filterOption={peDropdownFilterConfig}
                            onMenuScrollToBottom={onMenuScrollToBottom}
                        />
                    </Paper>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", margin: "0.1em" }}>
                    <div style={{ margin: "0.5em 1em" }}>
                        <Switch
                            unCheckedChildren="Preprocessing"
                            checkedChildren="On Demand"
                            checked={showOnDemandSearches}
                            onChange={handleShowOnDemandSearches}
                            style={{ marginBottom: "4px" }}
                        />
                        <Tooltip title="This option can only be 'Customer Searches' when 'Preprocessing' is selected above">
                            <Switch
                                disabled={!showOnDemandSearches}
                                unCheckedChildren="Show All Searches"
                                checkedChildren="Show Customer Searches Only"
                                checked={showCustomerSearches}
                                onChange={handleShowCustomerSearches}
                                style={{ display: "block" }}
                            />
                        </Tooltip>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Tooltip title="Fetch data with the selected options applied. Clears previously fetched data.">
                            <Button onClick={populatePeVerifiedIcpsDropdown} variant="outlined" disabled={peVerifiedIcpsDropdownLoading}>
                                Apply Changes
                            </Button>
                        </Tooltip>
                    </div>
                </div>
                <Divider sx={{ my: 1 }} />
                <Paper
                    elevation={3}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "0.5em",
                        gap: "4px",
                    }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Enter Icp ID"
                        value={manualSearchIcpId}
                        onChange={handleManualSearchIcpIdChange}
                        inputProps={{ "aria-label": "Enter Icp ID" }}
                    />
                    <IconButton type="button" sx={{ p: "10px" }} aria-label="Enter Icp ID" onClick={handleFetchManualIcpId}>
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </div>
            <Divider sx={{ mt: 1 }} />
            <div
                id="scrollable-div"
                ref={scrollableContainerRef}
                style={{
                    width: "500px",
                    overflowY: "scroll",
                    height: "60vh",
                    position: "relative",
                }}
                // onScroll={handleShowScrollButton}
            >
                <Stack>
                    <InfiniteScroll
                        dataLength={data.length}
                        next={handleNext}
                        hasMore={!endOfPageReached}
                        loader={data.length > 0 ? <h4 style={{ textAlign: "center" }}>Loading...</h4> : null}
                        scrollableTarget="scrollable-div"
                    >
                        {data.map((datum, index) => {
                            if (!showTaskAssignedTo && loggedInUserDetails.email !== datum.assigned_user) {
                                return undefined;
                            }
                            return (
                                <div
                                    onClick={handleClickLeftPane(index)}
                                    key={index}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <>
                                        <ProfileCard key={index} data={datum} highlighted={selectedIndex === index} />
                                        <Divider />
                                    </>
                                </div>
                            );
                        })}
                    </InfiniteScroll>
                    {showLoadMoreButton && !isLoading && (
                        <Button sx={{ my: 2, py: 1, mx: 2 }} variant="contained" onClick={handleLoadMoreData}>
                            Load More Data
                        </Button>
                    )}
                </Stack>
                {
                    // TODO: Get the scroll displaying properly
                }
                {/* {!showScrollToTopButton && (
        <Button
          style={{ position: "absolute", top: "0" }}
          onClick={scrollToTop}
        >
        </Button>
      )} */}
            </div>
        </Stack>
    );
};

export default LeftPane;
