import { Alert, Box, Button, CircularProgress, Divider, Input, Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import { Collapse, Layout, List, message, Progress } from "antd";
import { Content } from "antd/lib/layout/layout";
import { PieChart } from "@mui/x-charts/PieChart";
import Sider from "antd/lib/layout/Sider";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LinearProgress from "@mui/material/LinearProgress";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

import { url } from "../../url";
import dayjs from "dayjs";
import UserContext from "../../UserContext";
import { useOutletContext } from "react-router-dom";
import FindemLogo from "../utils/FindemLogo/FindemLogo";
import HeaderComponent from "../Header/Header";
import TabsHomePage from "../Home/TabsHomePage";
import ProfileCreatorForm from "../DummyProfileCreator/ProfileCreatorForm";
import FilterToolbar from "./FilterToolbar";
const { Panel } = Collapse;

const LeadershipProfile = ({ loggedInUserDetails, logoutUser }) => {
    const [cxoProfiles, setCxoProfiles] = useState([]);
    const [filterQuery, setFilterQuery] = useState();
    const [page, setPage] = useState(-1);
    const [hasMoreCxoProfiles, setHasMoreCxoProfiles] = useState(true);
    const [selectedProfile, setSelectedProfile] = useState();
    const [dev, setDev] = useState("prod");

    const fetchCxoProfiles = async (resetPage) => {
        try {
            const response = await axios.get(url.leadershipProfile.getProfiles, {
                params: { page: resetPage ? 0 : page + 1, filterQuery: filterQuery },
            });
            if (resetPage) {
                setCxoProfiles(response?.data?.data);
            } else {
                setCxoProfiles([...cxoProfiles, ...response.data.data]);
            }
            if (response.data.data.length == 0) {
                setHasMoreCxoProfiles(false);
                return;
            }

            setPage(resetPage ? 0 : page + 1);
            setHasMoreCxoProfiles(true);
        } catch (error) {
            console.log("Failed to fetch profiles:", error);
            message.error("Failed to fetch profiles!");
        }
    };

    const openProfileCreator = async (profile) => {
        try {
            setSelectedProfile();
            const response = await axios.get(url.leadershipProfile.getProfile, { params: { id: profile._id } });
            const filteredProfile = prepareProfileForEdit(response?.data?.data);
            setSelectedProfile(filteredProfile);
        } catch (error) {
            console.log("Failed to get profile:", error);
            message.error("Failed to get profile!");
        }
    };

    const handlePostCreatedProfile = async (createdProfile) => {
        try {
            const response = await axios.post(url.createPublicProfile, {
                newPublicProfile: createdProfile,
                profileType: "leadership",
                profileId: selectedProfile?.profile_id,
            });
            message.success(response?.data?.message);
            fetchCxoProfiles(true);
        } catch (error) {
            console.log("Failed to create new profile:", error);
            if (error?.response?.data?.message) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Failed to create new profile!");
        }
    };

    useEffect(() => {
        fetchCxoProfiles(true);
    }, [filterQuery]);

    if (dev != "pleease") {
        return (
            <>
                <Alert severity="info">Under Development @prateek.kumar@findem.ai</Alert>
                <Input onChange={(event) => setDev(event.target.value)} />
            </>
        );
    }

    if (dev === "pleease") {
        return (
            <>
                <HeaderComponent loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser} />
                <div className="pe-5 ps-5">
                    <TabsHomePage />
                </div>
                <FilterToolbar filterQuery={filterQuery} setFilterQuery={setFilterQuery} />
                <Layout
                    style={{
                        background: "none",
                        display: "flex",
                        flexDirection: "row",
                        margin: "0px 1rem",
                        justifyContent: "center",
                        padding: "5px",
                        borderRadius: "10px",

                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                >
                    <Sider
                        id="inifiniteSlider"
                        width={"28%"}
                        style={{
                            position: "relative",
                            background: "none",
                            borderRight: "2px solid gray",
                            overflow: "auto",
                            height: "80vh",
                            position: "relative",
                            overflowY: "auto",
                        }}
                    >
                        <InfiniteScroll
                            dataLength={cxoProfiles?.length}
                            next={fetchCxoProfiles}
                            hasMore={hasMoreCxoProfiles}
                            loader={<h4>Loading...</h4>}
                            scrollableTarget={"inifiniteSlider"}
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    <b>You have seen all the profiles!</b>
                                </p>
                            }
                        >
                            {cxoProfiles?.map((item) => {
                                return (
                                    <>
                                        <List.Item
                                            key={item._id}
                                            onClick={() => openProfileCreator(item)}
                                            style={{
                                                cursor: "pointer",
                                                backgroundColor: selectedProfile?._id === item._id ? "#E0FBE2" : "transparent",
                                                borderRadius: "5px",
                                                padding: "0.7rem 1.5rem 0.7rem 1.5rem",
                                            }}
                                        >
                                            <Box width={"100%"} display={"flex"} flexDirection={"column"}>
                                                <Stack>
                                                    <Stack>
                                                        <Typography sx={{ fontWeight: "bold" }}>{item?.candidate?.full_name}</Typography>
                                                        <Typography fontSize={"0.8rem"} sx={{ opacity: "0.5" }}>
                                                            {item?.candidate?.title}
                                                        </Typography>
                                                    </Stack>
                                                    <Typography fontSize={"0.9rem"}>Company Name: {item?.company_name}</Typography>
                                                    <Typography>
                                                        Scraping Url:{" "}
                                                        <a
                                                            href={item?.scraping_url}
                                                            style={{
                                                                maxWidth: "300px",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                                display: "inline-block",
                                                                verticalAlign: "bottom",
                                                            }}
                                                            target="_blank"
                                                        >
                                                            {item?.scraping_url}
                                                        </a>
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </List.Item>
                                        <Divider></Divider>
                                    </>
                                );
                            })}
                        </InfiniteScroll>
                    </Sider>
                    {selectedProfile && (
                        <Content style={{ display: "flex", flexDirection: "column" }}>
                            <ProfileCreatorForm profileData={selectedProfile} handlePostCreatedProfile={handlePostCreatedProfile} />
                        </Content>
                    )}
                    {!selectedProfile && (
                        <Content style={{ display: "flex", opacity: "0.5" }}>
                            <div
                                style={{
                                    flexGrow: "1",
                                    textAlign: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <div>
                                    <AdsClickIcon sx={{ marginRight: "6px" }} />
                                    Select left pane to display the details.
                                </div>
                            </div>
                        </Content>
                    )}
                </Layout>
            </>
        );
    }
};

const prepareProfileForEdit = (profileData) => {
    profileData["created_at"] = dayjs();
    profileData["updated_at"] = dayjs();
    profileData["ext_refs"] = [
        {
            source_ref: profileData?.profile_id,
            source: "leadershipPage",
            url: profileData?.scraping_url,
        },
    ];
    profileData["ext_src"] = "leadershipPage";
    profileData["ext_src_ref"] = profileData?.profile_id;

    return profileData;
};
export default LeadershipProfile;
