import React, { Component } from "react";
import findemLogo from "../images/findem-logo-2.png";
import carouselImage1 from "../images/carousel-login-1.jpeg";
import carouselImage2 from "../images/carousel-login-2.png";
import carouselImage3 from "../images/carousel-login-3.png";
import validator from "validator";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { url } from "../../url";
import { useLocation } from "react-router-dom";

class LoginPageClass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userEmail: "",
      userPassword: "",
      userEmailError: "",
      userPasswordError: "",
      toggleDisplayPassword: "password",
      eyeIconDisplayPassword: "none",
      eyeSlashIconDisplayPassword: "in-line",
      loginErrorMessages: "",
      loginErrorsDisplayAlert: "none",
    };
  }

  handleInputChange = (event) => {
    if (event.target.name === "email") {
      if (!validator.isEmail(event.target.value)) {
        this.setState({
          userEmail: event.target.value,
          userEmailError: "Please enter a valid email address",
        });
      } else {
        this.setState({
          userEmail: event.target.value,
          userEmailError: "",
        });
      }
    } else {
      if (event.target.value.length < 0) {
        this.setState({
          userPasswordError: "Please enter a valid password",
        });
      } else {
        this.setState({
          userPassword: event.target.value,
          userPasswordError: "",
        });
      }
    }
  };

  handleFormSubmission = (event) => {
    event.preventDefault();
    if (!validator.isEmail(event.target[0].value)) {
      this.setState({
        userEmailError: "Please enter a valid email address",
      });
    } else {
      this.setState({
        userEmailError: "",
      });
    }

    if (event.target[1].value.length === 0) {
      this.setState({
        userPasswordError: "Please enter a valid password",
      });
    } else {
      this.setState({
        userPasswordError: "",
      });
    }

    if (
      this.state.userEmail &&
      this.state.userPassword &&
      !this.state.userEmailError &&
      !this.state.userPasswordError
    ) {
      let data = {
        email: this.state.userEmail,
        password: this.state.userPassword,
        rememberMe: event.target[2].checked,
      };
      axios.post(url.login, data).then((res) => {
        if (!res.data.success) {
          this.setState({
            loginErrorMessages: res.data.message,
            loginErrorsDisplayAlert: "block",
          });
        } else {
          this.setState(
            {
              loginErrorMessages: "",
              loginErrorsDisplayAlert: "none",
            },
            () => {
              //sending logged in user details
              this.props.resFromServer(res.data.user);
            }
          );
        }
      });
    } else {
      console.log("Validation Error");
    }
  };

  showPassword = () => {
    this.setState({
      toggleDisplayPassword: "text",
      eyeIconDisplayPassword: "in-line",
      eyeSlashIconDisplayPassword: "none",
    });
  };

  hidePassword = () => {
    this.setState({
      toggleDisplayPassword: "password",
      eyeIconDisplayPassword: "none",
      eyeSlashIconDisplayPassword: "inline",
    });
  };

  responseSuccessGoogleLogin = (response) => {
    const decodedDetails = jwt_decode(response.credential);
    axios
      .post(url.googleLogin, decodedDetails)
      .then((res) => {
        if (!res.data.success) {
          this.setState({
            loginErrorMessages: res.data.message,
            loginErrorsDisplayAlert: "block",
          });
        } else {
          this.setState(
            {
              loginErrorMessages: "",
              loginErrorsDisplayAlert: "none",
            },
            () => {
              //sending logged in user details
              this.props.resFromServer(res.data.user);
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  responseErrorGoogleLogin = (error) => {
    console.log(error);
  };

  render() {
    if (this.props.loggedInSessionUser.name || this.props.loggedInUser.name) {
      return <Navigate to={this.props.pathname + this.props.search} />;
    } else {
      return (
        <GoogleOAuthProvider clientId={url.googleClientId}>
          <div>
            {/* Login Error Messages */}
            <div
              className={`alert alert-danger alert-dismissible fade show text-center d-${this.state.loginErrorsDisplayAlert}`}
              role="alert"
            >
              {this.state.loginErrorMessages}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => {
                  this.setState({
                    loginErrorsDisplayAlert: "none",
                    loginErrorMessages: "",
                  });
                }}
              ></button>
            </div>
            <div className="container bg-container">
              <div className="row row-container">
                <div className="col-6 carousel-container p-4">
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide h-50"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner mb-5 h-100 text-center">
                      <div className="carousel-item active">
                        <div className="carousel-image-container mb-5">
                          <img
                            className="carousel-image-2"
                            src={carouselImage2}
                            alt="carouselImage1"
                          />
                        </div>
                        <p>Findem Data Research App</p>
                      </div>
                      <div className="carousel-item">
                        <div className="carousel-image-container mb-5">
                          <div className="text-center">
                            <img
                              className="carousel-image-1 mb-3"
                              src={carouselImage2}
                              alt="carouselImage1"
                            />
                          </div>
                          <img
                            className="carousel-image-1-1"
                            src={carouselImage1}
                            alt="carouselImage1"
                          />
                        </div>
                        <p>Findem Data Research App</p>
                      </div>
                      <div className="carousel-item">
                        <div className="carousel-image-container mb-5">
                          <img
                            className="carousel-image-3"
                            src={carouselImage3}
                            alt="carouselImage1"
                          />
                        </div>
                        <p>Findem Data Research App</p>
                      </div>
                    </div>

                    <div className="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                        aria-label="Slide 1"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="2"
                        aria-label="Slide 3"
                      ></button>
                    </div>
                  </div>
                </div>

                <div className="col-6 p-4">
                  <div className="d-flex flex-column align-items-center mb-5">
                    <img
                      className="login-findem-logo"
                      src={findemLogo}
                      alt={"findem logo"}
                    />
                  </div>

                  {/* <p className="mt-5">Don't have an account? <Link to="/register">Register</Link></p> */}
                  <form
                    onSubmit={this.handleFormSubmission}
                    noValidate="noValidate"
                  >
                    <h4>LOGIN</h4>
                    <div className="mb-3">
                      <label htmlFor="userEmail" className="form-label">
                        Email address
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="userEmail"
                        value={this.state.userEmail}
                        onChange={this.handleInputChange}
                      />
                      <p className="text-danger">{this.state.userEmailError}</p>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="userPassword" className="form-label">
                        Password
                      </label>
                      <div className="d-flex justify-content-between align-items-baseline">
                        <input
                          type={this.state.toggleDisplayPassword}
                          name="password"
                          className="form-control"
                          autoComplete="on"
                          id="userPassword"
                          value={this.state.userPassword}
                          onChange={this.handleInputChange}
                        />
                        <div className="d-flex justify-content-between">
                          <i
                            className={`fa-solid fa-eye d-${this.state.eyeIconDisplayPassword}`}
                            onClick={this.hidePassword}
                          ></i>
                          <i
                            className={`fa-solid fa-eye-slash d-${this.state.eyeSlashIconDisplayPassword}`}
                            onClick={this.showPassword}
                          ></i>
                        </div>
                      </div>
                      <p className="text-danger">
                        {this.state.userPasswordError}
                      </p>
                    </div>
                    <div className="mb-3 d-flex justify-content-between">
                      <div>
                        <input
                          type="checkbox"
                          className="me-1"
                          name="isRemembered"
                          id="rememberMe"
                          style={{ cursor: "pointer" }}
                        />
                        <label
                          htmlFor="rememberMe"
                          style={{ cursor: "pointer" }}
                        >
                          Remember me
                        </label>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Login
                    </button>

                    <p className="oAuthOptions">Or Login With</p>
                    <div className="d-flex justify-content-center mt-3">
                      <GoogleLogin
                        clientId={url.googleClientId}
                        buttonText="GOOGLE"
                        onSuccess={this.responseSuccessGoogleLogin}
                        onError={this.responseErrorGoogleLogin}
                        cookiePolicy={"single_host_origin"}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </GoogleOAuthProvider>
      );
    }
  }
}
const LoginPage = (props) => {
  const { state } = useLocation();
  return (
    <LoginPageClass
      {...props}
      pathname={state?.pathname || "/"}
      search={state?.search || ""}
    />
  );
};
export default LoginPage;
