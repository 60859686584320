export const extractLinkedInId = (url) => {
    const regex = /\/in\/([^/]+)/;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return null;
}

export const isValidLinkedinUrl = (url) => {
    const linkedinRegex = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/;
  
    return linkedinRegex.test(url);
}

export const ICPType = {
  UserDefined : 0,
  Rediscovery : 1, // This is automatic created ICPs will not have metadata.
  Temporary : 2, // Temporary ICP created for analytics etc.
  Job : 3, // icp for jobs
  SelfService : 4, // icp for jobs
  SourcingAccelerator : 5, // SA Internal jobs
  Preprocessing : 6, // Preprocessing ICP
  ExecMap : 7 // ExecMap ICP
}

