import React, { useEffect, useState } from "react";
// import "./index.css";
import { CloseOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import axios from "axios";
import { PlusOutlined, MinusCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import _ from "lodash";
import { Button, Card, Form, Input, Space, InputNumber, Tabs, Spin, Modal, message, Select, DatePicker, Typography, Checkbox } from "antd";
import dayjs from "dayjs";
import ArrOfStrUIHandler from "../ArrOfStrUIHandler";
import ProjectForm from "./ProjectForm";
import DurationCard from "./DurationCard";
import AccoladesForm from "./AccoladesForm";
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Delete } from "@mui/icons-material";

const WorkExpForm = ({ form }) => {
    return (
        <Card size="small" title="Work Experience">
            <Form.List name="workexp">
                {(workFields, { add: addWorkExp, remove: removeWorkExp }) => (
                    <>
                        {workFields.map(({ key, name }) => {
                            return (
                                <Accordion
                                    sx={{
                                        backgroundColor: "#e3f2fd",
                                        marginBottom: 2,
                                        borderRadius: 1,
                                        boxShadow: 3,
                                        "&:hover": {
                                            boxShadow: "0 4px 10px rgba(25, 118, 210, 0.3)",
                                        },
                                    }}
                                >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
                                            <Typography>{`Experience ${key + 1}`}</Typography>
                                            <Box display="flex" alignItems="center">
                                                <IconButton size="small">
                                                    <Delete onClick={() => removeWorkExp(name)} />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {/* Projects */}
                                        <ProjectForm form={form} fieldPath={["workexp", name]} name={[name, "projects"]} />
                                        {/* Company Info*/}
                                        <Card size="small" title="Company">
                                            <Form.Item
                                                label={"name"}
                                                name={[name, "company", "name"]}
                                                rules={[{ required: true, message: "This field is required!" }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            {["company_id", "lnkd_company_id", "domain"].map((el, i) => {
                                                return (
                                                    <Form.Item key={i} label={el} name={[name, "company", el]}>
                                                        <Input />
                                                    </Form.Item>
                                                );
                                            })}
                                            <Form.Item label={"location"} name={[name, "company", "location"]}>
                                                <Input placeholder="e.g. Bengaluru, Karnataka, India" />
                                            </Form.Item>

                                            {/* Location Parsed */}
                                            <Card size="small" title="Location">
                                                <Form.Item label="City" name={[name, "company", "location_parsed", "city"]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item label="Country" name={[name, "company", "location_parsed", "country"]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item label="State" name={[name, "company", "location_parsed", "state"]}>
                                                    <Input />
                                                </Form.Item>
                                            </Card>
                                        </Card>

                                        {/* Duration */}
                                        <DurationCard fieldPath={["workexp", name]} name={name} form={form} />
                                        <AccoladesForm fieldPath={["workexp", name]} name={[name, "accolades"]} form={form}></AccoladesForm>

                                        {/* Investment Info*/}
                                        {/* <Card size="small" title="Investment Info">
                                        {[
                                            "series",
                                            "amount",
                                            "valuation",
                                            "investment_capacity",
                                            "partner_id",
                                            "is_lead",
                                            "title",
                                        ].map((el, i) => {
                                            return (
                                                <Form.Item key={i} label={el} name={[name, "investment_info", el]}>
                                                    <Input />
                                                </Form.Item>
                                            );
                                        })}
                                        <Form.Item label="investment_date" name={[name, "investment_info", "investment_date"]}>
                                            <DatePicker />
                                        </Form.Item> */}

                                        {/* Investment Firm */}
                                        {/* <Card size="small" title="Investment Firm">
                                            {[
                                                "company_id",
                                                "name",
                                                "lnkd_company_id",
                                                "lnkd_company_src_id",
                                                "twitter_handle",
                                                "crunchbase_url",
                                                "domain",
                                                "location",
                                                "err_string",
                                            ].map((el, i) => {
                                                return (
                                                    <Form.Item key={i} label={el} name={[name, "investment_info", "investment_firm", el]}>
                                                        <Input />
                                                    </Form.Item>
                                                );
                                            })} */}

                                        {/* Location Parsed */}
                                        {/* <Form.Item
                                                label="City"
                                                name={[name, "investment_info", "investment_firm", "location_parsed", "city"]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label="Country"
                                                name={[name, "investment_info", "investment_firm", "location_parsed", "country"]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label="State"
                                                name={[name, "investment_info", "investment_firm", "location_parsed", "state"]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Card> */}

                                        {/* Investment Firm Types */}
                                        {/* <ArrOfStrUIHandler
                                            name={name}
                                            cardLabel={"Investment Firm Type"}
                                            key1={"investment_info"}
                                            key2={"investment_firm_types"}
                                        /> */}

                                        {/* Lead Investment Firms */}
                                        {/* <Card size="small" title="Lead Investment Firms">
                                            <Form.List name={[name, "investment_info", "lead_investment_firms"]}>
                                                {(
                                                    leadInvestmentFirmsFields,
                                                    { add: addLeadInvestmentFirm, remove: removeLeadInvestmentFirm }
                                                ) => (
                                                    <>
                                                        {leadInvestmentFirmsFields.map((leadInvestmentFirmField) => (
                                                            <div
                                                                key={leadInvestmentFirmField.key}
                                                                // style={{ display: "flex", marginBottom: 8 }}
                                                                align="baseline"
                                                            >
                                                                <div
                                                                    style={{
                                                                        // border: "2px solid red",
                                                                        display: "flex",
                                                                        justifyContent: "end",
                                                                        alignItems: "end",
                                                                        marginBottom: "15px",
                                                                    }}
                                                                >
                                                                    <MinusCircleOutlined
                                                                        onClick={() =>
                                                                            removeLeadInvestmentFirm(leadInvestmentFirmField.name)
                                                                        }
                                                                    />
                                                                </div>
                                                                <Form.Item
                                                                    name={[leadInvestmentFirmField.name, "company_id"]}
                                                                    fieldKey={[leadInvestmentFirmField.fieldKey, "company_id"]}
                                                                    label="Company ID"
                                                                >
                                                                    <Input disabled={true} placeholder="Company ID" />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[leadInvestmentFirmField.name, "name"]}
                                                                    fieldKey={[leadInvestmentFirmField.fieldKey, "name"]}
                                                                    label="Company Name"
                                                                >
                                                                    <Input disabled={true} placeholder="Company Name" />
                                                                </Form.Item>
                                                            </div>
                                                        ))}
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => addLeadInvestmentFirm()}
                                                            block
                                                            icon={<PlusOutlined />}
                                                        >
                                                            Add Lead Investment Firm
                                                        </Button>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Card> */}

                                        {/* Investment Category */}
                                        {/* <ArrOfStrUIHandler
                                            name={name}
                                            cardLabel={"Investment Category"}
                                            key1={"investment_info"}
                                            key2={"investment_category"}
                                        /> */}

                                        {/* investment_subcategory */}
                                        {/* <ArrOfStrUIHandler
                                            name={name}
                                            cardLabel={"Investment Sub Category"}
                                            key1={"investment_info"}
                                            key2={"investment_subcategory"}
                                        /> */}

                                        {/* Company */}
                                        {/* <Card size="small" title="Company">
                                            {[
                                                "company_id",
                                                "name",
                                                "lnkd_company_id",
                                                "lnkd_company_src_id",
                                                "twitter_handle",
                                                "crunchbase_url",
                                                "domain",
                                                "location",
                                                "err_string",
                                            ].map((el, i) => {
                                                return (
                                                    <Form.Item key={i} label={el} name={[name, "investment_info", "company", el]}>
                                                        <Input />
                                                    </Form.Item>
                                                );
                                            })} */}

                                        {/* Location Parsed */}
                                        {/* <Form.Item label="City" name={[name, "investment_info", "company", "location_parsed", "city"]}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label="Country"
                                                name={[name, "investment_info", "company", "location_parsed", "country"]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label="State"
                                                name={[name, "investment_info", "company", "location_parsed", "state"]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Card>
                                    </Card> */}

                                        {/* Executive Profile */}
                                        {/* <Card size="small" title="Executive Profile">
                                        <Form.List name={[name, "exec_profile"]}>
                                            {(execProfileFields, { add: addExecProfile, remove: removeExecProfile }) => (
                                                <>
                                                    {execProfileFields.map((execProfileField, fieldKey) => {
                                                        return (
                                                            <Card
                                                                key={fieldKey}
                                                                size="small"
                                                                title={`Exec Profile ${fieldKey + 1}`}
                                                                extra={
                                                                    <MinusCircleOutlined
                                                                        onClick={() => removeExecProfile(execProfileField.name)}
                                                                    />
                                                                }
                                                                style={{
                                                                  borderColor: inputBorderColor,
                                                                }}
                                                            >
                                                                <div key={execProfileField.key}> */}
                                        {/* Fields for exec_profile */}

                                        {/* {[
                                                                        "source",
                                                                        "update_date",
                                                                        "exec_id",
                                                                        "est_compensation",
                                                                        "compensation_currency",
                                                                        "exec_name",
                                                                        "resolved",
                                                                        "ingested",
                                                                        "linkedin",
                                                                    ].map((el, i) => {
                                                                        return (
                                                                            <Form.Item key={i} label={el} name={[name, el]}>
                                                                                <Input />
                                                                            </Form.Item>
                                                                        );
                                                                    })} */}

                                        {/* Executive Titles */}
                                        {/* <Form.List name={[execProfileField.name, "exec_titles"]}>
                                                                        {(
                                                                            execTitlesFields,
                                                                            { add: addExecTitle, remove: removeExecTitle }
                                                                        ) => (
                                                                            <>
                                                                                {execTitlesFields.map((execTitleField, fieldKey) => (
                                                                                    <Card
                                                                                        size="small"
                                                                                        key={fieldKey}
                                                                                        title={`Exec Title ${fieldKey + 1}`}
                                                                                        extra={
                                                                                            <MinusCircleOutlined
                                                                                                onClick={() =>
                                                                                                    removeExecTitle(execTitleField.name)
                                                                                                }
                                                                                            />
                                                                                        }
                                                                                    >
                                                                                        <div
                                                                                            key={execTitleField.key}
                                                                                            style={{
                                                                                                marginBottom: 8,
                                                                                            }}
                                                                                        >
                                                                                            {[
                                                                                                "title",
                                                                                                "org_id",
                                                                                                "org_name",
                                                                                                "exec_id",
                                                                                                "rel_level",
                                                                                                "abs_level",
                                                                                            ].map((el, i) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <Form.Item
                                                                                                            key={i}
                                                                                                            name={[execTitleField.name, el]}
                                                                                                            label={el}
                                                                                                        >
                                                                                                            <Input />
                                                                                                        </Form.Item>
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                        </div>
                                                                                    </Card>
                                                                                ))}
                                                                                <Button
                                                                                    type="dashed"
                                                                                    onClick={() => addExecTitle()}
                                                                                    block
                                                                                    icon={<PlusOutlined />}
                                                                                >
                                                                                    Add Executive Title
                                                                                </Button>
                                                                            </>
                                                                        )}
                                                                    </Form.List> */}

                                        {/* Reportee PRIDs */}
                                        {/* <Card size="small" title="Reportee PRIDs">
                                                                        <Form.List name={[execProfileField.name, "reportee_prids"]}>
                                                                            {(
                                                                                reporteePridsFields,
                                                                                { add: addReporteePrid, remove: removeReporteePrid }
                                                                            ) => (
                                                                                <>
                                                                                    {reporteePridsFields.map((reporteePridField) => (
                                                                                        <Space
                                                                                            key={reporteePridField.key}
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                marginBottom: 8,
                                                                                            }}
                                                                                            align="baseline"
                                                                                        >
                                                                                            <Form.Item
                                                                                                name={[
                                                                                                    reporteePridField.name,
                                                                                                    "reportee_prids",
                                                                                                ]}
                                                                                                fieldKey={[
                                                                                                    reporteePridField.fieldKey,
                                                                                                    "reportee_prids",
                                                                                                ]}
                                                                                            >
                                                                                                <Input
                                                                                                    placeholder="Reportee PRID"
                                                                                                    addonAfter={
                                                                                                        <MinusCircleOutlined
                                                                                                            onClick={() =>
                                                                                                                removeReporteePrid(
                                                                                                                    reporteePridField.name
                                                                                                                )
                                                                                                            }
                                                                                                        />
                                                                                                    }
                                                                                                />
                                                                                            </Form.Item>
                                                                                        </Space>
                                                                                    ))}
                                                                                    <Button
                                                                                        type="dashed"
                                                                                        onClick={() => addReporteePrid()}
                                                                                        block
                                                                                        icon={<PlusOutlined />}
                                                                                    >
                                                                                        Add Reportee PRID
                                                                                    </Button>
                                                                                </>
                                                                            )}
                                                                        </Form.List>
                                                                    </Card> */}

                                        {/* Reports to PRIDs */}
                                        {/* <Card size="small" title="Reports to PRIDs">
                                                                        <Form.List name={[execProfileField.name, "reports_to_prids"]}>
                                                                            {(
                                                                                reporteePridsFields,
                                                                                { add: addReporteePrid, remove: removeReporteePrid }
                                                                            ) => (
                                                                                <>
                                                                                    {reporteePridsFields.map((reporteePridField) => (
                                                                                        <Space
                                                                                            key={reporteePridField.key}
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                marginBottom: 8,
                                                                                            }}
                                                                                            align="baseline"
                                                                                        >
                                                                                            <Form.Item
                                                                                                name={[
                                                                                                    reporteePridField.name,
                                                                                                    "reports_to_prids",
                                                                                                ]}
                                                                                                fieldKey={[
                                                                                                    reporteePridField.fieldKey,
                                                                                                    "reports_to_prids",
                                                                                                ]}
                                                                                            >
                                                                                                <Input
                                                                                                    placeholder="Reports to PRID"
                                                                                                    addonAfter={
                                                                                                        <MinusCircleOutlined
                                                                                                            onClick={() =>
                                                                                                                removeReporteePrid(
                                                                                                                    reporteePridField.name
                                                                                                                )
                                                                                                            }
                                                                                                        />
                                                                                                    }
                                                                                                />
                                                                                            </Form.Item>
                                                                                        </Space>
                                                                                    ))}
                                                                                    <Button
                                                                                        type="dashed"
                                                                                        onClick={() => addReporteePrid()}
                                                                                        block
                                                                                        icon={<PlusOutlined />}
                                                                                    >
                                                                                        Add Reports to PRID
                                                                                    </Button>
                                                                                </>
                                                                            )}
                                                                        </Form.List>
                                                                    </Card>
                                                                </div>
                                                            </Card>
                                                        );
                                                    })}
                                                    <Button type="dashed" onClick={() => addExecProfile()} block icon={<PlusOutlined />}>
                                                        Add Executive Profile
                                                    </Button>
                                                </>
                                            )}
                                        </Form.List>
                                    </Card> */}

                                        {/* Employee Profile */}
                                        {/* <Card size="small" title="Employee Profile">
                                        {[
                                            "id",
                                            "department",
                                            "category",
                                            "job_title",
                                            "cost_center",
                                            "level",
                                            "age",
                                            "radford_number",
                                            "radford_level",
                                            "updated_at",
                                        ].map((el, i) => {
                                            return (
                                                <Form.Item key={i} label={el} name={[name, "employee_profile", el]}>
                                                    <Input />
                                                </Form.Item>
                                            );
                                        })} */}

                                        {/* CSAT */}
                                        {/* <Form.List name={[name, "employee_profile", "csat"]}>
                                            {(csatFields, { add: addCsat, remove: removeCsat }) => (
                                                <>
                                                    {csatFields.map((csatField) => (
                                                        <Card
                                                            size="small"
                                                            title="CSAT"
                                                            extra={<MinusCircleOutlined onClick={() => removeCsat(csatField.name)} />}
                                                        >
                                                            <div
                                                                key={csatField.key}
                                                                // style={{ display: "flex", marginBottom: 8 }}
                                                                align="baseline"
                                                            >
                                                                <Form.Item
                                                                    name={[csatField.name, "score"]}
                                                                    fieldKey={[csatField.fieldKey, "score"]}
                                                                    label="Score"
                                                                >
                                                                    <InputNumber placeholder="Score" />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[csatField.name, "start_date"]}
                                                                    fieldKey={[csatField.fieldKey, "start_date"]}
                                                                    label="Start Date"
                                                                >
                                                                    <DatePicker placeholder="Score" />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[csatField.name, "end_date"]}
                                                                    fieldKey={[csatField.fieldKey, "end_date"]}
                                                                    label="End Date"
                                                                >
                                                                    <DatePicker placeholder="Score" />
                                                                </Form.Item>
                                                            </div>
                                                        </Card>
                                                    ))}
                                                    <Button type="dashed" onClick={() => addCsat()} block icon={<PlusOutlined />}>
                                                        Add CSAT Score
                                                    </Button>
                                                </>
                                            )}
                                        </Form.List> */}

                                        {/* Attainment */}
                                        {/* <Form.List name={[name, "employee_profile", "attainment"]}>
                                            {(attainmentFields, { add: addAttainment, remove: removeAttainment }) => (
                                                <>
                                                    {attainmentFields.map((attainementField) => (
                                                        <Card
                                                            size="small"
                                                            title="Attainment"
                                                            extra={
                                                                <MinusCircleOutlined
                                                                    onClick={() => removeAttainment(attainementField.name)}
                                                                />
                                                            }
                                                        >
                                                            <div
                                                                key={attainementField.key}
                                                                style={{ display: "flex", marginBottom: 8 }}
                                                                align="baseline"
                                                            >
                                                                <Form.Item
                                                                    name={[attainementField.name, "score"]}
                                                                    fieldKey={[attainementField.fieldKey, "score"]}
                                                                    label="Score"
                                                                >
                                                                    <InputNumber placeholder="Score" />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    name={[attainementField.name, "start_date"]}
                                                                    fieldKey={[attainementField.fieldKey, "start_date"]}
                                                                    label="Start Date"
                                                                >
                                                                    <DatePicker placeholder="Start Date" />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    name={[attainementField.name, "end_date"]}
                                                                    fieldKey={[attainementField.fieldKey, "end_date"]}
                                                                    label="End Date"
                                                                >
                                                                    <DatePicker placeholder="End Date" />
                                                                </Form.Item>
                                                            </div>
                                                        </Card>
                                                    ))}
                                                    <Button type="dashed" onClick={() => addAttainment()} block icon={<PlusOutlined />}>
                                                        Add Attainement
                                                    </Button>
                                                </>
                                            )}
                                        </Form.List> */}

                                        {/* Survey */}
                                        {/* <Form.List name={[name, "employee_profile", "survey"]}>
                                            {(surveyFields, { add: addSurvey, remove: removeSurvey }) => (
                                                <>
                                                    {surveyFields.map((surveyField) => (
                                                        <Card
                                                            size="small"
                                                            title="Survey"
                                                            extra={<MinusCircleOutlined onClick={() => removeSurvey(surveyField.name)} />}
                                                        >
                                                            <div
                                                                key={surveyField.key}
                                                                // style={{ display: "flex", marginBottom: 8 }}
                                                                align="baseline"
                                                            >
                                                                <Form.Item
                                                                    name={[surveyField.name, "name"]}
                                                                    fieldKey={[surveyField.fieldKey, "name"]}
                                                                    label="Name"
                                                                >
                                                                    <Input placeholder="Name" />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    name={[surveyField.name, "score"]}
                                                                    fieldKey={[surveyField.fieldKey, "score"]}
                                                                    label="Score"
                                                                >
                                                                    <InputNumber placeholder="Score" />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    name={[surveyField.name, "date"]}
                                                                    fieldKey={[surveyField.fieldKey, "date"]}
                                                                    label="Date"
                                                                >
                                                                    <DatePicker placeholder="Date" />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    name={[surveyField.name, "question"]}
                                                                    fieldKey={[surveyField.fieldKey, "question"]}
                                                                    label="Question"
                                                                >
                                                                    <Input placeholder="Question" />
                                                                </Form.Item>
                                                            </div>
                                                        </Card>
                                                    ))}
                                                    <Button type="dashed" onClick={() => addSurvey()} block icon={<PlusOutlined />}>
                                                        Add Survey
                                                    </Button>
                                                </>
                                            )}
                                        </Form.List>
                                    </Card> */}

                                        {/* Contract Company */}
                                        {/* <Card size="small" title="Contract Company">
                                        {["company_id", "name", "location"].map((el, i) => {
                                            return (
                                                <Form.Item key={i} label={el} name={[name, "contract_comp", el]}>
                                                    <Input />
                                                </Form.Item>
                                            );
                                        })}
                                    </Card> */}

                                        {/* Skills */}
                                        {/* <Card size="small" title="Skills">
                                        <Form.List name={[name, "skills"]}>
                                            {(skillFields, { add: addSkill, remove: removeSkill }) => (
                                                <>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            // flexDirection: "column",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            // border: "2px solid red",
                                                        }}
                                                    >
                                                        {skillFields.map((skillField) => (
                                                            <div key={skillField.key} align="baseline">
                                                                <Form.Item
                                                                    name={[skillField.name, "skills"]}
                                                                    fieldKey={[skillField.fieldKey, "skills"]}
                                                                >
                                                                    <Input
                                                                        placeholder="Skill"
                                                                        addonAfter={
                                                                            <MinusCircleOutlined
                                                                                onClick={() => removeSkill(skillField.name)}
                                                                            />
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <Button type="dashed" onClick={() => addSkill()} block icon={<PlusOutlined />}>
                                                        Add Skill
                                                    </Button>
                                                </>
                                            )}
                                        </Form.List>
                                    </Card> */}
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                        <Button type="dashed" onClick={() => addWorkExp()} block icon={<PlusOutlined />}>
                            Add Work Experience
                        </Button>
                    </>
                )}
            </Form.List>
        </Card>
    );
};

export default WorkExpForm;
