import { Alert } from "@mui/material";
import React from "react";
import CreatableSelect from "react-select/creatable";
const components = {
  DropdownIndicator: null,
};

const GenericCreatable = ({
  inputValue,
  setInputValue,
  value,
  setValue,
  showError,
  setShowError,
  handleKeyDown,
  handleOnChange,
  handleInputChange,
  placeholder,
  errorMessage,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "600px",
      }}
    >
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={value}
      />
      {showError && <Alert severity="error">{errorMessage}</Alert>}
    </div>
  );
};

export default GenericCreatable;
