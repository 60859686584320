export function transformObject(obj) {
  // Check if the input is an object
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  // If the object is a Date instance, return it as is
  if (obj instanceof Date) {
    return obj;
  }

  // Function to handle arrays of strings
  const handleArray = (arr, key) => {
    return arr.map((item) => ({ [key]: item }));
  };

  // Recursively transform the object
  const transformed = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      // Transform if it's an array of strings and not empty
      if (obj[key].length > 0 && typeof obj[key][0] === "string") {
        transformed[key] = handleArray(obj[key], key);
      } else {
        // If it's not an array of strings or it's empty, keep as is
        transformed[key] = obj[key].map((item) => transformObject(item));
      }
    } else {
      // For non-array values, recursively call the function
      transformed[key] = transformObject(obj[key]);
    }
  }
  return transformed;
}

export function reverseTransformObject(obj) {
  // Check if the input is an object
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  // Function to handle arrays of objects
  const handleArray = (arr, key) => {
    return arr.map((item) => {
      // Check if item is not null and has the required key
      return item && item[key] ? item[key] : null;
    });
  };

  // Recursively reverse transform the object
  const reversed = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      // Check if it's an array of objects with a specific key
      if (
        obj[key].length > 0 &&
        typeof obj[key][0] === "object" &&
        obj[key][0] !== null &&
        obj[key][0][key]
      ) {
        reversed[key] = handleArray(obj[key], key);
      } else {
        // Process each item in the array
        reversed[key] = obj[key].map((item) => reverseTransformObject(item));
      }
    } else {
      // For non-array values, recursively call the function
      reversed[key] = reverseTransformObject(obj[key]);
    }
  }
  return reversed;
}

export function cleanObject(obj) {
  // Check if the value is an object (and not null) or an array
  const isObject = (val) =>
    val && typeof val === "object" && !Array.isArray(val);
  const isArray = (val) => Array.isArray(val);

  // Recursively remove empty fields, but keep empty arrays
  function removeEmptyFields(value) {
    // Directly return non-object, non-array values that aren't empty
    if (!isObject(value) && !isArray(value)) {
      return value !== null && value !== "" && value !== undefined
        ? value
        : undefined;
    }

    // Handle arrays: keep the array even if it's empty
    if (isArray(value)) {
      return value
        .map((item) => removeEmptyFields(item))
        .filter((item) => item !== undefined);
    }

    // Handle objects
    const cleanedObj = {};
    for (const [key, val] of Object.entries(value)) {
      const cleanedValue = removeEmptyFields(val);
      if (cleanedValue !== undefined) {
        cleanedObj[key] = cleanedValue;
      }
    }

    return Object.keys(cleanedObj).length > 0 ? cleanedObj : undefined;
  }

  return removeEmptyFields(obj);
}

export function updateCandidateWithExternalRefs(ext_refs, candidate) {
  // Fields that are stored as strings
  const stringFields = [
    "crunchbase",
    "doximity",
    "dribbble",
    "kaggle",
    "linkedin",
    "twitter",
  ];
  // Fields that are stored as arrays of strings
  const arrayOfStringFields = [
    "behance",
    "artstation",
    "facebook",
    "github",
    "stackoverflow",
    "instagram",
    "leetcode",
    "codeforces",
    "codechef",
    "quora",
    "scholar",
  ];

  // Iterate over each external reference
  ext_refs.forEach((ref) => {
    // Normalize the source to lowercase for comparison
    const source = ref.source.toLowerCase();

    // Update the candidate object if the source is in the stringFields
    if (stringFields.includes(source)) {
      candidate[source] = ref.url; // Directly update or add the string field
    } else if (arrayOfStringFields.includes(source)) {
      // Handle fields that are arrays
      if (!candidate[source]) {
        // Initialize the field as an array with the URL if it doesn't exist
        candidate[source] = [ref.url];
      } else if (!candidate[source].includes(ref.url)) {
        // Add the URL to the array if it's not already present
        candidate[source].push(ref.url);
      }
    }
    // Ignore sources not included in stringFields or arrayOfStringFields
  });

  return candidate;
}
