import { ExpandLess, ExpandMore } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { DATA_STATUS } from "../../../utils/constants";
import { deleteDuplicateCxoProfile, fetchDuplicateLeadershipProfile } from "../services/leadership-profile-service";
import { url } from "../../../url";
import { message } from "antd";
import axios from "axios";
const LeftPaneProfileCard = ({ item, openProfileCreator, selectedProfile, profileStatusIcon, getProfileStatusText, setCxoProfiles }) => {
    const [showDuplicates, setShowDuplicates] = useState(false);
    const [duplicateProfiles, setDuplicateProfiles] = useState({ list: [], status: DATA_STATUS.IDLE });
    const [openDeletionConfirmationDialog, setOpenDeletionConfirmationDialog] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleViewDuplicates = async (event) => {
        event.stopPropagation();
        if (showDuplicates) {
            setShowDuplicates(false);
            return;
        }
        setShowDuplicates(true);
        setDuplicateProfiles((prev) => ({ ...prev, status: DATA_STATUS.LOADING }));
        const dupes = await fetchDuplicateLeadershipProfile(item.duplicate_profiles);
        setDuplicateProfiles((prev) => ({ ...prev, status: DATA_STATUS.SUCCEEDED, list: dupes }));
        if (dupes.length === 0) {
            setShowDuplicates(false);
        }
    };
    const handleCxoProfileDelete = async (event) => {
        event.stopPropagation();
        setIsDeleting(true);
        const result = await deleteDuplicateCxoProfile(item.profile_id, item.duplicate_profiles);
        setOpenDeletionConfirmationDialog(false);
        if (result) {
            message.success(result?.message || `Successfully deleted CXO profile!`);
        }
        setIsDeleting(false);
        setDuplicateProfiles();
        updateProfileDataPostDeletion(item?._id);
    };
    const openDuplicateProfile = (dupProfile) => (event) => {
        event.stopPropagation();
        openProfileCreator(dupProfile);
    };
    const hasDuplicates = item?.duplicate_profiles?.length > 0;

    const updateProfileDataPostDeletion = async (profileId) => {
        try {
            const response = await axios.get(url.leadershipProfile.getProfile, { params: { id: profileId } });
            setCxoProfiles((prevCxoProfiles) => {
                const updatedData = prevCxoProfiles.data.map((profile) =>
                    profile.profile_id === response?.data?.data?.profile_id ? response?.data?.data : profile
                );
                return { ...prevCxoProfiles, data: updatedData };
            });
        } catch (error) {
            console.log("Failed while getting updated profile!", error);
            message.error("Failed while getting updated profile!");
        }
    };

    const handleCloseDeletionConfirmationDialog = (event) => {
        event.stopPropagation();
        setOpenDeletionConfirmationDialog(false);
    };
    const handleOpenDeletionConfirmationDialog = (event) => {
        event.stopPropagation();
        setOpenDeletionConfirmationDialog(true);
    };

    return (
        <>
            <Box
                onClick={() => openProfileCreator(item)}
                sx={(theme) => ({
                    cursor: "pointer",
                    backgroundColor: selectedProfile?._id === item._id ? theme.palette.action.selected : "transparent",
                    borderRadius: "5px",
                    padding: "0.7rem 1.5rem 0.7rem 1.5rem",
                })}
            >
                <Box width={"100%"} display={"flex"} flexDirection={"column"}>
                    <Stack>
                        <Stack>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        textWrap: "wrap",
                                        wordBreak: "break-word",
                                    }}
                                >
                                    {item?.candidate?.full_name}
                                </Typography>
                                {item?.profile_status && (
                                    <Box display={"flex"} alignItems={"center"}>
                                        {profileStatusIcon}
                                        <Typography width={"100%"} textAlign="right" color="text.secondary" variant="body2">
                                            {getProfileStatusText(item)}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>

                            <Typography fontSize={"0.8rem"} sx={{ opacity: "0.5" }}>
                                {item?.candidate?.title}
                            </Typography>
                        </Stack>
                        <Typography fontSize={"0.9rem"}>Company Name: {item?.company_name}</Typography>
                        <Typography>
                            Scraping Url:{" "}
                            <a
                                href={item?.scraping_url}
                                rel="noreferrer"
                                style={{
                                    maxWidth: "300px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    display: "inline-block",
                                    verticalAlign: "bottom",
                                }}
                                target="_blank"
                            >
                                {item?.scraping_url}
                            </a>
                        </Typography>
                        <Typography>
                            LinkedIn Url:{" "}
                            <a
                                href={item?.linkedin_url}
                                rel="noreferrer"
                                style={{
                                    maxWidth: "300px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    display: "inline-block",
                                    verticalAlign: "bottom",
                                }}
                                target="_blank"
                            >
                                {item?.linkedin_url}
                            </a>
                        </Typography>
                        {item?.tags?.length > 0 &&
                            (() => {
                                let tag = item.tags.reduce(
                                    (res, t) => (dayjs(t?.tag_date).isAfter(dayjs(res.tag_date)) ? t : res),
                                    item.tags[0]
                                );
                                console.log(tag);
                                return (
                                    <Typography>
                                        Tag:{" "}
                                        <Tooltip title={dayjs(tag?.tag_date).format("MMM D, YYYY, h:mm: A")} arrow>
                                            <Chip label={tag?.tag} />
                                        </Tooltip>
                                    </Typography>
                                );
                            })()}

                        <Typography sx={{ mt: "0.5rem" }} width={"100%"} textAlign="right" color="text.secondary" variant="body2">
                            {dayjs(item.updated_at).format("MMM D, YYYY h:mm A")}{" "}
                        </Typography>
                        {/* Conditionally Show Duplicates Section */}
                        {hasDuplicates && (
                            <>
                                <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                                    <Typography variant="body2" color="warning.main">
                                        Possible Duplicates Found
                                    </Typography>
                                    <IconButton onClick={handleViewDuplicates} size="small">
                                        {showDuplicates ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                </Box>

                                <Collapse in={showDuplicates}>
                                    {duplicateProfiles?.status === DATA_STATUS.LOADING ? (
                                        <CircularProgress size={20} sx={{ m: 1 }} />
                                    ) : (
                                        duplicateProfiles?.list?.map((dup) => (
                                            <Box
                                                onClick={openDuplicateProfile(dup)}
                                                key={dup._id}
                                                sx={(theme) => ({
                                                    p: 1.5,
                                                    mt: 1,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    backgroundColor:
                                                        selectedProfile?._id === dup._id ? theme.palette.action.selected : "transparent",
                                                    borderRadius: 1,
                                                    cursor: "pointer",
                                                    transition: "background-color 0.2s ease-in-out",
                                                    "&:hover": {
                                                        backgroundColor: theme.palette.action.hover,
                                                        boxShadow: 1,
                                                    },
                                                })}
                                            >
                                                <Box>
                                                    <Typography fontSize="0.9rem" fontWeight="bold" lineHeight={1.4}>
                                                        {dup.candidate.full_name}
                                                    </Typography>
                                                    <Typography fontSize="0.8rem" color="text.secondary" lineHeight={1.3}>
                                                        {dup.company_name}
                                                    </Typography>
                                                </Box>

                                                <IconButton
                                                    onClick={handleOpenDeletionConfirmationDialog}
                                                    size="small"
                                                    sx={{
                                                        color: "error.main",
                                                        "&:hover": { color: "error.dark" },
                                                    }}
                                                >
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </Box>
                                        ))
                                    )}
                                </Collapse>
                            </>
                        )}
                    </Stack>
                </Box>
            </Box>
            <Dialog open={openDeletionConfirmationDialog} onClose={handleCloseDeletionConfirmationDialog}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this profile?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {!isDeleting && (
                        <Button onClick={handleCloseDeletionConfirmationDialog} color="secondary">
                            Cancel
                        </Button>
                    )}
                    <Button onClick={handleCxoProfileDelete} color="primary" disabled={isDeleting}>
                        {isDeleting ? <CircularProgress size={20} /> : "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default LeftPaneProfileCard;
