import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Paper, Tab, Tabs, Typography } from "@mui/material";
import HeaderComponent from "../Header/Header";
import TabsHomePage from "../Home/TabsHomePage";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

const tabItems = [
    {
        value: 1,
        label: "Refresh Request",
        pathname: "/instant-profile-refresh/refresh-request",
    },
    {
        value: 2,
        label: "Refresh History",
        pathname: "/instant-profile-refresh/refresh-history",
    },
];

const InstantProfileRefresh = ({ loggedInUserDetails, logoutUser }) => {
    const { pathname, search, state } = useLocation();
    const [activeTab, setActiveTab] = useState(1);
    const tabRef = useRef(null)
    const navigate = useNavigate();

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        const tabItem = tabItems.find((item) => item.value == newValue);
        navigate(tabItem.pathname);
    };

    useEffect(() => {
        let currentPathname = state?.pathname || pathname;
        let tabItem = tabItems.find((ele) => ele.pathname == currentPathname);
        if (tabItem) {
            setActiveTab(tabItem.value);
            navigate(tabItem.pathname);
        }
    }, []);

    return (
        <div>
            <HeaderComponent loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser} />
            <div className="pe-5 ps-5">
                <TabsHomePage />
            </div>
            <Box>
                <Tabs ref={tabRef} centered value={activeTab} onChange={handleTabChange}>
                    {tabItems.map((item) => {
                        return <Tab value={item.value} label={item.label}></Tab>;
                    })}
                </Tabs>
                <Divider sx={{ height: "0 !important" }}></Divider>

                <Outlet context={{tabRef}}></Outlet>
            </Box>
        </div>
    );
};

export default InstantProfileRefresh;
