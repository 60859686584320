export const validateDynamicallyAddedFields = (values) => {
  let failedValidations = [];

  for (const sectionKey in values) {
    if (Array.isArray(values[sectionKey])) {
      values[sectionKey].forEach((item, index) => {
        const isItemValid = Object.entries(item).some(
          ([key, value]) => value !== undefined && value !== ""
        );
        if (!isItemValid) {
          failedValidations.push({ sectionKey, index });
        }
      });
    }
  }

  return failedValidations;
};
