import React, { useEffect, useState } from "react";
// import "./index.css";
import { CloseOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import axios from "axios";
import { PlusOutlined, MinusCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { get, set } from "lodash";
import { Button, Card, Form, DatePicker, Typography, Checkbox } from "antd";

import ArrOfStrUIHandler from "../ArrOfStrUIHandler";
import dayjs from "dayjs";

const DurationCard = ({ fieldPath, name, form }) => {
    useEffect(() => {
        const fullPathToPresent = [...fieldPath, "duration", "to_present"];
        const fullPathToEndDate = [...fieldPath, "duration", "end_date"];
        const initialEndDate = get(form.getFieldsValue(true), fullPathToEndDate);

        const updatedValue = form.getFieldsValue(true);
        set(updatedValue, fullPathToPresent, false);
        if (initialEndDate === undefined || initialEndDate?.length < 1) {
            set(updatedValue, fullPathToEndDate, null);
            form.setFieldsValue(updatedValue);
        }
    }, []);

    const handleEndDateChange = (newDate) => {
        const updatedValue = form.getFieldsValue(true);

        set(updatedValue, [...fieldPath, "duration", "to_present"], !newDate);
        form.setFieldsValue(updatedValue);
    };

    return (
        <Card size="small" title="Duration">
            <Form.Item
                name={[name, "duration", "start_date"]}
                label="Start Date"
                normalize={(value) => (value && !dayjs.isDayjs(value) ? dayjs(value) : value)}
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                name={[name, "duration", "end_date"]}
                label={"End Date"}
                normalize={(value) => (value && !dayjs.isDayjs(value) ? dayjs(value) : value)}
            >
                <DatePicker onChange={handleEndDateChange} allowClear />
            </Form.Item>
            <Form.Item label="To Present" name={[name, "duration", "to_present"]} valuePropName="checked">
                <Checkbox />
            </Form.Item>
        </Card>
    );
};

export default DurationCard;
