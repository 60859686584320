import axios from "axios";
import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import "./rightPane.styles.css";
import { Spin } from "antd";
import EmailDeletionModal from "../modals/EmailDeletionModal";
import PhoneDeletionModal from "../modals/PhoneDeletionModal";
import NoEmailsFoundModal from "../modals/NoEmailsFoundModal";
import BrokenLinkedInUrlModal from "../modals/BrokenLinkedInUrlModal";
import FormSubmissionModal from "../modals/FormSubmissionModal";
import validator from "validator";
import { url } from "../../../../url";
import Button from "@mui/material/Button";
import { shortlistSource } from "../../../../util";

const RightPaneContainer = ({
  clickedUserDetails: user,
  loggedInUserDetails: loggedInUser,
  removeCandidateAfterEmailUpdate,
  getData,
  readOnlyFlag,
  setUsersAfterSave,
  setShowErrorMsg,
  setErrorMsg,
  handleLeftPaneClick
}) => {
  const [newEmail, setNewEmail] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [displayInputEmail, setDisplayInputEmail] = useState("d-none");
  const [displayAddEmailText, setDisplayAddEmailText] = useState("d-block");
  const [displayInputPhone, setDisplayInputPhone] = useState("d-none");
  const [displayAddPhoneText, setDisplayAddPhoneText] = useState("d-block");
  const [candidateNewEmail, addCandidateNewEmail] = useState("");
  const [candidateNewPhone, addCandidateNewPhone] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [updateEmailError, getUpdateEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [updatePhoneError, getUpdatePhoneError] = useState("");
  const [linkedInUrlColor, setLinkedInUrlColor] = useState(false);
  const [displaySpinner, setDisplaySpinner] = useState(false);
  const [displayEmailDeletionModal, setEmailDeletionModal] = useState(false);
  const [displayPhoneDeletionModal, setPhoneDeletionModal] = useState(false);
  const [displayNoEmailsFoundModal, setNoEmailsFoundModal] = useState(false);
  const [displaybrokenLinkedInUrlModal, setBrokenLinkedInUrlModal] =
    useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);

  useEffect(() => {
    setNewEmail("");
    addCandidateNewEmail("");
    addCandidateNewPhone(user ? user["phone"] : "");
    setDisplayInputEmail("d-none");
    setDisplayAddEmailText("d-block");
    setButtonDisabled(true);
    setLinkedInUrlColor(false);
  }, [user]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setDisplaySpinner(true);
    let userLinkedInId = user.linkedin.split("/");

    let data;
    if (event.target[3].value) {
      data = {
        prid: user.prid,
        lnkd_id: userLinkedInId[userLinkedInId.length - 1],
        user_email: loggedInUser.email,
        email: event.target[0].value,
        phone: event.target[3].value,
      };
    } else {
      data = {
        prid: user.prid,
        lnkd_id: userLinkedInId[userLinkedInId.length - 1],
        user_email: loggedInUser.email,
        email: event.target[0].value,
      };
    }

    if (data.email) {
      let auditLogData = {
        eventName: "add_missing_email",
        eventType: "insert",
        userId: loggedInUser._id,
        eventMetaData: {
          candidateId: user.prid,
          candidateName: user.name,
          customerName: user.customer ? user.customer : "not specified",
          shortlistLink: user.shortlisted_link,
          shortlistDate: user.shortlisted_on,
          shortlistSource: user.hasOwnProperty("shortlisted_source")
            ? shortlistSource[user.shortlisted_source]
            : "Bulk",
          createdAtAppDB: user.createdAt,
        },
        oldRecord: {
          candidateEmails: user.emails,
          candidatePhone: user.phone ? String(user.phone) : "",
        },
        newRecord: {
          addedEmail: [event.target[0].value],
          addedPhone: event.target[3].value ? [event.target[3].value] : [""],
        },
      };

      axios
        .post(url.updateMissingEmails, {
          updateEmailPayload: data,
          auditLogData: auditLogData,
          hideCandidate: user.prid,
        })
        .then((res) => {
          if (res.data.success) {
            setDisplaySpinner(false);
            setUsersAfterSave(true);

            let linkedinUrlArray = JSON.parse(
              localStorage.getItem("lnkdInUrls")
            );

            if (linkedinUrlArray === null) {
              linkedinUrlArray = [];
            }

            linkedinUrlArray.push(user.linkedin);
            localStorage.setItem(
              "lnkdInUrls",
              JSON.stringify(linkedinUrlArray)
            );
            removeCandidateAfterEmailUpdate(user.linkedin);
          } else {
            // let invalidEmail = res.data.error.split(" ");
            // setNewEmail(invalidEmail[invalidEmail.length - 1]);
            getUpdateEmailError(res.data.error);
            setDisplayAddEmailText("d-none");
            setDisplayInputEmail("d-block");
            addCandidateNewEmail("");
            setButtonDisabled(true);
            setDisplaySpinner(false);
          }
        })
        .catch((error) => {
          setDisplaySpinner(false);
          setErrorMsg(error?.response?.data?.error);
          setShowErrorMsg(true);
          console.log(error);
        })
        .finally(() => {
          handleLeftPaneClick(false);
        });
    } else {
      let noEmailFoundCandidate = {
        name: user.name,
        user_id: user.user_id,
        title: user.title,
        exec_id: user.exec_id,
        linkedin: user.linkedin,
        shortlisted_on: user.shortlisted_on_unix,
        prid: user.prid,
        enriched_profile: user.enriched_profile,
        emails: user.emails,
        shortlisted_link: user.shortlisted_link,
        phone: user.phone || "",
        companyDetails: user.companyDetails,
        taskAssignedTo: user.taskAssignedTo || "",
      };
      let auditLogData = {
        eventName: "no_email_found",
        eventType: "flag",
        userId: loggedInUser._id,
        eventMetaData: {
          candidateId: user.prid,
          execId: user.exec_id,
          candidateName: user.name,
          customerName: user.customer ? user.customer : "not specified",
          shortlistLink: user.shortlisted_link,
          shortlistDate: user.shortlisted_on,
          shortlistSource: user.hasOwnProperty("shortlisted_source")
            ? shortlistSource[user.shortlisted_source]
            : "Bulk",
          createdAtAppDB: user.createdAt,
        },
        oldRecord: {
          candidateEmails: user.emails,
          candidatePhone: user.phone ? String(user.phone) : "",
        },
        newRecord: {
          addedEmail: [event.target[0].value],
          addedPhone: event.target[3].value ? [event.target[3].value] : [""],
        },
      };
      axios
        .post(url.noEmailFoundCandidate, {
          exec_id: user.exec_id,
          noEmailFoundInternalAPIPayload: {
            icp_id: user.icp_id,
            prid: user.prid,
            exec_id: user.exec_id,
          },
          hideCandidate: user.prid,
          auditLogData: auditLogData,
          noEmailFoundCandidate: noEmailFoundCandidate,
        })
        .then((res) => {
          setUsersAfterSave(true);
          let linkedinUrlArray = JSON.parse(localStorage.getItem("lnkdInUrls"));

          if (linkedinUrlArray === null) {
            linkedinUrlArray = [];
          }

          linkedinUrlArray.push(user.linkedin);
          localStorage.setItem("lnkdInUrls", JSON.stringify(linkedinUrlArray));
          removeCandidateAfterEmailUpdate(user.linkedin);
          setDisplaySpinner(false);
        })
        .catch((error) => {
          console.log(error);
          setErrorMsg(error?.response?.data?.error);
          setShowErrorMsg(true);
          setDisplaySpinner(false);
        }).finally(() => {
          handleLeftPaneClick(false);
        });
    }
    // setUsersAfterSave(true);
  };

  const handleInputChange = (event) => {
    if (event.target.name === "email") {
      setNewEmail(event.target.value);
      getUpdateEmailError("");
    } else if (event.target.name === "phone") {
      setNewPhone(event.target.value);
    }
  };

  const controlInputEmailDisplay = (event) => {
    if (event.target.id === "cancelNewEmail") {
      setDisplayAddEmailText("d-block");
      setDisplayInputEmail("d-none");
      setNewEmail("");
      getUpdateEmailError("");
    } else {
      setDisplayAddEmailText("d-none");
      setDisplayInputEmail("d-block");
      getUpdateEmailError("");
    }
  };

  const controlInputPhoneDisplay = (event) => {
    if (event.target.id === "cancelNewPhone") {
      setDisplayAddPhoneText("d-block");
      setDisplayInputPhone("d-none");
      setNewPhone("");
    } else {
      setDisplayAddPhoneText("d-none");
      setDisplayInputPhone("d-block");
    }
  };

  const addCandidateEmail = () => {
    if (validator.isEmail(newEmail)) {
      addCandidateNewEmail(newEmail);
      setDisplayAddEmailText("d-block");
      setDisplayInputEmail("d-none");
      setButtonDisabled(false);
      getUpdateEmailError("");
    } else {
      getUpdateEmailError("Incorrect email format");
    }
  };

  const addCandidatePhone = () => {
    if (validator.isMobilePhone(newPhone, false, { strictMode: true })) {
      addCandidateNewPhone(newPhone);
      setDisplayAddPhoneText("d-block");
      setDisplayInputPhone("d-none");
      setPhoneNumberError("");
    } else {
      setPhoneNumberError("Please enter a valid phone number");
    }
  };

  const handleNoEmailFound = () => {
    setLinkedInUrlColor(true);
    setButtonDisabled(false);
  };

  const getRefreshedEmail = () => {
    setDisplaySpinner(true);
    let dataToBeSent = {
      prid: user.prid,
      lnkd_url: user.linkedin,
      user_email: loggedInUser.email,
    };

    axios
      .post(url.refreshEmail, dataToBeSent)
      .then((res) => {
        setDisplaySpinner(false);
        if (Object.values(res.data.data).length === 0) {
          setNoEmailsFoundModal(true);
        } else {
          setButtonDisabled(false);
          addCandidateNewEmail(
            Object.values(res.response.data)[
              Object.values(res.response.data).length
            ] - 1
          );
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMsg(error?.response?.data?.error);
        setShowErrorMsg(true);
      });
  };

  const displayModalControl = (event) => {
    if (event.target.id === "closeNoEmailsFoundModal") {
      setNoEmailsFoundModal(false);
    } else if (event.target.id === "closeBrokenLinkedInUrlModal") {
      setBrokenLinkedInUrlModal(false);
    } else if (event.target.id === "openBrokenLinkedInUrlModal") {
      setBrokenLinkedInUrlModal(true);
    } else if (event.target.id === "closeEmailDeletionModal") {
      setEmailDeletionModal(false);
    } else if (event.target.id === "closeAndRemoveEmailModal") {
      setNewEmail("");
      addCandidateNewEmail("");
      setButtonDisabled(true);
      setEmailDeletionModal(false);
    } else if (event.target.id === "openEmailDeletionModal") {
      setEmailDeletionModal(true);
    } else if (event.target.id === "openPhoneDeletionModal") {
      setPhoneDeletionModal(true);
    } else if (event.target.id === "closePhoneDeletionModal") {
      setPhoneDeletionModal(false);
    } else if (event.target.id === "closeAndRemovePhoneModal") {
      setNewPhone("");
      addCandidateNewPhone("");
      setPhoneDeletionModal(false);
    }
  };

  const handleReport = async () => {
    let auditLogData = {
      eventName: "broken_link_missing_emails",
      eventType: "report",
      userId: loggedInUser._id,
      eventMetaData: {
        candidateId: user.prid,
        candidateName: user.name,
        customerName: user.customer ? user.customer : "not specified",
        shortlistLink: user.shortlisted_link,
        shortlistDate: user.shortlisted_on,
        shortlistSource: user.hasOwnProperty("shortlisted_source")
          ? shortlistSource[user.shortlisted_source]
          : "Bulk",
        createdAtAppDB: user.createdAt,
      },
      oldRecord: {
        candidateEmails: user.emails,
        candidatePhone: user.phone ? String(user.phone) : "",
      },
      newRecord: {},
    };

    axios
      .post(url.handleReportMissingEmails, {
        hideCandidate: user.prid,
        auditLogData: auditLogData,
      })
      .then((res) => {
        setUsersAfterSave(true);
      })
      .catch((error) => {
        setErrorMsg(error?.response?.data?.error);
        setShowErrorMsg(true);
        console.error(error);
      }).finally(() => {
        handleLeftPaneClick(false);
      })
  };

  if (user && !displaySpinner) {
    // console.log(user, user.emails);
    return (
      <div className="h-100">
        <div className="d-flex justify-content-between p-4">
          <div className="d-flex flex-column w-50">
            <h6>
              <strong>{user.name}</strong>
            </h6>
            <p className="color-grey">
              {user.title !== "null" ? user.title : "not specified"}
            </p>
            <a href={`${user.enriched_profile}`} target="blank">
              Findem Enriched Profile{" "}
              <i className="fa-solid fa-arrow-up-right-from-square ms-1"></i>
            </a>
          </div>
          <div className="w-50">
            <h6 className="text-end">
              <strong>Customer: {user.customer || "not specified"}</strong>
            </h6>
            <h6 className="text-end">
              <strong>Shortlisted on: {user.shortlisted_on}</strong>
            </h6>
          </div>
        </div>
        <hr></hr>
        {/* Form  */}
        <form className="py-4 px-2 mt-3" onSubmit={handleFormSubmit}>
          <div>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                {readOnlyFlag ? null : (
                  <>
                    <div className="d-flex">
                      <label className="d-flex align-items-baseline form-label-width">
                        <i className="fa-solid fa-envelope me-1"></i>Email:{" "}
                      </label>
                      <div className="d-flex align-items-baseline margin-form-label">
                        <div>
                          <div
                            className={`${
                              candidateNewEmail ? "d-flex" : "d-none"
                            } align-items-baseline`}
                          >
                            <p>{candidateNewEmail}</p>
                            <i
                              className="fa-solid fa-circle-xmark ms-2 cursor-pointer"
                              onClick={displayModalControl}
                              id="openEmailDeletionModal"
                            ></i>
                          </div>

                          <p
                            className={`${displayAddEmailText} cursor-pointer color-blue`}
                            id="addEmailText"
                            onClick={controlInputEmailDisplay}
                          >
                            Add email
                          </p>
                          <div className={`d-flex ${displayInputEmail}`}>
                            <div>
                              <input
                                type="email"
                                placeholder="Enter Email"
                                value={newEmail}
                                name="email"
                                onChange={handleInputChange}
                              />
                              <p className="text-danger">{updateEmailError}</p>
                            </div>
                            <div className="d-flex">
                              <button
                                type="button"
                                className="btn btn-primary ms-1 me-2 add-cancel-btn cursor-pointer"
                                onClick={addCandidateEmail}
                              >
                                Add
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-primary add-cancel-btn cursor-pointer"
                                id="cancelNewEmail"
                                onClick={controlInputEmailDisplay}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                          <div className="d-flex align-items-baseline color-blue mt-2">
                            <i className="fa-solid fa-arrows-rotate me-1"></i>
                            <p
                              className="cursor-pointer"
                              onClick={getRefreshedEmail}
                            >
                              Refresh email
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <div className="d-flex">
                        <label className="d-flex align-items-baseline form-label-width">
                          <i className="fa-solid fa-phone me-1"></i>Phone:{" "}
                        </label>
                        <div className="d-flex flex-column margin-form-label">
                          {user.phone ? (
                            <div className="d-flex align-items-baseline">
                              <p>{newPhone}</p>
                              <i
                                className="fa-solid fa-circle-xmark ms-2 cursor-pointer"
                                onClick={displayModalControl}
                                id="openPhoneDeletionModal"
                              ></i>
                            </div>
                          ) : (
                            <div>
                              <div
                                className={`${
                                  candidateNewPhone ? "d-flex" : "d-none"
                                } align-items-baseline`}
                              >
                                <p>{candidateNewPhone}</p>
                                <i
                                  className="fa-solid fa-circle-xmark ms-2 cursor-pointer"
                                  onClick={displayModalControl}
                                  id="openPhoneDeletionModal"
                                ></i>
                              </div>
                              <p
                                className={`${displayAddPhoneText} cursor-pointer color-blue`}
                                id="addPhoneText"
                                onClick={controlInputPhoneDisplay}
                              >
                                Add Phone
                              </p>
                              <div className={`d-flex ${displayInputPhone}`}>
                                <div>
                                  <input
                                    type="text"
                                    placeholder="Enter Phone"
                                    value={newPhone}
                                    name="phone"
                                    onChange={handleInputChange}
                                  />
                                  <p className="text-danger">
                                    {updatePhoneError}
                                  </p>
                                </div>
                                <div className="d-flex">
                                  <button
                                    type="button"
                                    className="btn btn-primary ms-1 me-2 cursor-pointer add-cancel-btn"
                                    onClick={addCandidatePhone}
                                  >
                                    Add
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary cursor-pointer add-cancel-btn"
                                    id="cancelNewPhone"
                                    onClick={controlInputPhoneDisplay}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="d-flex mt-3">
                  <label className="d-flex align-items-baseline form-label-width">
                    <i className="fa-brands fa-linkedin me-1"></i>LinkedIn:{" "}
                  </label>
                  <div className="d-flex align-items-baseline margin-form-label">
                    {user.linkedin !== undefined ? (
                      <p
                        onClick={handleNoEmailFound}
                        className={`cursor-pointer ${
                          linkedInUrlColor ? "color-purple" : "color-blue"
                        }`}
                      >
                        <a
                          href={
                            !user.linkedin.startsWith("http")
                              ? "https://www." + user.linkedin
                              : user.linkedin
                          }
                          target="_blank"
                        >
                          {!user.linkedin.startsWith("http")
                            ? "https://www." + user.linkedin
                            : user.linkedin}
                        </a>
                      </p>
                    ) : (
                      <pre>
                        No linkedin link is available for this candidate
                        <br />
                        For now no action can be performed on it
                        <br />
                        Please report this when found
                      </pre>
                    )}
                  </div>
                </div>
              </div>
              {
                <ul className="d-flex flex-column text-dark">
                  {user.emails.length ? (
                    <span className="fw-bold">Existing emails</span>
                  ) : (
                    <span className="fw-bold">No existing emails</span>
                  )}
                  {user.emails.map((email) => {
                    return (
                      <li key={email} style={{ wordBreak: "break-all" }}>
                        {email}
                      </li>
                    );
                  })}
                </ul>
              }
            </div>
            {readOnlyFlag ? null : (
              <div className="d-flex justify-content-between align-items-end">
                <div>
                  <Tooltip
                    overlayStyle={{ maxWidth: "100px", fontSize: "10px" }}
                    placement="top"
                    title="Click this button to report a broken LinkedIn link"
                  >
                    {/* <button
                      type="button"
                      className="btn btn-danger d-flex align-items-baseline"
                      onClick={displayModalControl}
                      id="openBrokenLinkedInUrlModal"
                    >
                      Report
                      <i className="fa-solid fa-link ms-2"></i>
                    </button> */}
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => setReportModalOpen(true)}
                    >
                      Report
                    </Button>
                  </Tooltip>
                </div>
                <div>
                  {isButtonDisabled ? (
                    <>
                      <Tooltip
                        overlayStyle={{ maxWidth: "120px", fontSize: "10px" }}
                        placement="top"
                        title="Add email or click on LinkedIn link to look for an email first"
                      >
                        <button
                          type="button"
                          className="form-save-btn-disabled"
                          data-bs-toggle="modal"
                          data-bs-target="#formModal"
                          disabled={isButtonDisabled}
                        >
                          Save
                        </button>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="form-save-btn-enabled"
                        data-bs-toggle="modal"
                        data-bs-target="#formModal"
                        disabled={isButtonDisabled}
                      >
                        Save
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>

          {/*Modals*/}
          <FormSubmissionModal candidateNewEmail={candidateNewEmail} />

          <EmailDeletionModal
            displayEmailDeletionModal={displayEmailDeletionModal}
            displayModalControl={displayModalControl}
          />

          <PhoneDeletionModal
            displayPhoneDeletionModal={displayPhoneDeletionModal}
            displayModalControl={displayModalControl}
          />

          <NoEmailsFoundModal
            displayNoEmailsFoundModal={displayNoEmailsFoundModal}
            displayModalControl={displayModalControl}
          />

          <BrokenLinkedInUrlModal
            reportModalOpen={reportModalOpen}
            setReportModalOpen={setReportModalOpen}
            handleReport={handleReport}
          />
        </form>
      </div>
    );
  } else if (displaySpinner) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spin tip="Loading..."></Spin>
      </div>
    );
  }
};

export default RightPaneContainer;
