import React, { useEffect, useState } from "react";
import { url } from "../../url";
import axios from "axios";
import { Spin, Button, Select, Tooltip } from "antd";
import "./LocationHeuristics.styles.css";
import { Pagination, Typography, Tabs, Modal } from "antd";
const { Paragraph } = Typography;

const statusOptionsForErr1 = [
  { value: "approved", label: "approved" },
  { value: "not approved", label: "not approved" },
  { value: "broken link", label: "broken link" },
];

const statusOptionsForErr2 = [
  { value: "approved", label: "approved" },
  { value: "not approved", label: "not approved" },
];

const vendorMap = new Map();
vendorMap.set("Public_Source6", "PDL");
vendorMap.set("Public_Source7", "Xverum");

const errorType = new Map();
errorType.set("1", "1"); //
errorType.set("2", "2"); // approved/not approved

const ParsingError = (props) => {
  // console.log(props);
  const { loggedInUserDetails, showTaskAssignedTo } = props;
  const [locationData, setLocationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [workDoneCount, setWorkDoneCount] = useState(0);
  const [isButtonDisabled, setButtonDisabled] = useState([]);
  const [isStatusChecked, setStatusChecked] = useState([]);
  const [statusValue, setStatusValue] = useState({});
  const [page, setPage] = useState(1);
  const [totalPaginateSize, setTotalPaginateSize] = useState(0);
  const [readOnlyFlag, setReadOnlyFlag] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (element, index) => (event) => {
    setIsModalOpen(true);
    setModalData({ ...element, index: index });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getLocationHeuristicsData(1);
  }, [showTaskAssignedTo]);

  useEffect(() => {
    let tempButtonDisabled = isButtonDisabled.slice();
    for (let i = 0; i < isStatusChecked.length; i++) {
      if (isStatusChecked[i]) {
        tempButtonDisabled[i] = false;
      }
    }
    setButtonDisabled(tempButtonDisabled);
  }, [isStatusChecked]);

  const getLocationHeuristicsData = async (page) => {
    setLoading(true);
    let data;

    try {
      data = await axios.get(
        url.getLocationHeuristicsData +
          `?email=${loggedInUserDetails.email}&role=${loggedInUserDetails.role}&showTaskAssignedTo=${showTaskAssignedTo}&page=${
            page - 1
          }&type=parsing`
      );
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setLocationData(data.data.data);
    setReadOnlyFlag(data.data.readOnly);
    setTotalPaginateSize(data.data.totalPaginateSize);
    let arrayLength = data.data.data.length;
    setButtonDisabled(new Array(arrayLength).fill(true));
    setStatusChecked(new Array(arrayLength).fill(false));
    setWorkDoneCount(data.data.workDone);
  };

  const fetchLocationHeuristicsData = (page, pageSize) => {
    setPage(page);
  };

  useEffect(() => {
    // fetch location heuristics data
    getLocationHeuristicsData(page);
  }, [page]);

  const updateLocation = (id, loc) => {
    const newLocationData = locationData.map((location) => (location._id === id ? { ...location, field_val: loc } : location));
    setLocationData(newLocationData);
  };

  const setStatusValueFn = (status, index) => {
    console.log(status, index);
    if (!statusValue.hasOwnProperty(index) || status != statusValue[index]) {
      setStatusValue({ ...statusValue, [index]: status });
    }
    console.log(statusValue);
  };

  return (
    <>
      {!readOnlyFlag ? <p style={{ width: "100%", textAlign: "center" }}>Work done for today in location heuristics: {workDoneCount}</p> : null}
      <div className="mt-0 mb-4 total-data-container">
        <div className="pt-3 row h-100 w-100">
          {!loading ? (
            <div className="col-12 d-flex flex-column mx-5 justify-content-left">
              {locationData.length === 0 ? (
                <p className="text-center">No data to show</p>
              ) : locationData === "all data has been worked on" ? (
                <p className="text-center">All data has been worked on</p>
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Id</th>
                        <th scope="col">Address</th>
                        <th scope="col">Field Name</th>
                        <th scope="col">Current Value</th>
                        <th scope="col">Suggested Value</th>
                        {readOnlyFlag ? null : (
                          <>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </>
                        )}
                        {loggedInUserDetails.role === "admin" ? <th scope="col">Assigned To</th> : null}
                      </tr>
                    </thead>
                    <tbody>
                      {locationData.map((element, index) => {
                        // console.log(element);
                        return (
                          <tr key={index}>
                            <td>
                              <form id={`form${index}`}>
                                <input type="hidden" name="id" value={element.ref} data-ref={element.ref} />
                              </form>
                            </td>
                            <th scope="row">{(page - 1) * 20 + index + 1}</th>
                            <td>Lakewood, Colorado, United States</td>
                            <td>{element.field}</td>
                            <td>{element.old_field_val}</td>
                            <td>
                              <Paragraph
                                editable={{
                                  onChange: (e) => updateLocation(element._id, e),
                                }}>
                                {element.field_val}
                              </Paragraph>
                            </td>
                            {readOnlyFlag ? null : (
                              <>
                                <td scope="col">
                                  <Select
                                    size="small"
                                    placeholder="Please select status"
                                    style={{
                                      width: "100%",
                                    }}
                                    options={statusOptionsForErr2}
                                    onChange={(value) => {
                                      // let statusValueTemp =
                                      //   statusValue.slice();
                                      // statusValueTemp[index] = value;
                                      setStatusValueFn(value, index);
                                      if (value === "approved" || value === "not approved" || value === "broken link") {
                                        let getStatusTemp = isStatusChecked.slice();
                                        getStatusTemp[index] = true;
                                        setStatusChecked(getStatusTemp);
                                      }
                                    }}
                                  />
                                </td>
                                <td>
                                  {isButtonDisabled[index] ? (
                                    <Tooltip
                                      overlayStyle={{
                                        maxWidth: "120px",
                                        fontSize: "10px",
                                      }}
                                      placement="top"
                                      title="Select status to save">
                                      <Button type="primary" disabled={true}>
                                        Save
                                      </Button>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      <Button type="primary" onClick={showModal(element, index)}>
                                        Save
                                      </Button>

                                      <Modal
                                        title="Submit?"
                                        visible={isModalOpen}
                                        okButtonProps={{
                                          children: "Custom OK",
                                        }}
                                        cancelButtonProps={{
                                          children: "Custom cancel",
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                        onOk={async () => {
                                          //   let ref = event.target[0].dataset.ref;
                                          //   console.log(
                                          //     JSON.stringify(
                                          //       locationData[modalData.index]
                                          //     )
                                          //   );
                                          //   console.log(
                                          //     "IM INDEXXXXXXX WTF: ",
                                          //     index
                                          //   );

                                          let data = locationData[modalData.index];
                                          data["status"] = statusValue[modalData.index];
                                          data["type"] = "parsing";
                                          try {
                                            let markAsHide = await axios.post(url.markLocationHeuristic, data);
                                            getLocationHeuristicsData(page);
                                            let auditLogData = {
                                              eventName: "location_heuristics",
                                              eventType: "parsing",
                                              userId: loggedInUserDetails._id,
                                              eventMetaData: {
                                                status: statusValue[modalData.index],
                                                field: element.field,
                                                prevLoc: element.old_field_val,
                                                currLoc: element.field_val,
                                                identifier: element.identifier,
                                              },
                                              oldRecord: {},
                                              newRecord: {},
                                            };
                                            // send a post request to add into audit logs
                                            let audit = await axios.post(url.auditPost, auditLogData);
                                          } catch (error) {
                                            console.log(error);
                                          }
                                          console.log("IM index: ", element);
                                          setIsModalOpen(false);
                                        }}
                                        onCancel={handleCancel}>
                                        {/* <p>
                                          {JSON.stringify(modalData.field_val)}
                                        </p>
                                        <p>
                                          {JSON.stringify(
                                            modalData.old_field_val
                                          )}
                                        </p>

                                        <p>{modalData.index}</p> */}
                                        <p>Do you want to submit?</p>
                                      </Modal>
                                    </>
                                  )}
                                </td>
                              </>
                            )}
                            {loggedInUserDetails.role === "admin" ? <td scope="col">{element.taskAssignedTo}</td> : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Pagination
                    simple
                    onChange={fetchLocationHeuristicsData}
                    current={page}
                    total={totalPaginateSize}
                    defaultPageSize={20}
                    className="my-5"
                  />
                </>
              )}
            </div>
          ) : (
            <Spin className="text-center" tip="Loading..." />
          )}
        </div>
      </div>
    </>
  );
};

export default ParsingError;
