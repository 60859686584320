
import {
    Button, 
    Chip, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    TextField,
    List,
    ListItem,
    ListItemText,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormHelperText,
} from "@mui/material";
import { useState } from "react";
import { LINKEDIN_PREFIX } from "./AltLinkFinder";

const OTHER_OPTION = {
    value: "other",
    label: "Other"
}

const ALT_LINK_NULL_REASONS_OPTIONS = [
    {
        value: "Cannot Find Correct LI UID",
        label: "Cannot Find Correct LI UID",
    },
    {
        value: "Correct LI UID already a part of Deadlink EP",
        label: "Correct LI UID already a part of Deadlink EP",
    },
    {
        value: "Deadlink EP Display Error",
        label: "Deadlink EP Display Error",
    },
    {
        value: "Correct LI UID same as Deadlink LI UID",
        label: "Correct LI UID same as Deadlink LI UID",
    },
    OTHER_OPTION
];

export const SubmitModal = ({ submitModalDetails, handleCloseSubmitModal, handleSubmit }) => {
    const [comment, setComment] = useState("");
    const [selectedReason, setSelectedReason] = useState("");

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handleSelectedReason = (e) => {
        setSelectedReason(e.target.value);
        setComment(e.target.value);
        if (e.target.value == "other") {
            setComment("");
        }
    };

    const onConfirmClick = () => {
        handleSubmit(comment);
    };

    return (
        <Dialog
            open={submitModalDetails.isOpen}
            onClose={handleCloseSubmitModal}
            aria-describedby="Confirm your selection"
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle>Confirm</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{mb: 2}}>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary={
                                <Typography variant="subtitle1">
                                    Dead Link:
                                    <Chip
                                        sx={{ mx: 1 }}
                                        component="a" 
                                        href={`${LINKEDIN_PREFIX}/${submitModalDetails.data.dead_uid}`} 
                                        label={submitModalDetails.data.dead_uid} 
                                        variant="outlined" 
                                        clickable 
                                        target="_blank"
                                    />
                                </Typography>}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={
                                <Typography variant="subtitle1">
                                    Alt Link Selected/Added:
                                    <Chip
                                        sx={{ mx: 1 }}
                                        component={submitModalDetails.data.addedAltUid !== null && "a"} 
                                        href={`${LINKEDIN_PREFIX}/${submitModalDetails.data.addedAltUid ?? "#"}`} 
                                        label={submitModalDetails.data.addedAltUid ?? "null"} 
                                        variant="outlined" 
                                        clickable={submitModalDetails.data.addedAltUid !== null}
                                        target="_blank"
                                    />
                                </Typography>}
                            />
                        </ListItem>
                    </List>
                </DialogContentText>
                {!submitModalDetails?.data?.addedAltUid && (
                    <FormControl required sx={{ width: 500 }}>
                        <InputLabel id="alt-link-null-reasons-label">Select Reason</InputLabel>
                        <Select
                            labelId="required-label"
                            id="alt-link-null-reasons-options"
                            value={selectedReason}
                            label="Select Reason"
                            onChange={handleSelectedReason}
                        >
                            {ALT_LINK_NULL_REASONS_OPTIONS.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                           
                        </Select>
                        <FormHelperText>Reason for selected alt link is null.</FormHelperText>
                    </FormControl>
                )}
                {(selectedReason === OTHER_OPTION.value || submitModalDetails?.data?.addedAltUid) && (
                    <TextField
                        sx={{ mt: 4, width: 500 }}
                        label="Comment"
                        required={selectedReason === OTHER_OPTION.value ? true : false}
                        value={comment}
                        multiline
                        rows={4}
                        onChange={handleCommentChange}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseSubmitModal}>Close</Button>
                <Button
                    disabled={submitModalDetails?.data?.addedAltUid ? false : comment.length === 0 ? true : false}
                    onClick={onConfirmClick}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
