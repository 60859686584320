import lodashUnescape from "lodash/unescape";

export function decodeString(value) {
  return value && typeof value === "string"
    ? lodashUnescape(unescape(value))
    : value;
}

const MANUAL_TEXT = "Manual";
const BULK_TEXT = "Bulk";

// ! This manual and bulk shortlist source has to be in sync with the product BE
export const shortlistSource = {
  0: MANUAL_TEXT,
  1: MANUAL_TEXT,
  2: BULK_TEXT,
  3: MANUAL_TEXT,
  4: MANUAL_TEXT,
  5: MANUAL_TEXT,
  6: MANUAL_TEXT,
  7: MANUAL_TEXT,
  8: MANUAL_TEXT,
  10: MANUAL_TEXT,
  11: MANUAL_TEXT,
  12: MANUAL_TEXT,
  13: MANUAL_TEXT,
  14: MANUAL_TEXT,
  15: MANUAL_TEXT,
  16: MANUAL_TEXT,
  17: MANUAL_TEXT,
  18: MANUAL_TEXT
};

export const PLATFORM_SEARCH_URL = "https://app-next.findem.ai/searches"