import React, { useState } from "react";
import { Card, Form, Space, Input, Button } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const ArrOfStr = ({ name, cardLabel, key1, key2 }) => {
  const [disableMinusCircleOutlined, setDisableMinusCircleOutlined] =
    useState(true);
  return (
    <Card size="small" title={cardLabel}>
      <Form.List name={[name, key1, key2]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Space key={field.key} align="baseline">
                <Form.Item
                  name={[field.name, key2]}
                  fieldKey={[field.fieldKey, key2]}
                  rules={[
                    {
                      required: true,
                      message: `Please input the ${key2}`,
                    },
                  ]}
                >
                  <Input
                    disabled={true}
                    placeholder={`Enter ${key2}`}
                    addonAfter={
                      <MinusCircleOutlined
                        onClick={() =>
                          !disableMinusCircleOutlined && remove(field.name)
                        }
                      />
                    }
                  />
                </Form.Item>
              </Space>
            ))}
            <Button
              type="dashed"
              onClick={() => !disableMinusCircleOutlined && add()}
              block
              icon={<PlusOutlined />}
            >
              {`Add ${cardLabel}`}
            </Button>
          </>
        )}
      </Form.List>
    </Card>
  );
};

export default ArrOfStr;
