import { Autocomplete, Box, Divider, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { url } from "../../url";
import { message } from "antd";

const FilterToolbar = ({ filterQuery, setFilterQuery }) => {
    const [filterOptions, setFilterOptions] = useState({ isLoading: false, data: {} });

    const fetchFilterOptions = async () => {
        try {
            setFilterOptions({ ...filterOptions, isLoading: true });
            const response = await axios.get(url.leadershipProfile.getFilterOptions);
            setFilterOptions({ data: response?.data?.filterOptions, isLoading: false });
        } catch (error) {
            setFilterOptions({ isLoading: false });
            console.log("Failed to fetch filter options:", error);
            if (error?.response?.data?.message) {
                message.error(error?.response?.data?.message);
            }
            message.error("Failed to fetch filter options!");
        }
    };

    const handleCompanyDomainsChange = (event, newValues) => {
        setFilterQuery((prevFilterQuery) => {
            if (newValues.length === 0) {
                const { company_domain, ...rest } = prevFilterQuery;
                return rest;
            }
            return {
                ...prevFilterQuery,
                company_domain: { $in: newValues.map((item) => item.value) },
            };
        });
    };

    useEffect(() => {
        fetchFilterOptions();
    }, []);

    return (
        <>
            {!filterOptions?.isLoading && (
                <>
                    <Box
                        sx={{
                            margin: "10px 3rem",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Autocomplete
                            sx={{ minWidth: "10rem" }}
                            multiple
                            onChange={handleCompanyDomainsChange}
                            options={filterOptions?.data?.companyDomains || []}
                            getOptionLabel={(option) => option?.label || ""}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderTags={(value, getTagProps) => {
                                // Display the number of selected tags in the input field
                                return [`${value.length} selected`]; // Show the count of selected tags
                            }}
                            renderInput={(params) => <TextField {...params} label="Select Companies" />}
                        />
                    </Box>
                    <Divider></Divider>
                </>
            )}
        </>
    );
};

export default FilterToolbar;
