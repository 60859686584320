import React, { useState } from "react";
import "./modals.styles.css";

const FormSubmissionModal = ({ candidateNewEmail, userRef, modalComment, handleMessageFormEnable }) => {
  return (
    <>
      <div
        className="modal fade"
        id={`formModal${userRef}`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="formModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            {!candidateNewEmail ? (
              <div className="modal-body p-3">
                <h5 className="text-center">
                  Are you sure you want to submit?
                </h5>
                <div className="">
                  <label htmlFor="message-text" className="col-form-label">Message <span style={{ color: "red" }}>(*required)</span>:</label>
                  <textarea className="form-control" style={{ height: "160px" }} id={`message-text${userRef}`} value={modalComment} onChange={handleMessageFormEnable}></textarea>
                </div>

                <div className="d-flex justify-content-around mt-5">
                  <button
                    type="button"
                    className="modal-button mt-auto modal-no-btn-color"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                  <button
                    type="submit"
                    data-bs-dismiss="modal"
                    className="modal-button mt-auto modal-yes-btn-color"
                    disabled={modalComment.length > 0 ? false : true}
                  >
                    Yes
                  </button>
                </div>
              </div>
            ) : (
              <div className="modal-body p-5 text-center">
                <p>Are you sure you want to save changes?</p>
                <div className="d-flex justify-content-around mt-5">
                  <button
                    type="button"
                    className="modal-button modal-no-btn-color"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                  <button
                    type="submit"
                    data-bs-dismiss="modal"
                    className="modal-button modal-yes-btn-color"
                  >
                    Yes
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default FormSubmissionModal;
