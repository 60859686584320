import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    Input,
    Menu,
    MenuItem,
    Stack,
    Switch,
    Tooltip,
    Typography,
} from "@mui/material";
import { Collapse, Layout, List, message, Progress } from "antd";
import { Content } from "antd/lib/layout/layout";
import { PieChart } from "@mui/x-charts/PieChart";
import Sider from "antd/lib/layout/Sider";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LinearProgress from "@mui/material/LinearProgress";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

import { url } from "../../url";
import dayjs from "dayjs";
import UserContext from "../../UserContext";
import { useOutletContext } from "react-router-dom";
import FindemLogo from "../utils/FindemLogo/FindemLogo";
import HeaderComponent from "../Header/Header";
import TabsHomePage from "../Home/TabsHomePage";
import ProfileCreatorForm from "../DummyProfileCreator/ProfileCreatorForm";
import FilterToolbar from "./components/FilterToolbar";
import MappedProfileContainer from "./components/MappedProfileContainer";
import ReactJson from "@microlink/react-json-view";
const { Panel } = Collapse;

const LeadershipProfile = ({ loggedInUserDetails, logoutUser }) => {
    const [cxoProfiles, setCxoProfiles] = useState({ isLoading: true, data: [] });
    const [filterQuery, setFilterQuery] = useState();
    const [page, setPage] = useState(-1);
    const [hasMoreCxoProfiles, setHasMoreCxoProfiles] = useState(true);
    const [selectedProfile, setSelectedProfile] = useState();
    const [isProfileLoading, setIsProfileLoading] = useState(false);
    const [submitWithError, setSubmitWithError] = useState(false);
    const [isJsonView, setIsJsonView] = useState(false);

    const fetchCxoProfiles = async (resetPage) => {
        try {
            if (resetPage) {
                setCxoProfiles({ isLoading: true, data: [] });
            }
            const response = await axios.get(url.leadershipProfile.getProfiles, {
                params: { page: resetPage ? 0 : page + 1, filterQuery: filterQuery },
            });
            if (response.data.data.length == 0) {
                setHasMoreCxoProfiles(false);
                return;
            }
            if (resetPage) {
                setCxoProfiles({ isLoading: false, data: response?.data?.data });
            } else {
                setCxoProfiles({ isLoading: false, data: [...cxoProfiles?.data, ...response.data.data] });
            }

            setPage(resetPage ? 0 : page + 1);
            setHasMoreCxoProfiles(true);
        } catch (error) {
            setCxoProfiles({ ...cxoProfiles, isLoading: false });
            console.log("Failed to fetch profiles:", error);
            message.error("Failed to fetch profiles!");
        }
    };

    const openProfileCreator = async (profile) => {
        try {
            setSelectedProfile();
            setIsProfileLoading(true);
            const response = await axios.get(url.leadershipProfile.getProfile, { params: { id: profile._id } });
            const filteredProfile = prepareProfileForEdit(response?.data?.data);
            setSelectedProfile(filteredProfile);
            setIsProfileLoading(false);
        } catch (error) {
            console.log("Failed to get profile:", error);
            message.error("Failed to get profile!");
            setIsProfileLoading(false);
        }
    };

    const handlePostCreatedProfile = async (createdProfile) => {
        try {
            const response = await axios.post(url.leadershipProfile.createProfile, {
                newPublicProfile: createdProfile,
                profileId: selectedProfile?.profile_id,
                submitWithError: submitWithError,
            });
            message.success(response?.data?.message);
            setSelectedProfile(null);
            fetchCxoProfiles(true);
        } catch (error) {
            console.log("Failed to create new profile:", error);
            if (error?.response?.data?.message) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Failed to create new profile!");
        }
    };

    useEffect(() => {
        fetchCxoProfiles(true);
    }, [filterQuery]);

    return (
        <>
            <HeaderComponent loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser} />
            <div style={{ position: "sticky", top: "0", zIndex: "10", background: "white" }} className="pe-5 ps-5">
                <TabsHomePage />
            </div>
            <FilterToolbar filterQuery={filterQuery} setFilterQuery={setFilterQuery} />
            <Layout
                style={{
                    background: "none",
                    display: "flex",
                    flexDirection: "row",
                    margin: "0px 1rem",
                    justifyContent: "center",
                    padding: "5px",
                    borderRadius: "10px",

                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
            >
                <Sider
                    id="inifiniteSlider"
                    width={"20%"}
                    style={{
                        background: "none",
                        borderRight: "2px solid gray",
                        overflow: "auto",
                        height: "80vh",
                        overflowY: "auto",
                        position: "sticky",
                        top: "160px",
                    }}
                >
                    <InfiniteScroll
                        dataLength={cxoProfiles?.data?.length}
                        next={fetchCxoProfiles}
                        hasMore={hasMoreCxoProfiles}
                        loader={<h4>Loading...</h4>}
                        scrollableTarget={"inifiniteSlider"}
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                                <b>You have seen all the profiles!</b>
                            </p>
                        }
                    >
                        {cxoProfiles?.data?.map((item) => {
                            return (
                                <>
                                    <List.Item
                                        key={item._id}
                                        onClick={() => openProfileCreator(item)}
                                        style={{
                                            cursor: "pointer",
                                            backgroundColor: selectedProfile?._id === item._id ? "#E0FBE2" : "transparent",
                                            borderRadius: "5px",
                                            padding: "0.7rem 1.5rem 0.7rem 1.5rem",
                                        }}
                                    >
                                        <Box width={"100%"} display={"flex"} flexDirection={"column"}>
                                            <Stack>
                                                <Stack>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: "bold",
                                                            textWrap: "wrap",
                                                            wordBreak: "break-word",
                                                        }}
                                                    >
                                                        {item?.candidate?.full_name}
                                                    </Typography>

                                                    <Typography fontSize={"0.8rem"} sx={{ opacity: "0.5" }}>
                                                        {item?.candidate?.title}
                                                    </Typography>
                                                </Stack>
                                                <Typography fontSize={"0.9rem"}>Company Name: {item?.company_name}</Typography>
                                                <Typography>
                                                    Scraping Url:{" "}
                                                    <a
                                                        href={item?.scraping_url}
                                                        style={{
                                                            maxWidth: "300px",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                            display: "inline-block",
                                                            verticalAlign: "bottom",
                                                        }}
                                                        target="_blank"
                                                    >
                                                        {item?.scraping_url}
                                                    </a>
                                                </Typography>
                                                <Typography>
                                                    LinkedIn Url:{" "}
                                                    <a
                                                        href={item?.linkedin_url}
                                                        style={{
                                                            maxWidth: "300px",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                            display: "inline-block",
                                                            verticalAlign: "bottom",
                                                        }}
                                                        target="_blank"
                                                    >
                                                        {item?.linkedin_url}
                                                    </a>
                                                </Typography>

                                                <Typography sx={{mt: "0.5rem"}} width={"100%"} textAlign="right" color="text.secondary" variant="body2">
                                                    {dayjs(item.updated_at).format("MMM D, YYYY h:mm A")}{" "}
                                                </Typography>
                                            </Stack>
                                        </Box>
                                    </List.Item>
                                    <Divider></Divider>
                                </>
                            );
                        })}
                    </InfiniteScroll>
                </Sider>
                {selectedProfile && (
                    <Content>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Stack width={"75%"}>
                                <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    sx={{
                                        padding: "10px",
                                        borderBottom: "1px solid rgba(0,0,0, 0.2)",
                                        position: "sticky",
                                        top: "150px",
                                        background: "white",
                                        zIndex: "5",
                                    }}
                                >
                                    <FormControlLabel
                                        sx={{ padding: "0 1rem 0 1rem", borderRight: "1px solid rgba(0,0,0, 0.2)" }}
                                        control={
                                            <Switch checked={submitWithError} onChange={(e) => setSubmitWithError(e.target.checked)} />
                                        }
                                        label="Submit with error"
                                    />
                                    <Box display="flex" justifyContent="space-between">
                                        <Button
                                            variant="contained"
                                            onClick={() => setIsJsonView(!isJsonView)}
                                            sx={{
                                                textTransform: "none",
                                                color: isJsonView ? "#FFF" : "#172B4D",
                                                backgroundColor: isJsonView ? "#172B4D" : "#FFF",
                                                border: `1px solid ${isJsonView ? "#172B4D" : "#CCC"}`,
                                                "&:hover": {
                                                    backgroundColor: isJsonView ? "#0E1E2D" : "#F4F5F7",
                                                },
                                                transition: "background-color 0.3s, color 0.3s, border 0.3s",
                                            }}
                                        >
                                            {isJsonView ? "Switch to Form" : "View as JSON"}
                                        </Button>
                                    </Box>
                                </Box>
                                <Box display={"flex"} justifyContent={"center"}>
                                    {isJsonView ? (
                                        <ReactJson src={selectedProfile} name={false} displayDataTypes={false} />
                                    ) : (
                                        <ProfileCreatorForm
                                            profileData={selectedProfile}
                                            handlePostCreatedProfile={handlePostCreatedProfile}
                                        />
                                    )}
                                </Box>
                            </Stack>
                            <MappedProfileContainer selectedProfile={selectedProfile} />
                        </Box>
                    </Content>
                )}
                {!selectedProfile && !isProfileLoading && (
                    <Content style={{ display: "flex", opacity: "0.5" }}>
                        <div
                            style={{
                                flexGrow: "1",
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <div>
                                <AdsClickIcon sx={{ marginRight: "6px" }} />
                                Select left pane to display the details.
                            </div>
                        </div>
                    </Content>
                )}
                {!selectedProfile && isProfileLoading && (
                    <Content style={{ display: "flex", opacity: "0.5" }}>
                        <div
                            style={{
                                flexGrow: "1",
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <div>
                                <FindemLogo loading={true} color={"blue"} height={30} width={30}></FindemLogo>
                            </div>
                        </div>
                    </Content>
                )}
            </Layout>
        </>
    );
};

const prepareProfileForEdit = (profileData) => {
    profileData["created_at"] = dayjs();
    profileData["updated_at"] = dayjs();
    profileData["ext_refs"] = [
        {
            source_ref: profileData?.profile_id,
            source: "leadership_page",
            url: profileData?.scraping_url,
        },
    ];
    profileData["ext_src"] = "leadership_page";
    profileData["ext_src_ref"] = profileData?.profile_id;

    return profileData;
};
export default LeadershipProfile;
