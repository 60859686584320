import { Box, IconButton, Paper, TextField, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useRef, useState } from "react";
const PaginationFooter = ({ page, setPage, totalRecords, pageSize }) => {
    const totalPages = Math.ceil(totalRecords / pageSize);
    const [pageChangeInputOpen, setPageChangeInputOpen] = useState(false);
    const [inputPage, setInputPage] = useState(1);
    const pageNoInputRef = useRef();
    const handlePageNoChange = (event) => {
        const newPage = event.target.value;
        if (!newPage || newPage < 0) {
            return;
        }
        setInputPage(event.target.value);
    };
    const handleInputBlur = () => {
        if (pageChangeInputOpen) {
            setPage(pageNoInputRef.current.value);
            setPageChangeInputOpen(false);
        }
    };

    const handlePageNoClick = () => {
        if (!pageChangeInputOpen) {
            setPageChangeInputOpen(true);
            setTimeout(() => pageNoInputRef.current?.focus(), 0);
        }
    };
    const handlePageShift = (event) => {
        if (event.currentTarget.getAttribute("aria-label") === "move right") {
            setPage(Number(page) + 1);
        }
        if (event.currentTarget.getAttribute("aria-label") === "move left") {
            setPage(page - 1);
        }
    };
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <IconButton aria-label="move left" onClick={handlePageShift} disabled={page === 1}>
                <KeyboardArrowLeftIcon />
            </IconButton>
            <Box
                sx={{ minWidth: "1rem", background: "rgb(0,0,0,0.1)", textAlign: "center", borderRadius: "5px" }}
                onClick={handlePageNoClick}
            >
                {pageChangeInputOpen ? (
                    <input
                        ref={pageNoInputRef}
                        value={inputPage}
                        onChange={handlePageNoChange}
                        type="number"
                        onBlur={handleInputBlur}
                        min="1"
                        max={totalPages}
                        style={{ border: "none", width: "30px" }}
                    ></input>
                ) : (
                    <Typography>{page}</Typography>
                )}
            </Box>
            <Typography sx={{ fontSize: "2rem", padding: "0 0.5rem" }}>/</Typography>
            <Typography>{totalPages}</Typography>
            <IconButton onClick={handlePageShift} aria-label="move right" disabled={totalPages === page}>
                <KeyboardArrowRightIcon />
            </IconButton>
        </Box>
    );
};
export default PaginationFooter;
