import { Box, IconButton, Paper, TextField, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import HeaderComponent from "../../Header/Header";
import { useEffect, useRef, useState } from "react";
import { message } from "antd";
import axios from "axios";
import { url } from "../../../url";
import UserCard from "./components/UserCard";
import PaginationFooter from "./components/PaginationFooter";
import Header from "../components/Header";

const Users = ({ loggedInUserDetails, logoutUser }) => {
    const [usersData, setUsersData] = useState({ data: {}, isLoading: true });
    const [page, setPage] = useState(1);

    const fetchUsers = async () => {
        try {
            setUsersData({ data: {}, isLoading: true });
            const response = await axios.get(url.users, {
                params: {
                    page: page,
                    size: 20,
                },
            });
            setUsersData({ data: response?.data, isLoading: false });
        } catch (error) {
            console.log("Failed to fetch users:", error);
            message.error("Failed to fetch users!");
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [page]);
    return (
        <>
            {!usersData.isLoading && (
                <Paper sx={{ padding: "0 3rem" }} elevation={0}>
                    <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent:"space-around"}}>
                        {usersData?.data?.users.map((user, index) => {
                            return <UserCard key={index} user={user}></UserCard>;
                        })}
                    </Box>
                    <PaginationFooter page={page} setPage={setPage} totalRecords={usersData?.data?.totalUsers} pageSize={20} />
                </Paper>
            )}
        </>
    );
};

export default Users;
