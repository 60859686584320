import axios from "axios";
import { url } from "../../../url";
import { message } from "antd";

export const fetchDuplicateLeadershipProfile = async (profileId) => {
    try {
        const response = await axios.get(url.leadershipProfile.getProfile, { params: { profileId: profileId } });
        return [response?.data?.data];
    } catch (error) {
        console.log("Failed to get profile:", error);
        message.error("Failed to get duplicate profile!");
        return [];
    }
};
export const deleteDuplicateCxoProfile = async (profileId, dupeProfileId) => {
    try {
        const response = await axios.delete(url.leadershipProfile.deleteDupeCxoProfile(profileId, dupeProfileId));
        return response?.data;
    } catch (error) {
        console.log("Failed to delete cxo profile:", error);
        message.error(error?.response?.data?.message || "Failed to delete duplicate profile!");
        return null;
    }
};
