import React from "react";
import { Modal } from "react-bootstrap";
import "./modals.styles.css";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const BrokenLinkedInUrlModal = ({reportModalOpen, setReportModalOpen, handleReport}) => {
  return (
    <Dialog
    open={reportModalOpen}
    onClose={() => {
      setReportModalOpen(false);
    }}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {"Do you wish to proceed?"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
      If you report this candidate profile's LinkedIn URL as broken, you
        will no longer be able to edit the contact information.
      </DialogContentText>
    </DialogContent>
    <DialogActions style={{display: "flex", justifyContent:"space-between"}}>
      <Button onClick={() => {
        setReportModalOpen(false);
      }}>No, return to candidate</Button>
      <Button onClick={() => {
        handleReport(false);
      }} autoFocus>
        Yes, URL is broken
      </Button>
    </DialogActions>
  </Dialog>
  );
}

export default BrokenLinkedInUrlModal;
