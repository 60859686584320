import { CircularProgress } from "@mui/material";

const Loading = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                height: "67vh",
                alignItems: "center",
                width: "100%",
            }}
        >
            <CircularProgress />
        </div>
    );
};

export default Loading;
