import axios from "axios";
import { url } from "../../../url";

export const fetchProfileDetails = async (accountId, nDays) => {
    const response = await axios.get(url.customerDashboard.profileHistory, {
        params: {
            accountId: accountId,
            nDays: nDays,
        },
    });
    return response?.data;
};
