import { Typography } from "@mui/material";
import { motion } from "framer-motion";

const SelectSomething = ({ title, subTitle, Icon }) => {
   

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{
                height:"100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
            }}
        >
            {Icon && <Icon sx={{ fontSize: 80, color: "grey.500", mb: 2 }} />}
            <Typography variant="h6" color="textSecondary">
                {title}
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ maxWidth: 400, mt: 1 }}>
                {subTitle}
            </Typography>
        </motion.div>
    );
};

export default SelectSomething;
