import { Box, Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import LaunchIcon from '@mui/icons-material/Launch';
import FindemLogoPath from '../../assets/findem-logo-small-squircle.png'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CreatableSelect from 'react-select/creatable';
import { ENRICHED_PROFILE_LINK_PREFIX, LINKEDIN_PREFIX } from "./AltLinkFinder";
export const AltLinkTable = ({
    data,
    page,
    handleSelectionAltLink,
    handleOpenSubmitModal
}) => {
    return (
        <Table sx={{ minWidth: 650 }} aria-label="alt-link-data-table" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell style={{width: "10px"}}>Index</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Dead-Link EP</TableCell>
                    <TableCell>Source</TableCell>
                    {/* <TableCell>Is Customer ICP</TableCell> */}
                    <TableCell>Dead-Link</TableCell>
                    <TableCell>Alt-Link UID/EP</TableCell>
                    <TableCell align="right">Save</TableCell>
                    <TableCell align="right">Assigned To</TableCell>
                </TableRow>
            </TableHead>
            {!!data.length ?
                <TableBody>
                    {data.map((datum, index) => {
                        const altUidOptions =
                          datum?.alt_uid?.map((uid, idx) => {
                            return {
                              value: uid,
                              uid: uid,
                              label: (
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                      {decodeURI(uid)}
                                  <div style={{ display: "flex", gap: "0" }}>
                                    <Tooltip title="Open LinkedIn Profile">
                                      <IconButton component="a" href={`${LINKEDIN_PREFIX}/${uid}`} target="_blank">
                                        <LinkedInIcon style={{color:"#3E9BF7"}} />
                                      </IconButton>
                                  </Tooltip>
                                    {!!datum?.alt_profile_refs?.[idx]?.length && (
                                    <Tooltip title="Open EP">
                                        <IconButton
                                          component="a"
                                          href={`${ENRICHED_PROFILE_LINK_PREFIX}/${datum?.alt_profile_refs[idx]}?state=/searches/${datum.icp_id}&icpid=${datum.icp_id}&joid=${datum.owner_id}&aId=${datum.account_id}`}
                                          target="_blank"
                                        >
                                          <img src={FindemLogoPath} width={19} height={19.7}></img>
                                        </IconButton>
                                    </Tooltip>
                                    )}
                                  </div>
                                </div>
                              ),
                            };
                        }) ?? [];

                        return (
                            <TableRow 
                                key={datum._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {(index + 1) + ((page - 1) * 10)}   
                                </TableCell>
                                <TableCell>
                                    {datum.company_name}
                                </TableCell>
                                <TableCell>
                                    <a href={`${ENRICHED_PROFILE_LINK_PREFIX}/${datum.profile_ref}?state=/searches/${datum.icp_id}&icpid=${datum.icp_id}&joid=${datum.owner_id}&aId=${datum.account_id}`} 
                                        target="_blank"
                                    >
                                        Link
                                    </a>
                                </TableCell>
                                <TableCell>
                                    {datum.source}
                                </TableCell>
                                {/* <TableCell>
                                    {String(datum.is_customer_icp)}
                                </TableCell> */}
                                <TableCell 
                                    // in few cases uid is too long
                                    sx={{ maxWidth: "250px", textOverflow: "ellipsis", overflowX: "scroll"}}
                                >
                                    <a href={`${LINKEDIN_PREFIX}/${datum.dead_uid}`} target="_blank">
                                        {datum.dead_uid}
                                    </a>
                                </TableCell>
                                <TableCell>
                                    <CreatableSelect 
                                        isClearable 
                                        options={altUidOptions} 
                                        onChange={handleSelectionAltLink(index)}
                                        placeholder="Select/Enter a UID"
                                        // formatCreateLabel={(newString) => `Add new alt link: ${newString}`}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <Button 
                                        variant="contained"
                                        // disabled={datum.isDisabled}
                                        onClick={handleOpenSubmitModal}
                                        data-row={index}
                                    >
                                        Submit
                                    </Button>
                                </TableCell>
                                <TableCell align="right">
                                    {datum.assigned_to}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                :
                null
            }
        </Table>
    );
}