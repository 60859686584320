import AdsClickIcon from "@mui/icons-material/AdsClick";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { Box, Grid } from "@mui/material";

import { useState } from "react";
import FindemLogo from "../../../components/feedback/FindemLogo/FindemLogo";
import NoResultsTableOverlay from "../../../components/feedback/NoResultTableOverlay";
import SelectSomething from "../../../components/feedback/SelectSomething";
import LeftPane from "./LeftPane";
import RightPane from "./RighPane";

const LayoutContainer = ({ profilesData, toolbarData, filteredAndSortedProfiles, selectedProfile, setSelectedProfile }) => {
    const [isProfileLoading, setIsProfileLoading] = useState(false);

    const handleProfileClick = (event) => {
        let profileDetails = JSON.parse(event.currentTarget.dataset.profileDetails);
        const uniqueId = profileDetails?.bkup_ref + profileDetails?.click_date;
        profileDetails["uniqueId"] = uniqueId;
        setSelectedProfile(profileDetails);
    };

    return (
        <>
            {toolbarData?.accountId?.length > 0 ? (
                <>
                    {profilesData?.isLoading && <FindemLogo loading={true} color={"blue"} height={30} width={30}></FindemLogo>}
                    {!profilesData?.isLoading && profilesData?.data?.length < 1 && <NoResultsTableOverlay message={`No data found!`} />}
                    {!profilesData?.isLoading && profilesData?.data?.length > 0 && (
                        <Grid container m={0} p={0}>
                            <Grid
                                item
                                xs={3}
                                sx={(theme) => ({
                                    borderRight: theme.palette.border.et,
                                    pt: 0,
                                    pl: 0,
                                })}
                            >
                                <LeftPane
                                    filteredAndSortedProfiles={filteredAndSortedProfiles}
                                    handleProfileClick={handleProfileClick}
                                    selectedProfile={selectedProfile}
                                />
                            </Grid>
                            <Grid item xs={9} sx={{ pt: 0 }} flexGrow={1}>
                                {selectedProfile && (
                                    <Box display={"flex"} justifyContent={"space-between"} height={"100%"} width={"100%"}>
                                        <RightPane data={selectedProfile} />
                                    </Box>
                                )}
                                {!selectedProfile && !isProfileLoading && (
                                    <div
                                        style={{
                                            textAlign: "center",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            height: "300px",
                                        }}
                                    >
                                        <div>
                                            <AdsClickIcon sx={{ marginRight: "6px" }} />
                                            Select left pane to display the details.
                                        </div>
                                    </div>
                                )}
                                {!selectedProfile && isProfileLoading && (
                                    <div
                                        style={{
                                            textAlign: "center",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            height: "300px",
                                        }}
                                    >
                                        <div>
                                            <FindemLogo loading={true} color={"blue"} height={30} width={30}></FindemLogo>
                                        </div>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </>
            ) : (
                <SelectSomething
                    title={"No customer selected"}
                    subTitle={"Please select a customer from the dropdown above to view their profile details."}
                    Icon={PersonSearchIcon}
                />
            )}
        </>
    );
};

export default LayoutContainer;
