import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";

import AdsClickIcon from "@mui/icons-material/AdsClick";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LinearProgress from "@mui/material/LinearProgress";
import { PieChart } from "@mui/x-charts/PieChart";
import { Collapse, message } from "antd";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import dayjs from "dayjs";
import { useOutletContext } from "react-router-dom";
import { url } from "../../../url";
import UserContext from "../../../UserContext";
import FindemLogo from "../../utils/FindemLogo/FindemLogo";
const { Panel } = Collapse;

const InstantProfileRefreshHistory = () => {
    const loggedInUserDetails = useContext(UserContext);
    const [refreshRequestsData, setRefreshRequestsData] = useState({ isLoading: false, data: [] });
    const [page, setPage] = useState(-1);
    const [hasMoreHistoryData, setHasMoreHistoryData] = useState(true);
    const [selectedRefreshRequest, setSelectedRefreshRequest] = useState();
    const [refreshStatus, setRefreshStatus] = useState({ isLoading: false, data: {} });
    const [selectedStatusType, setSelectedStatusType] = useState(null);
    const [statusTypeOpen, setStatusTypeOpen] = useState(false);
    const { tabRef } = useOutletContext();
    const dividerRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const fetchRefreshRequestData = async () => {
        try {
            const response = await axios.get(url.getInstantProfileRefreshRequests, { params: { page: page + 1 } });
            if (response.data.data.length == 0) {
                setHasMoreHistoryData(false);
                return;
            }
            setRefreshRequestsData({ isLoading: true, data: [...refreshRequestsData?.data, ...response.data.data] });
            setPage(page + 1);
        } catch (error) {
            console.log("Failed to fetch refresh requests", error);
            message.error("Failed to fetch refresh request!");
        }
    };

    const showProfileRefreshStatus = async (requestData, pollingCall) => {
        if (requestData == undefined) {
            return;
        }
        setSelectedRefreshRequest(requestData);
        try {
            if (!pollingCall) {
                setRefreshStatus({ isLoading: true, data: {} });
            }
            let response = await axios.get(url.getInstantProfileRefreshStatus, {
                params: { userId: requestData.userId, auditLogId: requestData._id },
            });

            setRefreshStatus({ isLoading: false, data: response.data?.data });
        } catch (error) {
            setRefreshStatus({ isLoading: false, data: {} });
            message.error("Failed to get refresh status!");
        }
    };
    const handleStatusTypePopover = (event) => {
        let newStatusType = event.currentTarget?.dataset?.statusType;
        if (statusTypeOpen) {
            setStatusTypeOpen(false);
        } else {
            setAnchorEl(event.currentTarget);
            setStatusTypeOpen(true);
        }
        if (newStatusType) {
            setSelectedStatusType(newStatusType);
        }
    };
    const handleUidDownload = () => {
        message.info("This feature is not implemented yet.");
    };

    useEffect(() => {
        fetchRefreshRequestData();
    }, []);
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (selectedRefreshRequest != undefined) {
                showProfileRefreshStatus(selectedRefreshRequest, true);
            }
        }, 60 * 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [selectedRefreshRequest]);

    //Styling
    const theme = useTheme();
    const heights = theme.custom.heights;
    return (
        <Grid container m={0} p={0}>
            <Grid
                item
                xs={3}
                sx={(theme) => ({
                    borderRight: theme.palette.border.et,
                    pt: 0,
                    pl: 0,
                })}
            >
                <Box
                    id="inifiniteSlider"
                    sx={(theme) => ({
                        background: theme.palette.background.secondary,
                        justifyContent: "center",
                        padding: "5px",
                        borderRadius: "10px",
                        height: `calc(100vh - ${heights.header + heights.navbar + heights.subTabs}px)`,
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        overflowY: "auto",
                        position: "sticky",
                        top: heights.header + heights.navbar + heights.subTabs,
                    })}
                >
                    <InfiniteScroll
                        dataLength={refreshRequestsData.data.length}
                        next={fetchRefreshRequestData}
                        hasMore={hasMoreHistoryData}
                        loader={<h4>Loading...</h4>}
                        scrollableTarget={"inifiniteSlider"}
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                                <b>You have seen all history!</b>
                            </p>
                        }
                    >
                        {refreshRequestsData.data.map((item) => {
                            return (
                                <>
                                    <Box
                                        key={item._id}
                                        onClick={() => showProfileRefreshStatus(item)}
                                        sx={(theme) => ({
                                            cursor: "pointer",
                                            backgroundColor:
                                                selectedRefreshRequest?._id === item._id ? theme.palette.action.selected : "transparent",
                                            borderRadius: "5px",
                                            padding: "0.7rem 1.5rem 0.7rem 1.5rem",
                                        })}
                                    >
                                        <Box width={"100%"} display={"flex"} flexDirection={"column"}>
                                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                <Typography variant="h7" fontWeight={700} fontSize={17} gutterBottom>
                                                    <strong>UserId: </strong>
                                                    {item.userId}
                                                </Typography>
                                                <Box display="flex" alignItems="center" color="text.secondary">
                                                    <FiberManualRecordIcon sx={{ fontSize: 10 }} />
                                                    <Typography variant="body2" marginLeft={1}>
                                                        {dayjs(item.updatedAt).fromNow()}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Typography sx={{ cursor: "text" }} variant="caption">
                                                <strong>Label: </strong>
                                                {item.eventMetaData.instantProfileRefresh.label}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider></Divider>
                                </>
                            );
                        })}
                    </InfiniteScroll>
                </Box>
            </Grid>
            <Grid item xs={9} sx={{ pt: 0 }} flexGrow={1}>
                {selectedRefreshRequest && !refreshStatus.isLoading && (
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            minHeight: `calc(100vh - ${heights.header + heights.navbar + heights.subTabs}px)`,
                        }}
                    >
                        <header style={{ padding: "1rem" }}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-end",
                                            gap: "0.5rem",
                                        }}
                                    >
                                        <Typography>
                                            <strong>Requested By: </strong>
                                            {refreshStatus?.data?.userData?.name || "Unknown"}
                                        </Typography>
                                    </div>
                                    <Typography>
                                        <strong>
                                            Refresh Label: {selectedRefreshRequest?.eventMetaData?.instantProfileRefresh?.label}
                                        </strong>
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                                    <Typography>
                                        <strong>Total Uids: </strong>
                                        {refreshStatus?.data?.totalUids}
                                    </Typography>
                                    <Typography>
                                        <strong>Refresh Requested On:</strong>
                                        {selectedRefreshRequest?.updatedAt &&
                                            dayjs(selectedRefreshRequest?.updatedAt).format("MMMM D, YYYY h:mm A")}
                                    </Typography>
                                </div>
                            </div>
                        </header>
                        <hr></hr>
                        <Box
                            sx={{
                                padding: "1rem",
                                paddingBottom: "0",
                                display: "flex",
                                flexDirection: "column",
                                flexGrow: "1",
                            }}
                        >
                            <Typography style={{ fontSize: "1.2rem" }}>
                                Number of uids queued for refresh: {refreshStatus?.data?.totalUidsQueued}
                            </Typography>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant="body2">UIDs with Errors During Queuing</Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>Failed UIDs: {refreshStatus?.data?.failedUids}</Typography>
                                    <hr></hr>

                                    <Box sx={{ color: "red", display: "flex", flexWrap: "wrap", marginTop: "0.5rem" }}>
                                        {refreshStatus?.data?.batchResponses?.map((response) => {
                                            if (response?.status !== "200") {
                                                return response?.uids?.map((uid) => {
                                                    return <li style={{ width: "20%" }}>{uid + JSON.stringify(response?.status)}</li>;
                                                });
                                            }
                                        })}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            <Box
                                flexGrow={1}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "0.5rem",
                                    paddingBottom: "0",
                                    flexGrow: "1",
                                }}
                            >
                                <Box sx={{ width: "50%", padding: "1rem" }}>
                                    <strong>Refresh Status:</strong>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <LinearProgress
                                            color={
                                                getPercentage(
                                                    refreshStatus?.data?.refreshStatusData?.refreshedUIDsCount,
                                                    refreshStatus?.data?.totalUidsQueued
                                                ) == 100
                                                    ? "success"
                                                    : "primary"
                                            }
                                            sx={{ width: "100%", borderRadius: "10px", height: "1rem" }}
                                            variant="buffer"
                                            value={getPercentage(
                                                refreshStatus?.data?.refreshStatusData?.refreshedUIDsCount,
                                                refreshStatus?.data?.totalUidsQueued
                                            )}
                                            valueBuffer={0}
                                        />
                                        <p style={{ minWidth: "10px", padding: "0.4rem", color: "rgb(0,0,0,0.6)" }}>
                                            {`${getPercentage(
                                                refreshStatus?.data?.refreshStatusData?.refreshedUIDsCount,
                                                refreshStatus?.data?.totalUidsQueued
                                            )}%`}
                                        </p>
                                    </Box>
                                    <p style={{ marginTop: "3rem", fontWeight: "bold", marginBottom: "1rem" }}>Status Breakdown:</p>
                                    <PieChart
                                        sx={{ alignContent: "center" }}
                                        series={[
                                            {
                                                data: Object.entries(refreshStatus?.data?.refreshStatusData?.statusType || {}).map(
                                                    ([key, value], idx) => {
                                                        return { id: idx, value: value.length, label: key };
                                                    }
                                                ),
                                                highlightScope: { fade: "global", highlight: "item" },
                                                faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
                                            },
                                        ]}
                                        width={400}
                                        height={200}
                                    ></PieChart>
                                </Box>

                                <Box
                                    sx={(theme) => ({
                                        width: "50%",
                                        borderLeft: theme.palette.border.et,
                                    })}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "0.5rem",
                                            margin: "1px",
                                            borderRadius: "3px",
                                        }}
                                    >
                                        <Box display={"flex"} alignItems={"center"}>
                                            <Box>
                                                <Button variant="text" endIcon={<ExpandCircleDownIcon />} onClick={handleStatusTypePopover}>
                                                    Status: {selectedStatusType}
                                                </Button>
                                                <Menu
                                                    id="text-type-menu"
                                                    anchorEl={anchorEl}
                                                    open={statusTypeOpen}
                                                    onClose={handleStatusTypePopover}
                                                    MenuListProps={{
                                                        "aria-labelledby": "basic-button",
                                                    }}
                                                >
                                                    {Object.keys(refreshStatus?.data?.refreshStatusData?.statusType || {}).map(
                                                        (key, idx) => {
                                                            return (
                                                                <MenuItem data-status-type={key} onClick={handleStatusTypePopover}>
                                                                    {key}
                                                                </MenuItem>
                                                            );
                                                        }
                                                    )}
                                                </Menu>
                                            </Box>
                                        </Box>
                                        {
                                            <Box display={"flex"} alignItems={"center"}>
                                                <Button
                                                    component="label"
                                                    startIcon={<FileDownloadIcon />}
                                                    endIcon={false && <CircularProgress size={20} disableShrink />}
                                                    type="text"
                                                    onClick={handleUidDownload}
                                                >
                                                    Download UIDs
                                                </Button>
                                            </Box>
                                        }
                                    </Box>
                                    <Divider ref={dividerRef} style={{ margin: "0" }}></Divider>
                                    <Box sx={{ overflow: "auto" }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                margin: "0.5rem 0 0 1rem",
                                            }}
                                        >
                                            {(refreshStatus?.data?.refreshStatusData?.statusType[selectedStatusType] || []).map((ele) => {
                                                return (
                                                    <li style={{ width: "50%" }}>
                                                        <Tooltip
                                                            placement="right-end"
                                                            title={
                                                                ele?.description
                                                                    ? dayjs(ele?.description).isValid()
                                                                        ? dayjs(ele?.description).fromNow()
                                                                        : ele?.description
                                                                    : ""
                                                            }
                                                        >
                                                            {ele.uid}
                                                        </Tooltip>
                                                    </li>
                                                );
                                            })}
                                        </div>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )}
                {!selectedRefreshRequest && (
                    <div
                        style={{
                            flexGrow: "1",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            height: "100%",
                        }}
                    >
                        <div>
                            <AdsClickIcon sx={{ marginRight: "6px" }} />
                            Select left pane to display the details.
                        </div>
                    </div>
                )}
                {selectedRefreshRequest && refreshStatus.isLoading && (
                    <div
                        style={{
                            flexGrow: "1",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            height: "100%",
                        }}
                    >
                        <div>
                            <FindemLogo loading={true} color={"blue"} height={30} width={30}></FindemLogo>
                        </div>
                    </div>
                )}
            </Grid>
        </Grid>
    );
};

const getPercentage = (value, total) => {
    if (total === 0) {
        return 0;
    }
    return ((value / total) * 100).toFixed(2);
};
export default InstantProfileRefreshHistory;
