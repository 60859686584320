export const normalizeDatesRecursively = (obj) => {
    if (obj === null || obj === undefined) return obj;

    // Convert Date object to GMT string
    if (obj instanceof Date) {
        return obj.toUTCString();
    }

    // Convert only valid ISO date strings
    if (typeof obj === "string" && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(obj)) {
        return new Date(obj).toUTCString();
    }

    if (Array.isArray(obj)) {
        return obj.map(normalizeDatesRecursively);
    }

    if (typeof obj === "object") {
        return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, normalizeDatesRecursively(value)]));
    }

    return obj;
};

export const FILTER_OPTIONS = {
    ref: {
        label: "Ref",
        value: "ref",
        placeholder:"ref"
    },
    workexp_changed: {
        label: "Workexp Changed",
        value: "workexp_changed",
        placeholder:"true or false"
    },
    location_changed: {
        label: "Location Changed",
        value: "location_changed",
        placeholder:"true or false"
    },
    li: {
        label: "LinkedIn Url",
        value: "li",
        placeholder:"url"
    },
    user_id: {
        label: "User ID",
        value: "user_id",
        placeholder:"user id"
    },
};

export const SORT_OPTIONS = {
    click_date: {
        label: "EP Opened At",
        value: "click_date",
    },
};
