import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "antd";
import "./rightPane.styles.css";
import { Spin } from "antd";
import FormSubmissionModal from "../Modals/FormSubmissionModal";
import validator from "validator";
import { url } from "../../../url";
import Select from "react-select";
import ReactJson from "@microlink/react-json-view";

const statusOptions = [
  { value: "1", label: "Not Suspect" },
  { value: "2", label: "Not Sure" },
  { value: "3", label: "Wrong Email" },
];

const RightPaneContainer = ({
  clickedUserDetails: user,
  loggedInUserDetails: loggedInUser,
  removeCandidateAfterEmailUpdate,
  getData,
  readOnlyFlag,
  setUsersAfterSave,
}) => {
  const [newEmail, setNewEmail] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [displayInputEmail, setDisplayInputEmail] = useState("d-none");
  const [displayAddEmailText, setDisplayAddEmailText] = useState("d-block");
  const [displayInputPhone, setDisplayInputPhone] = useState("d-none");
  const [displayAddPhoneText, setDisplayAddPhoneText] = useState("d-block");
  const [candidateNewEmail, addCandidateNewEmail] = useState("");
  const [candidateNewPhone, addCandidateNewPhone] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [updateEmailError, getUpdateEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [updatePhoneError, getUpdatePhoneError] = useState("");
  const [linkedInUrlColor, setLinkedInUrlColor] = useState(false);
  const [displaySpinner, setDisplaySpinner] = useState(false);
  const [displayEmailDeletionModal, setEmailDeletionModal] = useState(false);
  const [displayPhoneDeletionModal, setPhoneDeletionModal] = useState(false);
  const [displayNoEmailsFoundModal, setNoEmailsFoundModal] = useState(false);
  const [displaybrokenLinkedInUrlModal, setBrokenLinkedInUrlModal] = useState(false);
  const [emailToRemove, setEmailToRemove] = useState("");
  const [status, setStatus] = useState("");
  const [modalComment, setModalComment] = useState("");
  const [contactOutData, setContactOutData] = useState({});
  const [contactOutLoader, setContactOutLoader] = useState(false);

  const handleMessageFormEnable = (event) => {
    setModalComment(event.target.value);
  };

  const handleContactOutData = (event) => {
    setContactOutLoader(true);
    axios
      .get(url.getContactOutData + `?email=${user.suspect_email}`)
      .then((response) => {
        setContactOutData(response.data.data.profile);
        console.log(response.data.data.profile);
        setContactOutLoader(false);
      })
      .catch((error) => {
        console.log(error.response.data.error);
        setContactOutData(error.response.data.error);
        setContactOutLoader(false);
      });
  };

  const handleStatusChange = (event) => {
    if (event === null) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
    setStatus(event);
  };

  useEffect(() => {
    setNewEmail("");
    addCandidateNewEmail("");
    addCandidateNewPhone(user ? user["phone"] : "");
    setDisplayInputEmail("d-none");
    setDisplayAddEmailText("d-block");
    setButtonDisabled(true);
    setLinkedInUrlColor(false);
    setModalComment("");
    setContactOutData({});
    setStatus(null);
  }, [user]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setDisplaySpinner(true);

    axios
      .post(url.wrongEmailUpdateStatus, {
        ref: user.ref,
        status: Number(status["value"]),
      })
      .then((res) => {
        console.log(res.status);

        let auditLogData = {
          eventName: "wrong_email",
          eventType: "review",
          userId: loggedInUser._id,
          eventMetaData: {
            candidateId: user.prid,
            candidateName: user.name,
            customerName: user.companyDetails[1],
            shortlistLink: user.enriched_profile,
            status: status["label"],
            suspectEmail: user.suspect_email,
            mismatchedNames: user.mismatched_names.join(" | "),
            comment: modalComment.trim(),
            ref: user.ref,
          },
        };

        axios
          .post(url.auditPost, auditLogData)
          .then((res) => {
            console.log(res.status);
            setUsersAfterSave(true);
          })
          .catch((error) => {
            console.log(error);
            removeCandidateAfterEmailUpdate(user.ref);
            setButtonDisabled(true);
            setStatus("");
            setDisplaySpinner(false);
          });
        axios
          .get(url.updateWrongEmailProfileHide + `?ref=${user.ref}`)
          .then((res) => {
            console.log(res.status);
          })
          .catch((error) => {
            console.log(error);
            removeCandidateAfterEmailUpdate(user.ref);
            setButtonDisabled(true);
            setStatus("");
            setDisplaySpinner(false);
          });
      })
      .catch((error) => {
        removeCandidateAfterEmailUpdate(user.ref);
        setButtonDisabled(true);
        setStatus("");
        setDisplaySpinner(false);
      });

    removeCandidateAfterEmailUpdate(user.ref);
    setButtonDisabled(true);
    setStatus(null);
    setModalComment("");
    setDisplaySpinner(false);
    // setUsersAfterSave(true);
  };

  if (user && !displaySpinner) {
    // console.log(user, user.emails);
    return (
      <div className="h-100">
        <div className="d-flex justify-content-between p-4">
          <div className="d-flex flex-column w-50">
            <h6>
              <strong>{user.name}</strong>
            </h6>
            <a href={`${user.enriched_profile}`} target="blank">
              Findem Enriched Profile <i className="fa-solid fa-arrow-up-right-from-square ms-1"></i>
            </a>
          </div>
          <div className="w-50">
            <h6 className="text-end">
              <strong>Customer: {user.customer || "not specified"}</strong>
            </h6>
          </div>
        </div>
        <hr></hr>
        {/* Form  */}
        <form className="py-4 px-2 mt-3" onSubmit={handleFormSubmit}>
          <div>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <h6>Suspect Email</h6>
                <p>{user.suspect_email !== "null" ? user.suspect_email : "No suspect email"}</p>
              </div>
              {readOnlyFlag ? null : (
                <div className="d-flex flex-column" style={{ width: "200px" }}>
                  <h6>Status</h6>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={false}
                    name="color"
                    options={statusOptions}
                    onChange={handleStatusChange}
                    value={status}
                  />
                </div>
              )}
              {
                <ul className="d-flex flex-column text-dark">
                  {user?.mismatched_names.length ? (
                    <span className="fw-bold">Mismatched names</span>
                  ) : (
                    <span className="fw-bold">No mismatched names</span>
                  )}
                  {user.mismatched_names.map((name) => {
                    return (
                      <li key={name} style={{ wordBreak: "break-all", cursor: "pointer" }}>
                        {name}
                      </li>
                    );
                  })}
                </ul>
              }
            </div>
            {readOnlyFlag ? null : (
              <>
                <div className="d-flex justify-content-between align-items-end">
                  <div>
                    {isButtonDisabled ? (
                      <>
                        <Tooltip overlayStyle={{ maxWidth: "120px", fontSize: "10px" }} placement="top" title="Please select the status carefully">
                          <button
                            type="button"
                            className="form-save-btn-disabled"
                            data-bs-toggle="modal"
                            data-bs-target="#formModal"
                            disabled={isButtonDisabled}>
                            Save
                          </button>
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="form-save-btn-enabled"
                          data-bs-toggle="modal"
                          data-bs-target={`#formModal${user.ref}`}
                          disabled={isButtonDisabled}>
                          Save
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <div>
                  {contactOutLoader ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <Spin tip="Getting data from Contactout"></Spin>
                    </div>
                  ) : (
                    <>
                      <button onClick={handleContactOutData} type="button" className="btn btn-primary mt-4">
                        Validate with Contactout
                      </button>
                      {Object.keys(contactOutData).length === 0 ? null : (
                        <ReactJson src={contactOutData} style={{ marginTop: "30px" }} name={false} displayDataTypes={false} />
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>

          {/*Modals*/}
          <FormSubmissionModal
            candidateNewEmail={candidateNewEmail}
            userRef={user.ref}
            modalComment={modalComment}
            handleMessageFormEnable={handleMessageFormEnable}
          />
        </form>
      </div>
    );
  } else if (displaySpinner) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spin tip="Loading..."></Spin>
      </div>
    );
  }
};

export default RightPaneContainer;
