import React from "react";
import { Modal } from "react-bootstrap";
import "./modals.styles.css";

const EmailDeletionModal = ({displayNoEmailsFoundModal, displayModalControl }) => {
  return (
    <>
      <Modal
        show={displayNoEmailsFoundModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <p className="text-center mt-3">Oops! No Emails Found</p>
          <div className="d-flex justify-content-around mt-5">
            <button
              type="button"
              className="modal-button mt-auto modal-no-btn-color"
              onClick={displayModalControl}
              id="closeNoEmailsFoundModal"
            >
              close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EmailDeletionModal;
