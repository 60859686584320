import { Avatar, Box, CardHeader, FormControl, InputLabel, MenuItem, Modal, Select, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Button, message } from "antd";
import axios from "axios";
import { url } from "../../../../url";

const EditUserModal = ({ open, userId, handleClose }) => {
    const [userData, setUserData] = useState();
    const [roleOptions, setRoleOptions] = useState([]);
    const [selectedRoleId, setSelectedRoleId] = useState("");
    const fetchUser = async () => {
        try {
            const response = await axios.get(url.getUser, {
                params: {
                    userId: userId,
                },
            });
            setUserData(response?.data?.data);
        } catch (error) {
            console.log("Failed to get user:", error);
            if (error?.response?.data?.message) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Failed to get user details!");
        }
    };
    const fetchRoleOptions = async () => {
        try {
            const response = await axios.get(url.getRoles, { params: { fields: "name" } });
            setRoleOptions(response?.data?.data);
        } catch (error) {
            setRoleOptions([]);
            console.log("Failed to fetch roles:", error);
            if (error?.response?.status) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Failed to get roles!");
        }
    };
    const handleRoleSelect = (event) => {
        setSelectedRoleId(event.target.value);
    };

    const handleRoleChange = async () => {
        try {
            const response = await axios.post(url.changeUserRole, {
                userId: userId,
                roleId: selectedRoleId,
            });
            fetchUser();
            handleClose()
            message.info("User updated successfully!")
        } catch (error) {
            console.log("Unable to change role:", error);
            if (error?.response?.data?.message) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Unable to change role!");
        }
    };
    useEffect(() => {
        fetchUser();
        fetchRoleOptions();
    }, []);
    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={{ margin: "3rem auto", width: "30%" }}>
                <Box sx={{ background: "white", borderRadius: "5px", padding: "2rem" }}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                            <Avatar>{userData?.name?.slice(0, 1).toUpperCase()}</Avatar>
                            <Typography fontWeight={"bold"} fontSize={"1.2rem"}>
                                {userData?.name}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", flexDirection:"column", alignItems:"flex-end" }}>
                            
                            <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold", marginLeft: "10px" }}>
                                {userData?.role?.name}
                            </Typography>
                            <Typography fontSize={"0.7rem"} color={"grey"}>Access Level: {userData?.role?.accessLevel}</Typography>
                        </Box>
                    </Stack>
                    <Stack marginTop={"2rem"} direction={"column"}>
                        <Typography>Email: {userData?.email}</Typography>
                        <Typography>Access Level: {userData?.role?.accessLevel}</Typography>
                        <Typography>Role: {userData?.role?.name}</Typography>
                    </Stack>
                    <FormControl sx={{ marginTop: "2rem" }} fullWidth>
                        <InputLabel id="role-input">Change Role</InputLabel>
                        <Select labelId="role-input" value={selectedRoleId} label="Change Role" onChange={handleRoleSelect}>
                            {roleOptions.map((role) => {
                                return (
                                    <MenuItem key={role._id} value={role._id}>
                                        {role.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Stack marginTop={"2rem"} direction={"row-reverse"}>
                        <Button onClick={handleRoleChange}>Update User</Button>
                    </Stack>
                </Box>
            </Box>
        </Modal>
    );
};

export default EditUserModal;
