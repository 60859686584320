import { Typography } from "@mui/material";

const IcpDropdownLabel = ({ companyName, name, unverifiedProfileCount, icpId, icpType }) => (
    <div>
        <Typography variant="body2">
            {companyName} - {name} {`(${unverifiedProfileCount})`}
        </Typography>

        <p
            id={`react-select-pe-option-${icpId}`}
            style={{
                margin: "0",
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                fontWeight: "500",
                fontSize: "0.875rem",
                lineHeight: "1.57",
                letterSpacing: "0.00714em",
                marginBottom: "0.35em",
                color: "#AEAEAE",
            }}
        >
            {icpId} ({icpType})
        </p>
    </div>
);
export default IcpDropdownLabel;
