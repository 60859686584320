import { Spin, Switch } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../layouts/navbar/Navbar";
import { url } from "../../url";
import { checkPermission, DRA_MODULES } from "../../utils/rbacUtils";
import Header from "../Header/Header";
import FilterComponent from "./Filter/FilterComponent";
import LeftPaneContainer from "./LeftPane/LeftPaneContainer";
import RightPaneContainer from "./RightPane/RightPaneContainer";
import RightPaneWhenNotClicked from "./RightPane/RightPaneWhenNotClicked";
import "./wrongEmail.css";

const WrongEmail = ({ loggedInUserDetails, logoutUser }) => {
  const [data, getData] = useState([]);
  const [dataInAction, setDataInAction] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(0);
  const [selectedLeftPane, selectLeftPane] = useState("white");
  const [isLeftPaneClicked, handleLeftPaneClick] = useState(false);
  const [clickedUserDetails, getClickedUserDetails] = useState("");
  const [nextObjectId, setNextObjectId] = useState("");
  const buttonRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [filteredCustomers, getFilteredCustomers] = useState([]);
  const [isFullDataLoaded, getFullData] = useState(false);
  const [loadingMoreDataSpinner, setLoadingMoreDataSpinner] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(["", ""]);
  const [selectedFilterFullOption, setSelectedFilterFullOption] = useState(null);
  const [listOfCompanies, setListOfCompanies] = useState([]);
  const [mainSpinner, setMainSpinner] = useState(false);
  const isFirstRender = useRef(true);
  const [showTaskAssignedTo, setShowTaskAssignedTo] = useState(true);
  const [readOnlyFlag, setReadOnlyFlag] = useState(false);
  const [workDoneCount, setWorkDoneCount] = useState(0);
  const [getUsersAfterSave, setUsersAfterSave] = useState(false);

  useEffect(() => {
    setMainSpinner(true);
    getListOfCompanies();
  }, []);

  const getListOfCompanies = async () => {
    // let res2 = await axios.get(url.customersAPI);
    // res2 = Object.entries(res2.data.response);
    let customersData = await axios.get(url.customersData);
    customersData = customersData.data.customersData;
    let res2 = [];

    for (let customer of customersData) {
      res2.push([customer.account_id, { users: customer.users, company_name: customer.company_name }]);
    }

    setCustomers(res2);
    // making a list of companies which will be used in filter options
    setListOfCompanies(
      res2
        .map((company) => [company[1]["company_name"], company[0]])
        .sort((a, b) => {
          return a[0].localeCompare(b[0]);
        })
    );
    fetchUsers("", res2);
  };
  useEffect(() => {
    getFullData(false);
    if (isFirstRender.current === false) {
      // reset page to 0
      setPage(0);
      // reset all data
      getData([]);

      if (selectedCustomer[0] === "") {
        setMainSpinner(true);
        fetchUsers("");
        setUsersAfterSave(false);
      } else if (selectedCustomer[0] === "Not specified") {
        // do nothing for now
      } else {
        // fetchCategoryData();
        setMainSpinner(true);
        fetchUsers(selectedCustomer[1]);
        setUsersAfterSave(false);
      }
    }
  }, [selectedCustomer, showTaskAssignedTo, getUsersAfterSave]);

  const fetchUsers = async (accountId, res2) => {
    let res = [];
    let responseFetch;
    try {
      if (accountId === "") {
        // responseFetch = await axios.get(url.candidatesAPI + "/" + "0");
        responseFetch = await axios.get(
          url.getWrongEmailData +
            `?page=0&email=${loggedInUserDetails.email}&showTaskAssignedTo=${showTaskAssignedTo}`
        );
      } else {
        // responseFetch = await axios.get(url.candidatesAPI + "/0/" + accountId);
        responseFetch = await axios.get(
          url.getWrongEmailData +
            `?page=0&account_id=${accountId}&email=${loggedInUserDetails.email}&showTaskAssignedTo=${showTaskAssignedTo}`
        );
      }
      res = res.concat(responseFetch.data.data)[0].candidates;
      if (!responseFetch.data.data.candidates) {
        res = [];
      }
      setWorkDoneCount(responseFetch.data.data.workDone);
      setNextPage(responseFetch.data.data.next_page);
    } catch (e) {
      res = [];
      console.error(e.message);
      setMainSpinner(false);
    }

    if (isFirstRender.current) {
      setReadOnlyFlag(responseFetch.data.data.readOnly);
    }
    setWorkDoneCount(responseFetch.data.data.workDone);

    setMainSpinner(false);

    // let res2 = await axios.get(url.customersAPI);
    // res2 = Object.entries(res2.data.response);

    // setCustomers(res2);
    // let res2 = customers;
    // let res2 = customers;
    // making a list of companies which will be used in filter options
    // setListOfCompanies(res2.map(company => [company[1]["company_name"], company[0]]));

    //check each user id in every array of customers data, making a function and calling it length of res2 times
    //each call will check each profile with customers data to get customers data

    const mapProfilesToCustomers = (currentObject) => {
      let userId = currentObject.user_id;
      let filteredObjects;
      if (res2) {
        filteredObjects = res2.filter((customerObject) => {
          return customerObject[1].users.includes(userId);
        });
        isFirstRender.current = false;
      } else {
        filteredObjects = customers.filter((customerObject) => {
          return customerObject[1].users.includes(userId);
        });
      }
      return filteredObjects;
    };

    //calling mapProfiles function with each profile object
    // .filter((object) => object.emails.length === 0)
    // .sort(
    //   (preObj, currObj) =>
    //     moment(preObj.shortlisted_on) - moment(currObj.shortlisted_on)
    // )
    let requiredData = res.map((currentObject) => {
      let responseFromMapProfiles = mapProfilesToCustomers(currentObject);
      //names has encoded uri strings, decoding them
      try {
        currentObject.name = decodeURIComponent(currentObject.name);
      } catch (error) {
        console.error("ERROR: ", currentObject.name, error);
      }

      currentObject.customer = responseFromMapProfiles.length ? responseFromMapProfiles[0][1].company_name : null;

      try {
        currentObject.title = decodeURIComponent(currentObject.title);
      } catch (error) {
        console.error("ERROR: ", currentObject.title, error);
      }
      currentObject.shortlisted_on_unix = currentObject.shortlisted_on;
      currentObject.shortlisted_on = moment.utc(currentObject.shortlisted_on).format("DD/MM/YYYY");
      return currentObject;
    });

    getData(requiredData);
    setDataInAction(requiredData);

    if (filteredCustomers.length !== 0) {
      requiredData = requiredData.filter((object) => filteredCustomers.includes(object.customer));
      getData(requiredData);
    }
  };

  const displayDetailsOnRightPane = (event) => {
    let requiredObject = data[event.target.id];
    setNextObjectId(Number(event.target.id) + 1);
    selectLeftPane(event.target.id);
    handleLeftPaneClick(true);
    getClickedUserDetails(requiredObject);
  };

  const filteredCustomersData = (filteredCustomers) => {
    getFilteredCustomers(filteredCustomers);
    getData([]);
  };

  const toggleVisible = (event) => {
    // console.log('scrollTop: ', event.currentTarget.scrollTop);
    // console.log('offsetHeight: ', event.currentTarget.offsetHeight);
    if (event.currentTarget.scrollTop > 300) {
      setVisible(true);
    } else if (event.currentTarget.scrollTop <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    buttonRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    if (page > 0) {
      fetchMoreData(selectedCustomer[1]);
    }
  }, [page]);

  const incrementPage = () => {
    setPage(nextPage);
  };
  const fetchMoreData = async (accountId) => {
    //making whole data to empty array so that spinner will run
    // getData([]);
    setLoadingMoreDataSpinner(true);
    // setPage((preValue) => preValue + 1);
    let linkedinUrlArray = JSON.parse(localStorage.getItem("lnkdInUrls"));
    let res;
    try {
      if (!accountId) {
        // res = await axios.get(url.candidatesAPI + "/" + page);
        res = await axios.get(
          url.getWrongEmailData +
            `?page=${page}&email=${loggedInUserDetails.email}&showTaskAssignedTo=${showTaskAssignedTo}`
        );
      } else {
        // res = await axios.get(url.candidatesAPI + "/" + page + "/" + accountId);
        res = await axios.get(
          url.getWrongEmailData +
            `?page=${page}&account_id=${accountId}&email=${loggedInUserDetails.email}&showTaskAssignedTo=${showTaskAssignedTo}`
        );
      }

      setLoadingMoreDataSpinner(false);
      // spinner should be reset
      if (!res.data.data.candidates || res.data.data.candidates.length === 0) {
        getFullData(true);
      } else {
        getFullData(false);
      }
    } catch (error) {
      console.log(error.message);
      getData(dataInAction);
      getFullData(true);
      setLoadingMoreDataSpinner(false);
      return null;
    }

    setNextPage(res.data.data.next_page);
    res = res.data.data.candidates;
    //check each user id in every array of customers data, making a function and calling it length of res times
    //each call will check each profile with customers data to get customers data
    const mapProfilesToCustomers = (currentObject) => {
      let userId = currentObject.user_id;
      let filteredObjects = customers.filter((customerObject) => {
        return customerObject[1].users.includes(userId);
      });
      return filteredObjects;
    };

    // .filter((object) => object.emails.length === 0)
    //calling mapProfiles function with each profile object
    let requiredData = res.map((currentObject) => {
      let responseFromMapProfiles = mapProfilesToCustomers(currentObject);
      //names has encoded uri strings, decoding them
      try {
        currentObject.name = decodeURIComponent(currentObject.name);
      } catch (error) {
        console.error("ERROR: ", currentObject.name, error);
      }

      currentObject.customer = responseFromMapProfiles.length ? responseFromMapProfiles[0][1].company_name : null;

      try {
        currentObject.title = decodeURIComponent(currentObject.title);
      } catch (error) {
        console.error("ERROR: ", currentObject.title, error);
      }
      currentObject.shortlisted_on_unix = currentObject.shortlisted_on;
      currentObject.shortlisted_on = moment.utc(currentObject.shortlisted_on).format("DD/MM/YYYY");
      return currentObject;
    });

    if (linkedinUrlArray !== null) {
      requiredData = requiredData.filter((object) => {
        if (linkedinUrlArray.includes(object.linkedin)) {
          return false;
        } else {
          return true;
        }
      });
    }

    // requiredData = [...requiredData, ...dataInAction].sort(
    //   (preObj, currObj) =>
    //     moment(preObj.shortlisted_on, "DD/MM/YYYY") -
    //     moment(currObj.shortlisted_on, "DD/MM/YYYY")
    // );
    // console.log("Required data: ", requiredData);
    // console.log("Data in action: ", dataInAction);

    requiredData = dataInAction.concat(requiredData);
    getData(requiredData);
    setDataInAction(requiredData);

    if (filteredCustomers.length !== 0) {
      requiredData = requiredData.filter((object) => filteredCustomers.includes(object.customer));
      getData(requiredData);
    }
  };

  const removeCandidateAfterEmailUpdate = (ref) => {
    getClickedUserDetails(data[Number(nextObjectId)]);
    let dataAfterEmailUpdate = dataInAction.filter((object) => object.ref !== ref);
    getData(dataAfterEmailUpdate);
    setDataInAction(dataAfterEmailUpdate);
  };

  const handleFilterChange = (event) => {
    // getFilteredCustomers(event.target.value)
    // getData([]);
    // setPage(0);
    if (event === null) {
      setSelectedCustomer(["", ""]);
    } else {
      setSelectedCustomer([event.value, event.accountId]);
    }
    setSelectedFilterFullOption(event);
    handleLeftPaneClick(false);
  };

  const onShowTaskAssignedToCheckedChange = () => {
    setShowTaskAssignedTo(!showTaskAssignedTo);
  };

  if (mainSpinner === false) {
    return (
      <>
        <Header loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser} />
        {readOnlyFlag ? <h6 className="text-center mt-5">No task assigned | Read only mode</h6> : null}

        <Navbar />

        <div className="mx-5" style={{ paddingBottom: "3rem" }}>
          <div className="pe-3 total-data-container">
            {!readOnlyFlag ? (
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  paddingTop: "8px",
                  margin: "0",
                }}>
                Work done for today in Wrong Email: {workDoneCount}
              </p>
            ) : null}
            <div className="row">
              <div className="col-4 p-4" id="scrollContainer">
                {checkPermission(loggedInUserDetails, DRA_MODULES.assignWork).update ? (
                  <h6 className="text-center">
                    <Switch
                      checkedChildren="Show all"
                      unCheckedChildren="Show only assigned"
                      className="mx-5"
                      checked={showTaskAssignedTo}
                      onChange={onShowTaskAssignedToCheckedChange}
                    />
                  </h6>
                ) : null}
                <FilterComponent
                  dataOriginal={data}
                  dataCloned={dataInAction}
                  filteredCustomersData={filteredCustomersData}
                  filteredCustomers={filteredCustomers}
                  handleFilterChange={handleFilterChange}
                  listOfCompanies={listOfCompanies}
                  selectedFilterFullOption={selectedFilterFullOption}
                />
                <div className="scroll-height" ref={buttonRef} onScroll={toggleVisible}>
                  <LeftPaneContainer
                    loggedInUserDetails={loggedInUserDetails}
                    onClick={handleLeftPaneClick}
                    displayUsers={data}
                    displayDetailsOnRightPane={displayDetailsOnRightPane}
                    selectedLeftPane={selectedLeftPane}
                    incrementPage={incrementPage}
                    isFullDataLoaded={isFullDataLoaded}
                    loadingMoreDataSpinner={loadingMoreDataSpinner}
                    selectedCustomer={selectedCustomer}
                  />

                  {/* Scroll Button */}
                  <div className="d-flex justify-content-end">
                    <button className={`scroll-top-button ${visible ? "display-scroll-btn" : "hide-scroll-btn"}`} onClick={scrollToTop}>
                      <i className="fa-solid fa-circle-arrow-up scroll-top-icon"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-8">
                {isLeftPaneClicked ? (
                  <RightPaneContainer
                    loggedInUserDetails={loggedInUserDetails}
                    clickedUserDetails={clickedUserDetails}
                    removeCandidateAfterEmailUpdate={removeCandidateAfterEmailUpdate}
                    getData={getData}
                    readOnlyFlag={readOnlyFlag}
                    setUsersAfterSave={setUsersAfterSave}
                  />
                ) : (
                  <RightPaneWhenNotClicked />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Header loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser} />
       
        <Navbar />
        
        <div className="d-flex justify-content-center align-items-center loader-container">
          <Spin tip="Loading..." />
        </div>
      </>
    );
  }
};

export default WrongEmail;
