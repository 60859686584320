import React, { useEffect, useState, useRef, useMemo } from "react";
import Header from "../Header/Header";
import TabsHomePage from "../Home/TabsHomePage";
import axios from "axios";
import Papa from "papaparse";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload, Spin } from "antd";
import { Box, LinearProgress, Button as MuiButton } from "@mui/material";
import { url } from "../../url";
import GenericCreatable from "../CopilotSearches/GenericCreatable";
import { Paper, Stack, Typography } from "@mui/material";
import GenericSnackbar from "../CopilotSearches/RTCopilotSearches/GenericSnackbar";

const AssignWork = ({ loggedInUserDetails, logoutUser }) => {
  const [csvFile, setCsvFile] = useState();
  const [assigningWorkLoader, setAssigningWorkLoader] = useState(false);
  const [resultFromUploadCsv, setResultFromUploadCsv] = useState("");
  const [errorFromUploadCsv, setErrorFromUploadCsv] = useState("");
  const [progressData, setProgressData] = useState({})

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    setErrorFromUploadCsv("");
    reader.onload = (e) => {
      Papa.parse(e.target.result, {
        complete: async function (results) {
          try{
            setAssigningWorkLoader(true);
            // Create a new EventSource connection for SSE
            const eventSource = new EventSource(`${url.getCsv}?data=${encodeURIComponent(JSON.stringify(results.data))}`);

            // Listen for events sent by the server
            eventSource.onmessage = (event) => {
              const data = JSON.parse(event.data);

              if (data?.data?.trackAssignedWork) {
                setResultFromUploadCsv(data?.data?.trackAssignedWork);
              }
              if (data?.data?.trackProgress){
                setProgressData(data?.data?.trackProgress);
              }

              if (data.sseClosed) {
                setAssigningWorkLoader(false);
                eventSource.close();
              }
            };

            eventSource.onerror = (error) => {
              console.error('SSE Error:', error);
              setErrorFromUploadCsv("An error occurred while processing the task.");
              setAssigningWorkLoader(false);
              setResultFromUploadCsv("");
              setProgressData({});
              eventSource.close();
            };            
          }catch(error) {
              setErrorFromUploadCsv(error);
              console.log(error);
              setAssigningWorkLoader(false);
              setResultFromUploadCsv("");
          };
        },
      });
    };
    reader.readAsText(file);

    // prevent upload
    return false;
  }
  return (
    <div>
      <div className="pe-5 ps-5">
        <div className="mt-4 mb-4 total-data-container">
          <div className="row h-100 w-100">
            <div className="col-12 d-flex flex-column justify-content-center align-items-center">
              {assigningWorkLoader ? (
                <Box>
                  <Spin
                    tip="Assigning work, please wait this might take a while..."
                    className="mt-5"
                    />
                </Box>
              ) : (
                <Upload
                  className="mt-5"
                  accept=".csv"
                  beforeUpload={handleFileUpload}
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              )}
              {(progressData != {} && progressData) && 
                  Object.keys(progressData).map((moduleName)=>{
                    let progress = Math.round( (progressData[moduleName]?.tasksAssigned / progressData[moduleName].totalTasks) * 100 )
                    return(
                      <Box sx={{ display: 'flex', alignItems: 'center', width:"30%" }}>

                          <Typography textAlign={"right"} width={"30%"} marginRight={"1rem"} variant="body2" sx={{ color: 'text.secondary' }}>
                            {moduleName}:
                          </Typography>
                        <Box sx={{width:"50%", mr: 1 }}>
                          <LinearProgress variant="determinate" value={progress} />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {`${progress}%`}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  })
                  }
              {resultFromUploadCsv ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">User</th>
                      <th scope="col">Tasks Assigned</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(resultFromUploadCsv).map((data, index) => {
                      let datum = data.split(" ");
                      return (
                        <tr key={index}>
                          <th scope="row">{`${datum[0]} - ${datum
                            .slice(1)
                            .join(" ")}`}</th>
                          <td>{resultFromUploadCsv[data]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : null}
              {errorFromUploadCsv ? (
                <p>
                  Faced an error in uploading csv, please check the emails and
                  upload again
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignWork;
