import { Input, Button, Select, Form, InputNumber, Spin, Tooltip } from "antd";
import { method, values } from "lodash";
import { url } from "../../url";
import { common } from "@mui/material/colors";

const YEAR_OPTIONS = [
  { value: "Less than 1 year", label: "Less than 1 year" },
  { value: "1 to 2 years", label: "1 to 2 years" },
  { value: "3 to 5 years", label: "3 to 5 years" },
  { value: "6 to 10 years", label: "6 to 10 years" },
  { value: "More than 10 years", label: "More than 10 years" },
];

const FUNCTION_OPTIONS = [
  { value: "Administrative", label: "Administrative" },
  { value: "Accounting", label: "Accounting" },
  { value: "Arts and Design", label: "Arts and Design" },
  { value: "Business Development", label: "Business Development" },
  { value: "Community and Social Services", label: "Community and Social Services" },
  { value: "Consulting", label: "Consulting" },
  { value: "Education", label: "Education" },
  { value: "Engineering", label: "Engineering" },
  { value: "Entrepreneurship", label: "Entrepreneurship" },
  { value: "Finance", label: "Finance" },
  { value: "Healthcare Services", label: "Healthcare Services" },
  { value: "Human Resources", label: "Human Resources" },
  { value: "Information Technology", label: "Information Technology" },
  { value: "Legal", label: "Legal" },
  { value: "Marketing", label: "Marketing" },
  { value: "Media and Communication", label: "Media and Communication" },
  { value: "Military and Protective Services", label: "Military and Protective Services" },
  { value: "Operations", label: "Operations" },
  { value: "Product Management", label: "Product Management" },
  { value: "Program and Project Management", label: "Program and Project Management" },
  { value: "Purchasing", label: "Purchasing" },
  { value: "Quality Assurance", label: "Quality Assurance" },
  { value: "Real Estate", label: "Real Estate" },
  { value: "Research", label: "Research" },
  { value: "Sales", label: "Sales" },
  { value: "Customer Success and Support", label: "Customer Success and Support" },
];

const RequestForm = ({
  isCompanyDetailsLoading,
  initialCompanyData,
  formValues,
  handleCheckExternalEmployeesCount,
  handleRequestFormSubmit,
  getCompanyDetails,
  isDisabled,
}) => {
  const validateCounts = (max) => (_, values) => {
    if (values && values.length > max) {
      return Promise.reject(new Error(`You can only select up to ${max} items.`));
    }
    return Promise.resolve();
  };

  const validateIntArray = () => (_, values) => {
    return new Promise((resolve, reject) => {
      values.map((ele) => {
        if (isNaN(parseInt(ele))) {
          reject(new Error("Enter only numeric values."));
        }
      });
      resolve();
    });
  };
  const handleCompanyDomainKeyUp = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.key === "Enter") {
      getCompanyDetails(event);
    }
  };

  return (
    <Form name="RefreshCompanyProfiles" form={formValues} initialValues={initialCompanyData} disabled={isDisabled}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            margin: "18px 0",
            gap: "25px",
            justifyContent: "center",
            width: "60%",
            padding: "5px",
            marginTop: "1rem",
            padding: "4rem",
            borderRadius: "10px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <div
            style={{
              width: "50%",
            }}
          >
            <Form.Item
              name="company_domain"
              label={<Tooltip title="eg. apple.com">Company Domain</Tooltip>}
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input onBlur={getCompanyDetails} onKeyUp={handleCompanyDomainKeyUp}></Input>
            </Form.Item>
            <Form.Item
              name="lnkd_company_id_numeric"
              label={<Tooltip title="eg. 1586">LinkedIn Company Id(Num)</Tooltip>}
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input suffix={isCompanyDetailsLoading && <Spin size="small" indicator="ant-icon" />}></Input>
            </Form.Item>
            <Form.Item name="company_name" label={<Tooltip title="eg. Apple">Company Name</Tooltip>}>
              <Input suffix={isCompanyDetailsLoading && <Spin size="small" indicator="ant-icon" />}></Input>
            </Form.Item>
            <Form.Item name="lnkd_company_id" label={<Tooltip title="eg. amazon-fullfilment-center">LinkedIn Company Id</Tooltip>}>
              <Input suffix={isCompanyDetailsLoading && <Spin size="small" indicator="ant-icon" />}></Input>
            </Form.Item>
            <Form.Item name="meta_ref" label={<Tooltip title="eg. 5b0c4a8f3200621bdb2ee26e">Meta Ref</Tooltip>}>
              <Input suffix={isCompanyDetailsLoading && <Spin size="small" indicator="ant-icon" />}></Input>
            </Form.Item>
            <Form.Item
              name="current_company_ids"
              label={<Tooltip title="eg. 1584, 4585">Current Company Ids</Tooltip>}
              rules={[{ required: true, message: "This field is required!" }, { validator: validateIntArray() }]}
            >
              <Select mode="tags" options={[]}></Select>
            </Form.Item>
            <Form.Item
              name="limit"
              label="Limit"
              rules={[{ type: "number", max: 2500, message: "Limit must be less than or equal to 2500" }]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="eg. 25" />
            </Form.Item>
            <Form.Item
              name="refresh_label"
              label={<Tooltip title="eg. box_refresh_0808">Refresh Label</Tooltip>}
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input
                style={{ width: "100%" }}
                disabled={true}
                suffix={isCompanyDetailsLoading && <Spin size="small" indicator="ant-icon" />}
              />
            </Form.Item>
            <Form.Item
              name="keywords"
              label={<Tooltip title="eg. CEO, Founder">Keywords</Tooltip>}
              rules={[{ validator: validateCounts(20) }]}
            >
              <Input></Input>
            </Form.Item>
          </div>
          <div
            style={{
              width: "50%",
            }}
          >
            <Form.Item
              name="title_keywords"
              label={<Tooltip title="eg. CEO, Founder">Title Keywords</Tooltip>}
              rules={[{ validator: validateCounts(20) }]}
            >
              <Select mode="tags" options={[]}></Select>
            </Form.Item>
            <Form.Item
              name="title_keywords_exclude"
              label={<Tooltip title="eg. HR, Sales Executive">Title Keywords Exclude</Tooltip>}
              rules={[{ validator: validateCounts(20) }]}
            >
              <Select mode="tags" options={[]}></Select>
            </Form.Item>
            <Form.Item
              name="functions"
              label={<Tooltip title="eg. Finance, Marketing">Functions</Tooltip>}
              rules={[{ validator: validateCounts(20) }]}
            >
              <Select mode="multiple" options={FUNCTION_OPTIONS}></Select>
            </Form.Item>
            <Form.Item name="year_in_current_company" label="Year in Current Company">
              <Select placeholder="Select" mode="multiple" options={YEAR_OPTIONS}></Select>
            </Form.Item>
            <Form.Item name="year_in_current_position" label="Year in Current Position">
              <Select placeholder="Select" mode="multiple" options={YEAR_OPTIONS}></Select>
            </Form.Item>
            <Form.Item
              name="past_company_ids"
              label={<Tooltip title="eg. 1584, 4585">Past Company Ids</Tooltip>}
              rules={[{ validator: validateIntArray() }]}
            >
              <Select mode="tags" options={[]}></Select>
            </Form.Item>
            <Form.Item
              name="geo_codes"
              label={<Tooltip title="eg. 102095887">Geo Codes</Tooltip>}
              rules={[{ validator: validateCounts(5) }]}
            >
              <Select mode="tags" options={[]}></Select>
            </Form.Item>
            <Form.Item
              name="geo_codes_exclude"
              label={<Tooltip title="eg. 102095887">Geo Codes Exclude</Tooltip>}
              rules={[{ validator: validateCounts(5) }]}
            >
              <Select mode="tags" options={[]}></Select>
            </Form.Item>
          </div>
        </div>
        {!isDisabled && (
          <div style={{ display: "flex" }}>
            <Form.Item label=" " colon={false}>
              <Button type="primary" onClick={handleRequestFormSubmit}>
                Send Refresh Request
              </Button>
            </Form.Item>
            <Form.Item label=" " colon={false}>
              <Button type="primary" onClick={handleCheckExternalEmployeesCount}>
                Check External Employees Count
              </Button>
            </Form.Item>
          </div>
        )}
      </div>
    </Form>
  );
};

export default RequestForm;
