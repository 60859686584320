import React from "react";
import {Modal} from "react-bootstrap";
import "./modals.styles.css";

const PhoneDeletionModal = ({displayPhoneDeletionModal, displayModalControl}) => {
  return (
    <>
      <Modal
        show={displayPhoneDeletionModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <p className="text-center mt-3">
            Are you sure you want to delete Phone?
          </p>
          <div className="d-flex justify-content-around mt-5">
            <button
              type="button"
              className="modal-button mt-auto modal-no-btn-color"
              id="closePhoneDeletionModal"
              onClick={displayModalControl}
            >
              No
            </button>
            <button
              type="button"
              className="modal-button mt-auto modal-yes-btn-color"
              id="closeAndRemovePhoneModal"
              onClick={displayModalControl}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PhoneDeletionModal;
