import React, { useEffect, useState } from "react";
// import "./index.css";
import { CloseOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import axios from "axios";
import { PlusOutlined, MinusCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { get, set } from 'lodash';
import { Button, Card, Form, Input, Space, InputNumber, Tabs, Spin, Modal, message, Select, DatePicker, Typography, Checkbox } from "antd";

import ArrOfStrUIHandler from "../ArrOfStrUIHandler";
import dayjs from "dayjs";
import DurationCard from "./DurationCard";


//fieldPath -> complete path ["workexp", "idx", "projects"]
//name -> only required path for antd form for e.g. ["idx", "projects"]
//form -> form instance of antd

const ProjectForm = ({ fieldPath, name, form }) => {
    return (
        <Card size="small" title="Projects">
            <Form.List name={name}>
                {(projectFields, { add: addProject, remove: removeProject }) => (
                    <>
                        {projectFields.map((projectField) => (
                            <Card
                                key={projectField.key}
                                size="small"
                                title={`Project ${projectField.key + 1}`}
                                extra={
                                    <MinusCircleOutlined
                                        onClick={() => removeProject(projectField.name)}
                                        style={{
                                            color: "red",
                                            marginLeft: "10px",
                                        }}
                                    />
                                }
                            >
                                {/* Other Fields of projects under workexp */}
                                <Form.Item
                                    name={[projectField.name, "name"]}
                                    fieldKey={[projectField.fieldKey, "name"]}
                                    label="Project Name"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item name={[projectField.name, "project_type"]} label={"Project Type"}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name={[projectField.name, "url"]} fieldKey={[projectField.fieldKey, "url"]} label="URL">
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name={[projectField.name, "description"]}
                                    fieldKey={[projectField.fieldKey, "description"]}
                                    label="Description"
                                >
                                    <Input.TextArea />
                                </Form.Item>

                                {/* Duration */}
                                <DurationCard
                                    fieldPath={[...fieldPath, "projects", projectField.name]}
                                    name={projectField.name}
                                    form={form}
                                />

                                {/* Role and Group */}
                                <Card size="small" title="Role and Group">
                                    <Form.Item name={[projectField.name, "role_and_group", "title"]} label="Title">
                                        <Input />
                                    </Form.Item>

                                    {/* <Form.Item
                                    name={[projectField.name, "role_and_group", "capacity"]}
                                    label="Capacity"
                                >
                                    <Input />
                                </Form.Item> */}

                                    {/* <Form.Item
                                    name={[projectField.name, "role_and_group", "group"]}
                                    label="Group"
                                >
                                    <Input />
                                </Form.Item> */}

                                    {/* <Form.Item
                                    name={[projectField.name, "role_and_group", "group_size"]}
                                    label="Group Size"
                                >
                                    <InputNumber />
                                </Form.Item> */}
                                </Card>

                                {/* Products Used */}
                                {/* <Card size="small" title="Products Used">
                                <Form.List name={[projectField.name, "products_used"]}>
                                    {(productFields, { add: addProduct, remove: removeProduct }) => (
                                        <>
                                            {productFields.map((productField, index) => (
                                                <Card
                                                    size="small"
                                                    title={`Product Used ${index + 1}`}
                                                    extra={
                                                        <MinusCircleOutlined
                                                            onClick={() => removeProduct(productField.name)}
                                                        />
                                                    }
                                                >
                                                    <Form.Item
                                                        name={[productField.name, "source"]}
                                                        fieldKey={[productField.fieldKey, "source"]}
                                                        label="Source"
                                                    >
                                                        <Input />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={[productField.name, "product_name"]}
                                                        fieldKey={[productField.fieldKey, "product_name"]}
                                                        label="Product Name"
                                                    >
                                                        <Input />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={[productField.name, "company_name"]}
                                                        fieldKey={[productField.fieldKey, "company_name"]}
                                                        label="company Name"
                                                    >
                                                        <Input />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={[productField.name, "company_domain"]}
                                                        fieldKey={[productField.fieldKey, "company_domain"]}
                                                        label="Company Domain"
                                                    >
                                                        <Input />
                                                    </Form.Item> */}

                                {/* Reviews Given */}

                                {/* <Card size="small" title="Reviews Given">
                                                        <Form.List
                                                            name={[productField.name, "reviews_given"]}
                                                        >
                                                            {(
                                                                reviewFields,
                                                                { add: addReview, remove: removeReview }
                                                            ) => (
                                                                <>
                                                                    {reviewFields.map(
                                                                        (reviewField, index) => (
                                                                            <Card
                                                                                size="small"
                                                                                title={`Review Given ${
                                                                                    index + 1
                                                                                }`}
                                                                                key={index}
                                                                                align="baseline"
                                                                                extra={
                                                                                    <MinusCircleOutlined
                                                                                        onClick={() =>
                                                                                            removeReview(
                                                                                                reviewField.name
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                }
                                                                            >
                                                                                <Form.Item
                                                                                    name={[
                                                                                        reviewField.name,
                                                                                        "review_type",
                                                                                    ]}
                                                                                    fieldKey={[
                                                                                        reviewField.fieldKey,
                                                                                        "review_type",
                                                                                    ]}
                                                                                    label="Review Type"
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>

                                                                                <Form.Item
                                                                                    name={[
                                                                                        reviewField.name,
                                                                                        "rating",
                                                                                    ]}
                                                                                    fieldKey={[
                                                                                        reviewField.fieldKey,
                                                                                        "rating",
                                                                                    ]}
                                                                                    label="Rating"
                                                                                >
                                                                                    <InputNumber />
                                                                                </Form.Item>

                                                                                <Form.Item
                                                                                    name={[
                                                                                        reviewField.name,
                                                                                        "date",
                                                                                    ]}
                                                                                    fieldKey={[
                                                                                        reviewField.fieldKey,
                                                                                        "date",
                                                                                    ]}
                                                                                    label="Date"
                                                                                >
                                                                                    <DatePicker />
                                                                                </Form.Item>

                                                                                <Form.Item
                                                                                    name={[
                                                                                        reviewField.name,
                                                                                        "review_desc",
                                                                                    ]}
                                                                                    fieldKey={[
                                                                                        reviewField.fieldKey,
                                                                                        "review_desc",
                                                                                    ]}
                                                                                    label="Review Description"
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>
                                                                            </Card>
                                                                        )
                                                                    )}
                                                                    <Button
                                                                        type="dashed"
                                                                        onClick={() => addReview()}
                                                                        block
                                                                        icon={<PlusOutlined />}
                                                                    >
                                                                        Add Review
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </Form.List>
                                                    </Card>
                                                </Card>
                                            ))}
                                            <Button
                                                type="dashed"
                                                onClick={() => addProduct()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Add Product Used
                                            </Button>
                                        </>
                                    )}
                                </Form.List>
                            </Card> */}
                            </Card>
                        ))}
                        <Button type="dashed" onClick={() => addProject()} block icon={<PlusOutlined />}>
                            Add Project
                        </Button>
                    </>
                )}
            </Form.List>
        </Card>
    );
};

export default ProjectForm;
