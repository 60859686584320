import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function GenericCheckboxList({
  data,
  handleChange,
  dataObject,
}) {
  console.log("data: ", data, ": \ndataObject: ", dataObject);
  return (
    <FormGroup>
      {data.map((datum, index) => {
        return (
          <FormControlLabel
            key={`${dataObject[datum]}_${index}`}
            control={
              <Checkbox
                checked={dataObject[datum]["checked"]}
                onChange={handleChange}
              />
            }
            label={
              <p style={{ marginBottom: "0px", fontSize: "0.875rem" }}>
                {datum}{" "}
                {dataObject[datum]["type"] ? (
                  <b>({dataObject[datum]["type"]})</b>
                ) : null}
              </p>
            }
            disabled={dataObject[datum]["disabled"]}
            name={datum}
          />
        );
      })}
    </FormGroup>
  );
}
