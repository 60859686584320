import styled from 'styled-components';
import { Input } from 'antd';

export const SearchContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 1px solid lightgrey;
    height: 100%;
    margin-top: 4rem;
    padding: 2rem;
    border-radius: 1rem;
    width: 60%;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
`;

export const InputLinkedin = styled(Input)`
    align-self: flex-start;
    height: 2rem;
`;

export const Container = styled.div`
    display: flex;
    justifyContent: center;
    alignItems: center;
`;

export const Email = styled.div`
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ECF9FF;
    padding: 2px 8px 2px 8px;
    border-radius: 8px;
`;

export const InputHelperMessage = styled.div`
    color: lightgrey;
`;

export const InputWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21rem;
`;

export const InputEmail = styled(Input)`
    align-self: flex-start;
    height: 2rem;
`;