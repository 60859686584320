import { Cancel, CheckCircle } from "@mui/icons-material"; // Import MUI icons
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    Link,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { useState } from "react";

import DiffDataEditor from "../../../components/Common/editor/DiffDataEditor";
import dayjs from "dayjs";

const RightPane = ({ data }) => {
    const [openDialog, setOpenDialog] = useState(false); // state to open/close the dialog
    const [diffViewerData, setDiffViewerData] = useState();

    const handleOpenDialog = (event) => {
        setOpenDialog(true);
        const field = event.currentTarget.id;
        if (field === "locationButton") {
            setDiffViewerData({ field: "Location", originalData: data?.location, modifiedData: data?.public_profile_location });
        } else if (field === "workexpButton") {
            setDiffViewerData({ field: "Workexp", originalData: data?.workexp, modifiedData: data?.public_profile_workexp });
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    return (
        <Paper elevation={3} sx={{ p: 3, width: "100%" }}>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography color={"text.primary"} variant="h6" gutterBottom>
                    Profile View Details
                </Typography>
                <Typography variant="body1">{data?.ref || "-"}</Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Grid container>
                <Grid item xs={12} md={4}>
                    <Stack spacing={2}>
                        <InfoItem label="Profile Ref" value={data?.ref} />
                        <InfoItem
                            label="Profile LinkedIn URL"
                            value={
                                <Link href={data.li} target="_blank" rel="noopener">
                                    {data.li}
                                </Link>
                            }
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Stack spacing={2}>
                        <InfoItem label="EP Opened By" value={data?.user_id} />
                        <InfoItem label="EP Opened At" value={dayjs.utc(data.click_date).local().format("MMMM D, YYYY h:mm A")} />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Stack spacing={2}>
                        <InfoItem
                            label="Profile Refreshed At when Opened"
                            value={dayjs.utc(data.updated_at).local().format("MMMM D, YYYY h:mm A")}
                        />
                        <InfoItem
                            label="Profile Refreshed At (Now)"
                            value={dayjs().utc(data.public_profile_updated_at).local().format("MMMM D, YYYY h:mm A")}
                        />
                    </Stack>
                </Grid>
            </Grid>
            {(data.workexp_changed || data.location_changed) && (
                <Box mt={4}>
                    <Typography variant="subtitle1" color="error">
                        Changes Detected
                    </Typography>
                    <Divider sx={{ my: 1 }} />

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant="h6" gutterBottom color={"text.primary"}>
                                            Work Experience Change
                                        </Typography>
                                        <Typography variant="body2" color={data.workexp_changed ? "error" : "green"}>
                                            <Box
                                                sx={{
                                                    padding: 1,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    cursor: data.workexp_changed ? "pointer" : "unset",
                                                }}
                                            >
                                                {data.workexp_changed ? (
                                                    <CheckCircle sx={{ color: "error.dark", marginRight: 1 }} />
                                                ) : (
                                                    <Cancel sx={{ color: "success.light", marginRight: 1 }} />
                                                )}
                                                {data.workexp_changed ? "Yes" : "No"}
                                            </Box>
                                        </Typography>
                                    </Box>

                                    <Button
                                        id="workexpButton"
                                        variant="contained"
                                        color="primary"
                                        sx={{ mt: 2 }}
                                        disabled={data.workexp_changed ? false : true}
                                        onClick={handleOpenDialog}
                                    >
                                        View Work Experience Changes
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Typography variant="h6" gutterBottom>
                                            Location Change
                                        </Typography>
                                        <Typography variant="body2" color={data.location_changed ? "error" : "green"}>
                                            <Box
                                                sx={{
                                                    padding: 1,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    cursor: data.location_changed ? "pointer" : "unset",
                                                }}
                                            >
                                                {data.location_changed ? (
                                                    <CheckCircle sx={{ color: "error.dark", marginRight: 1 }} />
                                                ) : (
                                                    <Cancel sx={{ color: "success.light", marginRight: 1 }} />
                                                )}
                                                {data.location_changed ? "Yes" : "No"}
                                            </Box>
                                        </Typography>
                                    </Box>
                                    <Button
                                        id="locationButton"
                                        variant="contained"
                                        color="primary"
                                        sx={{ mt: 2 }}
                                        disabled={data.location_changed ? false : true}
                                        onClick={handleOpenDialog}
                                    >
                                        View Location Changes
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    {/* Dialog for showing the diff */}
                    <Dialog open={openDialog} maxWidth="xl" fullWidth onClose={handleCloseDialog}>
                        <DialogContent>
                            {/* Show the diff content based on which change is available */}
                            <Box>
                                <Box height={"90vh"}>
                                    <Box display="flex" justifyContent="space-between" mb={2}>
                                        <Typography variant="h6" component="div" textAlign="center" width="50%">
                                            Last Viewed {diffViewerData?.field}
                                        </Typography>
                                        <Typography variant="h6" component="div" textAlign="center" width="50%">
                                            Current {diffViewerData?.field}
                                        </Typography>
                                    </Box>

                                    <DiffDataEditor
                                        originalData={diffViewerData?.originalData}
                                        modifiedData={diffViewerData?.modifiedData}
                                    />
                                </Box>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            )}
        </Paper>
    );
};

const InfoItem = ({ label, value }) => (
    <Box>
        <Typography variant="body2" color="textSecondary">
            {label}
        </Typography>
        <Typography variant="body1">{value || "-"}</Typography>
    </Box>
);

export default RightPane;
