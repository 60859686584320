export const normalizeDatesRecursively = (obj) => {
    if (obj === null || obj === undefined) return obj;

    // Convert Date object to GMT string
    if (obj instanceof Date) {
        return obj.toUTCString();
    }

    // Convert only valid ISO date strings
    if (typeof obj === "string" && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(obj)) {
        return new Date(obj).toUTCString();
    }

    if (Array.isArray(obj)) {
        return obj.map(normalizeDatesRecursively);
    }

    if (typeof obj === "object") {
        return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [key, normalizeDatesRecursively(value)])
        );
    }

    return obj;
};
