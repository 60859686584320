import { Spin, Tabs } from "antd";
import Header from "../Header/Header";
import TabsHomePage, { TAB_ROUTE_TO_NAME_ID_MAP } from "../Home/TabsHomePage";
import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { LoadingOutlined, ReloadOutlined, SyncOutlined } from "@ant-design/icons";

const RefreshCompanyProfiles = ({ loggedInUserDetails, logoutUser }) => {
  const { pathname, search, state } = useLocation();
  const [selectedTab, setSelectedTab] = useState("1");
  const navigate = useNavigate();
  const tabItems = [
    {
      key: "1",
      label: "Refresh Request",
      pathname: "/company-employee-profile-refresh/refresh",
    },
    {
      key: "2",
      label: "Refresh History",
      pathname: "/company-employee-profile-refresh/refresh-history",
    },
    {
      key: "3",
      label: "Check Employees Count (External Sources)",
      pathname: "/company-employee-profile-refresh/check-employees-count",
    },
  ];

  useEffect(() => {
    let currentPathname = state?.pathname || pathname;
    let tabItem = tabItems.find((ele) => ele.pathname == currentPathname);

    if (tabItem) {
      setSelectedTab(tabItem.key);
      navigate(tabItem.pathname);
    }
  }, []);

  const handleTabClick = (key) => {
    let tabItem = tabItems.find((ele) => ele.key == key);
    setSelectedTab(tabItem.key);
    navigate(tabItem.pathname);
  };

  return (
    <div>
      <Header loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser} />
      <div className="pe-5 ps-5">
        <TabsHomePage />
      </div>
      <div
        style={{
          margin: "1rem 3rem 0rem 3rem",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center", gap: "0.8rem" }}>
          {tabItems.map((item) => {
            return (
              <div
                style={{
                  padding: "10px 20px",
                  margin: "0 10px",
                  cursor: "pointer",
                  fontWeight: "bold",
                  color: selectedTab == item.key ? "#1890ff" : "#333",
                  borderBottom: selectedTab == item.key ? "2px solid #1890ff" : "none",
                }}
                onClick={() => handleTabClick(item.key)}
              >
                {item.label}
              </div>
            );
          })}
        </div>
      </div>
      <Outlet></Outlet>
    </div>
  );
};

export default RefreshCompanyProfiles;
