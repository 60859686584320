import { DiffEditor } from "@monaco-editor/react";
import { useTheme } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { sortJsonObject } from "../../utils/util";

const DiffDataEditor = ({ originalData, modifiedData, language = "javascript" }) => {
    const originalValue = JSON.stringify(sortJsonObject(originalData), null, 2);
    const modifiedValue = JSON.stringify(sortJsonObject(modifiedData), null, 2);
    const theme = useTheme();
    const editorRef = useRef(null);

    useEffect(() => {
        return () => {
            if (editorRef.current) {
                editorRef.current.setModel(null); 
            }
        };
    }, []);

    return (
        <DiffEditor
            language={language}
            theme={theme.palette.monacoTheme}
            original={originalValue}
            modified={modifiedValue}
            options={{
                readOnly: true,
                renderSideBySide: true,
                automaticLayout: true,
            }}
            onMount={(editor) => (editorRef.current = editor)}
        />
    );
};

export default DiffDataEditor;
