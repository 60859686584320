import axios from "axios";
import { url } from "../url";

export const fetchPublicProfile = async (ref) => {
    const response = await axios.get(url.publicProfile.getPublicProfile, {
        params: {
            ref: ref,
        },
    });
    return response?.data;
};
