import { Autocomplete, Button, Card, CardActions, CardContent, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

import { useState } from "react";
import { DRA_MODULES } from "../../../utils/rbacUtils";
const SelectInputCard = ({ onSubmit, label, inputLabel, roleData }) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);

    const toggleAddCard = () => {
        setOpen(!open);
    };
    const handleValueChange = (event, newOption) => {
        setValue(newOption);
    };
    const handleAddInput = () => {
        onSubmit({ moduleName: value?.name, moduleLabel: value?.label });
        setValue("");
        setOpen(false);
    };

    return open ? (
        <Card
            elevation={0}
            sx={{
                background: "#F4F5F7",
                overflow:"auto",
                minHeight: "15vh",
                borderRadius: "12px",
                boxShadow: "2px -4px 50px rgba(0, 0, 0, 0.5)",
                border: "2px solid black",
            }}
        >
            <CardContent>
                <Autocomplete
                    value={value}
                    onChange={handleValueChange}
                    options={Object.keys(DRA_MODULES)
                        .filter((key) => !roleData?.permissions.some((permission) => permission.moduleName === DRA_MODULES[key].name))
                        .map((key, idx) => ({
                            name: DRA_MODULES[key].name,
                            label: DRA_MODULES[key].label,
                            key: idx,
                        }))}
                    sx={{ width: 300 }}
                    isOptionEqualToValue={(option)=> option.name === value.name}
                    renderInput={(params) => <TextField {...params} label={inputLabel} />}
                />
            </CardContent>
            <CardActions>
                <Button sx={{ textTransform: "none" }} disabled={!value} onClick={handleAddInput}>
                    Add
                </Button>
                <IconButton onClick={toggleAddCard}>
                    <CloseIcon />
                </IconButton>
            </CardActions>
        </Card>
    ) : (
        <Button
            startIcon={<AddIcon />}
            sx={{
                background: "#F4F5F7",
                color: "#172B4D",
                textTransform: "none",
                justifyContent: "flex-start",
                paddingRight: "3rem",
                minHeight: "8vh",
            }}
            onClick={toggleAddCard}
        >
            {label}
        </Button>
    );
};
export default SelectInputCard;
