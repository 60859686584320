import { Typography } from 'antd';
import styled from 'styled-components';

export const SectionWrapper = styled.div`
    display: "flex";
    justify-content: "space-between";
    flex-direction: "column";
    gap: "8px";
    margin-bottom: 8px;
`;


export const SectionTitle = styled(Typography)`
    margin-bottom: 5px;
`;