import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { checkPermission, MODULE_ACTIONS } from "../../utils/rbacUtils";
import UnauthorizedPage from "../utils/UnauthorizedPageTemplate/UnauthorizedPage";

function ProtectedRoutes(props) {
  const { Component, loggedInSessionUser, loggedInUser, permissionRequired, module, loggedInUserDetails, logoutUser, nestedRoute } = props;
  const { pathname, search } = useLocation();
  if (loggedInSessionUser.name || loggedInUser.name) {
    if(permissionRequired && !checkPermission(loggedInSessionUser,module,MODULE_ACTIONS.READ).read){
      return <UnauthorizedPage loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser} nestedRoute={nestedRoute}/>
    }
    return <Component {...props} />;
  } else {
    return (
      <Navigate
        to="/login"
        state={{
          pathname: pathname,
          search: search,
        }}
      />
    );
  }
}

export default ProtectedRoutes;
