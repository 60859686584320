// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-styles {
  border: none;
  background-color: #fff;
}

.modal-content {
  height: 230px;
  border-radius: 40px;
  border-style: solid;
  border-width: 2px;
  border-color: black;
}

.modal-backdrop {
  background-color: rgb(179, 174, 174);
}

.modal-dialog {
  max-width: 550px;
}

.modal-button {
  width: 170px;
  height: 50px;
  border: none;
  border-radius: 8px;
  font-weight: bold;
}

.modal-yes-btn-color {
  background-color: #98dca1;
  color: white;
}

.modal-no-btn-color {
  background-color: grey;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/features/add-missing-email/components/modals/modals.styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd","sourcesContent":[".input-styles {\n  border: none;\n  background-color: #fff;\n}\n\n.modal-content {\n  height: 230px;\n  border-radius: 40px;\n  border-style: solid;\n  border-width: 2px;\n  border-color: black;\n}\n\n.modal-backdrop {\n  background-color: rgb(179, 174, 174);\n}\n\n.modal-dialog {\n  max-width: 550px;\n}\n\n.modal-button {\n  width: 170px;\n  height: 50px;\n  border: none;\n  border-radius: 8px;\n  font-weight: bold;\n}\n\n.modal-yes-btn-color {\n  background-color: #98dca1;\n  color: white;\n}\n\n.modal-no-btn-color {\n  background-color: grey;\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
