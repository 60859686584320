import { Modal, Typography } from "antd";
import React from "react";
import ReactJson from "@microlink/react-json-view";
const { Title } = Typography;

const TimeLineChangesModal = ({ isChangesModalOpen, handleChangesModalClose, candidateDetails }) => {
  // console.log(candidateDetails, 1111111);
  return (
    <>
      <Modal
        title=""
        visible={isChangesModalOpen}
        onCancel={handleChangesModalClose}
        width="65%"
        okButtonProps={{ style: { display: "none" } }}
        cancelText="Close">
        {/* {console.log(candidateDetails)} */}

        <Title level={4}>Collections Compared: {candidateDetails.collectionsDiffed}</Title>
        <Title level={4}>Source Ref: {candidateDetails.source_ref}</Title>
        <Title level={4}>Changes</Title>
        {candidateDetails?.data?.map((data) => {
          let display = {
            Change: data.Change,
            Path: data.Path,
            Before: data.Before,
            After: data.After,
          };
          return (
            <ReactJson src={display} name={false} displayDataTypes={false} theme="summerfruit:inverted" enableClipboard={false} collapsed={true} />
          );
        })}
      </Modal>
    </>
  );
};
export default TimeLineChangesModal;
