import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link } from "@mui/material";
import dayjs from "dayjs";
import { decodeString } from "../../../util";
import VerifiedIcon from "@mui/icons-material/Verified";
import Tooltip from "@mui/material/Tooltip";

// {
//   "_id": "65a07d7c9d0b43a3caa2d6c2",
//   "profile_id": "pub_5c7bae970cadfda94cd1e462",
//   "account_id": "7of9oso8zkavwmnwnce1y",
//   "channel": "ats",
//   "icp_id": "63ff9d606bfe52b36b50ccc0",
//   "priority": "low",
//   "sandbox_name": "findem_ai_public_profiles",
//   "verification_state": {
//       "status": "unverified"
//   },
//   "verification_state_ts": 1705101568060,
//   "icp_name": "Test Assign 01",
//   "icp_search_link": "https://app-next.findem.ai/searches/63ff9d606bfe52b36b50ccc0?joid=625ee19aadb3d7d7c1ef7202&redirect_joid=625ee19aadb3d7d7c1ef7202",
//   "customer_name": "Findem",
//   "ep_link": "https://app-next.findem.ai/profiles/pub_5c7bae970cadfda94cd1e462?state=/searches/63ff9d606bfe52b36b50ccc0&icpid=63ff9d606bfe52b36b50ccc0&joid=63ff9d606bfe52b36b50ccc0&aId=7of9oso8zkavwmnwnce1y"
// }

const ProfileCard = ({ data, highlighted }) => {
  // no need to memoize since this is a leaf node
  const showRefreshedRecently = data.updated_at
    ? dayjs(data.updated_at).isAfter(dayjs().subtract("1", "week"))
    : false;

  return (
    <Card
      sx={{
        minWidth: 275,
        backgroundColor: `${highlighted ? "rgb(236, 236, 236)" : ""}`,
      }}
    >
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          {decodeString(data.customer_name)}
        </Typography>

        <Typography variant="h6" component="div" gutterBottom>
          <Link href={`${data.icp_search_link}`} target="_blank">
            {decodeString(data.icp_name)}
          </Link>
        </Typography>
        <div
          style={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}
        >
          <Typography variant="h6" component="div" gutterBottom>
            {"Name: "}
            {data.full_name ? decodeString(data.full_name) : "Not Available"}
          </Typography>
          {showRefreshedRecently && (
            <Tooltip title="Refreshed within last 7 days">
              <VerifiedIcon color="success" fontSize="large" />
            </Tooltip>
          )}
        </div>
        <Typography variant="h6" component="div" gutterBottom>
          <Link href={`${data.ep_link}`} target="_blank">
            {" "}
            Findem Enriched Profile Link
          </Link>
        </Typography>
        <Typography variant="h6" component="div" gutterBottom>
          {"Channel Source: "}
          {data.channel}
        </Typography>
        {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Request Date: {dayjs(data.verification_state_ts).format("DD/MM/YYYY")}
        </Typography> */}
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          ICP Created at: {dayjs(data["icp_created_at"]).format("DD/MM/YYYY")}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          ICP Updated at: {dayjs(data["icp_updated_at"]).format("DD/MM/YYYY")}
        </Typography>
        {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Last Refreshed Date:{" "}
          {data.last_ingested_at
            ? dayjs(data.last_ingested_at).format("DD/MM/YYYY")
            : "Not Avaialble"}
        </Typography> */}
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Assigned User: {data.assigned_user || "Not available"}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Profile Type:{" "}
          {data.sandbox_name ? "Sandbox Profile" : "Public Profile"}
        </Typography>
        {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Profile_id: {data.profile_id}
        </Typography> */}
      </CardContent>
    </Card>
  );
};

export default ProfileCard;
