import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { Divider, Form, message, Switch } from "antd";
import { Tabs } from "antd";

import ProfileCreatorForm from "./ProfileCreatorForm";
import axios from "axios";

import { profileData, testProfile } from "./staticVariables";
import { url } from "../../url";
import Navbar from "../../layouts/navbar/Navbar";

const DummyProfileCreator = ({ loggedInUserDetails, logoutUser }) => {
    const [form] = Form.useForm();
    const [showTaskAssignedTo, setShowTaskAssignedTo] = useState(true);
    const [profileCreatorOpen, setProfileCreatorOpen] = useState(false);

    const onShowTaskAssignedToCheckedChange = (checked) => {
        if (checked) {
            setShowTaskAssignedTo(true);
        } else {
            setShowTaskAssignedTo(false);
        }
    };

    const showProfileCreator = () => {
        setProfileCreatorOpen(true);
    };

    const handlePostCreatedProfile = async (createdProfile) => {
        try {
            const response = await axios.post(url.createPublicProfile, { newPublicProfile: createdProfile });
            message.success(response?.data?.message);
        } catch (error) {
            console.log("Failed to create new profile:", error);
            if (error?.response?.data?.message) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Failed to create new profile!");
        }
    };

    return (
        <div>
            <Header loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser} />
            {loggedInUserDetails.role === "admin" ? (
                <h6 className="text-center">
                    <Switch
                        checkedChildren="Show all"
                        unCheckedChildren="Show only assigned"
                        className="mx-5"
                        defaultChecked
                        onChange={onShowTaskAssignedToCheckedChange}
                    />
                </h6>
            ) : null}
            <Navbar />
            <div className="pe-5 ps-5">
                <div
                    style={{
                        // border: "1px solid red",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "2rem",
                    }}
                >
                    <ProfileCreatorForm form={form} profileData={profileData} handlePostCreatedProfile={handlePostCreatedProfile} />
                </div>
            </div>
        </div>
    );
};

export default DummyProfileCreator;
