import { Container } from "@mui/material";
import { message } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import TableContainer from "./components/TableContainer";
import Toolbar from "./components/Toolbar";
import { fetchProfileDetails } from "./services/customer-dashboard-service";

const CustomerDashboard = ({ loggedInUserDetails, logoutUser }) => {
    const [tableData, setTableData] = useState({ isLoading: false, data: [] });
    const [toolbarData, setToolbarData] = useState({ dateRange: { startDate: dayjs().startOf("day"), endDate: dayjs() }, accountId: "" });

    const populateProfileDetails = async () => {
        try {
            if (toolbarData.accountId?.length === 0) {
                return;
            }

            setTableData({ isLoading: true });
            const nDays = toolbarData?.dateRange?.endDate.diff(toolbarData?.dateRange?.startDate, "day");
            const response = await fetchProfileDetails(toolbarData.accountId, nDays);
            setTableData({ isLoading: false, data: response?.data?.results });
        } catch (error) {
            message.error(error?.response?.data?.message || `Failed to load profile details!`);
            setTableData({ isLoading: false, data: [] });
        }
    };

    useEffect(() => {
        populateProfileDetails();
    }, [toolbarData]);

    return (
        <>
            <Toolbar toolbarData={toolbarData} setToolbarData={setToolbarData} />
            <Container
                maxWidth="100%"
                sx={(theme) => ({
                    height: `calc(100vh - ${theme.custom.top.level3}px)`,
                    pt: 3,
                    pb: 3,
                })}
            >
                <TableContainer tableData={tableData} />
            </Container>
        </>
    );
};

export default CustomerDashboard;
