import React, { useEffect, useState } from "react";
import { url } from "../../url";
import axios from "axios";
import { Spin, Switch, Button, Select, Tooltip } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import "./PendingShortlist.styles.css";
import { Pagination } from "antd";
import FilterComponent from "../../features/add-missing-email/components/filter/FilterComponent";
import CustomizedInputBase from "./IcpFilter";
import { Box, Stack } from "@mui/material";
import { checkPermission, DRA_MODULES } from "../../utils/rbacUtils";
import PendingShortlistsModal from "./PendingShortlistsModal";
import CompletedTasksCard from "../../components/cards/CompletedTasksCard";
import NoTaskAssignedCard from "../../components/cards/NoTaskAssignedCard";

const statusOptions = [
    { value: "reviewed", label: "reviewed" },
    { value: "broken link", label: "broken link" },
];

const SUPER_USER = ["dnyanesh@findem.ai", "bhargavi@findem.ai"];

const PendingShorlist = ({ loggedInUserDetails, logoutUser }) => {
    const [linkedinData, setLinkedinData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [finalCSV, setFinalCSV] = useState("");
    const [showTaskAssignedTo, setShowTaskAssignedTo] = useState(true);
    const [isButtonDisabled, setButtonDisabled] = useState([]);
    const [isLinkClicked, setLinkClicked] = useState([]);
    const [isStatusChecked, setStatusChecked] = useState([]);
    const [statusValue, setStatusValue] = useState([]);
    const [readOnlyFlag, setReadOnlyFlag] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPaginateSize, setTotalPaginateSize] = useState(0);
    const [workDoneCount, setWorkDoneCount] = useState(0);
    const [listOfCompanies, setListOfCompanies] = useState([]);
    const [selectedFilterFullOption, setSelectedFilterFullOption] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(["", ""]);
    const [icp_id, set_icp_id] = useState("");

    useEffect(() => {
        getLinkedinData(1);
    }, [showTaskAssignedTo, selectedCustomer]);

    useEffect(() => {
        getListOfCompanies();
    }, []);

    const getListOfCompanies = async () => {
        // let res2 = await axios.get(url.customersAPI);
        // res2 = Object.entries(res2.data.response);
        let customersData = await axios.get(url.customersData);
        customersData = customersData.data.customersData;
        let res2 = [];

        for (let customer of customersData) {
            res2.push([customer.account_id, { users: customer.users, company_name: customer.company_name }]);
        }

        // making a list of companies which will be used in filter options
        setListOfCompanies(
            res2
                .map((company) => [company[1]["company_name"], company[0]])
                .sort((a, b) => {
                    return a[0].localeCompare(b[0]);
                })
        );
    };

    useEffect(() => {
        for (let i = 0; i < isStatusChecked.length; i++) {
            if ((isStatusChecked[i] && SUPER_USER.includes(loggedInUserDetails.email)) || (isStatusChecked[i] && isLinkClicked[i])) {
                let getButtonDisabledTemp = isButtonDisabled.slice();
                getButtonDisabledTemp[i] = false;
                setButtonDisabled(getButtonDisabledTemp);
            }
        }
    }, [isStatusChecked, isLinkClicked]);

    useEffect(() => {
        // fetch location heuristics data
        getLinkedinData(page);
    }, [page]);

    useEffect(() => {
        if (icp_id.length === 0) {
            getLinkedinData(1);
        }
    }, [icp_id]);

    const getLinkedinData = async (page) => {
        setLoading(true);
        let linkedinData;

        try {
            linkedinData = await axios.get(
                url.getLinkedinData +
                    `?email=${loggedInUserDetails.email}&showTaskAssignedTo=${showTaskAssignedTo}&page=${page - 1}&accountId=${
                        selectedCustomer[1]
                    }&icp_id=${icp_id}`
            );
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
        setLinkedinData(linkedinData.data.data);
        setReadOnlyFlag(linkedinData.data.readOnly);
        let arrayLength = linkedinData.data.data.length;
        setTotalPaginateSize(linkedinData.data.totalPaginateSize);
        setButtonDisabled(new Array(arrayLength).fill(true));
        setLinkClicked(new Array(arrayLength).fill(false));
        setStatusChecked(new Array(arrayLength).fill(false));
        setWorkDoneCount(linkedinData.data.workDone);

        let dataToCsv = encodeURIComponent("id,url" + "\r\n");
        let index = 1;

        for (let data of linkedinData.data.data) {
            let linkedinUrls = data.linkedin_ids || "";
            let finalUrlString = "";
            for (let linkedinUrl of linkedinUrls) {
                finalUrlString += "https://www.linkedin.com/in/" + linkedinUrl + "/,";
            }
            dataToCsv += encodeURIComponent(`${index},${finalUrlString}` + "\r\n");
            index++;
        }

        setFinalCSV(dataToCsv);
    };

    const onShowTaskAssignedToCheckedChange = (checked) => {
        if (checked) {
            setShowTaskAssignedTo(true);
        } else {
            setShowTaskAssignedTo(false);
        }
    };

    const fetchPendingShortlistData = (page, pageSize) => {
        setPage(page);
    };

    const handleFilterChange = (event) => {
        if (event === null) {
            setSelectedCustomer(["", ""]);
        } else {
            setSelectedCustomer([event.value, event.accountId]);
        }
        setSelectedFilterFullOption(event);
        setPage(1);
    };

    return (
        <div>
            <Box
                sx={(theme) => ({
                    position: "sticky",
                    top: theme.custom.top.level2,
                    zIndex: theme.zIndex.navbar,
                    height: theme.custom.heights.filterbar,
                    boxSizing: "border-box",
                    pl: 3,
                    pr: 3,
                    borderBlockEnd: theme.palette.border.primary,
                    backgroundColor: theme.palette.background.default,
                })}
            >
                <Box
                    sx={(theme) => ({
                        padding: "",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        pt: 1,
                        pb: 1,
                    })}
                >
                    <Box display={"flex"} alignItems={"center"} flex={"0 1 60vw"}>
                        <Box sx={{ flex: "1 1 25%", pr: 2, borderRight: "1px solid grey" }}>
                            <FilterComponent
                                dataCloned={"something"}
                                listOfCompanies={listOfCompanies}
                                selectedFilterFullOption={selectedFilterFullOption}
                                handleFilterChange={handleFilterChange}
                            />
                        </Box>
                        <Box sx={{ flex: "1 1 20%", pl: 2, pr: 2, borderRight: "1px solid grey" }}>
                            <CustomizedInputBase set_icp_id={set_icp_id} icp_id={icp_id} getLinkedinData={getLinkedinData} />
                        </Box>
                        <Box sx={{ flex: "0 1 ", pl: 2, pr: 2, borderRight: "1px solid grey" }}>
                            <a
                                href={`data:text/csv;charset=utf-8,${finalCSV}`}
                                download="linkedin.csv"
                                className="text-center my-1"
                                style={{ color: "white" }}
                            >
                                <Button type="primary" icon={<DownloadOutlined />} size="large">
                                    Download Linkedin urls
                                </Button>
                            </a>
                        </Box>
                        {checkPermission(loggedInUserDetails, DRA_MODULES.assignWork).update ? (
                            <h6 className="text-center">
                                <Switch
                                    checkedChildren="Show all"
                                    unCheckedChildren="Show only assigned"
                                    className="mx-2"
                                    defaultChecked
                                    onChange={onShowTaskAssignedToCheckedChange}
                                />
                            </h6>
                        ) : null}
                    </Box>
                    {!readOnlyFlag ? <CompletedTasksCard tasksCompletedForToday={workDoneCount} /> : <NoTaskAssignedCard />}
                </Box>
            </Box>

            <Box
                sx={(theme) => ({
                    top: theme.custom.top.level3,
                    height: `calc(100vh - ${theme.custom.top.level3}px)`,
                })}
            >
                {!loading ? (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%", pl:3, pt:2}}>
                        {linkedinData.length === 0 ? (
                            <p className="text-center">No data to show</p>
                        ) : linkedinData === "all data has been worked on" ? (
                            <p className="text-center">All data has been worked on</p>
                        ) : (
                            <>
                                <table>
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Id</th>
                                            <th scope="col">Link</th>
                                            <th scope="col">Company</th>
                                            {readOnlyFlag ? null : (
                                                <>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </>
                                            )}
                                            <th scope="col">Assigned To</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {linkedinData.map((element, index) => {
                                            let linkedinUrls = element.linkedin_ids;
                                            let customerName = element?.companyDetails[1] || null;

                                            if (customerName == undefined || customerName?.trim().length === 0) {
                                                customerName = null;
                                            }

                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <PendingShortlistsModal
                                                            indexx={index}
                                                            sl_prid={element.sl_prid}
                                                            _id={element._id}
                                                        />
                                                        <form
                                                            id={`form${index}`}
                                                            onSubmit={async (event) => {
                                                                event.preventDefault();
                                                                let sl_prid = event.target[0].dataset.sl_prid;
                                                                let _id = event.target[0].dataset._id;

                                                                try {
                                                                    let markAsHide = await axios.post(
                                                                        url.markCandidateLinkedinLink +
                                                                            `?sl_prid=${sl_prid}&_id=${_id}&status=${statusValue[index]}`
                                                                    );

                                                                    getLinkedinData(page);
                                                                    let auditLogData = {
                                                                        eventName: "pending_shortlist",
                                                                        eventType: "mark",
                                                                        userId: loggedInUserDetails._id,
                                                                        eventMetaData: {
                                                                            pendingShortlist: linkedinUrls
                                                                                .map((link) => {
                                                                                    return "https://www.linkedin.com/in/" + link + "/";
                                                                                })
                                                                                .join(" | "),
                                                                            status: statusValue[index],
                                                                            candidateId: sl_prid,
                                                                            customerName: customerName,
                                                                        },
                                                                        oldRecord: {},
                                                                        newRecord: {},
                                                                    };
                                                                    // send a post request to add into audit logs
                                                                    let audit = await axios.post(url.auditPost, auditLogData);
                                                                } catch (error) {
                                                                    console.log(error);
                                                                }
                                                            }}
                                                        >
                                                            <input
                                                                type="hidden"
                                                                name="id"
                                                                value={element.sl_prid}
                                                                data-sl_prid={element.sl_prid}
                                                                data-_id={element._id}
                                                            />
                                                        </form>
                                                    </td>
                                                    <th scope="row">{(page - 1) * 20 + index + 1}</th>
                                                    <td key={index}>
                                                        {linkedinUrls.map((url, indexx) => {
                                                            return (
                                                                <div
                                                                    onClick={() => {
                                                                        let getLinkTemp = isLinkClicked.slice();
                                                                        getLinkTemp[index] = true;
                                                                        setLinkClicked(getLinkTemp);
                                                                    }}
                                                                    key={indexx}
                                                                >
                                                                    <a
                                                                        href={"https://www.linkedin.com/in/" + url + "/"}
                                                                        key={indexx}
                                                                        target="_blank"
                                                                    >
                                                                        {"https://www.linkedin.com/in/" + url + "/"}
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </a>
                                                                </div>
                                                            );
                                                        })}
                                                    </td>
                                                    <td key={index}>
                                                        <p>{customerName || "Not Available"}</p>
                                                    </td>
                                                    {readOnlyFlag ? null : (
                                                        <>
                                                            <td scope="col">
                                                                <Select
                                                                    size="small"
                                                                    placeholder="Please select status"
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                    options={statusOptions}
                                                                    onChange={(value) => {
                                                                        let statusValueTemp = statusValue.slice();
                                                                        statusValueTemp[index] = value;
                                                                        setStatusValue(statusValueTemp);

                                                                        if (value === "reviewed" || value === "broken link") {
                                                                            let getStatusTemp = isStatusChecked.slice();
                                                                            getStatusTemp[index] = true;
                                                                            setStatusChecked(getStatusTemp);
                                                                        }
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                {isButtonDisabled[index] ? (
                                                                    <Tooltip
                                                                        overlayStyle={{ maxWidth: "120px", fontSize: "10px" }}
                                                                        placement="top"
                                                                        title="Click on linkedin link and select status"
                                                                    >
                                                                        <input
                                                                            type="button"
                                                                            className="form-save-btn-disabled"
                                                                            disabled
                                                                            // data-bs-toggle="modal"
                                                                            // data-bs-target="#formModal"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-title="Default tooltip"
                                                                            value="Save"
                                                                        />
                                                                    </Tooltip>
                                                                ) : (
                                                                    <input
                                                                        type="button"
                                                                        className="form-save-btn-enabled"
                                                                        // form={`form${index}`}
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target={`#formModal${index}`}
                                                                        value="Save"
                                                                    />
                                                                )}
                                                            </td>
                                                        </>
                                                    )}
                                                    <td scope="col">{element.taskAssignedTo}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <Pagination
                                    simple
                                    onChange={fetchPendingShortlistData}
                                    current={page}
                                    total={totalPaginateSize}
                                    defaultPageSize={20}
                                    className="my-5"
                                />
                            </>
                        )}
                    </Box>
                ) : (
                    <Spin className="text-center" tip="Loading..." />
                )}
            </Box>
        </div>
    );
};

export default PendingShorlist;
