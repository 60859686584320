import { Cancel, CheckCircle } from "@mui/icons-material"; // Import MUI icons
import { Box, Button, Dialog, DialogActions, DialogContent, Link, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { message } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import NoResultsTableOverlay from "../../../components/common-feedback/NoResultTableOverlay";
import { fetchPublicProfile } from "../../../services/public-profile-service";
import DiffDataEditor from "../../../components/Common/editor/DiffDataEditor";
import { normalizeDatesRecursively } from "../customer-dashboard-utils";

const TableContainer = ({ tableData }) => {
    const [openDiffViewer, setOpenDiffViewer] = useState(false);
    const [diffViewerData, setDiffViewerData] = useState();

    const columns = [
        {
            field: "ref",
            minWidth: 300,
            sortable: false,
            flex: 1,
            renderHeader: (params) => <Typography fontWeight={"bold"}>{"Ref"}</Typography>,
            renderCell: (params) => (
                <Box display="flex" alignItems="center" width="100%" height="100%">
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            ),
        },
        {
            field: "li",
            renderHeader: (params) => <Typography fontWeight={"bold"}>{"LinkedIn Url"}</Typography>,
            minWidth: 350,
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Link href={params.value} target="_blank" rel="noopener noreferrer">
                    {params.value}
                </Link>
            ),
        },
        {
            field: "click_date",
            renderHeader: (params) => <Typography fontWeight={"bold"}> {"Click Date"}</Typography>,
            minWidth: 250,
            flex: 2,
            filterable: false,
            valueFormatter: (value) => dayjs(value).format("MMMM D, YYYY h:mm A"),
        },
        {
            field: "updated_at",
            renderHeader: (params) => <Typography fontWeight={"bold"}> {"Last Updated At"}</Typography>,
            minWidth: 250,
            flex: 2,
            filterable: false,
            valueFormatter: (value) => dayjs(value).format("MMMM D, YYYY h:mm A"),
        },

        {
            field: "workexp_changed",
            renderHeader: (params) => <Typography fontWeight={"bold"}>{"Current Workexp Change"}</Typography>,
            minWidth: 150,
            flex: 3,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <Tooltip title={params.value ? "Check Difference" : ""}>
                    <Box sx={{ padding: 1, display: "flex", alignItems: "center", cursor: params.value ? "pointer" : "unset" }}>
                        {params.value ? (
                            <CheckCircle sx={{ color: "error.dark", marginRight: 1 }} />
                        ) : (
                            <Cancel sx={{ color: "success.light", marginRight: 1 }} />
                        )}
                        {params.value ? "Yes" : "No"}
                    </Box>
                </Tooltip>
            ),
        },
        {
            field: "location_changed",
            renderHeader: (params) => <Typography fontWeight={"bold"}>{"Location Change"}</Typography>,
            minWidth: 150,
            flex: 3,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <Tooltip title={params.value ? "Check Difference" : ""}>
                    <Box sx={{ padding: 1, display: "flex", alignItems: "center", cursor: params.value ? "pointer" : "unset" }}>
                        {params.value ? (
                            <CheckCircle sx={{ color: "error.dark", marginRight: 1 }} />
                        ) : (
                            <Cancel sx={{ color: "success.light", marginRight: 1 }} />
                        )}
                        {params.value ? "Yes" : "No"}
                    </Box>
                </Tooltip>
            ),
        },
    ];

    const handleOpenDiffViewer = async (field, value, rowData) => {
        try {
            setDiffViewerData();
            setOpenDiffViewer(true);
            if (field === "location_changed") {
                setDiffViewerData({ field: "Location", originalData: rowData?.location, modifiedData: rowData?.public_profile_location });
            } else if (field === "workexp_changed") {
                setDiffViewerData({ field: "Workexp", originalData: rowData?.workexp, modifiedData: rowData?.public_profile_workexp });
            }
        } catch (error) {
            setOpenDiffViewer(false);
            message.error(error?.response?.data?.message || "Failed to open profile differences!");
        }
    };

    const handleCloseDiffViewer = () => {
        setOpenDiffViewer(false);
    };

    const handleCellClick = async (params) => {
        if ((params.field === "location_changed" || params.field === "workexp_changed") && params.value) {
            await handleOpenDiffViewer(params.field, params.value, params.row);
        }
    };

    return (
        <>
            <DataGrid
                columns={columns}
                rows={tableData?.data || []}
                getRowId={(row) => row?.bkup_ref + row?.click_date}
                pageSizeOptions={[10, 25, 100]}
                autoPageSize={false}
                initialState={{
                    pagination: { paginationModel: { page: 0, pageSize: 10 } },
                }}
                onCellClick={handleCellClick}
                loading={tableData?.isLoading || false}
                slotProps={{
                    loadingOverlay: {
                        variant: "skeleton",
                        noRowsVariant: "skeleton",
                    },
                    noRowsOverlay: {
                        message: "Profiles not found!",
                    },
                }}
                slots={{
                    noRowsOverlay: NoResultsTableOverlay,
                }}
            />
            <Dialog open={openDiffViewer} onClose={handleCloseDiffViewer} maxWidth="xl" fullWidth>
                <DialogContent>
                    <Box height={"90vh"}>
                        <Box display="flex" justifyContent="space-between" mb={2}>
                            <Typography variant="h6" component="div" textAlign="center" width="50%">
                                Last Viewed {diffViewerData?.field}
                            </Typography>
                            <Typography variant="h6" component="div" textAlign="center" width="50%">
                                Current {diffViewerData?.field}
                            </Typography>
                        </Box>

                        <DiffDataEditor originalData={diffViewerData?.originalData} modifiedData={diffViewerData?.modifiedData} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDiffViewer} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TableContainer;
