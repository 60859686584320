import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { FormControl, FormHelperText, InputLabel, MenuItem } from "@mui/material";

// rt copilot options for could not verify
// keep free text only for verified option
const COULD_NOT_VERIFY_REASONS_OPTIONS = [
    {
        value: "No valid Linkedin found for the candidate",
        label: "No valid Linkedin found for the candidate",
    },
    {
        value: "No valid personal email found for the candidate",
        label: "No valid personal email found for the candidate",
    },
    {
        value: "Could not verify existing personal email",
        label: "Could not verify existing personal email",
    },
];

export default function GenericModal({ open, handleClose, handleConfirm, title, message, status, messageRef }) {
    const [saveNotes, setSaveNotes] = React.useState("");
    const [couldNotVerifyFreeText, setCouldNotVerifyFreeText] = React.useState("");

    const handleSaveNotes = (e) => {
        setSaveNotes(e.target.value);
        // messageRef.current.value = e.target.value;
        console.log("experiment: ", messageRef.current.value);
    };

    const handleOtherReasonChange = (e) => {
        setCouldNotVerifyFreeText(e.target.value);
    };

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose} aria-labelledby="modal" aria-describedby="modal-used" maxWidth="xl">
                <div
                    style={{
                        margin: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "800px",
                    }}
                >
                    <DialogTitle id={`${title}-used`}>{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id={`${title}-used`}>{message}</DialogContentText>
                    </DialogContent>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        {status === "verified" ? (
                            <TextField
                                inputRef={messageRef}
                                id="notes-reason-copilot-search"
                                label={status === "verified" ? "Optional Notes" : "Reason"}
                                multiline
                                rows={4}
                                sx={{ width: 500 }}
                                required={status !== "verified"}
                                value={saveNotes}
                                onChange={handleSaveNotes}
                            />
                        ) : (
                            <>
                                <FormControl required sx={{ width: 500 }}>
                                    <InputLabel id="notes-reason-copilot-search">Select Reason</InputLabel>
                                    <Select
                                        labelId="required-label"
                                        id="notes-reason-copilot-search-simple-select-required"
                                        value={saveNotes}
                                        inputRef={messageRef}
                                        label="Select Reason"
                                        onChange={handleSaveNotes}
                                    >
                                        {COULD_NOT_VERIFY_REASONS_OPTIONS.map((option) => {
                                            return (
                                                <MenuItem value={option.value} key={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            );
                                        })}
                                        <MenuItem value="other">
                                            <em>Other</em>
                                        </MenuItem>
                                    </Select>
                                    <FormHelperText>Reason for could not verify</FormHelperText>
                                </FormControl>
                                {saveNotes === "other" && (
                                    <TextField
                                        id="notes-reason-copilot-search-other"
                                        label={"Other reason"}
                                        multiline
                                        rows={4}
                                        sx={{ width: 500, mt: 2 }}
                                        required={true}
                                        value={couldNotVerifyFreeText}
                                        onChange={handleOtherReasonChange}
                                    />
                                )}
                            </>
                        )}
                    </div>
                    <div
                        style={{
                            marginTop: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginLeft: "20px",
                            marginRight: "20px",
                        }}
                    >
                        <Button onClick={handleClose}>Close</Button>
                        <Button
                            onClick={() => {
                                // if could not verify and "other" option is chosen then take value of
                                // text field and put it in ref value
                                if (messageRef.current.value === "other") {
                                    messageRef.current.value = couldNotVerifyFreeText;
                                }
                                handleConfirm();
                            }}
                            // status !== "verified" && saveNotes && saveNotes.length === 0
                            disabled={
                                status === "verified"
                                    ? false
                                    : saveNotes == null ||
                                      saveNotes.length === 0 ||
                                      (saveNotes === "other" && couldNotVerifyFreeText.trim().length === 0)
                                    ? true
                                    : false
                            }
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    );
}
