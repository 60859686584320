import { Container } from "@mui/material";
import { message } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import LayoutContainer from "./components/LayoutContainer";
import Toolbar from "./components/Toolbar";
import { fetchProfileDetails } from "./services/customer-dashboard-service";

const CustomerDashboard = ({ loggedInUserDetails, logoutUser }) => {
    const [profilesData, setProfilesData] = useState({ isLoading: false, data: [] });
    const [toolbarData, setToolbarData] = useState({ dateRange: { startDate: dayjs().startOf("day"), endDate: dayjs() }, accountId: "" });
    const [selectedProfile, setSelectedProfile] = useState();
    const [filterField, setFilterField] = useState({});
    const [filterObject, setFilterObject] = useState("");
    const [sortingField, setSortingField] = useState("");
    const [sortObject, setSortObject] = useState({});

    //handle filters and sort value change
    const handleFilterValueChange = (event) => {
        if (!filterField) return;
        const value = event.target.value;

        setFilterObject((prev) => {
            const updatedFilters = { ...prev };

            if (value === "") {
                delete updatedFilters[filterField];
            } else {
                updatedFilters[filterField] = value;
            }

            return updatedFilters;
        });
    };
    const handleSortingValueChange = (event) => {
        if (!sortingField) return;
        const value = event.target.value;

        setSortObject((prev) => {
            const updatedSorting = { ...prev };

            if (value === "") {
                delete updatedSorting[sortingField];
            } else {
                updatedSorting[sortingField] = value;
            }
            console.log(updatedSorting);
            return updatedSorting;
        });
    };

    const handleFilterFieldChange = (event) => {
        setFilterField(event.target.value);
    };
    const handleSortingFieldChange = (event) => {
        console.log(event.target.value);
        setSortingField(event.target.value);
    };
    const handleFilterDelete = (fieldToDelete) => () => {
        setFilterObject((prev) => {
            const updatedFilters = { ...prev };
            delete updatedFilters[fieldToDelete];
            return updatedFilters;
        });
    };
    const handleSortingFieldDelete = (fieldToDelete) => () => {
        setSortObject((prev) => {
            const updatedSorting = { ...prev };
            delete updatedSorting[fieldToDelete];
            return updatedSorting;
        });
    };

    const filteredAndSortedProfiles = useMemo(() => {
        if (!profilesData?.data) return [];

        // Apply filtering
        let filteredProfiles = profilesData.data;
        if (filterObject && Object.keys(filterObject).length > 0) {
            filteredProfiles = filteredProfiles.filter((item) =>
                Object.entries(filterObject).every(([field, value]) => item[field]?.toString().toLowerCase().includes(value.toLowerCase()))
            );
        }

        // Apply sorting
        if (sortObject && Object.keys(sortObject).length > 0) {
            filteredProfiles = [...filteredProfiles].sort((a, b) => {
                for (const [sortField, sortOrder] of Object.entries(sortObject)) {
                    if (a[sortField] !== b[sortField]) {
                        return sortOrder === "asc" ? (a[sortField] > b[sortField] ? 1 : -1) : a[sortField] < b[sortField] ? 1 : -1;
                    }
                }
                return 0; // If all compared fields are equal, maintain order
            });
        }
        setSelectedProfile();
        return filteredProfiles;
    }, [profilesData?.data, filterObject, sortObject]);

    const populateProfileDetails = async () => {
        try {
            if (!toolbarData?.accountId || toolbarData.accountId?.length === 0) {
                return;
            }

            setProfilesData({ isLoading: true });
            const nDays = toolbarData?.dateRange?.endDate.diff(toolbarData?.dateRange?.startDate, "day");
            const response = await fetchProfileDetails(toolbarData.accountId, nDays);
            setProfilesData({ isLoading: false, data: response?.data?.results });
        } catch (error) {
            message.error(error?.response?.data?.message || `Failed to load profile details!`);
            setProfilesData({ isLoading: false, data: [] });
        }
    };

    useEffect(() => {
        populateProfileDetails();
    }, [toolbarData]);

    return (
        <>
            <Toolbar
                toolbarData={toolbarData}
                setToolbarData={setToolbarData}
                filterObject={filterObject}
                sortObject={sortObject}
                handleSortingFieldDelete={handleSortingFieldDelete}
                handleFilterDelete={handleFilterDelete}
                handleSortingFieldChange={handleSortingFieldChange}
                handleSortingValueChange={handleSortingValueChange}
                handleFilterValueChange={handleFilterValueChange}
                handleFilterFieldChange={handleFilterFieldChange}
                filterField={filterField}
                sortingField={sortingField}
            />
            <Container
                maxWidth="100%"
                sx={(theme) => ({
                    height: `calc(100vh - ${theme.custom.top.level3}px)`,
                })}
            >
                <LayoutContainer
                    toolbarData={toolbarData}
                    profilesData={profilesData}
                    filteredAndSortedProfiles={filteredAndSortedProfiles}
                    selectedProfile={selectedProfile}
                    setSelectedProfile={setSelectedProfile}
                />
            </Container>
        </>
    );
};

export default CustomerDashboard;
