import { Spin } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import IdleComponent from "../../components/IdleComponent";
import { url } from "../../url";
import "./addMissingEmails.css";
import LeftPaneContainer from "./components/left-pane/LeftPaneContainer";
import RightPaneContainer from "./components/right-pane/RightPaneContainer";
import RightPaneWhenNotClicked from "./components/right-pane/RightPaneWhenNotClicked";
// import AuditLogsContext from "../../AuditLogsContext";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Grid, IconButton } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import FilterToolbar from "./components/filter/FilterToolbar";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddMissingEmails = ({ loggedInUserDetails, logoutUser }) => {
    const [data, getData] = useState([]);
    const [dataInAction, setDataInAction] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(0);
    const [selectedLeftPane, selectLeftPane] = useState("white");
    const [isLeftPaneClicked, handleLeftPaneClick] = useState(false);
    const [clickedUserDetails, getClickedUserDetails] = useState("");
    const [nextObjectId, setNextObjectId] = useState("");
    const buttonRef = useRef(null);
    const [visible, setVisible] = useState(false);
    const [filteredCustomers, getFilteredCustomers] = useState([]);
    const [isFullDataLoaded, getFullData] = useState(false);
    const [loadingMoreDataSpinner, setLoadingMoreDataSpinner] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(["", ""]);
    const [selectedFilterFullOption, setSelectedFilterFullOption] = useState(null);
    const [listOfCompanies, setListOfCompanies] = useState([]);
    const [mainSpinner, setMainSpinner] = useState(false);
    const isFirstRender = useRef(true);
    // const [auditLogsData, setAuditLogsData] = useContext(AuditLogsContext);
    const [auditLogsData, setAuditLogsData] = useState([]);
    // const [auditLogLoading, setAuditLogLoading] = useState(false);
    const [showTaskAssignedTo, setShowTaskAssignedTo] = useState(true);
    const [readOnlyFlag, setReadOnlyFlag] = useState(false);
    // const [selectedShortlistSource, setSelectedShortlistSource] = useState(["manual", "manual"]);
    // const [selectedShortlistSourceFilter, setSelectedShortlistSourceFilter] = useState({ value: "manual", label: "Manual shortlist", shortlist_source: "manual" });
    const [selectedShortlistSource, setSelectedShortlistSource] = useState(["", ""]);
    const [selectedShortlistSourceFilter, setSelectedShortlistSourceFilter] = useState({});
    const [getUsersAfterSave, setUsersAfterSave] = useState(false);
    const [workDoneCount, setWorkDoneCount] = useState(0);
    const [searchByICPInUse, setSearchByICPInUse] = useState(false);
    const [icpId, setICPid] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [showErrorMsg, setShowErrorMsg] = useState(false);

    useEffect(() => {
        setMainSpinner(true);
        getListOfCompanies();
    }, []);

    const getAuditLogs = async () => {
        window.open(`${url.backend_url}/getAuditLogs`);
    };

    const getListOfCompanies = async () => {
        // let res2 = await axios.get(url.customersAPI);
        // res2 = Object.entries(res2.data.response);
        let customersData = await axios.get(url.customersData);
        customersData = customersData.data.customersData;
        let res2 = [];

        for (let customer of customersData) {
            res2.push([customer.account_id, { users: customer.users, company_name: customer.company_name }]);
        }

        setCustomers(res2);
        // making a list of companies which will be used in filter options
        setListOfCompanies(
            res2
                .map((company) => [company[1]["company_name"], company[0]])
                .sort((a, b) => {
                    return a[0].localeCompare(b[0]);
                })
        );
        icpId.length ? fetchUsersByICP("", res2) : fetchUsers("", res2);
    };

    useEffect(() => {
        getFullData(false);
        if (isFirstRender.current === false) {
            // reset page to 0
            setPage(0);
            // reset all data
            getData([]);
            if (selectedCustomer[0] === "") {
                setMainSpinner(true);
                icpId.length ? fetchUsersByICP("") : fetchUsers("");
                setUsersAfterSave(false);
            } else if (selectedCustomer[0] === "Not specified") {
                // do nothing for now
            } else {
                // fetchCategoryData();
                setMainSpinner(true);
                icpId.length ? fetchUsersByICP(selectedCustomer[1]) : fetchUsers(selectedCustomer[1]);
                setUsersAfterSave(false);
            }
        }
    }, [selectedCustomer, showTaskAssignedTo, selectedShortlistSource, getUsersAfterSave, icpId]);

    const fetchUsersByICP = async (accountId, res2) => {
        let res = [];
        let responseFetch;
        try {
            if (accountId === "") {
                // responseFetch = await axios.get(url.candidatesAPI + "/" + "0");
                responseFetch = await axios.get(
                    url.candidatesData +
                        `?page=0&email=${loggedInUserDetails.email}&showTaskAssignedTo=${showTaskAssignedTo}&icp_id=${icpId}`
                );
                setSearchByICPInUse(true);
            } else {
                // responseFetch = await axios.get(url.candidatesAPI + "/0/" + accountId);
                responseFetch = await axios.get(
                    url.candidatesData +
                        `?page=0&account_id=${accountId}&email=${loggedInUserDetails.email}&showTaskAssignedTo=${showTaskAssignedTo}&shortlisted_source=${selectedShortlistSource[1]}`
                );
            }
            // console.log(responseFetch, "responese Fetch");
            res = res.concat(responseFetch.data.data)[0].candidates;
            if (!responseFetch.data.data.candidates) {
                res = [];
            }
            setNextPage(responseFetch.data.data.next_page);
        } catch (e) {
            res = [];
            console.error(e.message);
            setMainSpinner(false);
        }

        if (isFirstRender.current) {
            setReadOnlyFlag(responseFetch.data.data.readOnly);
        }
        setWorkDoneCount(responseFetch.data.data.workDone);
        setMainSpinner(false);

        // let res2 = await axios.get(url.customersAPI);
        // res2 = Object.entries(res2.data.response);

        // setCustomers(res2);
        // let res2 = customers;
        // let res2 = customers;
        // making a list of companies which will be used in filter options
        // setListOfCompanies(res2.map(company => [company[1]["company_name"], company[0]]));

        //check each user id in every array of customers data, making a function and calling it length of res2 times
        //each call will check each profile with customers data to get customers data

        const mapProfilesToCustomers = (currentObject) => {
            let userId = currentObject.user_id;
            let filteredObjects;
            if (res2) {
                filteredObjects = res2.filter((customerObject) => {
                    return customerObject[1].users.includes(userId);
                });
                isFirstRender.current = false;
            } else {
                filteredObjects = customers.filter((customerObject) => {
                    return customerObject[1].users.includes(userId);
                });
            }
            return filteredObjects;
        };

        //calling mapProfiles function with each profile object
        // .filter((object) => object.emails.length === 0)
        // .sort(
        //   (preObj, currObj) =>
        //     moment(preObj.shortlisted_on) - moment(currObj.shortlisted_on)
        // )
        let requiredData = res.map((currentObject) => {
            let responseFromMapProfiles = mapProfilesToCustomers(currentObject);
            //names has encoded uri strings, decoding them
            try {
                currentObject.name = decodeURIComponent(currentObject.name);
            } catch (error) {
                console.error("ERROR: ", currentObject.name, error);
            }

            currentObject.customer = responseFromMapProfiles.length ? responseFromMapProfiles[0][1].company_name : null;

            try {
                currentObject.title = decodeURIComponent(currentObject.title);
            } catch (error) {
                console.error("ERROR: ", currentObject.title, error);
            }
            currentObject.shortlisted_on_unix = currentObject.shortlisted_on;
            currentObject.shortlisted_on = moment.utc(currentObject.shortlisted_on).format("DD/MM/YYYY");
            return currentObject;
        });

        getData(requiredData);
        setDataInAction(requiredData);

        if (filteredCustomers.length !== 0) {
            requiredData = requiredData.filter((object) => filteredCustomers.includes(object.customer));
            getData(requiredData);
        }
    };

    const fetchUsers = async (accountId, res2) => {
        let res = [];
        let responseFetch;
        try {
            if (accountId === "") {
                // responseFetch = await axios.get(url.candidatesAPI + "/" + "0");
                responseFetch = await axios.get(
                    url.candidatesData +
                        `?page=0&email=${loggedInUserDetails.email}&showTaskAssignedTo=${showTaskAssignedTo}&shortlisted_source=${selectedShortlistSource[1]}`
                );
                setSearchByICPInUse(false);
            } else {
                // responseFetch = await axios.get(url.candidatesAPI + "/0/" + accountId);
                responseFetch = await axios.get(
                    url.candidatesData +
                        `?page=0&account_id=${accountId}&email=${loggedInUserDetails.email}&showTaskAssignedTo=${showTaskAssignedTo}&shortlisted_source=${selectedShortlistSource[1]}`
                );
            }
            // console.log(responseFetch, "responese Fetch");
            res = res.concat(responseFetch.data.data)[0].candidates;
            if (!responseFetch.data.data.candidates) {
                res = [];
            }
            setNextPage(responseFetch.data.data.next_page);
        } catch (e) {
            res = [];
            console.error(e.message);
            setMainSpinner(false);
        }

        if (isFirstRender.current) {
            setReadOnlyFlag(responseFetch.data.data.readOnly);
        }
        setWorkDoneCount(responseFetch.data.data.workDone);
        setMainSpinner(false);

        // let res2 = await axios.get(url.customersAPI);
        // res2 = Object.entries(res2.data.response);

        // setCustomers(res2);
        // let res2 = customers;
        // let res2 = customers;
        // making a list of companies which will be used in filter options
        // setListOfCompanies(res2.map(company => [company[1]["company_name"], company[0]]));

        //check each user id in every array of customers data, making a function and calling it length of res2 times
        //each call will check each profile with customers data to get customers data

        const mapProfilesToCustomers = (currentObject) => {
            let userId = currentObject.user_id;
            let filteredObjects;
            if (res2) {
                filteredObjects = res2.filter((customerObject) => {
                    return customerObject[1].users.includes(userId);
                });
                isFirstRender.current = false;
            } else {
                filteredObjects = customers.filter((customerObject) => {
                    return customerObject[1].users.includes(userId);
                });
            }
            return filteredObjects;
        };

        //calling mapProfiles function with each profile object
        // .filter((object) => object.emails.length === 0)
        // .sort(
        //   (preObj, currObj) =>
        //     moment(preObj.shortlisted_on) - moment(currObj.shortlisted_on)
        // )
        let requiredData = res.map((currentObject) => {
            let responseFromMapProfiles = mapProfilesToCustomers(currentObject);
            //names has encoded uri strings, decoding them
            try {
                currentObject.name = decodeURIComponent(currentObject.name);
            } catch (error) {
                console.error("ERROR: ", currentObject.name, error);
            }

            currentObject.customer = responseFromMapProfiles.length ? responseFromMapProfiles[0][1].company_name : null;

            try {
                currentObject.title = decodeURIComponent(currentObject.title);
            } catch (error) {
                console.error("ERROR: ", currentObject.title, error);
            }
            currentObject.shortlisted_on_unix = currentObject.shortlisted_on;
            currentObject.shortlisted_on = moment.utc(currentObject.shortlisted_on).format("DD/MM/YYYY");
            return currentObject;
        });

        getData(requiredData);
        setDataInAction(requiredData);

        if (filteredCustomers.length !== 0) {
            requiredData = requiredData.filter((object) => filteredCustomers.includes(object.customer));
            getData(requiredData);
        }
    };

    const displayDetailsOnRightPane = (event) => {
        let requiredObject = data[event.currentTarget.id];
        setNextObjectId(Number(event.currentTarget.id) + 1);
        selectLeftPane(event.currentTarget.id);
        handleLeftPaneClick(true);
        getClickedUserDetails(requiredObject);
    };

    const filteredCustomersData = (filteredCustomers) => {
        getFilteredCustomers(filteredCustomers);
        getData([]);
    };

    const toggleVisible = (event) => {
        // console.log('scrollTop: ', event.currentTarget.scrollTop);
        // console.log('offsetHeight: ', event.currentTarget.offsetHeight);
        if (event.currentTarget.scrollTop > 300) {
            setVisible(true);
        } else if (event.currentTarget.scrollTop <= 300) {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        buttonRef.current.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    useEffect(() => {
        if (page > 0) {
            fetchMoreData(selectedCustomer[1]);
        }
    }, [page]);

    const incrementPage = () => {
        setPage(nextPage);
    };

    const fetchMoreData = async (accountId) => {
        //making whole data to empty array so that spinner will run
        // getData([]);
        setLoadingMoreDataSpinner(true);
        // setPage((preValue) => preValue + 1);
        let linkedinUrlArray = JSON.parse(localStorage.getItem("lnkdInUrls"));
        let res;

        try {
            if (accountId) {
                // res = await axios.get(url.candidatesAPI + "/" + page + "/" + accountId);
                res = await axios.get(
                    url.candidatesData +
                        `?page=${page}&account_id=${accountId}&email=${loggedInUserDetails.email}&showTaskAssignedTo=${showTaskAssignedTo}&shortlisted_source=${selectedShortlistSource[1]}`
                );
            } else if (icpId) {
                res = await axios.get(
                    url.candidatesData +
                        `?page=${page}&email=${loggedInUserDetails.email}&showTaskAssignedTo=${showTaskAssignedTo}&icp_id=${icpId}`
                );
            } else {
                // res = await axios.get(url.candidatesAPI + "/" + page);
                res = await axios.get(
                    url.candidatesData +
                        `?page=${page}&email=${loggedInUserDetails.email}&showTaskAssignedTo=${showTaskAssignedTo}&shortlisted_source=${selectedShortlistSource[1]}`
                );
            }

            setLoadingMoreDataSpinner(false);
            // spinner should be reset
            if (!res.data.data.candidates || res.data.data.candidates.length === 0) {
                getFullData(true);
            } else {
                getFullData(false);
            }
        } catch (error) {
            // console.log(error.message);
            getData(dataInAction);
            getFullData(true);
            setLoadingMoreDataSpinner(false);
            return null;
        }

        setNextPage(res.data.data.next_page);
        res = res.data.data.candidates;
        //check each user id in every array of customers data, making a function and calling it length of res times
        //each call will check each profile with customers data to get customers data
        const mapProfilesToCustomers = (currentObject) => {
            let userId = currentObject.user_id;
            let filteredObjects = customers.filter((customerObject) => {
                return customerObject[1].users.includes(userId);
            });
            return filteredObjects;
        };

        // .filter((object) => object.emails.length === 0)
        //calling mapProfiles function with each profile object
        let requiredData = res.map((currentObject) => {
            let responseFromMapProfiles = mapProfilesToCustomers(currentObject);
            //names has encoded uri strings, decoding them
            try {
                currentObject.name = decodeURIComponent(currentObject.name);
            } catch (error) {
                console.error("ERROR: ", currentObject.name, error);
            }

            currentObject.customer = responseFromMapProfiles.length ? responseFromMapProfiles[0][1].company_name : null;

            try {
                currentObject.title = decodeURIComponent(currentObject.title);
            } catch (error) {
                console.error("ERROR: ", currentObject.title, error);
            }
            currentObject.shortlisted_on_unix = currentObject.shortlisted_on;
            currentObject.shortlisted_on = moment.utc(currentObject.shortlisted_on).format("DD/MM/YYYY");
            return currentObject;
        });

        if (linkedinUrlArray !== null) {
            requiredData = requiredData.filter((object) => {
                if (linkedinUrlArray.includes(object.linkedin)) {
                    return false;
                } else {
                    return true;
                }
            });
        }

        // requiredData = [...requiredData, ...dataInAction].sort(
        //   (preObj, currObj) =>
        //     moment(preObj.shortlisted_on, "DD/MM/YYYY") -
        //     moment(currObj.shortlisted_on, "DD/MM/YYYY")
        // );
        // console.log("Required data: ", requiredData);
        // console.log("Data in action: ", dataInAction);

        requiredData = dataInAction.concat(requiredData);
        getData(requiredData);
        setDataInAction(requiredData);

        if (filteredCustomers.length !== 0) {
            requiredData = requiredData.filter((object) => filteredCustomers.includes(object.customer));
            getData(requiredData);
        }
    };

    const removeCandidateAfterEmailUpdate = (userLinkedIn) => {
        getClickedUserDetails(data[Number(nextObjectId)]);
        let dataAfterEmailUpdate = dataInAction.filter((object) => object.linkedin !== userLinkedIn);
        getData(dataAfterEmailUpdate);
        setDataInAction(dataAfterEmailUpdate);
    };

    const handleFilterChange = (event) => {
        // getFilteredCustomers(event.target.value)
        // getData([]);
        // setPage(0);
        if (event === null) {
            setSelectedCustomer(["", ""]);
        } else {
            setSelectedCustomer([event.value, event.accountId]);
        }
        setSelectedFilterFullOption(event);
        handleLeftPaneClick(false);
    };

    const onShowTaskAssignedToCheckedChange = () => {
        setShowTaskAssignedTo(!showTaskAssignedTo);
    };

    const handleShortlistSourceChange = (event) => {
        // if (event === null) {
        //   setSelectedShortlistSource(["", ""]);
        // } else {
        //   setSelectedShortlistSource([event.value, event.shortlist_source]);
        // }
        setSelectedShortlistSource([event.value, event.shortlist_source]);
        // console.log(event);
        setSelectedShortlistSourceFilter(event);
        handleLeftPaneClick(false);
    };

    return (
        <>
            <IdleComponent logoutUser={logoutUser}></IdleComponent>
            <FilterToolbar
                loggedInUserDetails={loggedInUserDetails}
                showTaskAssignedTo={showTaskAssignedTo}
                onShowTaskAssignedToCheckedChange={onShowTaskAssignedToCheckedChange}
                data={data}
                dataInAction={dataInAction}
                filteredCustomersData={filteredCustomersData}
                filteredCustomers={filteredCustomers}
                handleFilterChange={handleFilterChange}
                listOfCompanies={listOfCompanies}
                selectedFilterFullOption={selectedFilterFullOption}
                handleShortlistSourceChange={handleShortlistSourceChange}
                searchByICPInUse={searchByICPInUse}
                selectedShortlistSourceFilter={selectedShortlistSourceFilter}
                setICPid={setICPid}
                setSearchByICPInUse={setSearchByICPInUse}
                getUsersAfterSave={getUsersAfterSave}
                tasksCompletedForToday={workDoneCount}
                readOnlyFlag={readOnlyFlag}
            />
            {mainSpinner ? (
                <>
                    <div className="d-flex justify-content-center align-items-center loader-container">
                        <Spin tip="Loading..." />
                    </div>
                </>
            ) : (
                <>
                    <Grid container m={0} p={0}>
                        <Grid
                            item
                            xs={3}
                            sx={(theme) => ({
                                borderRight: theme.palette.border.et,
                                pt: 0,
                                pl: 0,
                            })}
                        >
                            <Box
                                sx={(theme) => ({
                                    position: "relative",
                                })}
                            >
                                <Box
                                    ref={buttonRef}
                                    onScroll={toggleVisible}
                                    sx={(theme) => ({
                                        ml: 1,
                                        overflowY: "auto",
                                        position: "sticky",
                                        top: theme.custom.top.level3,
                                        height: `calc(100vh - ${theme.custom.top.level3}px)`,
                                        bgcolor: theme.palette.background.secondary,
                                    })}
                                >
                                    <LeftPaneContainer
                                        loggedInUserDetails={loggedInUserDetails}
                                        onClick={handleLeftPaneClick}
                                        displayUsers={data}
                                        displayDetailsOnRightPane={displayDetailsOnRightPane}
                                        selectedLeftPane={selectedLeftPane}
                                        incrementPage={incrementPage}
                                        isFullDataLoaded={isFullDataLoaded}
                                        loadingMoreDataSpinner={loadingMoreDataSpinner}
                                        selectedCustomer={selectedCustomer}
                                        showTaskAssignedTo={showTaskAssignedTo}
                                        onShowTaskAssignedToCheckedChange={onShowTaskAssignedToCheckedChange}
                                    />
                                </Box>
                                {/* Scroll Button */}
                                <Box
                                    sx={{
                                        position: "absolute",
                                        bottom: 16,
                                        right: 16,
                                        zIndex: 1000,
                                    }}
                                >
                                    {visible && (
                                        <IconButton
                                            onClick={scrollToTop}
                                            sx={(theme) => ({
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.common.white,
                                                "&:hover": {
                                                    backgroundColor: theme.palette.primary.dark,
                                                },
                                                boxShadow: theme.shadows[3],
                                                borderRadius: "50%",
                                                width: 48,
                                                height: 48,
                                            })}
                                        >
                                            <KeyboardArrowUpIcon fontSize="large" />
                                        </IconButton>
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={9} sx={{ pt: 0 }} flexGrow={1}>
                            {isLeftPaneClicked ? (
                                <RightPaneContainer
                                    loggedInUserDetails={loggedInUserDetails}
                                    clickedUserDetails={clickedUserDetails}
                                    removeCandidateAfterEmailUpdate={removeCandidateAfterEmailUpdate}
                                    getData={getData}
                                    readOnlyFlag={readOnlyFlag}
                                    setUsersAfterSave={setUsersAfterSave}
                                    setShowErrorMsg={setShowErrorMsg}
                                    setErrorMsg={setErrorMsg}
                                    handleLeftPaneClick={handleLeftPaneClick}
                                />
                            ) : (
                                <RightPaneWhenNotClicked />
                            )}
                        </Grid>
                    </Grid>

                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={showErrorMsg}
                        onClose={(e) => {
                            setShowErrorMsg(false);
                        }}
                        key={"update failed error snackbar"}
                        autoHideDuration={6000}
                    >
                        <Alert
                            onClose={(e) => {
                                setShowErrorMsg(false);
                            }}
                            severity="error"
                            sx={{ width: "100%" }}
                        >
                            {errorMsg}
                        </Alert>
                    </Snackbar>
                </>
            )}
        </>
    );
};

export default AddMissingEmails;
