import React from "react";
import "./modals.styles.css";

const FormSubmissionModal = ({candidateNewEmail}) => {
  return (
    <>
      <div
        className="modal fade"
        id="formModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="formModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {!candidateNewEmail ? (
              <div className="modal-body p-3">
                <p className="text-center">
                  You have not entered any email addresses.
                </p>
                <p className="text-center">
                  Did you search the internet and check the Swordfish and
                  SalesQL extensions, but were unable to find any emails for
                  this candidate?
                </p>
                <div className="d-flex justify-content-around h-50">
                  <button
                    type="button"
                    className="modal-button mt-auto modal-no-btn-color"
                    data-bs-dismiss="modal"
                  >
                    No, return to candidate page
                  </button>
                  <button
                    type="submit"
                    data-bs-dismiss="modal"
                    className="modal-button mt-auto modal-yes-btn-color"
                  >
                    Yes, mark candidate as No Email Found
                  </button>
                </div>
              </div>
            ) : (
              <div className="modal-body p-5 text-center">
                <p>Are you sure you want to save changes?</p>
                <div className="d-flex justify-content-around mt-5">
                  <button
                    type="button"
                    className="modal-button modal-no-btn-color"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                  <button
                    type="submit"
                    data-bs-dismiss="modal"
                    className="modal-button modal-yes-btn-color"
                  >
                    Yes
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default FormSubmissionModal;
