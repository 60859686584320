const CustomErrorOverlay = ({ children, Fallback }) => {
    return (
        <>
            {Fallback ? (
                <Fallback></Fallback>
            ) : (
                <div style={{ padding: "10px", border: "1px solid red", color: "red" }}>
                    <h2>An error has occurred!</h2>
                    {children}
                </div>
            )}
        </>
    );
};

export default CustomErrorOverlay;
