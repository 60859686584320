import { useState, useEffect } from "react";
import "./App.css";
import LoginPage from "./components/Login/LoginPage";
import Home from "./components/Home/home";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import axios from "axios";
import ProtectedRoutes from "./components/Home/ProtectedRoutes";
import AddCustomer from "./components/AddCustomers/AddCustomer";
import CustomerDashboard from "./components/CustomerDashboard/CustomerDashboard";
import PendingShorlist from "./components/PendingShortlist/PendingShortlist";
import LocationHeuristics from "./components/LocationHeuristics/LocationHeuristics";
import WrongEmail from "./components/WrongEmail/WrongEmail";
import AssignWork from "./components/AssignWork/AssignWork";
import "antd/dist/antd.min.css";
import { googleLogout } from "@react-oauth/google";
import { url } from "./url";
import UserContext from "./UserContext";
import TabContext from "./TabContext";
import Admin from "./components/Admin/Admin";
import DirectUpdate from "./components/DirectUpdate/DirectUpdate";
import OptOut from "./components/OptOut/OptOut";
import TimeLine from "./components/TimeLine/TimeLine";
import FakeCxoHeuristics from "./components/FakeCxoHeuristics/FakeCxoHeuristics";
import News from "./components/News/News";
import RTCopilotSearches from "./components/CopilotSearches/RTCopilotSearches/RTCopilotSearches";
import DummyProfileCreator from "./components/DummyProfileCreator/DummyProfileCreator";
import { TAB_ROUTE_TO_NAME_ID_MAP } from "./components/Home/TabsHomePage";
import { AltLinkFinder } from "./components/AltLinkFinder/AltLinkFinder.js";
import Reports from "./components/Reports/Reports";
import RefreshCompanyProfiles from "./components/CompanyProfiles/RefreshCompanyProfiles";
import RefreshCompanyProfilesRequest from "./components/CompanyProfiles/RefreshCompanyProfilesRequest";
import RefreshHistory from "./components/CompanyProfiles/RefreshHistory";
import CheckEmployeesCount from "./components/CompanyProfiles/CheckEmployeesCount.js";
import ProfileVerificationStatus from "./components/ProfileVerificationStatus/ProfileVerificationStatus.js";
import ProfileOptOut from "./components/OptOut/ProfileOptOut/ProfileOptOut.js";
import OptOutByDomain from "./components/OptOut/OptOutByDomain/OptOutByDomain.js";
import InstantProfileRefresh from "./components/InstantProfileRefresh/InstantProfileRefresh.js";
import RefreshRequest from "./components/InstantProfileRefresh/InstantProfileRefreshRequest/InstantProfileRefreshRequest.js";
import InstantProfileRefreshRequest from "./components/InstantProfileRefresh/InstantProfileRefreshRequest/InstantProfileRefreshRequest.js";
import InstantProfileRefreshHistory from "./components/InstantProfileRefresh/InstantProfileRefreshHistory/RefreshHistory.js";
import UserGoups from "./components/UserGroups/UserGroups.js";
import SMBCopilotSearches from "./components/SMBCopilotSearches/SMBCopilotSearches.js";
import UserDashboard from "./components/UserDashboard/UserDashboard.js";
import UserProfile from "./components/UserDashboard/UserProfile/UserProfile.js";
import Users from "./components/UserDashboard/Users/Users.js";
import Roles from "./components/UserDashboard/Role/Roles.js";
import { DRA_MODULES } from "./components/utils/rbacUtils.js";
import ProfileEditor from "./components/ProfileEditor/ProfileEditor.js";
import LeadershipProfile from "./components/LeadershipProfile/LeadershipProfile.js";

const App = () => {
  const [loggedInUser, getLoggedInUser] = useState("");
  const [loggedInSessionUser, getLoggedInSessionUser] = useState("");
  const selectedTabState = useState(
    TAB_ROUTE_TO_NAME_ID_MAP["/"+window.location.pathname.split("/")[1]]?.id
  );
  const auditLogsState = useState([]);

  const resFromServer = (response) => {
    if (response) {
      getLoggedInUser(response);
    }
  };

  //removing details not cookie, check with it again
  const logoutUser = () => {
    localStorage.clear();
    //Logging out from google oauth
    googleLogout();
    axios
      .get(url.clearUserSession)
      .then(() => {
        getLoggedInUser("");
        getLoggedInSessionUser("");
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios
      .get(url.auth)
      .then((res) => {
        getLoggedInSessionUser(res.data.user);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [loggedInUser]);

  return (
    <>
      <TabContext.Provider value={selectedTabState}>
        <UserContext.Provider
          value={loggedInSessionUser._id ? loggedInSessionUser : loggedInUser}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Router>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <ProtectedRoutes
                      Component={Home}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                      module={DRA_MODULES.addMissingEmails}
                    />
                  }
                />
                <Route
                  exact
                  path="/add-customers"
                  element={
                    <ProtectedRoutes
                      Component={AddCustomer}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                    />
                  }
                />

                <Route
                  exact
                  path="/customer-dashboard"
                  element={
                    <ProtectedRoutes
                      Component={CustomerDashboard}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                    />
                  }
                />

                <Route
                  exact
                  path="/pending-shortlist"
                  element={
                    <ProtectedRoutes
                      Component={PendingShorlist}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                      module={DRA_MODULES.pendingShortList}
                    />
                  }
                />

                <Route
                  exact
                  path="/wrong-email"
                  element={
                    <ProtectedRoutes
                      Component={WrongEmail}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                      module={DRA_MODULES.wrongEmails}
                    />
                  }
                />

                <Route
                  exact
                  path="/location-heuristics"
                  element={
                    <ProtectedRoutes
                      Component={LocationHeuristics}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                    />
                  }
                />             
                <Route
                  exact
                  path="/opt-out"
                  element={
                    <ProtectedRoutes
                      Component={OptOut}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                      module={DRA_MODULES.optOut}
                    />
                  }
                >
                   <Route index element={<Navigate to="profile-opt-out" replace />} />
                   <Route path="profile-opt-out" element={<ProfileOptOut />}/>
                   <Route path="by-domain" element={<OptOutByDomain />} />
                </Route>

                <Route
                  path="/login"
                  element={
                    <LoginPage
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      resFromServer={resFromServer}
                    />
                  }
                />
                <Route
                  exact
                  path="/direct-update"
                  element={
                    <ProtectedRoutes
                      Component={DirectUpdate}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                      module={DRA_MODULES.directUpdate}
                    />
                  }
                />
                <Route
                  exact
                  path="/timeline"
                  element={
                    <ProtectedRoutes
                      Component={TimeLine}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                      module={DRA_MODULES.timeLine}
                    />
                  }
                />
                <Route
                  exact
                  path="/fake-cxo-heuristics"
                  element={
                    <ProtectedRoutes
                      Component={FakeCxoHeuristics}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                    />
                  }
                />
                <Route
                  exact
                  path="/news"
                  element={
                    <ProtectedRoutes
                      Component={News}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                      module={DRA_MODULES.news}
                    />
                  }
                />
                {/* <Route
                  exact
                  path="/leadership-page-validation"
                  element={
                    <ProtectedRoutes
                      Component={LeadershipPageValidation}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                    />
                  }
                /> */}
                <Route
                  exact
                  path="/rt-copilot-searches"
                  element={
                    <ProtectedRoutes
                      Component={RTCopilotSearches}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                      module={DRA_MODULES.rtCopilotSearches}
                    />
                  }
                />
                <Route
                  exact
                  path="/copilot-searches-paychex"
                  element={
                    <ProtectedRoutes
                      Component={SMBCopilotSearches}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                      module={DRA_MODULES.smbCopilotSearches}
                    />
                  }
                />

                <Route
                  exact
                  path="/profile-editor"
                  element={
                    <ProtectedRoutes
                      Component={ProfileEditor}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                      module={DRA_MODULES.profileEditor}
                    />
                  }
                />
                <Route
                  exact
                  path="/dummy-profile-creator"
                  element={
                    <ProtectedRoutes
                      Component={DummyProfileCreator}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                      module={DRA_MODULES.dummyProfileCreator}
                    />
                  }
                />
                <Route
                  exact
                  path="/leadership-profile"
                  element={
                    <ProtectedRoutes
                      Component={LeadershipProfile}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={false}
                    />
                  }
                />
                <Route
                  exact
                  path="/company-employee-profile-refresh"
                  element={
                      <ProtectedRoutes
                          Component={RefreshCompanyProfiles}
                          loggedInUser={loggedInUser}
                          loggedInSessionUser={loggedInSessionUser}
                          loggedInUserDetails={loggedInSessionUser._id ? loggedInSessionUser : loggedInUser}
                          logoutUser={logoutUser}
                          permissionRequired={true}
                          module={DRA_MODULES.companyEmployeeProfileRefresh}
                      />
                  }
              >
                  <Route index element={<Navigate to="refresh" replace />} />
                  <Route path="refresh" element={<RefreshCompanyProfilesRequest />}/>
                  <Route path="refresh-history" element={<RefreshHistory />} />
                  <Route path="check-employees-count" element={<CheckEmployeesCount />} />
              </Route>
                <Route
                  exact
                  path="/reports"
                  element={
                    <ProtectedRoutes
                      Component={Reports}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                      module={DRA_MODULES.reports}
                    />
                  }
                >
                </Route>
                <Route 
                  exact
                  path="/dead-link-alternative-finder"
                  element={
                    <ProtectedRoutes 
                      Component={AltLinkFinder}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                      module={DRA_MODULES.deadLinkAlternativeFinder}
                    />
                  }
                />
                <Route
                exact
                path="/profile-verification-status"
                element={
                  <ProtectedRoutes
                    Component={ProfileVerificationStatus}
                    loggedInUser={loggedInUser}
                    loggedInSessionUser={loggedInSessionUser}
                    loggedInUserDetails={loggedInSessionUser._id
                                            ? loggedInSessionUser
                                            : loggedInUser
                    }
                    logoutUser={logoutUser}
                    permissionRequired={true}
                    module={DRA_MODULES.profileVerificationStatus}
                    />
                }
                >
              </Route>
                <Route
                exact
                path="/instant-profile-refresh"
                element={
                  <ProtectedRoutes
                    Component={InstantProfileRefresh}
                    loggedInUser={loggedInUser}
                    loggedInSessionUser={loggedInSessionUser}
                    loggedInUserDetails={loggedInSessionUser._id
                                            ? loggedInSessionUser
                                            : loggedInUser
                    }
                    logoutUser={logoutUser}
                    permissionRequired={true}
                    module={DRA_MODULES.instantProfileRefresh}
                    />
                }
                >
                  <Route index element={<Navigate to="refresh-request" replace />} />
                  <Route path="refresh-request" element={<InstantProfileRefreshRequest />}/>
                  <Route path="refresh-history" element={<InstantProfileRefreshHistory />}></Route>
              </Route>
              <Route exact path="user-groups" element={
                <ProtectedRoutes
                  Component={UserGoups}
                  loggedInUser={loggedInUser}
                  loggedInSessionUser={loggedInSessionUser}
                  loggedInUserDetails={loggedInSessionUser._id
                                          ? loggedInSessionUser
                                          : loggedInUser
                  }
                  logoutUser={logoutUser}
                  permissionRequired={true}
                  module={DRA_MODULES.userGroups}
                  />
              }>

              </Route>
              <Route exact path="user-dashboard" element={
                <ProtectedRoutes
                  Component={UserDashboard}
                  loggedInUser={loggedInUser}
                  loggedInSessionUser={loggedInSessionUser}
                  loggedInUserDetails={loggedInSessionUser._id
                                          ? loggedInSessionUser
                                          : loggedInUser
                  }
                  logoutUser={logoutUser}
                  />
              }>
                  <Route index element={<Navigate to="profile" replace />} />
                  <Route path="profile" element={<UserProfile />}/>
                  <Route path="users" element={
                    <ProtectedRoutes
                      Component={Users}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={loggedInSessionUser._id
                                              ? loggedInSessionUser
                                              : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                      module={DRA_MODULES.users}
                      nestedRoute={true}
                    />
                  }/>
                  <Route path="roles" element={
                    <ProtectedRoutes
                      Component={Roles}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={loggedInSessionUser._id
                                              ? loggedInSessionUser
                                              : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                      module={DRA_MODULES.roles}
                      nestedRoute={true}
                    />
                  }/>
                  <Route path="admin" element={
                    <ProtectedRoutes
                      Component={Admin}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                      module={DRA_MODULES.admin}
                      nestedRoute={true}
                      />
                    }/>
                  <Route path="assign-tasks" element={
                    <ProtectedRoutes
                      Component={AssignWork}
                      loggedInUser={loggedInUser}
                      loggedInSessionUser={loggedInSessionUser}
                      loggedInUserDetails={
                        loggedInSessionUser._id
                          ? loggedInSessionUser
                          : loggedInUser
                      }
                      logoutUser={logoutUser}
                      permissionRequired={true}
                      module={DRA_MODULES.assignWork}
                      nestedRoute={true}
                      />
                  }/>
                  
                  
              </Route>
              {/* <Route path="/register" element={<RegistrationPage />} /> */}
              </Routes>
            </Router>
          </LocalizationProvider>
        </UserContext.Provider>
      </TabContext.Provider>
    </>
  );
};

export default App;
