import { configureStore } from "@reduxjs/toolkit";
import leadershipProfileReducer from "../features/leadership-profile/store/leadership-profile-slice";
import themeReducer from "../theme/store/theme-slice";
const store = configureStore({
    reducer: {
        leadershipProfile: leadershipProfileReducer,
        theme: themeReducer,
    },
});

export default store;
