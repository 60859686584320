import { Alert, Box, Button, CircularProgress, Menu, MenuItem, Tooltip } from "@mui/material";
import { Divider, message, Typography } from "antd";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import IntelligenceLogo from "../../../assets/intelligenceLogo.svg";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useRef, useState } from "react";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

const TextTypeMenu = {
    raw: {
        label: "View Raw Text",
    },
    format: {
        label: "View Formatted Text",
    },
};

const LeftPane = ({ setUids }) => {
    const [fileData, setFileData] = useState({ name: "", content: "", isLoading: false, isLargeToShow: false });
    const [visibleContent, setVisibleContent] = useState("");
    const [textType, setTextType] = useState("raw");
    const [textTypeOpen, setTextTypeOpen] = useState(false);
    const [listType, setListType] = useState("");
    const [analyse, setAnalyse] = useState({ analysing: false, data: {} });
    const quillRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleFileUpload = (event) => {
        try {
            const file = event.target.files[0];
            if (file && file.type === "text/csv") {
                setFileData({ name: "", content: "", isLoading: true });
                const reader = new FileReader();
                reader.onload = (e) => {
                    const content = e.target.result;
                    setFileData({
                        name: file.name,
                        content: content,
                        isLoading: false,
                        isLargeToShow: content.length > 50000,
                    });
                    const uidArray = content
                        .split(",")
                        .map((uid) => uid.trim())
                        .filter((uid) => uid.length > 0);
                    setUids([...new Set(uidArray)]);
                    setVisibleContent(content);
                };
                reader.readAsText(file);
            }
            setTextType("raw");
            event.target.value = null;
        } catch (error) {
            setFileData({ ...fileData, isLoading: false });
            message.error("Unable to upload file!");
            console.log("Failed to upload file:", error);
        }
    };

    const handleTextTypePopover = (event) => {
        let newTextType = event.currentTarget?.dataset?.textType;
        if (textTypeOpen) {
            setTextTypeOpen(false);
        } else {
            setAnchorEl(event.currentTarget);
            setTextTypeOpen(true);
        }
        if (newTextType && textType != newTextType) {
            if (newTextType === "raw") {
                setVisibleContent(fileData.content);
            } else {
                const text = fileData.content;
                const result = text.replace(/,/g, "\n");
                setVisibleContent(result);
            }
            setTextType(newTextType);
        }
    };
    const handleReset = () => {
        setFileData({ ...fileData, name: "", content: "", isLargeToShow: false });
        setVisibleContent("");
        setUids("");
    };

    const handleAnalyseContent = () => {
        setAnalyse({ ...analyse, analysing: true });
    };

    return (
        <Box sx={{ width: "80%", height: "80vh", display: "flex", flexDirection: "column" }} overflow={"auto"}>
            {fileData.isLargeToShow && (
                <Alert severity="warning">The file is too large to handle. You can still send the Refresh Request.</Alert>
            )}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0.5rem",
                    margin: "1px",
                    background: "white",
                    borderRadius: "3px",
                }}
            >
                <Box display={"flex"} alignItems={"center"}>
                    <Box>
                        <Button
                            sx={{ textTransform: "none", fontWeight: "600", color: "#172B4D" }}
                            variant="text"
                            endIcon={<ExpandCircleDownIcon />}
                            onClick={handleTextTypePopover}
                        >
                            {TextTypeMenu?.[textType]?.label}
                        </Button>
                        <Menu
                            id="text-type-menu"
                            anchorEl={anchorEl}
                            open={textTypeOpen}
                            onClose={handleTextTypePopover}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >
                            <MenuItem data-text-type="raw" onClick={handleTextTypePopover}>
                                View Raw Text
                            </MenuItem>
                            <MenuItem data-text-type="format" onClick={handleTextTypePopover}>
                                View Formatted Text
                            </MenuItem>
                        </Menu>
                    </Box>
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                    <Button
                        onClick={handleReset}
                        startIcon={<RotateLeftIcon />}
                        sx={{ textTransform: "none", fontWeight: "600", color: "#172B4D" }}
                        type="text"
                    >
                        Reset
                    </Button>
                    <Divider style={{ height: "1.5rem" }} type="vertical"></Divider>
                    <Button
                        component="label"
                        startIcon={<UploadFileIcon />}
                        endIcon={fileData.isLoading ? <CircularProgress size={20} disableShrink /> : fileData.name && `(${fileData.name})`}
                        sx={{ textTransform: "none", fontWeight: "600", color: "#172B4D" }}
                        type="text"
                    >
                        <input style={{ display: "none" }} id="file-input" type="file" accept=".csv" onChange={handleFileUpload} />
                        Upload File
                    </Button>
                </Box>
            </Box>
            <Divider style={{ margin: "0" }}></Divider>
            <Box sx={{ padding: "1rem" }}>
                {!fileData.isLoading && <Typography style={{ whiteSpace: "pre-line" }}>{visibleContent}</Typography>}
            </Box>
        </Box>
    );
};

export default LeftPane;
