import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useState } from "react";

const DeleteButtonWithConfirmation = ({disabled, onConfirm }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = (_, reason) => {
        if (reason !== "backdropClick") {
            setOpen(false);
        }
    };

    const handleConfirm = async () => {
        setLoading(true);
        try {
            await onConfirm();
        } finally {
            setLoading(false); // Stop loading (optional if close is manual)
        }
        handleClose();
    };

   
    return (
        <>
            
            <Button
                onClick={handleOpen}
                disabled={disabled}
                sx={(theme) => ({
                    marginTop: "1.5rem",
                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.error.contrastText,
                    "&:hover": {
                        backgroundColor: theme.palette.error.dark,
                        color: theme.palette.error.contrastText,
                    },
                })}
            >
                Delete
            </Button>

            {/* Confirmation Modal */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {!loading && (
                        <Button onClick={handleClose} color="secondary">
                            Cancel
                        </Button>
                    )}
                    <Button onClick={handleConfirm} color="primary" disabled={loading}>
                        {loading ? <CircularProgress size={20} /> : "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DeleteButtonWithConfirmation;
