import React, { useState } from "react";
import Select from "react-select";

const ShortlistSourceFilter = ({ handleShortlistSourceChange, selectedShortlistSourceFilter, searchByICPInUse }) => {
  return (
    <>
      {searchByICPInUse ? (
        <Select
          isDisabled
          name="shortlist_source"
          options={[
            {
              value: "all",
              label: "All shortlist source",
              shortlist_source: "all",
            },
            {
              value: "manual",
              label: "Manual shortlist",
              shortlist_source: "manual",
            },
            {
              value: "bulk",
              label: "Bulk shortlist",
              shortlist_source: "bulk",
            },
          ]}
          className="basic-single"
          classNamePrefix="select"
          onChange={handleShortlistSourceChange}
          value={
            selectedShortlistSourceFilter?.value
              ? selectedShortlistSourceFilter
              : {
                  value: "all",
                  label: "All shortlist source",
                  shortlist_source: "all",
                }
          }
        />
      ) : (
        <Select
          name="shortlist_source"
          options={[
            {
              value: "all",
              label: "All shortlist source",
              shortlist_source: "all",
            },
            {
              value: "manual",
              label: "Manual shortlist",
              shortlist_source: "manual",
            },
            {
              value: "bulk",
              label: "Bulk shortlist",
              shortlist_source: "bulk",
            },
          ]}
          className="basic-single"
          classNamePrefix="select"
          onChange={handleShortlistSourceChange}
          value={
            selectedShortlistSourceFilter?.value
              ? selectedShortlistSourceFilter
              : {
                  value: "all",
                  label: "All shortlist source",
                  shortlist_source: "all",
                }
          }
        />
      )}
    </>
  );
};

export default ShortlistSourceFilter;
