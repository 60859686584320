import React, { useState } from "react";
import _ from "lodash";
import {
  PlusOutlined,
  MinusCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Form,
  Input,
  Space,
  InputNumber,
  Tabs,
  Spin,
  Modal,
  message,
  Select,
} from "antd";

import axios from "axios";
import { url } from "../../url";

import { validateDynamicallyAddedFields } from "./validators";

import {
  transformObject,
  reverseTransformObject,
  cleanObject,
  updateCandidateWithExternalRefs,
} from "./utils";
import ArrOfStrUIHandler from "./ArrOfStrUIHandler";

const PopulatedFormToEdit = (props) => {
  const { dataProp, resetData, loggedInUserDetails } = props;
  const [form] = Form.useForm();
  const [auditLog, setOnSubmitAuditLog] = useState({});
  const [errText, setErrText] = useState("");
  const [isFinalModalOpen, setIsFinalModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [payloadProfile, setPayloadProfile] = useState({});
  const [disableMinusCircleOutlined, setDisableMinusCircleOutlined] =
    useState(true);
  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

  const data = transformObject(dataProp);

  const handleFinalModalCancel = () => {
    setIsFinalModalOpen(false);
  };

  const onFinish = (values) => {
    const failedValidations = validateDynamicallyAddedFields(values);

    if (failedValidations.length > 0) {
      const errorMessage = failedValidations
        .map(
          (failure) =>
            `Section "${failure.sectionKey}" at index ${
              failure.index + 1
            } is incomplete.`
        )
        .join(" ");
      message.error(`Please complete all required fields. ${errorMessage}`);
      return;
    }

    const changes = compareValues(data, values);
    if (Object.keys(changes).length > 0) {
      setOnSubmitAuditLog(changes);

      const dataForPayload = {
        ...data,
        ext_refs: changes?.ext_refs?.to,
      };

      const finalDataForPayload = reverseTransformObject(dataForPayload);

      const updatedCandidate = updateCandidateWithExternalRefs(
        dataForPayload.ext_refs,
        finalDataForPayload.candidate
      );

      const dataToClean = {
        ...finalDataForPayload,
        candidate: updatedCandidate,
      };

      const cleanedObject = cleanObject(dataToClean);

      if (cleanedObject) {
        setPayloadProfile(cleanedObject);
      }

      setIsFinalModalOpen(true);
    } else {
      const err = "No Changes Detected!";
      console.log(err);
      setErrText(err);
      setTimeout(() => {
        setErrText("");
      }, 4000);
    }
  };

  const handlePostEditedProfile = async () => {
    try {
      if (payloadProfile) {
        let audit_log_data = {
          eventName: "update_public_profile_dummy_profile_creator",
          eventType: "update",
          userId: loggedInUserDetails._id,
          eventMetaData: {},
          oldRecord: {
            old_public_profile: dataProp,
          },
          newRecord: {
            new_public_profile: payloadProfile,
          },
        };

        const response = await axios.post(url.updatePublicProfile, {
          audit_log_data,
        });

        message.success(
          response.data.message || "Profile updated successfully."
        );
        setIsFinalModalOpen(false);
      }
    } catch (err) {
      setIsFinalModalOpen(false);
      if (err.response && err.response.data && err.response.data.message) {
        message.error(err.response.data.message);
      } else if (err.message) {
        message.error(err.message);
      } else {
        message.error("An error occurred while updating the profile.");
      }
    }
  };

  const compareValues = (initial, current) => {
    const changes = {};
    Object.keys(current).forEach((key) => {
      if (!_.isEqual(initial[key], current[key])) {
        changes[key] = { from: initial[key], to: current[key] };
      }
    });
    return changes;
  };

  const sources = [
    "Behance",
    "Artstation",
    "Dribbble",
    "LinkedIn",
    "Twitter",
    "Facebook",
    "Doximity",
    "GitHub",
    "StackOverflow",
    "Instagram",
    "Kaggle",
    "LeetCode",
    "CodeForces",
    "CodeChef",
    "Quora",
    "Scholar",
  ];

  const sourceOptions = sources.map((source) => ({
    label: source,
    value: source,
  }));

  return (
    <Form
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 18,
      }}
      form={form}
      name="dynamic_form_complex"
      style={{
        width: 1200,
      }}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={Object.keys(data)?.length && data}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => {
            resetData();
          }}
          style={{
            width: "90px",
            backgroundColor: "transparent",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
          }}
        >
          <ArrowLeftOutlined style={{ fontSize: "1rem" }} /> <span>Back</span>
        </Button>
        {errText.length ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "1rem",
              padding: "10px",
              height: "70px",
              fontSize: "24px",
              fontWeight: 600,
              color: "#ff0000",
            }}
          >
            <span>{errText}</span>
          </div>
        ) : (
          <h4
            style={{
              textAlign: "center",
              marginTop: "1rem",
              height: "50px",
              fontSize: "40px",
              fontWeight: 600,
              backgroundImage: "linear-gradient(to left, #553c9a, #b393d3)",
              color: "transparent",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
            }}
          >
            Edit Profile
          </h4>
        )}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              marginTop: "1.5rem",
              boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </div>

      <div>
        <Tabs type="card" centered className="mt-2">
          <Tabs.TabPane tab="Other Fields" key="tab1">
            <div
              style={{
                display: "flex",
                rowGap: 16,
                flexDirection: "column",
                // border: "2px solid red",
                marginBottom: "1rem",
                padding: "1rem",
                borderRadius: "10px",
                //   boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
              }}
            >
              {Object.keys(data)
                ?.filter((i) => typeof data[i] === "string")
                .map((i) => (
                  <Form.Item key={i} label={i} name={i}>
                    <Input disabled={true} placeholder={`${i}`} />
                  </Form.Item>
                ))}
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Candidate" key="tab2">
            {/* for candidate */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: 16,
                marginBottom: "1rem",
                padding: "1rem",
                borderRadius: "10px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <Card size="small" title="Candidate Information">
                {[
                  "full_name",
                  "about_me",
                  "address",
                  "angel",
                  "crunchbase",
                  "dob",
                  "doximity",
                  "dribbble",
                  "email",
                  "gender",
                  "kaggle",
                  "lang",
                  "linkedin",
                  "location_raw",
                  "nfx_signal",
                  "phone",
                  "research_gate",
                  "rgate",
                  "sname",
                  "title",
                  "twitter",
                  "type",
                  "num_connections",
                ].map((el) => {
                  return (
                    <Form.Item label={el} name={["candidate", el]}>
                      {el === "about_me" ? (
                        <Input.TextArea disabled={true} />
                      ) : (
                        <Input disabled={true} />
                      )}
                    </Form.Item>
                  );
                })}

                <Card size="small" title="Location">
                  <Form.Item
                    label="City"
                    name={["candidate", "location", "city"]}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                  <Form.Item
                    label="Country"
                    name={["candidate", "location", "country"]}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                  <Form.Item
                    label="State"
                    name={["candidate", "location", "state"]}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Card>

                {/* Connections */}
                <Card size="small" title="Connections">
                  <Form.List name={["candidate", "connections"]}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, fieldKey }) => (
                          <div key={key}>
                            <Card
                              size="small"
                              title={`Connection ${fieldKey + 1}`}
                              extra={
                                <MinusCircleOutlined
                                  onClick={() =>
                                    !disableMinusCircleOutlined && remove(name)
                                  }
                                />
                              }
                            >
                              <Form.Item
                                name={[name, "ext_src"]}
                                label="External Source"
                              >
                                <Input
                                  disabled={true}
                                  placeholder="External Source"
                                />
                              </Form.Item>

                              {/* Emails Nested List */}
                              <Form.List name={[name, "emails"]}>
                                {(
                                  emailFields,
                                  { add: addEmail, remove: removeEmail }
                                ) => (
                                  <Card size="small" title="Emails">
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          // marginBottom: 8,
                                        }}
                                      >
                                        {emailFields.map((emailField) => (
                                          <div
                                            key={emailField.key}
                                            style={{
                                              display: "flex",
                                              // marginBottom: 8,
                                            }}
                                            align="baseline"
                                          >
                                            <Form.Item
                                              name={[emailField.name, "emails"]}
                                              fieldKey={[
                                                emailField.fieldKey,
                                                "emails",
                                              ]}
                                              // label="E-mail"
                                              // rules={[
                                              //   {
                                              //     type: "email",
                                              //     message:
                                              //       "The input is not valid E-mail!",
                                              //   },
                                              //   {
                                              //     required: true,
                                              //     message:
                                              //       "Please input your E-mail!",
                                              //   },
                                              // ]}
                                            >
                                              <Input
                                                disabled={true}
                                                style={{
                                                  width: "300px",
                                                  margin: "0 5px 0 5px",
                                                }}
                                                placeholder="Email"
                                                addonAfter={
                                                  <MinusCircleOutlined
                                                    onClick={() =>
                                                      !disableMinusCircleOutlined &&
                                                      removeEmail(
                                                        emailField.name
                                                      )
                                                    }
                                                  />
                                                }
                                              />
                                            </Form.Item>
                                          </div>
                                        ))}
                                      </div>
                                      <Button
                                        type="dashed"
                                        onClick={() =>
                                          !disableMinusCircleOutlined &&
                                          addEmail()
                                        }
                                        block
                                        icon={<PlusOutlined />}
                                      >
                                        Add Email
                                      </Button>
                                    </>
                                  </Card>
                                )}
                              </Form.List>
                            </Card>
                          </div>
                        ))}
                        <Button
                          type="dashed"
                          onClick={() => !disableMinusCircleOutlined && add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Connection
                        </Button>
                      </>
                    )}
                  </Form.List>
                </Card>

                {[
                  "artstation",
                  "behance",
                  "codechef",
                  "codeforces",
                  "connections",
                  "ethnicity",
                  "github",
                  "leetcode",
                  "quora",
                  "scholar",
                  "stackoverflow",
                  "candidate_tags",
                ].map((field) => (
                  <Card
                    size="small"
                    title={`${field.charAt(0).toUpperCase() + field.slice(1)}`}
                  >
                    <Form.List name={["candidate", field]}>
                      {(fields, { add, remove }) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {fields.map(({ key, name, fieldKey }) => (
                            <Space key={key} align="baseline">
                              <Form.Item
                                name={[name, field]}
                                fieldKey={[fieldKey, field]}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: `Please input the ${field}`,
                                //   },
                                // ]}
                              >
                                <Input
                                  disabled={true}
                                  placeholder={field}
                                  addonAfter={
                                    <MinusCircleOutlined
                                      onClick={() =>
                                        !disableMinusCircleOutlined &&
                                        remove(name)
                                      }
                                    />
                                  }
                                />
                              </Form.Item>
                            </Space>
                          ))}
                          <Button
                            type="dashed"
                            onClick={() => !disableMinusCircleOutlined && add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add {field.charAt(0).toUpperCase() + field.slice(1)}
                          </Button>
                        </div>
                      )}
                    </Form.List>
                  </Card>
                ))}

                {/* Candidate Tags */}
                <Card size="small" title="Candidate Tags">
                  <Form.List name={["candidate", "candidate_tags"]}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, fieldKey }) => (
                          <div key={key}>
                            <Card size="small" title={`Tag ${fieldKey + 1}`}>
                              <Form.Item
                                name={[name, "tag_name"]}
                                label="Tag Name"
                              >
                                <Input disabled={true} placeholder="Tag Name" />
                              </Form.Item>

                              <Form.Item
                                name={[name, "tag_desc"]}
                                label="Tag Description"
                              >
                                <Input
                                  disabled={true}
                                  placeholder="Tag Description"
                                />
                              </Form.Item>

                              <Form.Item
                                name={[name, "tag_ts"]}
                                label="Tag Timestamp"
                              >
                                <Input
                                  disabled={true}
                                  placeholder="Tag Timestamp"
                                />
                              </Form.Item>

                              {/* ... other fields for each tag ... */}
                            </Card>
                            <MinusCircleOutlined
                              onClick={() =>
                                !disableMinusCircleOutlined && remove(name)
                              }
                            />
                          </div>
                        ))}
                        <Button
                          type="dashed"
                          onClick={() => !disableMinusCircleOutlined && add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Tag
                        </Button>
                      </>
                    )}
                  </Form.List>
                </Card>

                <Card size="small" title="Other Contacts">
                  <Form.List name={["candidate", "other_contacts"]}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, fieldKey }) => (
                          <Space
                            key={key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                          >
                            <Form.Item
                              name={[name, "type"]}
                              fieldKey={[fieldKey, "type"]}
                            >
                              <Input disabled={true} placeholder="Type" />
                            </Form.Item>
                            <Form.Item
                              name={[name, "id"]}
                              fieldKey={[fieldKey, "id"]}
                            >
                              <Input disabled={true} placeholder="ID" />
                            </Form.Item>
                            <MinusCircleOutlined
                              disabled={true}
                              onClick={() =>
                                !disableMinusCircleOutlined && remove(name)
                              }
                            />
                          </Space>
                        ))}
                        <Button
                          type="dashed"
                          onClick={() => !disableMinusCircleOutlined && add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Contact
                        </Button>
                      </>
                    )}
                  </Form.List>
                </Card>
              </Card>
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Skills" key="tab3">
            {/* for skills */}
            <div
              style={{
                display: "flex",
                // rowGap: 16,
                flexDirection: "column",
                marginBottom: "1rem",
                padding: "1rem",
                borderRadius: "10px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <Card size="small" title="Skills">
                <Form.List name="skills">
                  {(fields, { add, remove }) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {fields.map(({ key, name, fieldKey }) => (
                        <Space key={key} align="baseline">
                          <Form.Item
                            name={[name, "skills"]}
                            fieldKey={[fieldKey, "skills"]}
                          >
                            <Input
                              disabled={true}
                              style={{ width: "250px", margin: "0 5px 0 5px" }}
                              placeholder="Skill"
                              addonAfter={
                                <MinusCircleOutlined
                                  onClick={() =>
                                    !disableMinusCircleOutlined && remove(name)
                                  }
                                />
                              }
                            />
                          </Form.Item>
                        </Space>
                      ))}
                      <Button
                        type="dashed"
                        onClick={() => !disableMinusCircleOutlined && add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Skill
                      </Button>
                    </div>
                  )}
                </Form.List>
              </Card>
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Workexp" key="tab4">
            {/* for workexp */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: 16,
                marginBottom: "1rem",
                padding: "1rem",
                borderRadius: "10px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <Card size="small" title="Work Experience">
                <Form.List name="workexp">
                  {(workFields, { add: addWorkExp, remove: removeWorkExp }) => (
                    <>
                      {workFields.map(({ key, name, fieldKey }) => (
                        <Card
                          key={key}
                          size="small"
                          title={`Experience ${fieldKey + 1}`}
                          extra={
                            <MinusCircleOutlined
                              onClick={() =>
                                !disableMinusCircleOutlined &&
                                removeWorkExp(name)
                              }
                            />
                          }
                        >
                          {/* Company Info*/}
                          <Card size="small" title="Company">
                            {[
                              "company_id",
                              "name",
                              "lnkd_company_id",
                              "lnkd_company_src_id",
                              "twitter_handle",
                              "crunchbase_url",
                              "domain",
                              "location",
                              "err_string",
                            ].map((el, i) => {
                              return (
                                <Form.Item
                                  key={i}
                                  label={el}
                                  name={[name, "company", el]}
                                >
                                  <Input disabled={true} />
                                </Form.Item>
                              );
                            })}

                            {/* Location Parsed */}
                            <Form.Item
                              label="City"
                              name={[
                                name,
                                "company",
                                "location_parsed",
                                "city",
                              ]}
                            >
                              <Input disabled={true} />
                            </Form.Item>
                            <Form.Item
                              label="Country"
                              name={[
                                name,
                                "company",
                                "location_parsed",
                                "country",
                              ]}
                            >
                              <Input disabled={true} />
                            </Form.Item>
                            <Form.Item
                              label="State"
                              name={[
                                name,
                                "company",
                                "location_parsed",
                                "state",
                              ]}
                            >
                              <Input disabled={true} />
                            </Form.Item>
                          </Card>

                          {/* Duration */}
                          <Card size="small" title="Duration">
                            {["start_date", "end_date", "to_present"].map(
                              (el, i) => {
                                return (
                                  <Form.Item
                                    key={i}
                                    label={el}
                                    name={[name, "duration", el]}
                                  >
                                    <Input disabled={true} />
                                  </Form.Item>
                                );
                              }
                            )}
                          </Card>

                          {/* Investment Info*/}
                          <Card size="small" title="Investment Info">
                            {[
                              "series",
                              "amount",
                              "valuation",
                              "investment_date",
                              "investment_capacity",
                              "partner_id",
                              "is_lead",
                              "title",
                            ].map((el, i) => {
                              return (
                                <Form.Item
                                  key={i}
                                  label={el}
                                  name={[name, "investment_info", el]}
                                >
                                  <Input disabled={true} />
                                </Form.Item>
                              );
                            })}

                            {/* Investment Firm */}
                            <Card size="small" title="Investment Firm">
                              {[
                                "company_id",
                                "name",
                                "lnkd_company_id",
                                "lnkd_company_src_id",
                                "twitter_handle",
                                "crunchbase_url",
                                "domain",
                                "location",
                                "err_string",
                              ].map((el, i) => {
                                return (
                                  <Form.Item
                                    key={i}
                                    label={el}
                                    name={[
                                      name,
                                      "investment_info",
                                      "investment_firm",
                                      el,
                                    ]}
                                  >
                                    <Input disabled={true} />
                                  </Form.Item>
                                );
                              })}

                              {/* Location Parsed */}
                              <Form.Item
                                label="City"
                                name={[
                                  name,
                                  "investment_info",
                                  "investment_firm",
                                  "location_parsed",
                                  "city",
                                ]}
                              >
                                <Input disabled={true} />
                              </Form.Item>
                              <Form.Item
                                label="Country"
                                name={[
                                  name,
                                  "investment_info",
                                  "investment_firm",
                                  "location_parsed",
                                  "country",
                                ]}
                              >
                                <Input disabled={true} />
                              </Form.Item>
                              <Form.Item
                                label="State"
                                name={[
                                  name,
                                  "investment_info",
                                  "investment_firm",
                                  "location_parsed",
                                  "state",
                                ]}
                              >
                                <Input disabled={true} />
                              </Form.Item>
                            </Card>

                            {/* Investment Firm Types */}
                            <ArrOfStrUIHandler
                              name={name}
                              cardLabel={"Investment Firm Type"}
                              key1={"investment_info"}
                              key2={"investment_firm_types"}
                            />

                            {/* Lead Investment Firms */}
                            <Card size="small" title="Lead Investment Firms">
                              {/* Lead Investment Firms */}
                              <Form.List
                                name={[
                                  name,
                                  "investment_info",
                                  "lead_investment_firms",
                                ]}
                              >
                                {(
                                  leadInvestmentFirmsFields,
                                  {
                                    add: addLeadInvestmentFirm,
                                    remove: removeLeadInvestmentFirm,
                                  }
                                ) => (
                                  <>
                                    {leadInvestmentFirmsFields.map(
                                      (leadInvestmentFirmField) => (
                                        <div
                                          key={leadInvestmentFirmField.key}
                                          // style={{ display: "flex", marginBottom: 8 }}
                                          align="baseline"
                                        >
                                          <div
                                            style={{
                                              // border: "2px solid red",
                                              display: "flex",
                                              justifyContent: "end",
                                              alignItems: "end",
                                              marginBottom: "15px",
                                            }}
                                          >
                                            <MinusCircleOutlined
                                              onClick={() =>
                                                !disableMinusCircleOutlined &&
                                                removeLeadInvestmentFirm(
                                                  leadInvestmentFirmField.name
                                                )
                                              }
                                            />
                                          </div>
                                          <Form.Item
                                            name={[
                                              leadInvestmentFirmField.name,
                                              "company_id",
                                            ]}
                                            fieldKey={[
                                              leadInvestmentFirmField.fieldKey,
                                              "company_id",
                                            ]}
                                            label="Company ID"
                                          >
                                            <Input
                                              disabled={true}
                                              placeholder="Company ID"
                                            />
                                          </Form.Item>
                                          <Form.Item
                                            name={[
                                              leadInvestmentFirmField.name,
                                              "name",
                                            ]}
                                            fieldKey={[
                                              leadInvestmentFirmField.fieldKey,
                                              "name",
                                            ]}
                                            label="Company Name"
                                          >
                                            <Input
                                              disabled={true}
                                              placeholder="Company Name"
                                            />
                                          </Form.Item>
                                        </div>
                                      )
                                    )}
                                    <Button
                                      type="dashed"
                                      onClick={() =>
                                        !disableMinusCircleOutlined &&
                                        addLeadInvestmentFirm()
                                      }
                                      block
                                      icon={<PlusOutlined />}
                                    >
                                      Add Lead Investment Firm
                                    </Button>
                                  </>
                                )}
                              </Form.List>
                            </Card>

                            {/* Investment Category */}
                            <ArrOfStrUIHandler
                              name={name}
                              cardLabel={"Investment Category"}
                              key1={"investment_info"}
                              key2={"investment_category"}
                            />

                            {/* investment_subcategory */}
                            <ArrOfStrUIHandler
                              name={name}
                              cardLabel={"Investment Sub Category"}
                              key1={"investment_info"}
                              key2={"investment_subcategory"}
                            />

                            {/* Company */}
                            <Card size="small" title="Company">
                              {[
                                "company_id",
                                "name",
                                "lnkd_company_id",
                                "lnkd_company_src_id",
                                "twitter_handle",
                                "crunchbase_url",
                                "domain",
                                "location",
                                "err_string",
                              ].map((el, i) => {
                                return (
                                  <Form.Item
                                    key={i}
                                    label={el}
                                    name={[
                                      name,
                                      "investment_info",
                                      "company",
                                      el,
                                    ]}
                                  >
                                    <Input disabled={true} />
                                  </Form.Item>
                                );
                              })}

                              {/* Location Parsed */}
                              <Form.Item
                                label="City"
                                name={[
                                  name,
                                  "investment_info",
                                  "company",
                                  "location_parsed",
                                  "city",
                                ]}
                              >
                                <Input disabled={true} />
                              </Form.Item>
                              <Form.Item
                                label="Country"
                                name={[
                                  name,
                                  "investment_info",
                                  "company",
                                  "location_parsed",
                                  "country",
                                ]}
                              >
                                <Input disabled={true} />
                              </Form.Item>
                              <Form.Item
                                label="State"
                                name={[
                                  name,
                                  "investment_info",
                                  "company",
                                  "location_parsed",
                                  "state",
                                ]}
                              >
                                <Input disabled={true} />
                              </Form.Item>
                            </Card>
                          </Card>

                          {/* Executive Profile */}
                          <Card size="small" title="Executive Profile">
                            <Form.List name={[name, "exec_profile"]}>
                              {(
                                execProfileFields,
                                {
                                  add: addExecProfile,
                                  remove: removeExecProfile,
                                }
                              ) => (
                                <>
                                  {execProfileFields.map(
                                    (execProfileField, fieldKey) => {
                                      return (
                                        <Card
                                          key={fieldKey}
                                          size="small"
                                          title={`Exec Profile ${fieldKey + 1}`}
                                          extra={
                                            <MinusCircleOutlined
                                              onClick={() =>
                                                !disableMinusCircleOutlined &&
                                                removeExecProfile(
                                                  execProfileField.name
                                                )
                                              }
                                            />
                                          }
                                          // style={{
                                          //   borderColor: inputBorderColor,
                                          // }}
                                        >
                                          <div key={execProfileField.key}>
                                            {/* Fields for exec_profile */}

                                            {[
                                              "source",
                                              "update_date",
                                              "exec_id",
                                              "est_compensation",
                                              "compensation_currency",
                                              "exec_name",
                                              "resolved",
                                              "ingested",
                                              "linkedin",
                                            ].map((el, i) => {
                                              return (
                                                <Form.Item
                                                  key={i}
                                                  label={el}
                                                  name={[name, el]}
                                                >
                                                  <Input disabled={true} />
                                                </Form.Item>
                                              );
                                            })}

                                            {/* Executive Titles */}
                                            <Form.List
                                              name={[
                                                execProfileField.name,
                                                "exec_titles",
                                              ]}
                                            >
                                              {(
                                                execTitlesFields,
                                                {
                                                  add: addExecTitle,
                                                  remove: removeExecTitle,
                                                }
                                              ) => (
                                                <>
                                                  {execTitlesFields.map(
                                                    (
                                                      execTitleField,
                                                      fieldKey
                                                    ) => (
                                                      <Card
                                                        size="small"
                                                        key={fieldKey}
                                                        title={`Exec Title ${
                                                          fieldKey + 1
                                                        }`}
                                                        extra={
                                                          <MinusCircleOutlined
                                                            onClick={() =>
                                                              !disableMinusCircleOutlined &&
                                                              removeExecTitle(
                                                                execTitleField.name
                                                              )
                                                            }
                                                          />
                                                        }
                                                      >
                                                        <div
                                                          key={
                                                            execTitleField.key
                                                          }
                                                          style={{
                                                            marginBottom: 8,
                                                          }}
                                                        >
                                                          {[
                                                            "title",
                                                            "org_id",
                                                            "org_name",
                                                            "exec_id",
                                                            "rel_level",
                                                            "abs_level",
                                                          ].map((el, i) => {
                                                            return (
                                                              <>
                                                                <Form.Item
                                                                  key={i}
                                                                  name={[
                                                                    execTitleField.name,
                                                                    el,
                                                                  ]}
                                                                  label={el}
                                                                >
                                                                  <Input
                                                                    disabled={
                                                                      true
                                                                    }
                                                                  />
                                                                </Form.Item>
                                                              </>
                                                            );
                                                          })}
                                                        </div>
                                                      </Card>
                                                    )
                                                  )}
                                                  <Button
                                                    type="dashed"
                                                    onClick={() =>
                                                      !disableMinusCircleOutlined &&
                                                      addExecTitle()
                                                    }
                                                    block
                                                    icon={<PlusOutlined />}
                                                  >
                                                    Add Executive Title
                                                  </Button>
                                                </>
                                              )}
                                            </Form.List>

                                            {/* Reportee PRIDs */}
                                            <Card
                                              size="small"
                                              title="Reportee PRIDs"
                                            >
                                              <Form.List
                                                name={[
                                                  execProfileField.name,
                                                  "reportee_prids",
                                                ]}
                                              >
                                                {(
                                                  reporteePridsFields,
                                                  {
                                                    add: addReporteePrid,
                                                    remove: removeReporteePrid,
                                                  }
                                                ) => (
                                                  <>
                                                    {reporteePridsFields.map(
                                                      (reporteePridField) => (
                                                        <Space
                                                          key={
                                                            reporteePridField.key
                                                          }
                                                          style={{
                                                            display: "flex",
                                                            marginBottom: 8,
                                                          }}
                                                          align="baseline"
                                                        >
                                                          <Form.Item
                                                            name={[
                                                              reporteePridField.name,
                                                              "reportee_prids",
                                                            ]}
                                                            fieldKey={[
                                                              reporteePridField.fieldKey,
                                                              "reportee_prids",
                                                            ]}
                                                          >
                                                            <Input
                                                              disabled={true}
                                                              placeholder="Reportee PRID"
                                                              addonAfter={
                                                                <MinusCircleOutlined
                                                                  onClick={() =>
                                                                    !disableMinusCircleOutlined &&
                                                                    removeReporteePrid(
                                                                      reporteePridField.name
                                                                    )
                                                                  }
                                                                />
                                                              }
                                                            />
                                                          </Form.Item>
                                                        </Space>
                                                      )
                                                    )}
                                                    <Button
                                                      type="dashed"
                                                      onClick={() =>
                                                        addReporteePrid()
                                                      }
                                                      block
                                                      icon={<PlusOutlined />}
                                                    >
                                                      Add Reportee PRID
                                                    </Button>
                                                  </>
                                                )}
                                              </Form.List>
                                            </Card>

                                            {/* Reports to PRIDs */}
                                            <Card
                                              size="small"
                                              title="Reports to PRIDs"
                                            >
                                              <Form.List
                                                name={[
                                                  execProfileField.name,
                                                  "reports_to_prids",
                                                ]}
                                              >
                                                {(
                                                  reporteePridsFields,
                                                  {
                                                    add: addReporteePrid,
                                                    remove: removeReporteePrid,
                                                  }
                                                ) => (
                                                  <>
                                                    {reporteePridsFields.map(
                                                      (reporteePridField) => (
                                                        <Space
                                                          key={
                                                            reporteePridField.key
                                                          }
                                                          style={{
                                                            display: "flex",
                                                            marginBottom: 8,
                                                          }}
                                                          align="baseline"
                                                        >
                                                          <Form.Item
                                                            name={[
                                                              reporteePridField.name,
                                                              "reports_to_prids",
                                                            ]}
                                                            fieldKey={[
                                                              reporteePridField.fieldKey,
                                                              "reports_to_prids",
                                                            ]}
                                                          >
                                                            <Input
                                                              disabled={true}
                                                              placeholder="Reports to PRID"
                                                              addonAfter={
                                                                <MinusCircleOutlined
                                                                  onClick={() =>
                                                                    !disableMinusCircleOutlined &&
                                                                    removeReporteePrid(
                                                                      reporteePridField.name
                                                                    )
                                                                  }
                                                                />
                                                              }
                                                            />
                                                          </Form.Item>
                                                        </Space>
                                                      )
                                                    )}
                                                    <Button
                                                      type="dashed"
                                                      onClick={() =>
                                                        !disableMinusCircleOutlined &&
                                                        addReporteePrid()
                                                      }
                                                      block
                                                      icon={<PlusOutlined />}
                                                    >
                                                      Add Reports to PRID
                                                    </Button>
                                                  </>
                                                )}
                                              </Form.List>
                                            </Card>
                                          </div>
                                        </Card>
                                      );
                                    }
                                  )}
                                  <Button
                                    type="dashed"
                                    onClick={() =>
                                      !disableMinusCircleOutlined &&
                                      addExecProfile()
                                    }
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Add Executive Profile
                                  </Button>
                                </>
                              )}
                            </Form.List>
                          </Card>

                          {/* Employee Profile */}
                          <Card size="small" title="Employee Profile">
                            {[
                              "id",
                              "department",
                              "category",
                              "job_title",
                              "cost_center",
                              "level",
                              "age",
                              "radford_number",
                              "radford_level",
                              "updated_at",
                            ].map((el, i) => {
                              return (
                                <Form.Item
                                  key={i}
                                  label={el}
                                  name={[name, "employee_profile", el]}
                                >
                                  <Input disabled={true} />
                                </Form.Item>
                              );
                            })}

                            {/* CSAT */}
                            <Form.List
                              name={[name, "employee_profile", "csat"]}
                            >
                              {(
                                csatFields,
                                { add: addCsat, remove: removeCsat }
                              ) => (
                                <>
                                  {csatFields.map((csatField) => (
                                    <Card
                                      size="small"
                                      title="CSAT"
                                      extra={
                                        <MinusCircleOutlined
                                          onClick={() =>
                                            !disableMinusCircleOutlined &&
                                            removeCsat(csatField.name)
                                          }
                                        />
                                      }
                                    >
                                      <div
                                        key={csatField.key}
                                        // style={{ display: "flex", marginBottom: 8 }}
                                        align="baseline"
                                      >
                                        <Form.Item
                                          name={[csatField.name, "score"]}
                                          fieldKey={[
                                            csatField.fieldKey,
                                            "score",
                                          ]}
                                          label="Score"
                                        >
                                          <InputNumber placeholder="Score" />
                                        </Form.Item>
                                        <Form.Item
                                          name={[csatField.name, "start_date"]}
                                          fieldKey={[
                                            csatField.fieldKey,
                                            "start_date",
                                          ]}
                                          label="Start Date"
                                        >
                                          <Input
                                            disabled={true}
                                            placeholder="Score"
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          name={[csatField.name, "end_date"]}
                                          fieldKey={[
                                            csatField.fieldKey,
                                            "end_date",
                                          ]}
                                          label="End Date"
                                        >
                                          <Input
                                            disabled={true}
                                            placeholder="Score"
                                          />
                                        </Form.Item>
                                      </div>
                                    </Card>
                                  ))}
                                  <Button
                                    type="dashed"
                                    onClick={() =>
                                      !disableMinusCircleOutlined && addCsat()
                                    }
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Add CSAT Score
                                  </Button>
                                </>
                              )}
                            </Form.List>

                            {/* Attainment */}
                            <Form.List
                              name={[name, "employee_profile", "attainment"]}
                            >
                              {(
                                attainmentFields,
                                { add: addAttainment, remove: removeAttainment }
                              ) => (
                                <>
                                  {attainmentFields.map((attainementField) => (
                                    <Card
                                      size="small"
                                      title="Attainment"
                                      extra={
                                        <MinusCircleOutlined
                                          onClick={() =>
                                            !disableMinusCircleOutlined &&
                                            removeAttainment(
                                              attainementField.name
                                            )
                                          }
                                        />
                                      }
                                    >
                                      <div
                                        key={attainementField.key}
                                        // style={{ display: "flex", marginBottom: 8 }}
                                        align="baseline"
                                      >
                                        <Form.Item
                                          name={[
                                            attainementField.name,
                                            "score",
                                          ]}
                                          fieldKey={[
                                            attainementField.fieldKey,
                                            "score",
                                          ]}
                                          label="Score"
                                        >
                                          <InputNumber placeholder="Score" />
                                        </Form.Item>

                                        <Form.Item
                                          name={[
                                            attainementField.name,
                                            "start_date",
                                          ]}
                                          fieldKey={[
                                            attainementField.fieldKey,
                                            "start_date",
                                          ]}
                                          label="Start Date"
                                        >
                                          <Input
                                            disabled={true}
                                            placeholder="Start Date"
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          name={[
                                            attainementField.name,
                                            "end_date",
                                          ]}
                                          fieldKey={[
                                            attainementField.fieldKey,
                                            "end_date",
                                          ]}
                                          label="End Date"
                                        >
                                          <Input
                                            disabled={true}
                                            placeholder="End Date"
                                          />
                                        </Form.Item>
                                      </div>
                                    </Card>
                                  ))}
                                  <Button
                                    type="dashed"
                                    onClick={() =>
                                      !disableMinusCircleOutlined &&
                                      addAttainment()
                                    }
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Add Attainement
                                  </Button>
                                </>
                              )}
                            </Form.List>

                            {/* Survey */}
                            <Form.List
                              name={[name, "employee_profile", "survey"]}
                            >
                              {(
                                surveyFields,
                                { add: addSurvey, remove: removeSurvey }
                              ) => (
                                <>
                                  {surveyFields.map((surveyField) => (
                                    <Card
                                      size="small"
                                      title="Survey"
                                      extra={
                                        <MinusCircleOutlined
                                          onClick={() =>
                                            !disableMinusCircleOutlined &&
                                            removeSurvey(surveyField.name)
                                          }
                                        />
                                      }
                                    >
                                      <div
                                        key={surveyField.key}
                                        // style={{ display: "flex", marginBottom: 8 }}
                                        align="baseline"
                                      >
                                        <Form.Item
                                          name={[surveyField.name, "name"]}
                                          fieldKey={[
                                            surveyField.fieldKey,
                                            "name",
                                          ]}
                                          label="Name"
                                        >
                                          <Input
                                            disabled={true}
                                            placeholder="Name"
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          name={[surveyField.name, "score"]}
                                          fieldKey={[
                                            surveyField.fieldKey,
                                            "score",
                                          ]}
                                          label="Score"
                                        >
                                          <InputNumber placeholder="Score" />
                                        </Form.Item>

                                        <Form.Item
                                          name={[surveyField.name, "date"]}
                                          fieldKey={[
                                            surveyField.fieldKey,
                                            "date",
                                          ]}
                                          label="Date"
                                        >
                                          <Input
                                            disabled={true}
                                            placeholder="Date"
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          name={[surveyField.name, "question"]}
                                          fieldKey={[
                                            surveyField.fieldKey,
                                            "question",
                                          ]}
                                          label="Question"
                                        >
                                          <Input
                                            disabled={true}
                                            placeholder="Question"
                                          />
                                        </Form.Item>
                                      </div>
                                    </Card>
                                  ))}
                                  <Button
                                    type="dashed"
                                    onClick={() =>
                                      !disableMinusCircleOutlined && addSurvey()
                                    }
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Add Survey
                                  </Button>
                                </>
                              )}
                            </Form.List>
                          </Card>

                          {/* Contract Company */}
                          <Card size="small" title="Contract Company">
                            {["company_id", "name", "location"].map((el, i) => {
                              return (
                                <Form.Item
                                  key={i}
                                  label={el}
                                  name={[name, "contract_comp", el]}
                                >
                                  <Input disabled={true} />
                                </Form.Item>
                              );
                            })}
                          </Card>

                          {/* Skills */}
                          <Card size="small" title="Skills">
                            <Form.List name={[name, "skills"]}>
                              {(
                                skillFields,
                                { add: addSkill, remove: removeSkill }
                              ) => (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      // flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      // border: "2px solid red",
                                    }}
                                  >
                                    {skillFields.map((skillField) => (
                                      <div
                                        key={skillField.key}
                                        align="baseline"
                                      >
                                        <Form.Item
                                          name={[skillField.name, "skills"]}
                                          fieldKey={[
                                            skillField.fieldKey,
                                            "skills",
                                          ]}
                                        >
                                          <Input
                                            disabled={true}
                                            placeholder="Skill"
                                            addonAfter={
                                              <MinusCircleOutlined
                                                onClick={() =>
                                                  !disableMinusCircleOutlined &&
                                                  removeSkill(skillField.name)
                                                }
                                              />
                                            }
                                          />
                                        </Form.Item>
                                      </div>
                                    ))}
                                  </div>
                                  <Button
                                    type="dashed"
                                    onClick={() =>
                                      !disableMinusCircleOutlined && addSkill()
                                    }
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Add Skill
                                  </Button>
                                </>
                              )}
                            </Form.List>
                          </Card>

                          {/* Projects */}
                          <Card size="small" title="Projects">
                            <Form.List name={[name, "projects"]}>
                              {(
                                projectFields,
                                { add: addProject, remove: removeProject }
                              ) => (
                                <>
                                  {projectFields.map((projectField) => (
                                    <Card
                                      key={projectField.key}
                                      size="small"
                                      title={`Project ${projectField.key + 1}`}
                                      extra={
                                        <MinusCircleOutlined
                                          onClick={() =>
                                            !disableMinusCircleOutlined &&
                                            removeProject(projectField.name)
                                          }
                                          style={{
                                            color: "red",
                                            marginLeft: "10px",
                                          }}
                                        />
                                      }
                                    >
                                      {/* Other Fields of projects under workexp */}
                                      <Form.Item
                                        name={[projectField.name, "name"]}
                                        fieldKey={[
                                          projectField.fieldKey,
                                          "name",
                                        ]}
                                        label="Project Name"
                                      >
                                        <Input disabled={true} />
                                      </Form.Item>

                                      <Form.Item
                                        name={[
                                          projectField.name,
                                          "project_type",
                                        ]}
                                        fieldKey={[
                                          projectField.fieldKey,
                                          "project_type",
                                        ]}
                                        label="Project Type"
                                      >
                                        <Input disabled={true} />
                                      </Form.Item>

                                      <Form.Item
                                        name={[projectField.name, "url"]}
                                        fieldKey={[
                                          projectField.fieldKey,
                                          "url",
                                        ]}
                                        label="URL"
                                      >
                                        <Input disabled={true} />
                                      </Form.Item>

                                      <Form.Item
                                        name={[
                                          projectField.name,
                                          "description",
                                        ]}
                                        fieldKey={[
                                          projectField.fieldKey,
                                          "description",
                                        ]}
                                        label="Description"
                                      >
                                        <Input.TextArea disabled={true} />
                                      </Form.Item>

                                      {/* Duration */}
                                      <Card size="small" title="Duration">
                                        <Form.Item
                                          label="Start Date"
                                          name={[
                                            projectField.name,
                                            "duration",
                                            "start_date",
                                          ]}
                                        >
                                          <Input disabled={true} />
                                        </Form.Item>
                                        <Form.Item
                                          label="End Date"
                                          name={[
                                            projectField.name,
                                            "duration",
                                            "end_date",
                                          ]}
                                        >
                                          <Input disabled={true} />
                                        </Form.Item>
                                      </Card>

                                      {/* Role and Group */}
                                      <Card size="small" title="Role and Group">
                                        <Form.Item
                                          name={[
                                            projectField.name,
                                            "role_and_group",
                                            "title",
                                          ]}
                                          label="Title"
                                        >
                                          <Input disabled={true} />
                                        </Form.Item>

                                        <Form.Item
                                          name={[
                                            projectField.name,
                                            "role_and_group",
                                            "capacity",
                                          ]}
                                          label="Capacity"
                                        >
                                          <Input disabled={true} />
                                        </Form.Item>

                                        <Form.Item
                                          name={[
                                            projectField.name,
                                            "role_and_group",
                                            "group",
                                          ]}
                                          label="Group"
                                        >
                                          <Input disabled={true} />
                                        </Form.Item>

                                        <Form.Item
                                          name={[
                                            projectField.name,
                                            "role_and_group",
                                            "group_size",
                                          ]}
                                          label="Group Size"
                                        >
                                          <InputNumber />
                                        </Form.Item>
                                      </Card>

                                      {/* Products Used */}
                                      <Card size="small" title="Products Used">
                                        <Form.List
                                          name={[
                                            projectField.name,
                                            "products_used",
                                          ]}
                                        >
                                          {(
                                            productFields,
                                            {
                                              add: addProduct,
                                              remove: removeProduct,
                                            }
                                          ) => (
                                            <>
                                              {productFields.map(
                                                (productField, index) => (
                                                  <Card
                                                    size="small"
                                                    title={`Product Used ${
                                                      index + 1
                                                    }`}
                                                    extra={
                                                      <MinusCircleOutlined
                                                        onClick={() =>
                                                          !disableMinusCircleOutlined &&
                                                          removeProduct(
                                                            productField.name
                                                          )
                                                        }
                                                      />
                                                    }
                                                  >
                                                    <Form.Item
                                                      name={[
                                                        productField.name,
                                                        "source",
                                                      ]}
                                                      fieldKey={[
                                                        productField.fieldKey,
                                                        "source",
                                                      ]}
                                                      label="Source"
                                                    >
                                                      <Input disabled={true} />
                                                    </Form.Item>

                                                    <Form.Item
                                                      name={[
                                                        productField.name,
                                                        "product_name",
                                                      ]}
                                                      fieldKey={[
                                                        productField.fieldKey,
                                                        "product_name",
                                                      ]}
                                                      label="Product Name"
                                                    >
                                                      <Input disabled={true} />
                                                    </Form.Item>

                                                    <Form.Item
                                                      name={[
                                                        productField.name,
                                                        "company_name",
                                                      ]}
                                                      fieldKey={[
                                                        productField.fieldKey,
                                                        "company_name",
                                                      ]}
                                                      label="company Name"
                                                    >
                                                      <Input disabled={true} />
                                                    </Form.Item>

                                                    <Form.Item
                                                      name={[
                                                        productField.name,
                                                        "company_domain",
                                                      ]}
                                                      fieldKey={[
                                                        productField.fieldKey,
                                                        "company_domain",
                                                      ]}
                                                      label="Company Domain"
                                                    >
                                                      <Input disabled={true} />
                                                    </Form.Item>

                                                    {/* Reviews Given */}

                                                    <Card
                                                      size="small"
                                                      title="Reviews Given"
                                                    >
                                                      <Form.List
                                                        name={[
                                                          productField.name,
                                                          "reviews_given",
                                                        ]}
                                                      >
                                                        {(
                                                          reviewFields,
                                                          {
                                                            add: addReview,
                                                            remove:
                                                              removeReview,
                                                          }
                                                        ) => (
                                                          <>
                                                            {reviewFields.map(
                                                              (
                                                                reviewField,
                                                                index
                                                              ) => (
                                                                <Card
                                                                  size="small"
                                                                  title={`Review Given ${
                                                                    index + 1
                                                                  }`}
                                                                  key={index}
                                                                  align="baseline"
                                                                  extra={
                                                                    <MinusCircleOutlined
                                                                      onClick={() =>
                                                                        !disableMinusCircleOutlined &&
                                                                        removeReview(
                                                                          reviewField.name
                                                                        )
                                                                      }
                                                                    />
                                                                  }
                                                                >
                                                                  <Form.Item
                                                                    name={[
                                                                      reviewField.name,
                                                                      "review_type",
                                                                    ]}
                                                                    fieldKey={[
                                                                      reviewField.fieldKey,
                                                                      "review_type",
                                                                    ]}
                                                                    label="Review Type"
                                                                  >
                                                                    <Input
                                                                      disabled={
                                                                        true
                                                                      }
                                                                    />
                                                                  </Form.Item>

                                                                  <Form.Item
                                                                    name={[
                                                                      reviewField.name,
                                                                      "rating",
                                                                    ]}
                                                                    fieldKey={[
                                                                      reviewField.fieldKey,
                                                                      "rating",
                                                                    ]}
                                                                    label="Rating"
                                                                  >
                                                                    <InputNumber />
                                                                  </Form.Item>

                                                                  <Form.Item
                                                                    name={[
                                                                      reviewField.name,
                                                                      "date",
                                                                    ]}
                                                                    fieldKey={[
                                                                      reviewField.fieldKey,
                                                                      "date",
                                                                    ]}
                                                                    label="Date"
                                                                  >
                                                                    <Input
                                                                      disabled={
                                                                        true
                                                                      }
                                                                    />
                                                                  </Form.Item>

                                                                  <Form.Item
                                                                    name={[
                                                                      reviewField.name,
                                                                      "review_desc",
                                                                    ]}
                                                                    fieldKey={[
                                                                      reviewField.fieldKey,
                                                                      "review_desc",
                                                                    ]}
                                                                    label="Review Description"
                                                                  >
                                                                    <Input
                                                                      disabled={
                                                                        true
                                                                      }
                                                                    />
                                                                  </Form.Item>
                                                                </Card>
                                                              )
                                                            )}
                                                            <Button
                                                              type="dashed"
                                                              onClick={() =>
                                                                !disableMinusCircleOutlined &&
                                                                addReview()
                                                              }
                                                              block
                                                              icon={
                                                                <PlusOutlined />
                                                              }
                                                            >
                                                              Add Review
                                                            </Button>
                                                          </>
                                                        )}
                                                      </Form.List>
                                                    </Card>
                                                  </Card>
                                                )
                                              )}
                                              <Button
                                                type="dashed"
                                                onClick={() =>
                                                  !disableMinusCircleOutlined &&
                                                  addProduct()
                                                }
                                                block
                                                icon={<PlusOutlined />}
                                              >
                                                Add Product Used
                                              </Button>
                                            </>
                                          )}
                                        </Form.List>
                                      </Card>
                                    </Card>
                                  ))}
                                  <Button
                                    type="dashed"
                                    onClick={() =>
                                      !disableMinusCircleOutlined &&
                                      addProject()
                                    }
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Add Project
                                  </Button>
                                </>
                              )}
                            </Form.List>
                          </Card>
                        </Card>
                      ))}
                      <Button
                        type="dashed"
                        onClick={() =>
                          !disableMinusCircleOutlined && addWorkExp()
                        }
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Work Experience
                      </Button>
                    </>
                  )}
                </Form.List>
              </Card>
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Ext Refs" key="tab5">
            {/* for ext_refs */}
            <Form.List name="ext_refs">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    rowGap: 16,
                    flexDirection: "column",
                    marginBottom: "1rem",
                    padding: "1rem",
                    borderRadius: "10px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  {fields.map((field) => (
                    <Card
                      size="small"
                      title={`Ext Ref ${field.name + 1}`}
                      key={field.key}
                      extra={
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      }
                    >
                      <>
                        <Form.Item label="Source" name={[field.name, "source"]}>
                          <Select
                            placeholder="Select a source"
                            options={sourceOptions}
                          />
                        </Form.Item>

                        <Form.Item
                          label="Source Ref"
                          name={[field.name, "source_ref"]}
                        >
                          <Input placeholder="Source Ref" />
                        </Form.Item>

                        <Form.Item
                          label="URL"
                          name={[field.name, "url"]}
                          rules={[
                            {
                              validator: (_, value) => {
                                if (!value) {
                                  // If the field is empty, no validation is required
                                  return Promise.resolve();
                                }
                                if (urlPattern.test(value)) {
                                  // If it's a valid URL, resolve the promise
                                  return Promise.resolve();
                                }
                                // If it's not a valid URL, reject the promise with an error message
                                return Promise.reject(
                                  "Please enter a valid URL"
                                );
                              },
                            },
                          ]}
                        >
                          <Input placeholder="URL" />
                        </Form.Item>
                      </>
                    </Card>
                  ))}

                  <Button type="dashed" onClick={() => add()} block>
                    + Add Ext Refs
                  </Button>
                </div>
              )}
            </Form.List>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Compensation History" key="tab10">
            {/* Compensation History */}
            <Card title="Compensation History" size="small">
              <Form.List name="compensation_history">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey }) => (
                      <Card
                        size="small"
                        extra={
                          <MinusCircleOutlined
                            onClick={() =>
                              !disableMinusCircleOutlined && remove(name)
                            }
                          />
                        }
                        title={`Compensation Hisory ${fieldKey + 1}`}
                        key={key}
                        align="baseline"
                      >
                        <Form.Item
                          name={[name, "est_compensation"]}
                          fieldKey={[fieldKey, "est_compensation"]}
                          label="Estimated Compensation"
                        >
                          <Input
                            disabled={true}
                            placeholder="Estimated Compensation"
                          />
                        </Form.Item>
                        <Form.Item
                          name={[name, "date"]}
                          fieldKey={[fieldKey, "date"]}
                          label="Date"
                        >
                          <Input disabled={true} placeholder="Date" />
                        </Form.Item>
                      </Card>
                    ))}
                    <Button
                      type="dashed"
                      onClick={() => !disableMinusCircleOutlined && add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Compensation Entry
                    </Button>
                  </>
                )}
              </Form.List>
            </Card>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Dedup History" key="tab11">
            {/* Dedupe History */}
            <Card title="Dedupe History" size="small">
              <Form.List name="dedupe_history">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey }) => (
                      <Card
                        key={key}
                        size="small"
                        title={`Dedup Hitory ${fieldKey + 1}`}
                        extra={
                          <MinusCircleOutlined
                            onClick={() =>
                              !disableMinusCircleOutlined && remove(name)
                            }
                          />
                        }
                      >
                        <Form.Item
                          name={[name, "dupl_prid"]}
                          fieldKey={[fieldKey, "dupl_prid"]}
                          label="Duplicate PRID"
                        >
                          <Input disabled={true} placeholder="Duplicate PRID" />
                        </Form.Item>
                        <Form.Item
                          name={[name, "dedupe_ts"]}
                          fieldKey={[fieldKey, "dedupe_ts"]}
                          label="Dedupe Timestamp"
                        >
                          <Input
                            disabled={true}
                            placeholder="Dedupe Timestamp"
                          />
                        </Form.Item>
                      </Card>
                    ))}
                    <Button
                      type="dashed"
                      onClick={() => !disableMinusCircleOutlined && add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Dedupe History
                    </Button>
                  </>
                )}
              </Form.List>
            </Card>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Manual Update History" key="tab12">
            {/* Manual Update History */}
            <Card title="Manual Update History" size="small">
              <Form.List name="manual_update_history">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey }) => (
                      <Card
                        extra={
                          <MinusCircleOutlined
                            onClick={() =>
                              !disableMinusCircleOutlined && remove(name)
                            }
                          />
                        }
                        size="small"
                        title={`Manual Update History ${fieldKey + 1}`}
                        key={key}
                        align="baseline"
                      >
                        <Form.Item
                          name={[name, "field"]}
                          fieldKey={[fieldKey, "field"]}
                          label="Field"
                        >
                          <Input disabled={true} placeholder="Field" />
                        </Form.Item>
                        <Form.Item
                          name={[name, "value"]}
                          fieldKey={[fieldKey, "value"]}
                          label="Value"
                        >
                          <Input disabled={true} placeholder="Value" />
                        </Form.Item>
                        <Form.Item
                          name={[name, "updated_at"]}
                          fieldKey={[fieldKey, "updated_at"]}
                          label="Updated At"
                        >
                          <Input disabled={true} />
                        </Form.Item>
                      </Card>
                    ))}
                    <Button
                      type="dashed"
                      onClick={() => !disableMinusCircleOutlined && add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Manual Update
                    </Button>
                  </>
                )}
              </Form.List>
            </Card>
          </Tabs.TabPane>

          {/* <Tabs.TabPane tab="Accolades" key="tab6">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Coming Soon
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Educaiton" key="tab7">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Coming Soon
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Published Work" key="tab8">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Coming Soon
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Additional Info" key="tab9">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Coming Soon
            </div>
          </Tabs.TabPane> */}
        </Tabs>
      </div>
      {/* MODAL */}
      <>
        <Modal
          style={{ marginTop: "14rem" }}
          title="Confirm?"
          visible={isFinalModalOpen}
          onOk={handlePostEditedProfile}
          onCancel={handleFinalModalCancel}
          okText="Yes"
          cancelText="No"
        >
          {isLoading ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>Submitting! Please wait...</span>
              </div>
            </>
          ) : (
            <p>Do you agree to submit?</p>
          )}
        </Modal>
      </>
    </Form>
  );
};
export default PopulatedFormToEdit;
