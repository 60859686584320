// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

.form-save-btn-disabled {
  text-align: center;
  border: none;
  background-color: grey;
  color: white;
  width: 7rem;
  height: 2.5rem;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 4px;
}

.form-save-btn-enabled {
  border: none;
  background-color: #98dca1;
  color: white;
  width: 7rem;
  height: 2.5rem;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 4px;
}

.opt-out-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.opt-out-btn {
  margin-right: 3rem;
}

.site-tag-plus {
  background: #fff;
  border-style: dashed;
}
.edit-tag {
  -webkit-user-select: none;
          user-select: none;
}
.tag-input {
  width: 78px;
  margin-right: 8px;
  vertical-align: top;
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/OptOut/ProfileOptOut/ProfileOptOut.styles.css"],"names":[],"mappings":"AAAA;;;EAGE,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,sBAAsB;EACtB,YAAY;EACZ,WAAW;EACX,cAAc;EACd,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,cAAc;EACd,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,oBAAoB;AACtB;AACA;EACE,yBAAiB;UAAjB,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":["*,\n*::before,\n*::after {\n  box-sizing: border-box;\n  margin: 0;\n}\n\n.form-save-btn-disabled {\n  text-align: center;\n  border: none;\n  background-color: grey;\n  color: white;\n  width: 7rem;\n  height: 2.5rem;\n  padding: 6px 12px;\n  font-size: 1rem;\n  border-radius: 4px;\n}\n\n.form-save-btn-enabled {\n  border: none;\n  background-color: #98dca1;\n  color: white;\n  width: 7rem;\n  height: 2.5rem;\n  padding: 6px 12px;\n  font-size: 1rem;\n  border-radius: 4px;\n}\n\n.opt-out-box {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.opt-out-btn {\n  margin-right: 3rem;\n}\n\n.site-tag-plus {\n  background: #fff;\n  border-style: dashed;\n}\n.edit-tag {\n  user-select: none;\n}\n.tag-input {\n  width: 78px;\n  margin-right: 8px;\n  vertical-align: top;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
