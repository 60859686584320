import React from "react";
import "./leftPane.styles.css";
import { Spin } from "antd";
import dayjs from 'dayjs';
const LeftPaneContainer = ({
  loggedInUserDetails,
  displayUsers,
  displayDetailsOnRightPane,
  selectedLeftPane,
  incrementPage,
  isFullDataLoaded,
  loadingMoreDataSpinner,
  selectedCustomer
}) => {
  if (displayUsers.length) {
    let [companyName, accountId] = selectedCustomer;
    let leftPaneContainers = displayUsers.map((object, index) => {
      return (
        <div key={index} className="left-pane-container">
          <div
            id={index}
            onClick={displayDetailsOnRightPane}
            className={`p-3 d-flex justify-content-between left-pane-container-items ${Number(selectedLeftPane) === Number(index) ? "bgc-when-clicked" : "bgc-when-not-clicked"}`}
          >
            <div id={index}>
              <h6 id={index}>
                <strong id={index}>{object.serialNumber ? String(object.serialNumber) + " -" : null}  {object.name}</strong>
              </h6>
              <p
                id={index}
                className="shortlist-para"
              >
                Created At {dayjs(object.createdAt).format("DD/MM/YYYY")}
              </p>

              <h6 id={index} className="mt-3 fs-6">
                <span className="text-muted">Assigned to </span>: {object.taskAssignedTo}
              </h6>

            </div>
            <div id={index}>
              <h6 id={index}>
                <strong id={index}>{object.companyDetails[1]}</strong>
              </h6>
            </div>
          </div>
          <hr />
        </div>
      );
      // }
    });
    return (
      <div className="left-pane-height">
        {leftPaneContainers}
        <div className="d-flex justify-content-center mt-3">
          {
            isFullDataLoaded
              ?
              <p className="color-blue">End of Content</p>
              :
              loadingMoreDataSpinner
                ?
                <Spin tip="Loading..." />
                :
                <button
                  onClick={() => incrementPage()}
                  className="load-more-button d-flex align-items-baseline mx-4"
                >
                  <i className="fa-solid fa-rotate-right me-1"></i>Load More
                  Candidates
                </button>
          }
        </div>
      </div>
    );
  } else {
    return (<p className="text-dark text-center">Sorry, no data to show for this customer</p>);
  }
};

export default LeftPaneContainer;
