import React, { useEffect, useState } from "react";
// import "./index.css";
import { CloseOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import axios from "axios";
import { PlusOutlined, MinusCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { get, set } from "lodash";
import { Button, Card, Form, DatePicker, Typography, Checkbox, Space, Select, Input } from "antd";

import ArrOfStrUIHandler from "../ArrOfStrUIHandler";
import dayjs from "dayjs";

const AccoladesForm = ({ fieldPath, name, form }) => {
    useEffect(() => {
        const fullPathToEndorsements = [...fieldPath, "accolades", "endorsements"];
        const fullPathToAwards = [...fieldPath, "accolades", "awards"];
        const awards = get(form.getFieldsValue(true), fullPathToAwards);
        const endorsements = get(form.getFieldsValue(true), fullPathToEndorsements);
        const updatedFormValues = form.getFieldsValue(true);
        if (!awards) {
            set(updatedFormValues, fullPathToAwards, []);
        }
        if (!endorsements) {
            set(updatedFormValues, fullPathToEndorsements, []);
        }
        form.setFieldsValue(updatedFormValues);
    }, []);
    return (
        <Card size="small" title={"Accolades"}>
            <Form.List name={[...name, "awards"]}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name }) => (
                            <Card
                                key={key}
                                size="small"
                                title={`Awards ${key + 1}`}
                                extra={<MinusCircleOutlined onClick={() => remove(name)} />}
                            >
                                <Form.Item label={"name"} name={[name, "name"]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={[name, "date"]}
                                    label="Date"
                                    normalize={(value) => (value && !dayjs.isDayjs(value) ? dayjs(value) : value)}
                                >
                                    <DatePicker />
                                </Form.Item>
                                <Form.Item label={"description"} name={[name, "description"]}>
                                    <Input.TextArea />
                                </Form.Item>
                                <Form.Item label={"issuer"} name={[name, "issuer"]}>
                                    <Input />
                                </Form.Item>
                            </Card>
                        ))}
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Awards
                        </Button>
                    </>
                )}
            </Form.List>
            <Form.List name={[...name, "endorsements"]}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name }) => (
                            <Card
                                key={key}
                                size="small"
                                title={`Endorsement ${key + 1}`}
                                extra={<MinusCircleOutlined onClick={() => remove(name)} />}
                            >
                                <Form.Item label={"endorser"} name={[name, "endorser"]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label={"category"} name={[name, "category"]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label={"capacity"} name={[name, "capacity"]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={[name, "date"]}
                                    label="Date"
                                    normalize={(value) => (value && !dayjs.isDayjs(value) ? dayjs(value) : value)}
                                >
                                    <DatePicker />
                                </Form.Item>
                                <Form.Item label={"description"} name={[name, "description"]}>
                                    <Input.TextArea />
                                </Form.Item>
                            </Card>
                        ))}
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Endorsements
                        </Button>
                    </>
                )}
            </Form.List>
        </Card>
    );
};

export default AccoladesForm;
