// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cursor-pointer {
  cursor: pointer;
}

.color-grey {
  color: grey;
}

.filter-scroll {
  overflow-y: scroll;
  height: 8rem;
}

.filter-search {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid grey;
  margin-top: 10px;
  margin-bottom: 5px;
}

.reset-btn {
  background-color: grey;
  color: white;
  border: none;
  border-radius: 2px;
}

.search-btn {
  background-color: #4798FE;
  color: white;
  border: none;
  border-radius: 2px;
}

.input-icons i {
  position: absolute;
}

.icon {
  padding-top: 1rem;
  padding-left: 1.8rem;
  font-size: 0.9rem;
  margin-left: auto;
  color: grey;
}

.input-field {
  width: 100%;
  text-align: center;
}

.dropdown-menu {
  border: none;
  background-color: transparent;
}

.dropdown-content {
  background-color: #fff;
  border: 0.1px rgb(172, 172, 172) solid;
  padding: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/components/WrongEmail/Filter/filter.styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,6BAA6B;EAC7B,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,iBAAiB;EACjB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,6BAA6B;AAC/B;;AAEA;EACE,sBAAsB;EACtB,sCAAsC;EACtC,aAAa;AACf","sourcesContent":[".cursor-pointer {\n  cursor: pointer;\n}\n\n.color-grey {\n  color: grey;\n}\n\n.filter-scroll {\n  overflow-y: scroll;\n  height: 8rem;\n}\n\n.filter-search {\n  border-left: none;\n  border-right: none;\n  border-top: none;\n  border-bottom: 1px solid grey;\n  margin-top: 10px;\n  margin-bottom: 5px;\n}\n\n.reset-btn {\n  background-color: grey;\n  color: white;\n  border: none;\n  border-radius: 2px;\n}\n\n.search-btn {\n  background-color: #4798FE;\n  color: white;\n  border: none;\n  border-radius: 2px;\n}\n\n.input-icons i {\n  position: absolute;\n}\n\n.icon {\n  padding-top: 1rem;\n  padding-left: 1.8rem;\n  font-size: 0.9rem;\n  margin-left: auto;\n  color: grey;\n}\n\n.input-field {\n  width: 100%;\n  text-align: center;\n}\n\n.dropdown-menu {\n  border: none;\n  background-color: transparent;\n}\n\n.dropdown-content {\n  background-color: #fff;\n  border: 0.1px rgb(172, 172, 172) solid;\n  padding: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
