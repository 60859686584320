import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Input, Button } from "antd";
import React, { useEffect, useState } from "react";

const SearchByICP = ({ setICPid, setSearchByICPInUse, getUsersAfterSave }) => {
  const [inputICPId, setInputICPId] = useState("");

  const handleSearchButton = () => {
    localStorage.setItem("icpId", inputICPId);
    setICPid(localStorage.getItem("icpId"));
  };

  const handleClearIcon = () => {
    localStorage.removeItem("icpId");
    setInputICPId("");
    setICPid("");
  };

  useEffect(() => {
    localStorage.getItem("icpId") ? setInputICPId(localStorage.getItem("icpId")) : setInputICPId("");
    localStorage.removeItem("icpId");
  }, []);

  useEffect(() => {
    inputICPId.length ? localStorage.setItem("icpId", inputICPId) : localStorage.setItem("icpId", "");
  }, [getUsersAfterSave]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "8px 0",
      }}>
      <Input
        size="large"
        placeholder="Search by ICP id..."
        value={inputICPId.length ? inputICPId : localStorage.getItem("icpId")}
        style={{
          borderRadius: "4px 0 0 4px",
          height: "2.5rem",
        }}
        suffix={
          inputICPId.length || localStorage.getItem("icpId") ? (
            <Button onClick={handleClearIcon} style={{ border: "0", padding: "0" }}>
              <CloseCircleOutlined style={{ padding: "5px 10px" }} />
            </Button>
          ) : (
            <Button disabled style={{ border: "0", padding: "0" }}>
              <CloseCircleOutlined style={{ padding: "5px 10px" }} />
            </Button>
          )
        }
        onChange={(e) => setInputICPId(e.target.value)}
        onFocus={() => setSearchByICPInUse(true)}
        onBlur={() => setSearchByICPInUse(false)}
      />

      {inputICPId.length ? (
        <Button
          onClick={handleSearchButton}
          style={{
            height: "2.5rem",
            borderRadius: "0 4px 4px 0",
          }}>
          <SearchOutlined
            style={{
              fontSize: "20px",
            }}
          />
        </Button>
      ) : (
        <Button
          disabled
          style={{
            height: "2.5rem",
            borderRadius: "0 4px 4px 0",
          }}>
          <SearchOutlined
            style={{
              fontSize: "20px",
            }}
          />
        </Button>
      )}
    </div>
  );
};
export default SearchByICP;
