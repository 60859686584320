import "./FindemLogo.css";
const FindemLogo = ({ loading, height, width, color, backgroundColor }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={height ? height : "20"}
            width={width ? width : "25"}
            viewBox="0 0 47 46"
            fill="none"
        >
            <path
                d="M10.3871 40.8063C10.3871 43.6747 8.06187 45.9999 5.19355 45.9999C2.32523 45.9999 0 43.6747 0 40.8063C0 37.938 2.32523 35.6128 5.19355 35.6128C8.06187 35.6128 10.3871 37.938 10.3871 40.8063Z"
                fill={color ? color : "#4599FA"}
                className={loading ? "loading1" : ""}
            />
            <path
                d="M0 22.9999C0 20.1316 2.32523 17.8064 5.19355 17.8064H26.957C29.8253 17.8064 32.1505 20.1316 32.1505 22.9999C32.1505 25.8683 29.8253 28.1935 26.957 28.1935H5.19355C2.32523 28.1935 0 25.8683 0 22.9999Z"
                fill={color ? color : "#4599FA"}
                className={loading ? "loading2" : ""}
            />
            <path
                d="M0 5.19355C0 2.32523 2.32523 0 5.19355 0H41.7957C44.664 0 46.9892 2.32523 46.9892 5.19355C46.9892 8.06186 44.664 10.3871 41.7957 10.3871H5.19355C2.32523 10.3871 0 8.06186 0 5.19355Z"
                fill={color ? color : "#4599FA"}
                className={loading ? "loading1" : ""}
            />
        </svg>
    );
};

export default FindemLogo;
