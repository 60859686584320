import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { VariableSizeList } from "react-window";

const LeftPane = ({ handleProfileClick, selectedProfile, filteredAndSortedProfiles }) => {
    const rowHeights = useRef({});
    const listRef = useRef(null);

    const setRowHeight = (index, size) => {
        rowHeights.current[index] = size;
        console.log(listRef);
        listRef.current?.resetAfterIndex(index);
    };

    const getItemSize = (index) => rowHeights.current[index] || 150;

    //style
    const theme = useTheme();
    const heights = theme.custom?.heights;
    const listTopHeight = heights?.header + heights?.navbar + heights?.filterbar;

    const [listHeight, setListHeight] = useState(window.innerHeight - listTopHeight);
    useEffect(() => {
        const updateHeight = () => setListHeight(window.innerHeight - listTopHeight);

        window.addEventListener("resize", updateHeight);
        return () => window.removeEventListener("resize", updateHeight);
    }, [listTopHeight]);
    return (
        <>
            <Box
                sx={(theme) => ({
                    overflowY: "auto",
                    position: "sticky",
                    top: theme.custom.top.level3,
                    height: `calc(100vh - ${listTopHeight}px)`,
                    bgcolor: theme.palette.background.secondary,
                })}
            >
                <VariableSizeList
                    ref={listRef}
                    height={listHeight} // Convert to number
                    itemCount={filteredAndSortedProfiles.length}
                    itemSize={getItemSize}
                    width="100%"
                    itemData={{
                        filteredAndSortedProfiles,
                        selectedProfile,
                        handleProfileClick,
                        setRowHeight,
                    }}
                >
                    {ProfileCard}
                </VariableSizeList>
            </Box>
        </>
    );
};

const ProfileCard = ({ index, style, data }) => {
    const { handleProfileClick, selectedProfile, filteredAndSortedProfiles } = data;
    const item = filteredAndSortedProfiles[index];
    const uniqueId = item?.bkup_ref + item?.click_date;
    const rowRef = useRef(null);

    useEffect(() => {
        if (rowRef.current) {
            const height = rowRef.current.getBoundingClientRect().height;
            data.setRowHeight(index, height);
            console.log("HHe", rowRef);
        }
    }, [data, index]);
    return (
        <Box ref={rowRef} key={uniqueId} style={{ ...style, height: "auto" }}>
            <Box
                onClick={handleProfileClick}
                data-profile-details={JSON.stringify(item)}
                sx={(theme) => ({
                    cursor: "pointer",
                    backgroundColor: selectedProfile?.uniqueId === uniqueId ? theme.palette.action.selected : "transparent",
                    borderRadius: "5px",
                    padding: "0.7rem 1.5rem 0.7rem 1.5rem",
                })}
            >
                <Box width={"100%"} display={"flex"} flexDirection={"column"}>
                    <Stack>
                        <Stack>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        textWrap: "wrap",
                                        wordBreak: "break-word",
                                    }}
                                >
                                    {item?.ref}
                                </Typography>
                            </Box>

                            <Typography fontSize={"0.8rem"} sx={{ opacity: "0.5" }}>
                                {item?.candidate?.title}
                            </Typography>
                        </Stack>
                        <Typography fontSize={"0.9rem"}>Location Changed: {item?.location_changed ? "Yes" : "No"}</Typography>
                        <Typography fontSize={"0.9rem"}>Workexp Changed: {item?.workexp_changed ? "Yes" : "No"}</Typography>

                        <Typography>
                            LinkedIn Url:{" "}
                            <a
                                href={item?.li}
                                rel="noreferrer"
                                style={{
                                    maxWidth: "300px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    display: "inline-block",
                                    verticalAlign: "bottom",
                                }}
                                target="_blank"
                            >
                                {item?.li}
                            </a>
                        </Typography>
                        <Typography fontSize={"0.9rem"}>User ID: {item?.user_id}</Typography>

                        <Typography sx={{ mt: "0.5rem" }} width={"100%"} textAlign="right" color="text.secondary" variant="body2">
                            {dayjs(item.updated_at).format("MMM D, YYYY h:mm A")}{" "}
                        </Typography>
                    </Stack>
                </Box>
            </Box>
            <Divider></Divider>
        </Box>
    );
};

export default LeftPane;
