import React, { useEffect, useState } from "react";
// import "./index.css";
import _ from "lodash";
import { Form, Input, Spin, Modal, Select, DatePicker } from "antd";

import { sources } from "./staticVariables";
import WorkExpForm from "./formComponents/WorkExpForm";
import EducationForm from "./formComponents/EducationForm";
import CandidateForm from "./formComponents/CandidateForm";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { reverseTransformObject, transformObject } from "./utils";
import SkillsForm from "./formComponents/SkillsForm";
import AccoladesForm from "./formComponents/AccoladesForm";
import { Box, Button, Card, CardHeader, Divider, IconButton, Tab, Tabs, Typography } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useTheme } from "@emotion/react";
import AdditionalInfoForm from "./formComponents/AdditionalInfoForm";
import DeleteButtonWithConfirmation from "./formComponents/DeleteButtonWithConfirmation";

const ProfileCreatorForm = ({
    form,
    profileData,
    handlePostCreatedProfile,
    readOnlyView,
    isUpdate,
    isDelete,
    getCurrentFieldPath,
    submitDisabled,
    handleDelete,
}) => {
    const [isFinalModalOpen, setIsFinalModalOpen] = useState(false);
    const [createdProfile, setCreatedProfile] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const [initialFormData, setInitialFormData] = useState();
    const theme = useTheme();
    const [formTab, setFormTab] = useState("tab1");

    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

    const sourceOptions = sources.map((source) => ({
        label: source,
        value: source,
    }));

    const onFinish = (values) => {
        setCreatedProfile(reverseTransformObject(values));
        console.log(reverseTransformObject(values));
        setIsFinalModalOpen(true);
    };

    const handleSubmit = () => {
        form.submit();
    };

    const onConfirm = () => {
        handlePostCreatedProfile(createdProfile);
        setIsFinalModalOpen(false);
    };

    const handleFinalModalCancel = () => {
        setIsFinalModalOpen(false);
    };

    const onReset = () => {
        form.resetFields();
        form.setFieldsValue(initialFormData);
    };

    const handleFormTabChange = (_, newValue) => {
        console.log(newValue);
        setFormTab(newValue);
    };

    useEffect(() => {
        const newInitialFormData = transformObject(profileData);
        form.resetFields();
        setInitialFormData(newInitialFormData);
        form.setFieldsValue(newInitialFormData);
    }, [profileData]);

    //Styles
    const heights = theme?.custom?.heights;
    const tabTop = heights?.header + heights?.navbar + heights?.filterbar + heights?.profileCreatorToolbar;

    return (
        <>
            <Form
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 18,
                }}
                form={form}
                name="dynamic_form_complex"
                style={{
                    width: "100%",
                    margin: "1rem",
                    backgroundColor: theme.palette.background.paper,
                }}
                onFinish={onFinish}
                autoComplete="off"
                scrollToFirstError
                disabled={readOnlyView}
                onFieldsChange={getCurrentFieldPath}
            >
                <Box
                    sx={(theme) => ({
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        bgcolor: "background.paper",
                    })}
                >
                    <Button
                        onClick={onReset}
                        variant="contained"
                        style={{
                            width: "90px",
                        }}
                    >
                        <RestartAltIcon style={{ fontSize: "1rem" }} /> <span>Reset</span>
                    </Button>

                    <h4
                        style={{
                            textAlign: "center",
                            marginTop: "1rem",
                            height: "50px",
                            fontSize: "40px",
                            fontWeight: 600,
                            backgroundImage: "linear-gradient(to left, #553c9a, #b393d3)",
                            color: "transparent",
                            backgroundClip: "text",
                            WebkitBackgroundClip: "text",
                        }}
                    >
                        {readOnlyView ? `Read Only Profile` : isUpdate ? "Update Profile" : "Create Profile"}
                    </h4>

                    <Box display={"flex"} gap={"1rem"}>
                        {isDelete && (
                            <Form.Item>
                                <DeleteButtonWithConfirmation onConfirm={handleDelete} disabled={readOnlyView} />
                            </Form.Item>
                        )}
                        <Form.Item>
                            <Button
                                onClick={handleSubmit}
                                disabled={submitDisabled || readOnlyView}
                                style={{
                                    marginTop: "1.5rem",
                                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                                }}
                            >
                                {isUpdate ? "Update" : "Submit"}
                            </Button>
                        </Form.Item>
                    </Box>
                </Box>

                <div>
                    <Tabs
                        sx={(theme) => ({
                            position: "sticky",
                            top: tabTop,
                            zIndex: theme.zIndex?.profileCreatorToolbar,
                            backgroundColor: "background.default",
                            color: "text.primary",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                        })}
                        value={formTab}
                        onChange={handleFormTabChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        aria-label="full width tabs example"
                        centered
                    >
                        <Tab label="Other Fields" value={"tab1"} />
                        <Tab label="Candidate" value={"tab2"} />
                        <Tab label="Skills" value={"tab3"} />
                        <Tab label="Workexp" value="tab4" />
                        <Tab label="Ext Refs" value="tab5" />
                        <Tab label="Educations" value="tab7" />
                        <Tab label="Accolades" value="tab6" />
                        <Tab label="Additional Info" value="tab9" />
                    </Tabs>
                    <TabPanel value={formTab} index={"tab1"}>
                        <div
                            style={{
                                display: "flex",
                                rowGap: 16,
                                flexDirection: "column",
                                // border: "2px solid red",
                                marginBottom: "1rem",
                                padding: "1rem",
                                borderRadius: "10px",
                                //   boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                            }}
                        >
                            <Form.Item key={"ext_src"} label={"ext_src"} name={["ext_src"]}>
                                <Input disabled />
                            </Form.Item>
                            <Form.Item key={"ext_src_ref"} label={"ext_src_ref"} name={["ext_src_ref"]}>
                                <Input disabled />
                            </Form.Item>
                            <Form.Item key={"created_at"} label={"created_at"} name={["created_at"]}>
                                <DatePicker disabled placeholder={`Select created_at`} />
                            </Form.Item>
                            <Form.Item key={"updated_at"} label={"updated_at"} name={["updated_at"]}>
                                <DatePicker disabled placeholder={`Select updated_at`} />
                            </Form.Item>
                        </div>
                    </TabPanel>

                    <TabPanel value={formTab} index={"tab2"}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                rowGap: 16,
                                marginBottom: "1rem",
                                padding: "1rem",
                                borderRadius: "10px",
                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                        >
                            <CandidateForm form={form} />
                        </div>
                    </TabPanel>

                    <TabPanel value={formTab} index={"tab3"}>
                        {" "}
                        <div
                            style={{
                                display: "flex",
                                // rowGap: 16,
                                flexDirection: "column",
                                marginBottom: "1rem",
                                padding: "1rem",
                                borderRadius: "10px",
                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                        >
                            <SkillsForm fieldPath={["skills"]} name={["skills"]} form={form} />
                        </div>
                    </TabPanel>
                    <TabPanel value={formTab} index={"tab4"}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                rowGap: 16,
                                marginBottom: "1rem",
                                padding: "1rem",
                                borderRadius: "10px",
                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                        >
                            <WorkExpForm form={form} />
                        </div>
                    </TabPanel>
                    <TabPanel value={formTab} index={"tab5"}>
                        {" "}
                        <Form.List name="ext_refs">
                            {(fields, { add, remove }) => (
                                <div
                                    style={{
                                        display: "flex",
                                        rowGap: 16,
                                        flexDirection: "column",
                                        marginBottom: "1rem",
                                        padding: "1rem",
                                        borderRadius: "10px",
                                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                    }}
                                >
                                    {fields.map((field) => (
                                        <Card key={field.key} variant="outlined" sx={{ p: 2, backgroundColor: "profileCreatorForm.main" }}>
                                            <CardHeader
                                                action={
                                                    <IconButton
                                                        onClick={() => {
                                                            remove(field.name);
                                                        }}
                                                        aria-label="settings"
                                                    >
                                                        <RemoveCircleIcon />
                                                    </IconButton>
                                                }
                                                sx={{ p: 0 }}
                                                title={<Typography variant="body1">{`Ext Ref ${field.name + 1}`}</Typography>}
                                            />
                                            <Divider sx={{ mb: 3 }} />

                                            <>
                                                <Form.Item label="Source" name={[field.name, "source"]}>
                                                    <Select placeholder="Select a source" options={sourceOptions} />
                                                </Form.Item>

                                                <Form.Item label="Source Ref" name={[field.name, "source_ref"]}>
                                                    <Input placeholder="Source Ref" />
                                                </Form.Item>

                                                <Form.Item
                                                    label="URL"
                                                    name={[field.name, "url"]}
                                                    rules={[
                                                        {
                                                            validator: (_, value) => {
                                                                if (!value) {
                                                                    // If the field is empty, no validation is required
                                                                    return Promise.resolve();
                                                                }
                                                                if (urlPattern.test(value)) {
                                                                    // If it's a valid URL, resolve the promise
                                                                    return Promise.resolve();
                                                                }
                                                                // If it's not a valid URL, reject the promise with an error message
                                                                return Promise.reject("Please enter a valid URL");
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="URL" />
                                                </Form.Item>
                                            </>
                                        </Card>
                                    ))}

                                    <Button onClick={() => add()}>+ Add Ext Refs</Button>
                                </div>
                            )}
                        </Form.List>
                    </TabPanel>
                    <TabPanel value={formTab} index={"tab7"}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                rowGap: 16,
                                marginBottom: "1rem",
                                padding: "1rem",
                                borderRadius: "10px",
                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                        >
                            <EducationForm form={form} />
                        </div>
                    </TabPanel>
                    <TabPanel value={formTab} index={"tab6"}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                rowGap: 16,
                                marginBottom: "1rem",
                                padding: "1rem",
                                borderRadius: "10px",
                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                        >
                            <AccoladesForm fieldPath={[]} name={["accolades"]} form={form} />
                        </div>
                    </TabPanel>
                    <TabPanel value={formTab} index={"tab9"}>
                        {" "}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                rowGap: 16,
                                marginBottom: "1rem",
                                padding: "1rem",
                                borderRadius: "10px",
                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                        >
                            <AdditionalInfoForm fieldPath={["additional_info"]} name={["additional_info"]} form={form} />
                        </div>
                    </TabPanel>
                </div>
                {/* MODAL */}
                <>
                    <Modal
                        style={{ marginTop: "14rem" }}
                        title="Confirm?"
                        open={isFinalModalOpen}
                        onOk={onConfirm}
                        onCancel={handleFinalModalCancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        {isCreating ? (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Spin />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <span>Submitting! Please wait...</span>
                                </div>
                            </>
                        ) : (
                            <p>Do you agree to submit?</p>
                        )}
                    </Modal>
                </>
            </Form>
        </>
    );
};

const TabPanel = ({ children, value, index }) => {
    return (
        <div role="tabpanel" hidden={value !== index}>
            <Box p={2}>{children}</Box>
        </div>
    );
};
export default ProfileCreatorForm;
