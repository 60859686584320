import { Box, Paper } from "@mui/material";
import Role from "./components/Role";
import InputCard from "./components/InputCard";
import { useContext, useEffect, useState } from "react";
import { message } from "antd";
import axios from "axios";
import { url } from "../../../url";
import SelectInputCard from "./components/SelectInputCard";
import { DRA_MODULES, usePermission } from "../../../utils/rbacUtils";
import { useNavigate, useOutletContext } from "react-router-dom";
import UserContext from "../../../UserContext";

const Roles = () => {
    const loggedInUserDetails = useContext(UserContext);
    const [rolesData, setRolesData] = useState({ data: [], isLoading: true });
    const navigate = useNavigate();
    const headerRef = useOutletContext();
    const hasPermission = usePermission(DRA_MODULES.roles);

    const fetchRoles = async () => {
        try {
            const response = await axios.get(url.getRoles);
            setRolesData({ data: response?.data?.data, isLoading: false });
        } catch (error) {
            setRolesData({ ...rolesData, isLoading: false });
            console.log("Failed to fetch roles:", error);
            if (error?.response?.status) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Failed to get roles!");
        }
    };
    const addRole = async (roleName) => {
        try {
            const response = await axios.post(url.crudRole, { roleName: roleName });
            setRolesData({ data: [...rolesData?.data, response?.data?.data], isLoading: false });
        } catch (error) {
            console.log("Failed to add role:", error);
            if (error?.response?.status) {
                message.error(error?.response?.data?.message);
                return;
            }
            message.error("Failed to add new role!");
        }
    };
    useEffect(() => {
        if (!hasPermission.read) {
            navigate("/");
        }
        fetchRoles();
    }, []);
    return (
        <>
            <Paper
                elevation={0}
                sx={{ padding: "3rem", height: `calc(100vh - ${headerRef?.current?.getBoundingClientRect().bottom}px)`, color: "#172B4D" }}
            >
                <Box sx={{ display: "flex", gap: "1rem", alignItems: "flex-start", overflowY: "auto", width: "100%", height: "100%" }}>
                    {rolesData?.data?.map((role) => {
                        return <Role key={role._id} role={role} rolesData={rolesData} setRolesData={setRolesData} />;
                    })}
                    {hasPermission.write && <InputCard label={"Add Role"} inputLabel={"Role Name"} onSubmit={addRole} />}
                </Box>
            </Paper>
        </>
    );
};

export default Roles;
