import { Modal, Typography } from "antd";
import React from "react";
import ReactJson from "@microlink/react-json-view";
const { Title } = Typography;

const TimeLineModal = ({ isModalOpen, handleClose, candidateDetails }) => {
  // console.log(candidateDetails);
  return (
    <>
      <Modal title="Title" visible={isModalOpen} onCancel={handleClose} width={800} okButtonProps={{ style: { display: "none" } }} cancelText="Close">
        <ReactJson src={candidateDetails} name={false} displayDataTypes={false} />
      </Modal>
    </>
  );
};
export default TimeLineModal;
