import TabsHomePage from "../../Home/TabsHomePage";

const { Result } = require("antd");
const { default: HeaderComponent } = require("../../Header/Header");
const { Button } = require("@mui/material");

const UnauthorizedPage = ({ loggedInUserDetails, logoutUser, nestedRoute }) => {
    return (
        <>
            {!nestedRoute && (
                <>
                    <HeaderComponent loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser} />
                    <TabsHomePage />
                </>
            )}
            <Result status="403" title="403" subTitle="Sorry, you are not authorized to access this page." />
        </>
    );
};

export default UnauthorizedPage;
