import { DataGrid } from "@mui/x-data-grid";
import "./StatusTable.css";
import { useEffect, useState } from "react";
import { message } from "antd";
import axios from "axios";
import { url } from "../../url";
import dayjs from "dayjs";
import NoResultsTableOverlay from "./NoResultTableOverlay";
import { Alert, Backdrop, Box, Divider, FormControlLabel, FormLabel, IconButton, Paper, Radio, RadioGroup, Tooltip } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { PLATFORM_SEARCH_URL } from "../../util";
import FindemLogo from "../utils/FindemLogo/FindemLogo";
import LaunchIcon from "@mui/icons-material/Launch";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

let columns = [
    {
        field: "icpData",
        headerName: "ICP ID",
        minWidth: 300,
        sortable: false,
        flex: 1,
    },
    { field: "company", headerName: "Company", minWidth: 300, sortable: true, flex: 1 },
    { field: "total_profiles", headerName: "Total Profiles", minWidth: 200, flex: 3, filterable: false },
    { field: "verified", headerName: "Verified Profiles", minWidth: 200, flex: 3, filterable: false },
    { field: "unverified", headerName: "Unverified Profiles", minWidth: 200, flex: 3, filterable: false },
    {
        field: "created_at",
        headerName: "Created At",
        minWidth: 250,
        flex: 2,
        filterable: false,
        valueFormatter: (value) => dayjs(value).format("MMMM D, YYYY h:mm A"),
    },
    {
        field: "updated_at",
        headerName: "Updated At",
        minWidth: 250,
        flex: 2,
        filterable: false,
        valueFormatter: (value) => dayjs(value).format("MMMM D, YYYY h:mm A"),
    },
];

const StatusTable = ({ copilotIcpData, dateRange, handleDateChange, icpType, handleIcpTypeChange, icpDataRows, setIcpDataRows }) => {
    const [backDropOpen, setBackDropOpen] = useState(false);
    const [companyNames, setCompanyNames] = useState({});

    const renderIcpIdColumn = (params) => {
        return (
            <Box display={"flex"} justifyContent={"space-between"}>
                <p>{params?.value?.icpId} </p>
                <Tooltip title="Open Search">
                    <a
                        href={`${PLATFORM_SEARCH_URL}/${params?.value?.icpId}?joid=${params.value?.ownerId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <IconButton data-icp-id={params.value}>
                            <LaunchIcon />
                        </IconButton>
                    </a>
                </Tooltip>
            </Box>
        );
    };

    const fetchIcpDataRows = async () => {
        try {
            setIcpDataRows({ isLoading: true, data: [] });
            if (copilotIcpData.icp_ids?.length == 0) {
                setIcpDataRows({ isLoading: false, data: [] });
                return;
            }
            const response = await axios.post(url.getCopilotIcpStats, {
                icp_ids: copilotIcpData?.icp_ids,
            });
            if (!response?.data?.icp_ids || response?.data?.icp_ids?.length == 0) {
                setIcpDataRows({ isLoading: false, data: [] });
                return;
            }
            let companyNamesObj = companyNames;
            if (Object.keys(companyNames).length == 0) {
                companyNamesObj = await getCompanyNames();
            }
            const rows = Object.entries(response.data.icp_ids).map((ele) => {
                return {
                    ...ele[1],
                    id: ele[0],
                    icpData: { icpId: ele[0], ownerId: copilotIcpData?.owners?.[ele[0]] },
                    company: companyNamesObj[copilotIcpData?.owners?.[ele[0]]] || "Not Found",
                };
            });
            setIcpDataRows({ isLoading: false, data: rows });
        } catch (error) {
            console.log("Failed to get status of ICP IDs:", error);
            message.error("Failed to get profiles status!");
        }
    };

    const getCompanyNames = async () => {
        try {
            const response = await axios.get(url.customersAPI);
            let companyNamesObj = {};
            for (let companyObj of Object.values(response?.data?.response)) {
                companyObj?.users.forEach((user) => {
                    companyNamesObj[user] = companyObj?.company_name;
                });
            }
            setCompanyNames(companyNamesObj);
            return companyNamesObj;
        } catch (error) {
            console.log("Failed to get company names:", error);
            message.error("Failed to get company names.");
        }
    };

    useEffect(() => {
        fetchIcpDataRows();
    }, [copilotIcpData]);

    // For rendering custom cell for icp_id column
    columns[0].renderCell = renderIcpIdColumn;

    return (
        <Paper elevation={3} sx={{ width: "100%", marginTop: "0.7rem", height: "80vh" }}>
            <DataGrid
                sx={{
                    padding: "0rem 2rem",
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                    },
                }}
                pageSizeOptions={[10, 25, 100]}
                autoPageSize={false}
                initialState={{
                    pagination: { paginationModel: { page: 0, pageSize: 10 } },
                }}
                columns={columns}
                rows={icpDataRows.data}
                loading={icpDataRows.isLoading}
                slotProps={{
                    loadingOverlay: {
                        variant: "skeleton",
                        noRowsVariant: "skeleton",
                    },
                    toolbar: {
                        dateRange: dateRange,
                        handleDateChange: handleDateChange,
                        icpType: icpType,
                        handleIcpTypeChange: handleIcpTypeChange,
                    },
                }}
                slots={{
                    noRowsOverlay: NoResultsTableOverlay,
                    toolbar: TableToolBar,
                }}
            ></DataGrid>
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backDropOpen}>
                <FindemLogo color={"white"} loading={true} width={40} height={40}></FindemLogo>{" "}
            </Backdrop>
        </Paper>
    );
};

const TableToolBar = ({ dateRange, handleDateChange, icpType, handleIcpTypeChange }) => {
    return (
        <>
            <Box
                sx={{
                    margin: "10px 0",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Box sx={{ mr: "1rem" }}>
                    <FormLabel sx={{ mb: "0.6rem", fontWeight: "bold", color: "#1976D2" }} id="dateRange">
                        Date Range
                    </FormLabel>
                    <Box display="flex" alignItems="center" flexDirection="column" width={"100%"}>
                        <Box display="flex" mb={2} justifyContent={"space-between"} alignItems={"center"}>
                            <DateTimePicker
                                format="DD/MM/YYYY hh:mm A"
                                sx={{ width: "45%" }}
                                label="Start Date"
                                value={dateRange.startDate}
                                onChange={handleDateChange("startDate")}
                                disableFuture
                                closeOnSelect={true}
                                viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                }}
                            />
                            <Divider orientation="vertical" sx={{ margin: "0 5px", backgroundColor: "black", width: "15px" }} />
                            <DateTimePicker
                                format="DD/MM/YYYY hh:mm A"
                                sx={{ width: "45%" }}
                                label="End Date"
                                value={dateRange.endDate}
                                onChange={handleDateChange("endDate")}
                                disableFuture
                                viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
                <Divider orientation="vertical" variant="middle" flexItem sx={{ height: "auto !important" }}></Divider>
                <Box sx={{ ml: "1rem" }}>
                    <FormLabel sx={{ fontWeight: "bold", color: "#1976D2" }} id="icpType">
                        ICP Type
                    </FormLabel>
                    <RadioGroup
                        sx={{
                            "& .MuiRadioGroup-root	": {
                                gap: "0rem",
                            },
                        }}
                        value={icpType}
                        onChange={handleIcpTypeChange}
                    >
                        <FormControlLabel value="preprocessing" control={<Radio />} label="Preprocessing" />
                        <FormControlLabel value="ondemand" control={<Radio />} label="On Demand" />
                    </RadioGroup>
                </Box>
            </Box>
            <Divider></Divider>
        </>
    );
};
export default StatusTable;
