import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, CircularProgress, TextField } from "@mui/material";
import { useEffect } from "react";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PaginatedAutocomplete = ({ onChange, onInputChange, input, options, selectedOptions, hasMore, next, isLoading, label }) => {
    const groupedOptions = [
        ...selectedOptions,
        ...(options || []).filter((option) => !selectedOptions.some((selected) => selected.value === option.value)),
    ];

    const handleScroll = (event) => {
        if (hasMore) {
            const listboxNode = event.currentTarget;
            if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 50) {
                next(false, input);
            }
        }
    };

    useEffect(() => {
        next(false, input);
    }, []);

    return (
        <Autocomplete
            multiple
            onChange={onChange}
            options={groupedOptions}
            getOptionLabel={(option) => option?.label || ""}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderTags={(value, getTagProps) => {
                return [`${value.length} selected`];
            }}
            onInputChange={onInputChange}
            ListboxProps={{
                onScroll: handleScroll,
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {isLoading ? <CircularProgress size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                    <li key={key} {...optionProps}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.label}
                    </li>
                );
            }}
        />
    );
};

export default PaginatedAutocomplete;
