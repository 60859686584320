import { Outlet, useLocation, useNavigate } from "react-router-dom";
import HeaderComponent from "../Header/Header";
import Header from "./components/Header";
import { useEffect, useRef } from "react";

const UserDashboard = ({ loggedInUserDetails, logoutUser }) => {
    const headerRef = useRef(null);
    const {pathname, state} = useLocation()

    

    return (
        <>
            <HeaderComponent loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser} />
            <Header headerRef={headerRef} />
            <Outlet context={headerRef} />
        </>
    );
};

export default UserDashboard;
