import {
  Link,
  Stack,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip,
} from "@mui/material";
import { decodeString } from "../../../util";
import dayjs from "dayjs";
import GenericCreatable from "../GenericCreatable";
import validator from "validator";
import GenericModal from "../GenericModal";
import { useMemo, useState } from "react";
import { url } from "../../../url";
import axios from "axios";
import VerifiedIcon from "@mui/icons-material/Verified";
import GenericCheckboxList from "./GenericCheckboxList";
import TextField from "@mui/material/TextField";
import ConfirmContactInfoModal from "./ConfirmContactInfoModal";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";

const createOption = (label) => ({
  label,
  value: label,
});

const emailsFilter = (email) => {
  return email !== undefined && email !== null && !!email?.trim?.length;
}

const RightPane = ({
  data,
  status,
  handleStatusChange,
  inputEmail,
  setInputEmail,
  addedEmails,
  setAddedEmails,
  showInvalidEmailError,
  setShowInvalidEmailError,
  inputPhoneNumber,
  setInputPhoneNumber,
  addedPhoneNumbers,
  setAddedPhoneNumbers,
  showInvalidPhoneNumberError,
  setShowInvalidPhoneNumberError,
  contactInfoData,
  handleAddInputEmail,
  handleEmailDisplayChange,
  emailsToDisplay,
  directEmailsFromATS,
  emailInputError,
  handleAddInputPhoneNumber,
  phoneInputError,
  phoneNumbersToDisplay,
  handlePhoneNumberDisplayChange,
  handleOnChangeEmailInput,
  handleSaveContactInfo,
  handleOnChangeEmailPhoneNumberInput,
  showConfirmationContactInfoModal,
  handleCloseConfirmContactInfoModal,
  handleShowConfirmContactInfoModal,
  showUnsavedContactInfoWarning,
  removedEmailsToDisplay,
}) => {
  // filtering nulls/undefined/trimming length 0 from emails
  const filteredRemovedEmailsToDisplay = useMemo(() => {
    let filteredRemovedEmailsToDisplay = {};

    for (let key in removedEmailsToDisplay) {
      const data = removedEmailsToDisplay[key];
      if (key !== null && key !== undefined && !!key?.trim()?.length) {
        filteredRemovedEmailsToDisplay[key] = data;
      }
    }

    return filteredRemovedEmailsToDisplay;

  }, [removedEmailsToDisplay]);

  const filteredDirectEmailsFromAts = useMemo(() => {
    let filteredDirectEmailsFromAts = {};

    for (let key in directEmailsFromATS) {
      const data = directEmailsFromATS[key];

      if (key !== null && key !== undefined && !!key?.trim()?.length) {
        filteredDirectEmailsFromAts[key] = data;
      }
    }

    return filteredDirectEmailsFromAts;
  }, [directEmailsFromATS]);

  const filteredEmailsToDisplay = useMemo(() => {
    let filteredEmailsToDisplay = {};

    for (let key in emailsToDisplay) {
      const data = emailsToDisplay[key];

      if (key !== null && key !== undefined && !!key?.trim()?.length) {
        filteredEmailsToDisplay[key] = data;
      }
    }

    return filteredEmailsToDisplay;
  }, [emailsToDisplay]);


  // email methods for creatable select
  const handleKeyDownCreatableEmail = (event) => {
    if (!inputEmail) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (!validator.isEmail(inputEmail)) {
          setShowInvalidEmailError(true);
        } else {
          setShowInvalidEmailError(false);
          setAddedEmails((prev) => [...prev, createOption(inputEmail)]);
          setInputEmail("");
        }

        event.preventDefault();
    }
  };

  const handleOnChangeCreatableEmail = (event) => {
    setAddedEmails(event);
  };

  const handleInputChangeCreatableEmail = (event) => {
    // this line may cause problems
    setShowInvalidEmailError(false);
    setInputEmail(event);
  };

  // end
  // phone methods for creatable select
  const handleKeyDownCreatablePhone = (event) => {
    // validator.isMobilePhone(newPhone, false, { strictMode: true })
    if (!inputPhoneNumber) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (
          !validator.isMobilePhone(inputPhoneNumber, false, {
            strictMode: true,
          })
        ) {
          setShowInvalidPhoneNumberError(true);
        } else {
          setShowInvalidPhoneNumberError(false);
          setAddedPhoneNumbers((prev) => [
            ...prev,
            createOption(inputPhoneNumber),
          ]);
          setInputPhoneNumber("");
        }

        event.preventDefault();
    }
  };
  const handleOnChangeCreatablePhone = (event) => {
    setAddedPhoneNumbers(event);
  };
  const handleInputChangeCreatablePhone = (event) => {
    // this line may cause problems
    setShowInvalidPhoneNumberError(false);
    setInputPhoneNumber(event);
  };
  // end

  // no need to memoize since this is a leaf node
  const showRefreshedRecently = data.updated_at
    ? dayjs(data.updated_at).isAfter(dayjs().subtract("1", "week"))
    : false;

  // consolidate all linkedins here
  const allLinkedins = useMemo(() => {
    const allLinkedins = new Set();
    
    for (let linkedin of contactInfoData["linkedins"]) {
      allLinkedins.add(linkedin);
    }

    if (contactInfoData["ats_info"]) {
      for (let atsObj of contactInfoData["ats_info"]) {
        if (Array.isArray(atsObj?.["linkedin_ids"])) {
          for (let linkedin of atsObj["linkedin_ids"]) {
            let prefix = "https://linkedin.com/in/";
            if (!linkedin?.includes(prefix)) {
              allLinkedins.add(prefix + linkedin);
              continue;
            }
            allLinkedins.add(linkedin);
          }
        }
      }
    }

    return Array.from(allLinkedins);
  }, [contactInfoData]);

  return (
    <div style={{ padding: "20px 40px" }}>
      {console.log("added emails: ", addedEmails)}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Stack spacing={2}>
          <Typography variant="h6" component="div" gutterBottom>
            <Link href={`${data.icp_search_link}`} target="_blank">
              {decodeString(data.icp_name)}
            </Link>
          </Typography>
          <div style={{ display: "flex", gap: "2rem" }}>
            <div>
              <Avatar
                alt={decodeString(data.full_name)}
                // TODO: Change this with data from api

                src={
                  data.linkedin_uid
                    ? `https://fm-profile-imgs.s3.us-east-2.amazonaws.com/${data["linkedin_uid"]}.jpg`
                    : null
                }
                sx={{ width: 100, height: 100 }}
              />
            </div>
            <div>
              <Typography variant="h6" component="div" gutterBottom>
                {"Name: "}
                {data.full_name
                  ? decodeString(data.full_name)
                  : "Not Available"}
                {showRefreshedRecently && (
                  <Tooltip title="Refreshed within last 7 days">
                    <VerifiedIcon
                      sx={{ ml: 1 }}
                      color="success"
                      fontSize="large"
                    />
                  </Tooltip>
                )}
              </Typography>
              <Typography variant="h6" component="div" gutterBottom>
                {"Channel Source: "}
                {data.channel}
              </Typography>

              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Profile Type:{" "}
                {data.sandbox_name ? "Sandbox Profile" : "Public Profile"}
              </Typography>
            </div>
          </div>
        </Stack>
        <Stack spacing={2}>
          <Typography variant="h6">
            Customer Name: {decodeString(data.customer_name)}
          </Typography>
          <Typography variant="h6" component="div" gutterBottom>
            <Link href={`${data.ep_link}`} target="_blank">
              {" "}
              Findem Enriched Profile Link
            </Link>
          </Typography>
          {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Request Date:{" "}
            {dayjs(data.verification_state_ts).format("DD/MM/YYYY")}
          </Typography> */}
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            ICP Created at: {dayjs(data["icp_created_at"]).format("DD/MM/YYYY")}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            ICP Updated at: {dayjs(data["icp_updated_at"]).format("DD/MM/YYYY")}
          </Typography>
        </Stack>
      </div>
      <Divider sx={{ mb: 3 }} />
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="h6">Existing LinkedIn</Typography>
          <Stack>
            <ul>
              {!!allLinkedins?.length ? (
                allLinkedins.map((linkedin) => {
                  return (
                    <li>
                      <Typography variant="body1" key={linkedin}>
                        <Link href={linkedin} target="_blank">
                          {linkedin}
                        </Link>
                      </Typography>
                    </li>
                  );
                })
              ) : (
                <Typography variant="body1">No Linkedin Found</Typography>
              )}
            </ul>
          </Stack>
        </div>
        <div>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            Actions
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "16px",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div style={{ marginBottom: "7px" }}>
            <Typography variant="h6">Removed Emails</Typography>
            {/*
                Display logic is import for showing emails
                if sandbox_name then show emails from sandbox only
                if public then show emails from public only
                */}
            <Stack>
              {Object.keys(filteredRemovedEmailsToDisplay).length > 0 ? (
                <>
                  <GenericCheckboxList
                    data={Object.keys(filteredRemovedEmailsToDisplay)}
                    handleChange={() => {}}
                    dataObject={filteredRemovedEmailsToDisplay}
                  />
                </>
              ) : (
                <Typography>No removed emails to display</Typography>
              )}
            </Stack>
          </div>
          <div style={{ marginBottom: "7px" }}>
            <Typography variant="h6">Direct Emails from ats</Typography>
            <Stack>
              {Object.keys(filteredDirectEmailsFromAts).length > 0 ? (
                <>
                  <GenericCheckboxList
                    data={Object.keys(filteredDirectEmailsFromAts)}
                    handleChange={() => {}}
                    dataObject={filteredDirectEmailsFromAts}
                  />
                </>
              ) : (
                <Typography>No direct emails from ats to display</Typography>
              )}
            </Stack>
          </div>
          <div style={{ marginBottom: "7px" }}>
            <Typography variant="h6">Existing Emails</Typography>
            {/*
                Display logic is import for showing emails
                if sandbox_name then show emails from sandbox only
                if public then show emails from public only
                */}
            <Stack>
              {Object.keys(filteredEmailsToDisplay).length > 0 ? (
                <>
                  <GenericCheckboxList
                    data={Object.keys(filteredEmailsToDisplay)}
                    handleChange={handleEmailDisplayChange}
                    dataObject={filteredEmailsToDisplay}
                  />
                </>
              ) : (
                <Typography>No Existing emails to display</Typography>
              )}
            </Stack>
            <div>
              {/* <Typography variant="h5" sx={{ mb: 1 }}>
              Add Emails
            </Typography> */}
              <Stack spacing={1} sx={{ width: 500 }}>
                <TextField
                  id="add-email-copilot-searches"
                  label="Add Email"
                  value={inputEmail}
                  onChange={handleOnChangeEmailInput}
                  helperText="Correct format e.g. johdoe@gmail.com"
                  error={emailInputError}
                />
                <Button
                  onClick={handleAddInputEmail}
                  variant="contained"
                  sx={{ width: 200 }}
                >
                  Add Email
                </Button>
              </Stack>

              {/* <GenericCreatable
              id={"email-creatable"}
              inputValue={inputEmail}
              setInputValue={setInputEmail}
              value={addedEmails}
              setValue={setAddedEmails}
              showError={showInvalidEmailError}
              setShowError={setShowInvalidEmailError}
              handleKeyDown={handleKeyDownCreatableEmail}
              handleOnChange={handleOnChangeCreatableEmail}
              handleInputChange={handleInputChangeCreatableEmail}
              placeholder={"Add email and press enter..."}
              errorMessage={
                "Invalid Email, correct format e.g.: john@gmail.com"
              }
            /> */}
            </div>
          </div>
          <div>
            <Typography variant="h6">Existing Phone Numbers</Typography>
            {/*
                Display logic is import for showing emails
                if sandbox_name then show emails from sandbox only
                if public then show emails from public only
                */}
            <Stack>
              {Object.keys(phoneNumbersToDisplay).length > 0 ? (
                <GenericCheckboxList
                  data={Object.keys(phoneNumbersToDisplay)}
                  handleChange={handlePhoneNumberDisplayChange}
                  dataObject={phoneNumbersToDisplay}
                />
              ) : (
                <Typography>No Existing Phone Numbers to display</Typography>
              )}
            </Stack>
          </div>

          {
            //TODO: support for phone number
          }
          <div>
            {/* <Typography variant="h5">Add Phone Number</Typography>
            <GenericCreatable
              id={"phone-number-creatable"}
              inputValue={inputPhoneNumber}
              setInputValue={setInputPhoneNumber}
              value={addedPhoneNumbers}
              setValue={setAddedPhoneNumbers}
              showError={showInvalidPhoneNumberError}
              setShowError={setShowInvalidPhoneNumberError}
              handleKeyDown={handleKeyDownCreatablePhone}
              handleOnChange={handleOnChangeCreatablePhone}
              handleInputChange={handleInputChangeCreatablePhone}
              placeholder={"Add phone number and press enter..."}
              errorMessage={
                "Invalid Phone Number, correct format e.g.: +916212344789"
              }
            /> */}
            <Stack spacing={1} sx={{ width: 500 }}>
              <TextField
                id="add-phone-number-copilot-searches"
                label="Add phone number"
                value={inputPhoneNumber}
                onChange={handleOnChangeEmailPhoneNumberInput}
                helperText="Correct format e.g. +916212344789"
                error={phoneInputError}
              />
              <Button
                onClick={handleAddInputPhoneNumber}
                variant="contained"
                sx={{ width: 200 }}
              >
                Add Phone Number
              </Button>
            </Stack>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "flex-start",
            gap: "100px",
          }}
        >
          {/* <Typography variant="h5">Actions</Typography> */}
          <div style={{ width: "200px" }}>
            <FormControl fullWidth>
              <InputLabel id="status-select">Save Status</InputLabel>
              <Select
                labelId="status-select"
                id="simple-select"
                value={status}
                label="Select Status"
                onChange={handleStatusChange}
                error={showUnsavedContactInfoWarning}
              >
                {/* {row.status.map((x) => {
                return (
                    <MenuItem value={x.value} key={x.value}>
                    {x.label}
                    </MenuItem>
                    );
                })} */}
                <MenuItem value={"verified"}>Verified</MenuItem>
                {/* <MenuItem value={"rejected"}>Rejected</MenuItem> */}
                <MenuItem value={"could_not_verify"}>Could Not Verify</MenuItem>
              </Select>
            </FormControl>
            {showUnsavedContactInfoWarning && (
              <Alert severity="warning" sx={{ mt: 1 }}>
                You have unsaved contact info changes. Click on the button below
                if you want to save your changes.
              </Alert>
            )}
          </div>

          <Button
            variant="contained"
            disabled={emailInputError || phoneInputError}
            onClick={handleShowConfirmContactInfoModal}
            color="secondary"
          >
            Save Contact Info
          </Button>
        </div>
      </div>
      <ConfirmContactInfoModal
        id={"generic-rt-copilot-right-pane-save-contact-info-modal"}
        open={showConfirmationContactInfoModal}
        handleClose={handleCloseConfirmContactInfoModal}
        handleConfirm={handleSaveContactInfo}
        data={data}
      />
    </div>
  );
};
export default RightPane;
