import dayjs from "dayjs";
export const testProfile = {
    created_at: dayjs(),
    updated_at: dayjs(),
    last_ingested_at: dayjs(),
    int_updated_at: dayjs(),
    candidate: {
        full_name: "Prateek Agnihotri",
        sname: "",
        location_raw: "Bengaluru, Karnataka, India",
        gender: "male",
        ethnicity: ["indiansubcontinent", "black"],
    },
    skills: ["Ludo Master"],
    workexp: [
        {
            company: {
                name: "Fortis Financial Group",
                company_id: "5e861573e32eb77541ab7f4d",
                lnkd_company_id: "fortisfinancialgroup",
                location: "Bellevue, WA",
            },
            duration: {
                start_date: dayjs(),
                end_date: null,
                to_present: true,
            },
            projects: [
                {
                    role_and_group: {
                        title: "CEO & Founder",
                    },
                },
            ],
        },
    ],
    ext_refs: [
        {
            source: "LinkedIn",
            source_ref: "mikeboroughs",
            url: "https://www.linkedin.com/in/mikeboroughs",
        },
    ],
    educations: [
        {
            education_details: {
                degree: [],
                major: [],
            },
            school_info: {
                name: "",
                school_id: "",
            },
            duration: {
                start_date: dayjs(),
                end_date: null,
                to_present: true,
            },
        },
    ],
};
export const profileData = {
    created_at: dayjs(),
    updated_at: dayjs(),
    ext_src: "Dummy",
    candidate: {
        full_name: "",
        sname: "",
        location_raw: "",
        gender: "",
        ethnicity: [],
    },
    skills: [],
    workexp: [],
    ext_refs: [],
    education: [],
};
export const sources = [
    "Behance",
    "Artstation",
    "Dribbble",
    "LinkedIn",
    "Twitter",
    "Facebook",
    "Doximity",
    "GitHub",
    "StackOverflow",
    "Instagram",
    "Kaggle",
    "LeetCode",
    "CodeForces",
    "CodeChef",
    "Quora",
    "Scholar",
];
export const ethnicityValues = ["eastasian", "indiansubcontinent", "black", "white", "middleeast", "hispanic"];

export const genderValues = ["male", "female"];
