import React, { useEffect, useState, useRef } from "react";
import Header from "../Header/Header";
import TabsHomePage from "../Home/TabsHomePage";
import axios from "axios";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload, Spin } from "antd";
import { url } from "../../url";
import { DownloadOutlined } from "@ant-design/icons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";

const Admin = ({ loggedInUserDetails, logoutUser }) => {
  const getTaskAssignmentData = async () => {
    window.open(`${url.backend_url}/getTaskAssignmentLists`);
  };

  const [loading, setLoading] = useState(false);
  const [rtCopilotTaskAssignmentData, setRtCopilotTaskAssignmentData] =
    useState([]);
  const [rtCopilotTotalCount, setRtCopilotTotalCount] = useState("-");

  useEffect(() => {
    fetchRtCopilotTaskAssignment();
  }, []);

  const fetchRtCopilotTaskAssignment = async () => {
    try {
      setLoading(true);
      const response = await axios.post(url.rtCopilotTaskAssignment, {});
      const peVerifiedIcpsResponse = await axios.post(url.peVerifiedIcps, {});
      let totalCount = 0;

      for (let data of peVerifiedIcpsResponse.data.data) {
        totalCount += data.unverified_profile_count;
      }

      setRtCopilotTotalCount(totalCount);
      setRtCopilotTaskAssignmentData(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="pe-5 ps-5">
        <div className="mt-4 mb-4 total-data-container">
          <div className="row h-100 w-100">
            <div className="col-12 mt-3 d-flex flex-column justify-content-center align-items-center">
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                size="large"
                onClick={getTaskAssignmentData}
              >
                Download
              </Button>
              {loading ? (
                <p>Loading</p>
              ) : (
                <>
                  <Typography variant="body1" sx={{ mt: 4 }}>
                    RT Copilot task assignment details
                  </Typography>
                  {/* <Typography variant="body2" sx={{ mt: 1 }}>
                    Unverified Profile Count: {rtCopilotTotalCount}
                  </Typography> */}
                  <TableContainer component={Paper} sx={{ mt: 5 }}>
                    <Table
                      sx={{ minWidth: 650 }}
                      aria-label="task assigment details"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Email</TableCell>
                          {/* <TableCell align="right">
                            {" "}
                            Work Assigned Count
                          </TableCell> */}
                          <TableCell align="right">Work Done Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rtCopilotTaskAssignmentData.map((data) => (
                          <TableRow
                            key={data.email}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {data.email}
                            </TableCell>
                            {/* <TableCell align="right">
                              {data.work_assigned_count}
                            </TableCell> */}
                            <TableCell align="right">
                              {data.work_done_count}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
