import { ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { GlobalStyles } from "@mui/material";
import { themes } from ".."; 

const ThemeProviderWrapper = ({ children }) => {
    const themeName = useSelector((state) => state.theme.themeName);
    const theme = themes[themeName];

    const GlobalStyle = () => (
        <GlobalStyles
            styles={(theme) => ({
                body: {
                    fontFamily: theme.typography.fontFamily,
                    margin: 0,
                    padding: 0,
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary,
                },
            })}
        />
    );

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            {children}
        </ThemeProvider>
    );
};

export default ThemeProviderWrapper;
