import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs, useTheme } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useFeatureFlags } from "../../customHooks/useFeatureFlags";

const tabItems = [
    {
        value: 1,
        label: "Profile OptOut",
        pathname: "/opt-out/profile-opt-out",
    },
    {
        value: 2,
        label: "Opt-Out By Domain",
        pathname: "/opt-out/by-domain",
    },
    {
        value: 3,
        label: "Client Specific Opt-Out",
        pathname: "/opt-out/client-specific-opt-out",
    },
];

const OptOut = ({ loggedInUserDetails, logoutUser }) => {
    const { pathname, search, state } = useLocation();
    const [activeTab, setActiveTab] = React.useState(1);
    const navigate = useNavigate();

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        const tabItem = tabItems.find((item) => item.value == newValue);
        navigate(tabItem.pathname + search);
    };

    useEffect(() => {
        let currentPathname = state?.pathname || pathname;
        let tabItem = tabItems.find((ele) => ele.pathname == currentPathname);
        if (tabItem) {
            setActiveTab(tabItem.value);
            navigate(tabItem.pathname + search);
        }
    }, []);

    //Styling
    const theme = useTheme();
    const heights = theme.custom.heights;
    return (
        <div>
            <Box>
                <Tabs
                    sx={{ height: heights.subTabs, borderBlockEnd: theme.palette.border.primary }}
                    centered
                    value={activeTab}
                    onChange={handleTabChange}
                >
                    {tabItems.map((item, index) => {
                        return <Tab key={index} value={item.value} label={item.label}></Tab>;
                    })}
                </Tabs>
                <Box sx={(theme) => ({ height: `calc(100vh - ${heights.header + heights.navbar + heights.subTabs}px)` })}>
                    <Outlet></Outlet>
                </Box>
            </Box>
        </div>
    );
};

export default OptOut;
