import { useContext } from "react";
import UserContext from "../UserContext";

export const usePermission = (module) => {
    let hasPermission = { read: false, update: false, write: false, delete: false };
    try {
        const user = useContext(UserContext);
        user?.role?.permissions.forEach((permission) => {
            if (permission.moduleName === module.name) {
                permission.actions.forEach((action) => {
                    hasPermission[action] = true;
                });
                return hasPermission;
            }
        });
        return hasPermission;
    } catch (error) {
        console.log("Some error while getting user permissions", error);
        return hasPermission;
    }
};
export const checkPermission = (user, module) => {
    let hasPermission = { read: false, update: false, write: false, delete: false };
    try {
        user?.role?.permissions.forEach((permission) => {
            if (permission.moduleName === module.name) {
                permission.actions.forEach((action) => {
                    hasPermission[action] = true;
                });
                return hasPermission;
            }
        });
        return hasPermission;
    } catch (error) {
        console.log("Some error while getting user permissions", error);
        return hasPermission;
    }
};

export const DRA_MODULES = {
    addMissingEmails: {
        name: "add missing emails",
        label: "Add Missing Emails",
    },
    pendingShortList: {
        name: "pending shortlist",
        label: "Pending Shortlist",
    },
    wrongEmails: {
        name: "wrong emails",
        label: "Wrong Emails",
    },
    assignWork: {
        name: "assign work",
        label: "Assign Work",
    },
    admin: {
        name: "admin",
        label: "Admin",
    },
    directUpdate: {
        name: "direct update",
        label: "Direct Update",
    },
    optOut: {
        name: "optout",
        label: "Optout",
    },
    timeLine: {
        name: "timeline",
        label: "Timeline",
    },
    news: {
        name: "news",
        label: "Fetch Company News and Events",
    },
    rtCopilotSearches: {
        name: "rt copilot searches",
        label: "RT Copilot Searches",
    },
    dummyProfileCreator: {
        name: "dummy profile creator",
        label: "Dummy Profile Creator",
    },
    profileEditor: {
        name: "profile editor",
        label: "Profile Editor",
    },
    deadLinkAlternativeFinder: {
        name: "dead link alternative finder",
        label: "Dead Link Alternative Finder",
    },
    companyEmployeeProfileRefresh: {
        name: "company employee profile refresh",
        label: "Company Employee Profile Refresh",
    },
    profileVerificationStatus: {
        name: "profile verification status",
        label: "Profile Verification Status",
    },
    instantProfileRefresh: {
        name: "instant profile refresh",
        label: "Instant Profile Refresh",
    },
    smbCopilotSearches: {
        name: "smb copilot searches",
        label: "Copilot Searches - Paychex",
    },
    reports: {
        name: "reports",
        label: "Reports",
    },
    users: {
        name: "users",
        label: "Users",
    },
    userGroups: {
        name: "user groups",
        label: "User Groups",
    },
    roles: {
        name: "roles",
        label: "Roles",
    },
    leadershipProfile: {
        name: "leadership profile",
        label: "Leadership Profile",
    },
    customerDashboard: {
        name: "customer dashboard",
        label: "Customer Dashboard",
    },
};

export const MODULE_ACTIONS = {
    READ: "read",
    WRITE: "write",
    UPDATE: "update",
    DELETE: "delete",
};
