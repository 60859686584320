import { Box, Divider } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";

const DateRange = ({ dateRange, onChange, disableTime = false }) => {
    const handleDateChange = (field) => (value) => {
        if (dayjs(value).isAfter(dayjs())) {
            value = dayjs();
        }

        const newDateRange = {
            ...dateRange,
            [field]: field === "endDate" && !dayjs(value).isSame(dayjs(), "day") ? dayjs(value).endOf("day") : value,
        };
        onChange(newDateRange);
    };

    const PickerComponent = disableTime ? DatePicker : DateTimePicker;
    const format = disableTime ? "DD/MM/YYYY" : "DD/MM/YYYY hh:mm A";

    return (
        <Box sx={{ mr: "1rem" }}>
            <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                <PickerComponent
                    format={format}
                    sx={{ width: "48%" }}
                    label="Start Date"
                    value={dateRange.startDate}
                    onChange={handleDateChange("startDate")}
                    disableFuture
                    closeOnSelect={true}
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                    }}
                />
                <Box width={"2%"}>
                    <Divider />
                </Box>

                <PickerComponent
                    format={format}
                    sx={{ width: "48%" }}
                    label="End Date"
                    value={dateRange.endDate}
                    onChange={handleDateChange("endDate")}
                    disableFuture
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                    }}
                />
            </Box>
        </Box>
    );
};

export default DateRange;
