import React, { useEffect, useState } from "react";
// import "./index.css";
import { CloseOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import axios from "axios";
import { PlusOutlined, MinusCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import _ from "lodash";
import { Button, Card, Form, Input, Space, InputNumber, Tabs, Spin, Modal, message, Select, DatePicker, Typography, Checkbox } from "antd";

import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import DurationCard from "./DurationCard";
import ProjectForm from "./ProjectForm";
import SkillsForm from "./SkillsForm";
import AccoladesForm from "./AccoladesForm";

const EducationForm = ({ form }) => {
    return (
        <Card size="small" title="Educations">
            <Form.List name="education">
                {(educationFields, { add: addEducation, remove: removeEducation }) => (
                    <>
                        {educationFields.map(({ key, name }) => {
                            return (
                                <Card
                                    key={key}
                                    size="small"
                                    title={`Education ${key + 1}`}
                                    extra={<MinusCircleOutlined onClick={() => removeEducation(name)} />}
                                >
                                    {/* education details*/}
                                    <Card size="small" title="Education details">
                                        {["degree", "major"].map((field, idx) => (
                                            <Card key={idx} size="small" title={`${field.charAt(0).toUpperCase() + field.slice(1)}`}>
                                                <Form.List name={[name, "education_details", field]}>
                                                    {(fields, { add, remove }) => (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "start",
                                                                alignItems: "center",
                                                                flexWrap: "wrap",
                                                            }}
                                                        >
                                                            {fields.map(({ key, name, fieldKey }) => (
                                                                <Space key={key} align="baseline">
                                                                    <Form.Item
                                                                        name={[name, field]}
                                                                        fieldKey={[fieldKey, field]}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: `Please input the ${field}`,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            placeholder={field}
                                                                            addonAfter={
                                                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </Space>
                                                            ))}
                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                Add {field.charAt(0).toUpperCase() + field.slice(1)}
                                                            </Button>
                                                        </div>
                                                    )}
                                                </Form.List>
                                            </Card>
                                        ))}
                                        <Form.Item label={"program_type"} name={[name, "education_details", "program_type"]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label={"specialization"} name={[name, "education_details", "specialization"]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="description" name={[name, "education_details", "description"]}>
                                            <TextArea />
                                        </Form.Item>
                                        <Form.Item label={"grades"} name={[name, "education_details", "grades"]}>
                                            <Input />
                                        </Form.Item>
                                        <ProjectForm fieldPath={["education", name]} name={[name, "projects"]} form={form} />
                                        <SkillsForm
                                            fieldPath={["education", name, "education_details"]}
                                            name={[name, "education_details", "skills"]}
                                            form={form}
                                        />
                                        <AccoladesForm
                                            fieldPath={["education", name, "education_details"]}
                                            name={[name, "education_details", "accolades"]}
                                            form={form}
                                        ></AccoladesForm>
                                    </Card>

                                    {/* Duration */}
                                    <DurationCard fieldPath={["education", name]} name={name} form={form} />

                                    {/* school info*/}
                                    <Card size="small" title="School Info">
                                        {["name", "location", "domain", "department"].map((field, idx) => {
                                            return (
                                                <Form.Item key={idx} label={field} name={[name, "school_info", field]}>
                                                    <Input />
                                                </Form.Item>
                                            );
                                        })}
                                    </Card>
                                </Card>
                            );
                        })}
                        <Button type="dashed" onClick={() => addEducation()} block icon={<PlusOutlined />}>
                            Add Education
                        </Button>
                    </>
                )}
            </Form.List>
        </Card>
    );
};

export default EducationForm;
