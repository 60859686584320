import React, { useEffect, useState } from "react";
import HeaderComponent from "../Header/Header";
import TabsHomePage from "../Home/TabsHomePage";
import { Box, Tab, Tabs } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const tabItems = [
    {
        value: 1,
        label: "Profile OptOut",
        pathname: "/opt-out/profile-opt-out",
    },
    {
        value: 2,
        label: "Opt-Out By Domain",
        pathname: "/opt-out/by-domain",
    },
];

const OptOut = ({ loggedInUserDetails, logoutUser }) => {
    const { pathname, search, state } = useLocation();
    const [activeTab, setActiveTab] = React.useState(1);
    const navigate = useNavigate();

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        const tabItem = tabItems.find((item) => item.value == newValue);
        navigate(tabItem.pathname);
    };

    useEffect(() => {
        let currentPathname = state?.pathname || pathname;
        let tabItem = tabItems.find((ele) => ele.pathname == currentPathname);
        if (tabItem) {
            setActiveTab(tabItem.value);
            navigate(tabItem.pathname);
        }
    }, []);
    return (
        <div>
            <HeaderComponent loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser} />
            <div className="pe-5 ps-5">
                <TabsHomePage />
            </div>
            <Box>
                <Tabs centered value={activeTab} onChange={handleTabChange}>
                    {tabItems.map((item) => {
                        return <Tab value={item.value} label={item.label}></Tab>;
                    })}
                </Tabs>
                
                <Outlet></Outlet>
            </Box>
        </div>
    );
};

export default OptOut;
