import {
    Box,
    Button,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    Input,
    InputLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Paper,
    Radio,
    RadioGroup,
    Skeleton,
    Stack,
    TextField,
    Tooltip,
    Typography,
    InputAdornment,
} from "@mui/material";
import { message } from "antd";
import { useEffect, useState } from "react";
import { url } from "../../../url";
import axios from "axios";
import FindemLogoPath from "../../../assets/findem-logo-small-squircle.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { ENRICHED_PROFILE_LINK_PREFIX, LINKEDIN_PREFIX } from "../../AltLinkFinder/AltLinkFinder";
import SubmitButtonWithConfirmation from "./SubmitButtonWithConfirmation";
import SearchIcon from "@mui/icons-material/Search";
import { extSourceNames, linkedInUidRegex } from "../../utils/constants";

const MappedProfileContainer = ({ selectedProfile }) => {
    const [possibleMappings, setPossibleMappings] = useState({ isLoading: false, data: [] });
    const [mappedProfilesFromSearch, setMappedProfilesFromSearch] = useState({ isLoading: false, data: [] });
    const [highProbabilityMappedProfile, setHighProbabilityMappedProfile] = useState({ isLoading: false, data: [] });
    const [selectedMappedProfile, setSelectedMappedProfile] = useState(null);
    const [searchUrlInput, setSearchUrlInput] = useState("");

    const fetchPossibleMappings = async () => {
        try {
            setPossibleMappings({ isLoading: true, data: [] });
            const response = await axios.get(url.leadershipProfile.findMappings, {
                params: {
                    profileId: selectedProfile?.profile_id,
                    name: selectedProfile?.candidate?.full_name,
                    title: selectedProfile?.candidate?.title,
                    company: selectedProfile?.company_name,
                },
            });
            if (response?.data?.results?.length == 0) {
                message.info("No mapping found!");
                return;
            }
            setPossibleMappings({ isLoading: false, data: response?.data?.data?.results || [] });
            setSelectedMappedProfile(null);
        } catch (error) {
            setPossibleMappings({ isLoading: false, data: [] });
            console.log(error);
            message.error(error?.response?.data?.message || "An error occurred while retrieving mappings.");
        }
    };

    const fetchMappingsByLinkedinUrl = async () => {
        try {
            if (!searchUrlInput) {
                message.error("Please provide a url or uid!");
                return;
            }
            setMappedProfilesFromSearch({ isLoading: true, data: [] });
            const match = searchUrlInput.match(linkedInUidRegex);
            const uid = match ? match[1] : searchUrlInput;

            const response = await axios.get(url.leadershipProfile.searchMappingsByLinkedinURL, {
                params: {
                    uid: uid,
                },
            });
            if (response?.data?.data?.length == 0) {
                message.info(`No mapping found for this URL:${searchUrlInput}!`);
                return;
            }
            setMappedProfilesFromSearch({ isLoading: false, data: response?.data?.data || [] });
            setSelectedMappedProfile(null);
        } catch (error) {
            setMappedProfilesFromSearch({ isLoading: false, data: [] });
            console.log(error);
            message.error(error?.response?.data?.message || "An error occurred while searching mappings.");
        }
    };

    const fetchMappedProfileByRef = async () => {
        try {
            if (!selectedProfile?.mapping_found) {
                return;
            }
            setHighProbabilityMappedProfile({ isLoading: true, data: [] });
            const ref = selectedProfile?.mapped_profile?.ref;
            const response = await axios.get(url.leadershipProfile.getMappedProfileByRef, {
                params: {
                    ref: ref,
                },
            });
            if (response?.data?.data?.length == 0) {
                message.info(`No mapping found for this ref:${ref}!`);
                return;
            }
            console.log({ isLoading: false, data: response?.data?.data || [] });
            setHighProbabilityMappedProfile({ isLoading: false, data: response?.data?.data || [] });
            setSelectedMappedProfile(null);
        } catch (error) {
            setHighProbabilityMappedProfile({ isLoading: false, data: [] });
            console.log(error);
            message.error(error?.response?.data?.message || "An error occurred while finding high probability mapping.");
        }
    };

    const updateMappedProfile = async () => {
        try {
            const response = await axios.post(url.leadershipProfile.updateMappedProfile, {
                newPublicProfile: selectedProfile,
                profileId: selectedProfile?.profile_id,
                submitWithError: false,
                mappedProfileRef: selectedMappedProfile?.ref,
            });
            message.info(response?.data?.message);
            console.log(response);
        } catch (error) {
            console.log(error);
            message.error(error?.response?.data?.message || "An error occurred while updating mapped profile.");
        }
    };

    const handleSelectedMappedProfile = (event) => {
        const newSelectedMappedProfile =
            possibleMappings?.data?.find((profile) => profile?.ref === event.target.value) ||
            mappedProfilesFromSearch?.data?.find((profile) => profile?.ref === event.target.value) ||
            highProbabilityMappedProfile?.data?.find((profile) => profile?.ref === event.target.value);

        setSelectedMappedProfile(newSelectedMappedProfile);
    };
    const handleSearchUrlChange = (event) => {
        setSearchUrlInput(event.target.value);
    };

    useEffect(() => {
        fetchPossibleMappings();
        fetchMappedProfileByRef();
    }, [selectedProfile]);

    return (
        <Box
            sx={{
                width: "25%",
                bgcolor: "#F4F5F7",
                height: "80vh",
                overflowY: "auto",
                position: "sticky",
                top: "160px",
                borderLeft: "2px solid gray",
            }}
        >
            <SubmitButtonWithConfirmation selectedMappedProfile={selectedMappedProfile} updateMappedProfile={updateMappedProfile} />
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3, pl: 2, pr: 2 }}>
                <TextField
                    sx={{
                        width: "100%",
                        maxWidth: "500px",
                        backgroundColor: "#f1f9ff",
                        borderRadius: "25px",
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "25px",
                        },
                        "& .MuiInputAdornment-root": {
                            color: "#3E9BF7",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#3E9BF7",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#2979FF",
                        },
                    }}
                    onChange={handleSearchUrlChange}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            fetchMappingsByLinkedinUrl();
                        }
                    }}
                    placeholder="Search Mapping..."
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton onClick={fetchMappingsByLinkedinUrl} edge="start" sx={{ color: "#3E9BF7" }}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <>
                <List>
                    <RadioGroup onChange={handleSelectedMappedProfile}>
                        {mappedProfilesFromSearch?.data?.length > 0 && (
                            <>
                                <ListSubheader sx={{ fontWeight: "bold" }}>{"Mappings Found from URL Search"}</ListSubheader>
                                {mappedProfilesFromSearch?.data?.map((profileData) => {
                                    return (
                                        <FormControlLabel
                                            sx={{
                                                ".MuiFormControlLabel-label": { width: "100%" },
                                                borderBottom: "1px solid rgba(0,0,0,0.4)",
                                                mr: "0",
                                                padding: "0 1rem 0 1rem",
                                                width: "100%",
                                            }}
                                            value={profileData?.ref}
                                            control={<Radio />}
                                            label={
                                                <ListItem disablePadding>
                                                    <Box width={"100%"} display={"flex"} flexDirection={"column"}>
                                                        <Stack mb={"1rem"}>
                                                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                <Typography
                                                                    sx={{
                                                                        maxWidth: "300px",
                                                                        textOverflow: "ellipsis",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    {profileData?.candidate?.full_name}
                                                                </Typography>
                                                                <div style={{ display: "flex", gap: "0" }}>
                                                                    <Tooltip title="Open LinkedIn Profile">
                                                                        <IconButton
                                                                            component="a"
                                                                            href={
                                                                                profileData?.ext_refs?.find(
                                                                                    (ext_ref) => ext_ref.source === extSourceNames.LinkedIn
                                                                                ).url
                                                                            }
                                                                            target="_blank"
                                                                        >
                                                                            <LinkedInIcon style={{ color: "#3E9BF7" }} />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title="Open EP">
                                                                        <IconButton
                                                                            component="a"
                                                                            href={`${ENRICHED_PROFILE_LINK_PREFIX}/${profileData?.ref}`}
                                                                            target="_blank"
                                                                        >
                                                                            <img src={FindemLogoPath} width={19} height={19.7}></img>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                            </Box>
                                                            <Typography fontSize={"0.8rem"} sx={{ opacity: "0.5" }}>
                                                                {profileData?.title}
                                                            </Typography>
                                                        </Stack>
                                                        <Stack>
                                                            <Typography fontSize={"0.9rem"}>Ref: {profileData?.ref}</Typography>
                                                        </Stack>
                                                    </Box>
                                                </ListItem>
                                            }
                                        />
                                    );
                                })}
                            </>
                        )}
                        {mappedProfilesFromSearch?.isLoading && (
                            <Stack spacing={1}>
                                {[...Array(2)].map((_, index) => {
                                    return (
                                        <Skeleton
                                            sx={{ borderBottom: "1px solid rgba(0,0,0, 0.4)" }}
                                            variant="rectangular"
                                            width={"100%"}
                                            height={70}
                                        />
                                    );
                                })}
                            </Stack>
                        )}

                        {highProbabilityMappedProfile?.data?.length > 0 && (
                            <Box>
                                <ListSubheader sx={{ fontWeight: "bold" }}>{"High-Probability Mappings"}</ListSubheader>
                                {highProbabilityMappedProfile?.data?.map((profileData) => {
                                    return (
                                        <FormControlLabel
                                            sx={{
                                                ".MuiFormControlLabel-label": { width: "100%" },
                                                borderBottom: "1px solid rgba(0,0,0,0.4)",
                                                mr: "0",
                                                padding: "0 1rem 0 1rem",
                                                width: "100%",
                                            }}
                                            value={profileData?.ref}
                                            control={<Radio />}
                                            label={
                                                <ListItem disablePadding>
                                                    <Box width={"100%"} display={"flex"} flexDirection={"column"}>
                                                        <Stack mb={"1rem"}>
                                                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                <Typography
                                                                    sx={{
                                                                        maxWidth: "300px",
                                                                        textOverflow: "ellipsis",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    {profileData?.candidate?.full_name}
                                                                </Typography>
                                                                <div style={{ display: "flex", gap: "0" }}>
                                                                    <Tooltip title="Open LinkedIn Profile">
                                                                        <IconButton
                                                                            component="a"
                                                                            href={
                                                                                profileData?.ext_refs?.find(
                                                                                    (ext_ref) => ext_ref.source === extSourceNames.LinkedIn
                                                                                ).url
                                                                            }
                                                                            target="_blank"
                                                                        >
                                                                            <LinkedInIcon style={{ color: "#3E9BF7" }} />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title="Open EP">
                                                                        <IconButton
                                                                            component="a"
                                                                            href={`${ENRICHED_PROFILE_LINK_PREFIX}/${profileData?.ref}`}
                                                                            target="_blank"
                                                                        >
                                                                            <img src={FindemLogoPath} width={19} height={19.7} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                            </Box>
                                                            <Typography fontSize={"0.8rem"} sx={{ opacity: "0.5" }}>
                                                                {profileData?.title}
                                                            </Typography>
                                                        </Stack>
                                                        <Stack>
                                                            <Typography fontSize={"0.9rem"}>Ref: {profileData?.ref}</Typography>
                                                        </Stack>
                                                    </Box>
                                                </ListItem>
                                            }
                                        />
                                    );
                                })}
                            </Box>
                        )}
                        {highProbabilityMappedProfile?.isLoading && (
                            <Stack spacing={1}>
                                {[...Array(1)].map((_, index) => {
                                    return (
                                        <Skeleton
                                            sx={{ borderBottom: "1px solid rgba(0,0,0, 0.4)" }}
                                            variant="rectangular"
                                            width={"100%"}
                                            height={70}
                                        />
                                    );
                                })}
                            </Stack>
                        )}
                        {possibleMappings?.data?.length > 0 && (
                            <Box sx={{ mb: "2rem" }}>
                                <ListSubheader sx={{ fontWeight: "bold" }}>{"Possible Mappings"}</ListSubheader>
                                {possibleMappings?.data?.map((profileData) => {
                                    return (
                                        <FormControlLabel
                                            sx={{
                                                ".MuiFormControlLabel-label": { width: "100%" },
                                                borderBottom: "1px solid rgba(0,0,0,0.4)",
                                                mr: "0",
                                                padding: "0 1rem 0 1rem",
                                                width: "100%",
                                            }}
                                            value={profileData?.ref}
                                            control={<Radio />}
                                            label={
                                                <ListItem disablePadding>
                                                    <Box width={"100%"} display={"flex"} flexDirection={"column"}>
                                                        <Stack mb={"1rem"}>
                                                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                <Typography
                                                                    sx={{
                                                                        maxWidth: "300px",
                                                                        textOverflow: "ellipsis",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    {profileData?.full_name}
                                                                </Typography>
                                                                <div style={{ display: "flex", gap: "0" }}>
                                                                    <Tooltip title="Open LinkedIn Profile">
                                                                        <IconButton
                                                                            component="a"
                                                                            href={profileData?.linkedin}
                                                                            target="_blank"
                                                                        >
                                                                            <LinkedInIcon style={{ color: "#3E9BF7" }} />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title="Open EP">
                                                                        <IconButton
                                                                            component="a"
                                                                            href={`${ENRICHED_PROFILE_LINK_PREFIX}/${profileData?.ref}`}
                                                                            target="_blank"
                                                                        >
                                                                            <img src={FindemLogoPath} width={19} height={19.7} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                            </Box>
                                                            <Typography fontSize={"0.8rem"} sx={{ opacity: "0.5" }}>
                                                                {profileData?.title}
                                                            </Typography>
                                                        </Stack>
                                                        <Stack>
                                                            <Typography fontSize={"0.9rem"}>Ref: {profileData?.ref}</Typography>
                                                        </Stack>
                                                    </Box>
                                                </ListItem>
                                            }
                                        />
                                    );
                                })}
                            </Box>
                        )}
                        {possibleMappings?.isLoading && (
                            <Stack spacing={1}>
                                {[...Array(5)].map((_, index) => {
                                    return (
                                        <Skeleton
                                            sx={{ borderBottom: "1px solid rgba(0,0,0, 0.4)" }}
                                            variant="rectangular"
                                            width={"100%"}
                                            height={70}
                                        />
                                    );
                                })}
                            </Stack>
                        )}
                    </RadioGroup>
                </List>
            </>
        </Box>
    );
};

export default MappedProfileContainer;
