import React, { useState } from "react";
import "./filter.styles.css";
import Select from 'react-select';

const FilterComponent = ({ dataOriginal, dataCloned, filteredCustomersData, filteredCustomers, handleFilterChange, listOfCompanies, selectedFilterFullOption }) => {
  const [filterCustomers, getFilteredCustomers] = useState("");

  if (dataCloned) {
    //includes duplicates
    // let customersWithDuplicates = dataCloned.map((object) => object.customer);

    // // let customers = customersWithDuplicates.filter((customer, index) => customersWithDuplicates.indexOf(customer) === index);
    // let customers = [...new Set(customersWithDuplicates).values()];
    // const getFilterOptions = () => {

    //   // if (filterCustomers !== "") {
    //   //   customers = customers.filter((customer) => customer.includes(filterCustomers))
    //   // }
    //   let getFilterCustomerOptions = customers.map((customer) => {
    //     return (
    //       // <div key={index} className="dropdown-item">
    //       //   <input id={index} className="me-2 cursor-pointer checkbox-input" checked={filteredCustomers.includes(customer) ? true : false} onChange={() => handleCheckbox(customer)} type="checkbox"></input>
    //       //   <label htmlFor={index} className="cursor-pointer">{customer}</label>
    //       // </div>
    //       { value: customer ? customer : "Not specified", label: customer ? customer : "Not specified", color: '#666666' }
    //     );
    //   });

    //   return getFilterCustomerOptions;

    const getFilterOptions = () => {
      const BLACKLISTED_CUSTOMERS = ["5b0c4a8b3200621bdb2cde08", "5b0c4ad23200621bdb504051", "5bd4fa5710cac61dcbb634df", "5bd4fa5710cac61dcbb634df", "puut8curmqop6tnms6owcs",
        "5ug6h66ultjafocq7xgxjr", "xo6cn4f0q2obgjbwa1ri6r", "kapuf09z8y81metfauknug", "sample", "n/a", "1965", "q4hhfq89q6b3vbyjrg60jy", "rq7m04cwqaiof7x0lr011"];

      let getFilterCustomerOptions = listOfCompanies.reduce((accum, company) => {
        if (!company[0].toLowerCase().includes("demo") &&
          !company[0].toLowerCase().includes("test") && !BLACKLISTED_CUSTOMERS.includes(company[0].toLowerCase()) &&
          !BLACKLISTED_CUSTOMERS.includes(company[1].toLowerCase())) {
          accum.push({ value: company[0], label: company[0], color: "#666666", accountId: company[1] });
        }

        return accum;
      }, []);
      // console.log(getFilterCustomerOptions.length);
      return getFilterCustomerOptions;
    }

    const handleInputChange = (event) => {
      // getFilteredCustomers(event.target.value)
      let selectedCustomers = event.map((customer) => customer.value);
      // console.log("Selected customers: ", selectedCustomers);
    }

    const handleCheckbox = (customer) => {
      if (filteredCustomers.includes(customer)) {
        let newFilteredCustomers = filteredCustomers.filter((existingCustomer) => existingCustomer !== customer)
        filteredCustomersData(newFilteredCustomers);
      } else {
        filteredCustomersData([...filteredCustomers, customer]);
      }
    }

    return (
      <div className="mb-2">

        < Select
          name="customers"
          options={getFilterOptions()}
          placeholder={<div className="text-start">Filter customers...&nbsp;<i className="fa-solid fa-filter"></i></div>}
          className="basic-single"
          classNamePrefix="select"
          isClearable="true"
          isSearchable="true"
          onChange={handleFilterChange}
          value={selectedFilterFullOption}
        />
      </div>
    );
  };
}
export default FilterComponent;

// [{ value: "Not specified", label: "Not specified", color: "#666666" }]