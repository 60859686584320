import LabelIcon from "@mui/icons-material/Label";
import SendIcon from "@mui/icons-material/Send";
import { Backdrop, Box, Button, FormControl, Input, InputLabel, Typography } from "@mui/material";
import { Divider, message } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useContext, useState } from "react";
import { url } from "../../../url";
import UserContext from "../../../UserContext";
import FindemLogo from "../../feedback/FindemLogo/FindemLogo";

const RightPane = ({ uids }) => {
    const loggedInUserDetails = useContext(UserContext);
    const [label, setLabel] = useState("");
    const [sendingRequest, setSendingRequest] = useState(false);

    const handleSendRequest = async () => {
        if (uids.length === 0) {
            message.info("UIDs/URLs are not provided!");
            return;
        }
        try {
            setSendingRequest(true);
            const response = await axios.post(url.instantProfileRefresh, {
                uids: uids,
                label: `instant_refresh_${dayjs().format("DD_MM_YYYY")}_${loggedInUserDetails.email}_${label}`,
                userId: loggedInUserDetails._id,
            });
            message.success("Refresh request sent successfully.");
            setSendingRequest(false);
        } catch (error) {
            console.log("Failed to send request:", error);
            setSendingRequest(false);
            message.error("Failed to send request!");
        }
    };

    const handleLabelChange = (event) => {
        setLabel(event.target.value);
    };
    return (
        <Box sx={{ width: "20%", bgcolor: "background.secondary", height: "100%", padding: "1rem" }}>
            <Box
                sx={{
                    minHeight: "20vh",
                    padding: "1rem",
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: "background.default",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <FormControl variant="standard">
                    <InputLabel required htmlFor="filled-basic">
                        Label
                    </InputLabel>
                    <Input onChange={handleLabelChange} id="filled-basic" label="Label" startAdornment={<LabelIcon />} variant="filled" />
                </FormControl>

                <Button
                    onClick={handleSendRequest}
                    sx={{
                        borderRadius: "3px",
                        fontWeight: "bold",
                        width: "80%",
                        marginTop: "1rem",
                    }}
                    disabled={label.length === 0}
                    endIcon={<SendIcon />}
                >
                    Send Request
                </Button>
                <Divider></Divider>
                <Typography>
                    <strong>{uids.length}</strong> {`profile${uids.length > 1 ? "s" : ""} will be refreshed`}.
                </Typography>
            </Box>
            <Backdrop sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })} open={sendingRequest}>
                <FindemLogo loading color={"white"} height={"30"} width={"30"} />
            </Backdrop>
        </Box>
    );
};

export default RightPane;
