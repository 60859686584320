import React from "react";
import "./modals.styles.css";

const PendingShortlistsModal = ({ indexx, sl_prid, _id }) => {
    return (
        <>
            <div
                className="modal fade"
                id={`formModal${indexx}`}
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="formModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        {
                            <div className="modal-body p-5 text-center">
                                <p>Are you sure you want to save changes?</p>
                                <div className="d-flex justify-content-around mt-5">
                                    <button
                                        type="button"
                                        className="modal-button modal-no-btn-color"
                                        data-bs-dismiss="modal"
                                    >
                                        No
                                    </button>
                                    <input
                                        type="submit"
                                        data-bs-dismiss="modal"
                                        className="modal-button modal-yes-btn-color"
                                        form={`form${indexx}`}
                                        data-sl_prid={sl_prid}
                                        data-_id={_id}
                                        value="Yes"
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default PendingShortlistsModal;
