// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-pane-container-items {
    cursor: pointer;
}

.left-pane-height {
    height: 65vh;
}

.bgc-when-clicked {
    background-color: rgb(236, 236, 236);
}

.bgc-when-not-clicked {
    background-color: #fff;
}

.shortlist-para {
    font-style: italic;
    color: grey;
    margin: 0;
}

.color-blue {
    color: #4798FE;
}

.load-more-button {
    border: none;
    border-radius: 5px;
    background-color: #4798FE;
    color: white;
    padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/WrongEmail/LeftPane/leftPane.styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;AACb;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".left-pane-container-items {\n    cursor: pointer;\n}\n\n.left-pane-height {\n    height: 65vh;\n}\n\n.bgc-when-clicked {\n    background-color: rgb(236, 236, 236);\n}\n\n.bgc-when-not-clicked {\n    background-color: #fff;\n}\n\n.shortlist-para {\n    font-style: italic;\n    color: grey;\n    margin: 0;\n}\n\n.color-blue {\n    color: #4798FE;\n}\n\n.load-more-button {\n    border: none;\n    border-radius: 5px;\n    background-color: #4798FE;\n    color: white;\n    padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
