import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DATA_STATUS } from "../../../utils/constants";
import { fetchDuplicateLeadershipProfile } from "../services/leadership-profile-service";

export const getDuplicateLeadershipProfile = createAsyncThunk("leadershipProfile/getDuplicateLeadershipProfile",async(profileId)=>{
    return await fetchDuplicateLeadershipProfile(profileId)
});

const leadershipProfileSlice = createSlice({
    name: "leadershipProfile",
    initialState: { duplicateProfiles: { list: [], status: DATA_STATUS.IDLE, error: null } },
    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(getDuplicateLeadershipProfile.pending, (state) => {
            state.duplicateProfiles.status = DATA_STATUS.LOADING;
          })
          .addCase(getDuplicateLeadershipProfile.fulfilled, (state, action) => {
            state.duplicateProfiles.status = DATA_STATUS.SUCCEEDED;
            state.duplicateProfiles.list = action.payload;
          })
          .addCase(getDuplicateLeadershipProfile.rejected, (state, action) => {
            state.duplicateProfiles.status = DATA_STATUS.FAILED;
            state.duplicateProfiles.error = action.error.message;
          });
      },
});

export default leadershipProfileSlice.reducer;
