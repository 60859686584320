// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
}

/* Custom CSS to align icon and text within Ant Design message */
.ant-message-notice-content {
  display: flex;
  align-items: center; /* Align items vertically */
  border: 1px solid rgb(238, 70, 70);
}

.ant-message-custom-content {
  display: flex;
  align-items: center; /* Ensure icon and text align */
}

/* If the icon still appears misaligned, you might need to adjust its margin or alignment specifically */
.ant-message-custom-content .anticon {
  margin-right: 8px; /* Adjust spacing as needed */
  /* Adjust vertical position if necessary, though align-items should suffice */
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA,gEAAgE;AAChE;EACE,aAAa;EACb,mBAAmB,EAAE,2BAA2B;EAChD,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,mBAAmB,EAAE,+BAA+B;AACtD;;AAEA,wGAAwG;AACxG;EACE,iBAAiB,EAAE,6BAA6B;EAChD,6EAA6E;AAC/E","sourcesContent":["body {\n  margin: 0;\n}\n\n/* Custom CSS to align icon and text within Ant Design message */\n.ant-message-notice-content {\n  display: flex;\n  align-items: center; /* Align items vertically */\n  border: 1px solid rgb(238, 70, 70);\n}\n\n.ant-message-custom-content {\n  display: flex;\n  align-items: center; /* Ensure icon and text align */\n}\n\n/* If the icon still appears misaligned, you might need to adjust its margin or alignment specifically */\n.ant-message-custom-content .anticon {\n  margin-right: 8px; /* Adjust spacing as needed */\n  /* Adjust vertical position if necessary, though align-items should suffice */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
