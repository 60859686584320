import { Alert } from "@mui/material";
import { useContext } from "react";
import UserContext from "../../../UserContext";

const UserProfile = () => {
    const loggedInUserDetails = useContext(UserContext);
    return (
        <>
            <Alert color="info" severity="info">
                Hi {loggedInUserDetails.name} ({loggedInUserDetails.email}),
                <br />
                This section is under development.
            </Alert>
        </>
    );
};

export default UserProfile;
