import { Autocomplete, Box, Checkbox, Divider, TextField, CircularProgress } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { url } from "../../../url";
import { message } from "antd";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {debounce} from "lodash";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FilterToolbar = ({ filterQuery, setFilterQuery }) => {
    const [companyFilterOptions, setCompanyFilterOptions] = useState({ isLoading: false, data: [] });
    const [companyFilterPage, setCompanyFilterPage] = useState(-1);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [companyFilterInput, setCompanyFilterInput] = useState("");
    const [hasMore, setHasMore] = useState(true);

    const fetchCompanyFilterOptions = useCallback(
        debounce(async (reset, companyName) => {
            try {
                setCompanyFilterOptions((prev) => ({ ...prev, isLoading: true }));
                const response = await axios.get(url.leadershipProfile.getCompanyFilterOptions, {
                    params: {
                        companyName: companyName,
                        page: reset ? 0 : companyFilterPage + 1,
                    },
                });

                if (response?.data?.data?.length > 0) {
                    setCompanyFilterPage(reset ? 0 : companyFilterPage + 1);
                    setCompanyFilterOptions((prev) => ({
                        data: reset ? response.data.data : [...prev.data, ...response.data.data],
                        isLoading: false,
                    }));
                } else {
                    setHasMore(false);
                    setCompanyFilterOptions((prev) => ({ ...prev, isLoading: false }));
                }
            } catch (error) {
                setCompanyFilterOptions((prev) => ({ ...prev, isLoading: false }));
                console.error("Failed to fetch filter options:", error);
                if (error?.response?.data?.message) {
                    message.error(error?.response?.data?.message);
                }
                message.error("Failed to fetch filter options!");
            }
        }, 300),
        [companyFilterPage]
    );

    const handleCompanyLinksChange = (event, newValues) => {
        setFilterQuery((prevFilterQuery) => {
            if (newValues.length === 0) {
                const { company_link, ...rest } = prevFilterQuery;
                return rest;
            }
            return {
                ...prevFilterQuery,
                company_link: { $in: newValues.map((item) => item.value) },
            };
        });
        setSelectedOptions(newValues);
    };

    const groupedOptions = [
        ...selectedOptions,
        ...(companyFilterOptions?.data || []).filter((option) => !selectedOptions.some((selected) => selected.value === option.value)),
    ];

    const handleCompanyFilterScroll = (event) => {
        if (hasMore) {
            const listboxNode = event.currentTarget;
            if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 50) {
                fetchCompanyFilterOptions(false, companyFilterInput);
            }
        }
    };

    const handleCompanyFilterInputChange = (event, value) => {
        setCompanyFilterInput(value);
        setHasMore(true)
        fetchCompanyFilterOptions(true, value);
    };

    useEffect(() => {
        fetchCompanyFilterOptions();
    }, []);

    return (
        <>
            <Box sx={{ position: "sticky", top: "75px", background: "white", zIndex: "9" }}>
                <Box
                    sx={{
                        margin: "0px 3rem",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                    }}
                >
                    <Autocomplete
                        sx={{ minWidth: "20rem" }}
                        multiple
                        onChange={handleCompanyLinksChange}
                        options={groupedOptions}
                        getOptionLabel={(option) => option?.label || ""}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        renderTags={(value, getTagProps) => {
                            return [`${value.length} selected`]; // Show the count of selected tags
                        }}
                        onInputChange={handleCompanyFilterInputChange}
                        ListboxProps={{
                            onScroll: handleCompanyFilterScroll,
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Companies"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {companyFilterOptions.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        renderOption={(props, option, { selected }) => {
                            const { key, ...optionProps } = props;
                            return (
                                <li key={key} {...optionProps}>
                                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                    {option.label}
                                </li>
                            );
                        }}
                    />
                </Box>
                <Divider></Divider>
            </Box>
        </>
    );
};

export default FilterToolbar;
