import React, { useContext, useMemo } from "react";
import findemLogo from "../../assets/findem-logo-small-squircle.png";
import "./header.styles.css";
import { useNavigate } from "react-router-dom";

import { DownOutlined } from "@ant-design/icons";
import { Layout, Menu, Image, Row, Col, Dropdown, Space, Typography } from "antd";
import { checkPermission, DRA_MODULES } from "../utils/rbacUtils";
import TabContext from "../../TabContext";
import { TAB_ROUTE_TO_NAME_ID_MAP } from "../Home/TabsHomePage";
const { Header } = Layout;

const HeaderComponent = ({ loggedInUserDetails, logoutUser }) => {
    const activeTab = useContext(TabContext);
    const navigate = useNavigate();

    const menuItems = [
        {
            key: "reports",
            label: "Reports",
            permissionRequired: true,
            module: DRA_MODULES.reports,
            onClick: () => {
                navigate("/reports");
            },
        },
        {
            key: "userGroups",
            label: "User Groups",
            permissionRequired: true,
            module: DRA_MODULES.userGroups,
            onClick: () => {
                navigate("/user-groups");
            },
        },
        {
            key: "userDashboard",
            label: "User Dashboard",
            onClick: () => {
                navigate("/user-dashboard");
            },
        },
        {
            key: "Logout",
            label: "Logout",
            onClick: () => logoutUser(),
        },
    ];

    const navigateToApp = () => {
        const path = Object.keys(TAB_ROUTE_TO_NAME_ID_MAP).find((key) => TAB_ROUTE_TO_NAME_ID_MAP[key]?.id === activeTab[0]) || "/";
        navigate(path);
    };

    return (
        <div style={{ display: "flex", justifyContent: "space-between", margin: "15px 47px 20px 47px", alignItems: "center" }}>
            <div onClick={navigateToApp} style={{ cursor: "pointer" }}>
                <img src={findemLogo} alt="Findem Logo" width="32px" height="32px" style={{ display: "inline", marginRight: "8px" }} />
                <span style={{ fontSize: "15px" }}>
                    <span style={{ color: "#4798FE", fontWeight: "600" }}>FINDEM</span> DATA RESEARCH APPLICATION
                </span>
            </div>
            <div>
                <Dropdown
                    overlay={
                        <Menu
                            items={menuItems.map((item) => {
                                if (item?.permissionRequired && !checkPermission(loggedInUserDetails, item.module).read) {
                                    return;
                                }
                                return { key: item.key, label: item.label, onClick: item.onClick };
                            })}
                        />
                    }
                >
                    <Typography.Link>
                        <Space>
                            {`Hello ${loggedInUserDetails.name}`}
                            <DownOutlined />
                        </Space>
                    </Typography.Link>
                </Dropdown>
            </div>
        </div>
    );
};

export default HeaderComponent;
