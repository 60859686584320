import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { message } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { url } from "../../../url";
import { LEADERSHIP_CONST } from "../../../utils/constants";
import CompletedTasksCard from "../../cards/CompletedTasksCard";
import PaginatedAutocomplete from "../../input/PaginatedAutocomplete";

const { PROFILE_STATUS } = LEADERSHIP_CONST;

const FilterToolbar = ({ filterQuery, setFilterQuery, tasksCompletedForToday }) => {
    const [companyFilterOptions, setCompanyFilterOptions] = useState({ isLoading: false, data: [] });
    const [noOrgChartCompanyFilterOptions, setNoOrgChartCompanyFilterOptions] = useState({ isLoading: false, data: [] });
    const [companyFilterPage, setCompanyFilterPage] = useState(-1);
    const [noOrgChartCompanyFilterPage, setNoOrgChartCompanyFilterPage] = useState(-1);
    const [companyFilterSelectedOptions, setCompanyFilterSelectedOptions] = useState([]);
    const [noOrgChartCompanyFilterSelectedOptions, setNoOrgChartCompanyFilterSelectedOptions] = useState([]);
    const [companyFilterInput, setCompanyFilterInput] = useState("");
    const [noOrgChartCompanyFilterInput, setNoOrgChartCompanyFilterInput] = useState("");

    const [selectedProfileStatus, setSelectedProfileStatus] = useState([]);
    const [companyFilterHasMore, setCompanyFilterHasMore] = useState(true);
    const [noOrgChartCompanyFilterHasMore, setNoOrgChartCompanyFilterHasMore] = useState(true);

    const fetchCompanyFilterOptions = useCallback(
        debounce(async (reset, companyName) => {
            try {
                setCompanyFilterOptions((prev) => ({ ...prev, isLoading: true }));
                const response = await axios.get(url.leadershipProfile.getCompanyFilterOptions, {
                    params: {
                        companyName: companyName,
                        page: reset ? 0 : companyFilterPage + 1,
                    },
                });

                if (response?.data?.data?.length > 0) {
                    setCompanyFilterPage(reset ? 0 : companyFilterPage + 1);
                    setCompanyFilterOptions((prev) => ({
                        data: reset ? response.data.data : [...prev.data, ...response.data.data],
                        isLoading: false,
                    }));
                } else {
                    setCompanyFilterHasMore(false);
                    setCompanyFilterOptions((prev) => ({ ...prev, isLoading: false }));
                }
            } catch (error) {
                setCompanyFilterOptions((prev) => ({ ...prev, isLoading: false }));
                console.error("Failed to fetch filter options:", error);
                if (error?.response?.data?.message) {
                    message.error(error?.response?.data?.message);
                }
                message.error("Failed to fetch filter options!");
            }
        }, 300),
        [companyFilterPage]
    );
    const fetchNoOrgChartCompanyFilterOptions = useCallback(
        debounce(async (reset, companyName) => {
            try {
                setNoOrgChartCompanyFilterOptions((prev) => ({ ...prev, isLoading: true }));
                const response = await axios.get(url.leadershipProfile.getNoOrgChartCompanyFilterOptions, {
                    params: {
                        companyName: companyName,
                        page: reset ? 0 : noOrgChartCompanyFilterPage + 1,
                    },
                });

                if (response?.data?.data?.length > 0) {
                    setNoOrgChartCompanyFilterPage(reset ? 0 : noOrgChartCompanyFilterPage + 1);
                    setNoOrgChartCompanyFilterOptions((prev) => ({
                        data: reset ? response.data.data : [...prev.data, ...response.data.data],
                        isLoading: false,
                    }));
                } else {
                    setNoOrgChartCompanyFilterHasMore(false);
                    setNoOrgChartCompanyFilterOptions((prev) => ({ ...prev, isLoading: false }));
                }
            } catch (error) {
                setNoOrgChartCompanyFilterOptions((prev) => ({ ...prev, isLoading: false }));
                console.error("Failed to fetch filter options for Companies Without Orgchart:", error);
                if (error?.response?.data?.message) {
                    message.error(error?.response?.data?.message);
                }
                message.error("Failed to fetch filter options!");
            }
        }, 300),
        [noOrgChartCompanyFilterPage]
    );

    const handleCompanyLinksChange = (event, newValues) => {
        setFilterQuery((prevFilterQuery) => {
            return {
                ...prevFilterQuery,
                company_link: [
                    ...(noOrgChartCompanyFilterSelectedOptions || []).map((item) => item.value),
                    ...newValues.map((item) => item.value),
                ],
            };
        });
        setCompanyFilterSelectedOptions(newValues);
    };
    const handleNoOrgChartCompanyLinksChange = (event, newValues) => {
        setFilterQuery((prevFilterQuery) => {
            return {
                ...prevFilterQuery,
                company_link: [...(companyFilterSelectedOptions || []).map((item) => item.value), ...newValues.map((item) => item.value)],
            };
        });
        setNoOrgChartCompanyFilterSelectedOptions(newValues);
    };

    const handleCompanyFilterInputChange = (event, value) => {
        setCompanyFilterInput(value);
        setCompanyFilterHasMore(true);
        fetchCompanyFilterOptions(true, value);
    };
    const handleNoOrgChartCompanyFilterInputChange = (event, value) => {
        setNoOrgChartCompanyFilterInput(value);
        setNoOrgChartCompanyFilterHasMore(true);
        fetchNoOrgChartCompanyFilterOptions(true, value);
    };
    const handleProfileStatusChange = (event) => {
        const newValues = event.target.value;
        setSelectedProfileStatus(newValues);
        setFilterQuery((prevFilterQuery) => {
            if (newValues.length === 0) {
                const { profile_status, ...rest } = prevFilterQuery;
                return rest;
            }
            return {
                ...prevFilterQuery,
                profile_status: newValues.map((item) => item),
            };
        });
        console.log(newValues);
    };

    return (
        <>
            <Box
                sx={(theme) => ({
                    position: "sticky",
                    top: theme.custom.top.level2,
                    zIndex: theme.zIndex.navbar,
                    height: theme.custom.heights.filterbar,
                    boxSizing: "border-box",
                    pl: 3,
                    pr: 3,
                    borderBlockEnd: theme.palette.border.primary,
                    backgroundColor: theme.palette.background.default,
                })}
            >
                <Box
                    sx={(theme) => ({
                        padding: "",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        pt: 1,
                        pb: 1,
                    })}
                >
                    <Box display={"flex"} alignItems={"center"}>
                        <Box sx={{ width: "20rem", pr: 2, borderRight: "1px solid grey" }}>
                            <PaginatedAutocomplete
                                onChange={handleCompanyLinksChange}
                                onInputChange={handleCompanyFilterInputChange}
                                selectedOptions={companyFilterSelectedOptions}
                                next={fetchCompanyFilterOptions}
                                options={companyFilterOptions.data}
                                input={companyFilterInput}
                                hasMore={companyFilterHasMore}
                                isLoading={companyFilterOptions.isLoading}
                                label={"Select Companies"}
                            />
                        </Box>
                        <FormControl sx={{ minWidth: "10rem", mr: 2, ml: 2 }}>
                            <InputLabel id="profile-status-filter">Profile Status</InputLabel>
                            <Select
                                multiple
                                labelId="profile-status-filter"
                                value={selectedProfileStatus}
                                label="Profile Status"
                                onChange={handleProfileStatusChange}
                            >
                                <MenuItem value={PROFILE_STATUS.UNPROCESSED}>Unverified</MenuItem>
                                <MenuItem value={PROFILE_STATUS.VERIFIED}>Verified</MenuItem>
                                <MenuItem value={PROFILE_STATUS.ERRORED}>Errored</MenuItem>
                            </Select>
                        </FormControl>
                        <Box sx={{ width: "20rem", pl: 2, borderLeft: "1px solid grey" }}>
                            <PaginatedAutocomplete
                                onChange={handleNoOrgChartCompanyLinksChange}
                                onInputChange={handleNoOrgChartCompanyFilterInputChange}
                                selectedOptions={noOrgChartCompanyFilterSelectedOptions}
                                next={fetchNoOrgChartCompanyFilterOptions}
                                options={noOrgChartCompanyFilterOptions.data}
                                input={noOrgChartCompanyFilterInput}
                                hasMore={noOrgChartCompanyFilterHasMore}
                                isLoading={noOrgChartCompanyFilterOptions.isLoading}
                                label={"Companies Without Orgchart"}
                            />
                        </Box>
                    </Box>
                    <CompletedTasksCard tasksCompletedForToday={tasksCompletedForToday} />
                </Box>
            </Box>
        </>
    );
};

export default FilterToolbar;
